import { getHierarchyRangeFilter } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/hierarchy-range-filter/index.tsx';
import { getIssueTypesByProjectId } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-types/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { StateProps } from './types.tsx';

export default createStructuredSelector<State, StateProps>({
	issueTypesByProjectId: getIssueTypesByProjectId,
	hierarchyRangeFilter: getHierarchyRangeFilter,
});
