import type { Grouping } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import type { Sorting, VisualisationsState } from './types.tsx';

export const RESET = 'state.domain.view-settings.visualisations.RESET' as const;

export const CHANGE_GROUP = 'state.domain.view-settings.visualisations.CHANGE_GROUP' as const;

export const CHANGE_SORT = 'state.domain.view-settings.visualisations.CHANGE_SORT' as const;

export const TOGGLE_EXPAND_GROUP =
	'state.domain.view-settings.visualisations.TOGGLE_EXPAND_GROUP' as const;

export const TOGGLE_SHOW_SPRINTS =
	'state.domain.view-settings.visualisations.TOGGLE_SHOW_SPRINTS' as const;

export const TOGGLE_SHOW_AGGREGATE =
	'state.domain.view-settings.visualisations.TOGGLE_SHOW_AGGREGATE' as const;

export type ChangeGroupActionPayload = {
	grouping: Grouping;
};

export type ChangeSortActionPayload = {
	sorting: Sorting;
};

export type ToggleExpandGroupActionPayload = {
	grouping: Grouping;
	groupName: string;
	expand: boolean;
};

export type ToggleShowSprintsActionPayload = {
	showSprints: boolean;
};

export type ToggleShowAggregatePayload = {
	showAggregate: boolean;
};

export type ResetAction = {
	type: typeof RESET;
	payload: VisualisationsState;
};

export type ChangeGroupAction = {
	type: typeof CHANGE_GROUP;
	payload: ChangeGroupActionPayload;
};

export type ChangeSortAction = {
	type: typeof CHANGE_SORT;
	payload: ChangeSortActionPayload;
};

export type ToggleExpandGroupAction = {
	type: typeof TOGGLE_EXPAND_GROUP;
	payload: ToggleExpandGroupActionPayload;
};

export type ToggleShowSprintsAction = {
	type: typeof TOGGLE_SHOW_SPRINTS;
	payload: ToggleShowSprintsActionPayload;
};

export type ToggleShowAggregateAction = {
	type: typeof TOGGLE_SHOW_AGGREGATE;
	payload: ToggleShowAggregatePayload;
};

export const reset = (payload: VisualisationsState): ResetAction => ({
	type: RESET,
	payload,
});

export const changeGroup = (payload: ChangeGroupActionPayload): ChangeGroupAction => ({
	type: CHANGE_GROUP,
	payload,
});

export const changeSort = (payload: ChangeSortActionPayload): ChangeSortAction => ({
	type: CHANGE_SORT,
	payload,
});

export const toggleExpandGroup = (
	payload: ToggleExpandGroupActionPayload,
): ToggleExpandGroupAction => ({
	type: TOGGLE_EXPAND_GROUP,
	payload,
});

export const toggleShowSprints = (
	payload: ToggleShowSprintsActionPayload,
): ToggleShowSprintsAction => ({
	type: TOGGLE_SHOW_SPRINTS,
	payload,
});

export const toggleShowAggregate = (
	payload: ToggleShowAggregatePayload,
): ToggleShowAggregateAction => ({
	type: TOGGLE_SHOW_AGGREGATE,
	payload,
});
