import * as R from 'ramda';
import {
	RESET,
	CHANGE,
	CLEAR,
	CLEAR_ALL,
	type ResetAction,
	type ChangeAction,
	type ClearAction,
	type ClearAllAction,
} from './actions.tsx';
import customFieldFilterReducer from './custom-field-filter/reducer.tsx';
import {
	CROSS_PROJECT_RELEASE_FILTER_ID,
	HIERARCHY_FILTER_ID,
	HIERARCHY_RANGE_FILTER_ID,
	CUSTOM_FIELD_FILTER_ID,
	type FiltersState,
	DATE_RANGE_FILTER_ID,
} from './types.tsx';

type Action = ResetAction | ChangeAction | ClearAction | ClearAllAction;

const initialState: FiltersState = {
	[CROSS_PROJECT_RELEASE_FILTER_ID]: {
		id: CROSS_PROJECT_RELEASE_FILTER_ID,
		value: [],
	},
	[DATE_RANGE_FILTER_ID]: {
		id: DATE_RANGE_FILTER_ID,
		value: {},
	},
};

const reducer = (state: FiltersState, action: Action): FiltersState => {
	switch (action.type) {
		case RESET: {
			return { ...action.payload };
		}

		case CHANGE: {
			const { id, value } = action.payload;
			return {
				...state,
				[id]: {
					id,
					value,
				},
			};
		}

		case CLEAR: {
			const id = action.payload;
			return {
				...state,
				[id]: R.merge(state[id] ?? {}, {
					value: initialState[id]?.value,
				}),
			};
		}

		case CLEAR_ALL: {
			return {
				...initialState,
				[HIERARCHY_FILTER_ID]: state[HIERARCHY_FILTER_ID],
				[HIERARCHY_RANGE_FILTER_ID]: state[HIERARCHY_RANGE_FILTER_ID],
			};
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const finalReducer = (state: FiltersState = initialState, action: any): FiltersState => ({
	...reducer(state, action),
	[CUSTOM_FIELD_FILTER_ID]: customFieldFilterReducer(state[CUSTOM_FIELD_FILTER_ID], action),
});

export default finalReducer;
