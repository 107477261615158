import React, { useMemo, useCallback, useState } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { fg } from '@atlassian/jira-feature-gating';
import {
	G300,
	Y300,
	N200A,
} from '@atlassian/jira-polaris-lib-color-palette/src/ui/colors/index.tsx';
import {
	DEFAULT,
	getPaletteEntryForMainColor,
} from '@atlassian/jira-polaris-lib-color-palette/src/ui/index.tsx';
import messages from './messages.tsx';

type Props = {
	value: number | undefined;
	mainColor?: string;
	getColorForValue?: (value: number | undefined) => string | undefined;
	onChange?: (arg1: number | undefined) => void;
	isInsideTooltip?: boolean;
};

type RatingNumber = 1 | 2 | 3 | 4 | 5;

type RatingElementProps = {
	value: number;
	selectedValue: number;
	label: string;
	mainColor?: string;
	onChange?: (arg1: number) => void;
	onHover?: (arg1: number | undefined) => void;
	isInsideTooltip?: boolean;
};

const RatingElement = ({
	value,
	selectedValue,
	label,
	onChange,
	onHover,
	mainColor,
	isInsideTooltip,
}: RatingElementProps) => {
	const onChangeCallback = useCallback(() => onChange?.(value), [onChange, value]);
	const selected = selectedValue >= value;

	return (
		<RatingElementContainer
			data-testid={`polaris-common.common.ui.rating.rating-element-container-${value}${
				selected ? '-selected' : ''
			}`}
			onClick={onChangeCallback}
			onMouseEnter={() => onHover?.(value)}
			onMouseLeave={() => onHover?.(undefined)}
			isEditable={!!onHover}
		>
			{isInsideTooltip && fg('polaris-pinned-fields-for-ideas-in-plans') ? (
				<RatingElementWrapper>
					<RatingElementInner
						data-testid="polaris-lib-rating.ui.rating-element-inner"
						isFilled={selected}
						mainColor={mainColor}
					/>
				</RatingElementWrapper>
			) : (
				<Tooltip content={label}>
					<RatingElementWrapper>
						<RatingElementInner
							data-testid="polaris-lib-rating.ui.rating-element-inner"
							isFilled={selected}
							mainColor={mainColor}
						/>
					</RatingElementWrapper>
				</Tooltip>
			)}
		</RatingElementContainer>
	);
};

export const Rating = ({
	value,
	onChange,
	mainColor,
	getColorForValue,
	isInsideTooltip,
}: Props) => {
	const { formatMessage } = useIntl();
	const [hoveringValue, setHoveringValue] = useState<number | undefined>(undefined);

	const selectedValue = useMemo(() => {
		if (hoveringValue !== undefined) {
			return hoveringValue;
		}
		if (value !== undefined) {
			return value;
		}
		return 0;
	}, [hoveringValue, value]);

	const selectedColor = useMemo(() => {
		const palette = getPaletteEntryForMainColor(mainColor);
		const color = getColorForValue ? getColorForValue(selectedValue) : palette.mainColor;
		// G300 is a default color for rating elements
		return color === DEFAULT.highlightColor ? G300 : color;
	}, [getColorForValue, selectedValue, mainColor]);

	const handleChange = useCallback(
		(input: number) => {
			// reset value if the same value is clicked
			const newValue = value === input ? undefined : input;
			onChange?.(newValue);
		},
		[onChange, value],
	);

	return (
		<RatingContainer>
			{[...Array(5).keys()].map((val) => {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				const elementValue = (val + 1) as RatingNumber;
				const label =
					formatMessage(messages[`impact${elementValue}`]) +
					(onChange && value === elementValue ? ` (${formatMessage(messages.clickToReset)})` : '');
				return (
					<RatingElement
						mainColor={selectedColor}
						key={elementValue}
						selectedValue={selectedValue}
						onChange={handleChange}
						value={elementValue}
						label={label}
						onHover={onChange && setHoveringValue}
						isInsideTooltip={isInsideTooltip}
					/>
				);
			})}
		</RatingContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RatingElementWrapper = styled.div({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	width: '12px',
	height: '12px',
	marginTop: token('space.025', '2px'),
	marginRight: token('space.025', '2px'),
	marginBottom: token('space.025', '2px'),
	marginLeft: token('space.025', '2px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RatingContainer = styled.div({
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RatingElementContainer = styled.div<{ isEditable: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	cursor: ({ isEditable }) => (isEditable ? 'pointer' : 'default'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RatingElementInner = styled.div<{
	isFilled: boolean;
	mainColor?: string;
}>({
	borderRadius: '6px',
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ isFilled, mainColor }) =>
		isFilled
			? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				`${mainColor !== undefined ? mainColor : Y300}`
			: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				`${token('color.background.inverse.subtle', N200A)}`,
	transition: 'all 200ms linear',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ isFilled }) => (isFilled ? '12px' : '4px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ isFilled }) => (isFilled ? '12px' : '4px'),
});
