import React, { useCallback, useState } from 'react';
import ChevronDownIcon from '@atlaskit/icon/utility/migration/chevron-down';
import { Popup, type ContentProps, type TriggerProps } from '@atlaskit/popup'; // ignore-for-ENGHEALTH-17759
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { FormattedMessage } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import HoverObserver from '@atlassian/jira-portfolio-3-common/src/hover-observer/index.tsx';
import ScrollLock from '@atlassian/jira-portfolio-3-common/src/scroll-lock/index.tsx';
import ChangeIndicator from '@atlassian/jira-portfolio-3-portfolio/src/common/view/change-indicator/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

const DependenciesCell = (props: Props) => {
	const {
		renderDialogContent,
		isChanged,
		isOptimizedMode,
		messages,
		isDialogLocked,
		externalIssueLinks,
		internalIssueLinks,
		isReadOnly,
	} = props;

	const [isHovered, setIsHovered] = useState(false);
	const [isOpen, setIsOpen] = useState(false);

	const onToggle = useCallback(() => {
		setIsOpen(!isOpen);
	}, [isOpen]);

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const onHoverChanged = useCallback((isHovered: boolean) => {
		setIsHovered(isHovered);
	}, []);

	const onClose = useCallback(() => {
		if (isDialogLocked) return;
		setIsOpen(false);
	}, [isDialogLocked]);

	const getIssueLinksCount = useCallback(
		() => externalIssueLinks.length + internalIssueLinks.length,
		[externalIssueLinks.length, internalIssueLinks.length],
	);

	const renderIssueCount = useCallback(
		(triggerProps: Partial<TriggerProps>) => (
			<div
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={styles.issuesCount}
				onClick={onToggle}
				tabIndex={0}
				role="button"
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				onKeyDown={() => {}}
				{...triggerProps}
			>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.issuesLabel}>
					<FormattedMessage {...messages.issues} values={{ count: getIssueLinksCount() }} />
				</div>

				{(isHovered || isOpen) &&
					(isVisualRefreshEnabled() ? (
						<Box xcss={iconContainerStyle}>
							<ChevronDownIcon label="" color={token('color.icon')} />
						</Box>
					) : (
						<ChevronDownIcon label="" LEGACY_size="large" color={token('color.icon')} />
					))}
			</div>
		),
		[getIssueLinksCount, isHovered, isOpen, messages.issues, onToggle],
	);

	const renderAddDependency = useCallback(
		(triggerProps: Partial<TriggerProps>) =>
			!isReadOnly && (isHovered || isOpen) ? (
				<Button appearance="link" onClick={onToggle} {...triggerProps}>
					<FormattedMessage {...messages.addDependency} />
				</Button>
			) : null,
		[isHovered, isOpen, isReadOnly, messages.addDependency, onToggle],
	);

	const renderTrigger = useCallback(
		({ ref, ...triggerProps }: TriggerProps) => (
			<HoverObserver onHoverChanged={onHoverChanged}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div ref={ref} className={styles.container}>
					{getIssueLinksCount() > 0
						? renderIssueCount(triggerProps)
						: renderAddDependency(triggerProps)}
					{isChanged && !isOptimizedMode ? <ChangeIndicator /> : null}
				</div>
			</HoverObserver>
		),
		[
			getIssueLinksCount,
			isChanged,
			isOptimizedMode,
			onHoverChanged,
			renderAddDependency,
			renderIssueCount,
		],
	);

	const renderContent = useCallback(
		({ update }: ContentProps) => (
			<Box xcss={dialogStyles}>
				{renderDialogContent && renderDialogContent(update)}
				<ScrollLock />
			</Box>
		),
		[renderDialogContent],
	);

	return (
		<Popup
			isOpen={isOpen}
			onClose={onClose}
			trigger={renderTrigger}
			content={renderContent}
			placement="bottom-start"
			shouldUseCaptureOnOutsideClick
		/>
	);
};

const dialogStyles = xcss({
	maxWidth: '800px',
	maxHeight: '400px',
	paddingTop: 'space.200',
	paddingBottom: 'space.200',
	paddingLeft: 'space.300',
	paddingRight: 'space.300',
	boxSizing: 'border-box',
});

const iconContainerStyle = xcss({
	paddingTop: '0',
	paddingBottom: '0',
	paddingLeft: 'space.100',
	paddingRight: 'space.100',
});

export default DependenciesCell;
