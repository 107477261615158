import {
	createSelector,
	createStructuredSelector,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { getIssuePriorities } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-priorities/index.tsx';
import type { IssuePriorities } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issue-priorities/types.tsx';

import type { MapStateToProps, Priority, OwnProps } from './types.tsx';

const getPriorities = createSelector(
	[getIssuePriorities, (_: State, { value }: OwnProps) => value],
	(issuePriorities: IssuePriorities, value: string[]): Priority[] => {
		const counts = value.reduce((acc: Record<string, number>, curr: string) => {
			acc[curr] = (acc[curr] || 0) + 1;
			return acc;
		}, {});

		const priorities = Object.keys(counts)
			.map((priorityId) => ({
				id: priorityId,
				name: issuePriorities[priorityId].name,
				iconUrl: issuePriorities[priorityId].iconUrl,
				sequence: issuePriorities[priorityId].sequence,
				count: counts[priorityId],
			}))
			.sort((a, b) => a.sequence - b.sequence);

		return priorities;
	},
);

const mapStateToProps: MapStateToProps = createStructuredSelector({
	priorities: getPriorities,
});

export default mapStateToProps;
