import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	emptyPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.component-filter.empty-placeholder',
		defaultMessage: 'All',
		description: 'This is a placeholder for no filter set',
	},
	searchComponentsPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.component-filter.search-components-placeholder',
		defaultMessage: 'Show issues from components',
		description: 'This is a placeholder for search',
	},
	searchComponentsLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.component-filter.search-components-label',
		defaultMessage: 'Choose from components',
		description: 'Aria label for components search filed that appears on activating Filters button',
	},
	searchComponentsPlaceholderIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.component-filter.search-components-placeholder-issue-term-refresh',
		defaultMessage: 'Show work items from components',
		description: 'This is a placeholder for search',
	},
});
