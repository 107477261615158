import React from 'react';
import Lozenge, { type ThemeAppearance } from '@atlaskit/lozenge';
import { fg } from '@atlassian/jira-feature-gating';
import { ISSUE_STATUS_CATEGORIES } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import FlyoutContent from '../../common/ui/flyout-content.tsx';
import messages from '../../common/ui/messages.tsx';
import type { Props } from './types.tsx';

const appearances = {
	[ISSUE_STATUS_CATEGORIES.TODO]: 'default',
	[ISSUE_STATUS_CATEGORIES.INPROGRESS]: 'inprogress',
	[ISSUE_STATUS_CATEGORIES.DONE]: 'success',
};

const StatusFlyout = (props: Props) => {
	const { statuses, value } = props;

	const label = fg('jira-issue-terminology-refresh-m3')
		? messages.issueCountLabelIssueTermRefresh
		: messages.issueCountLabel;

	const content = statuses.map((status) => ({
		title: (
			<Lozenge
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				appearance={appearances[status.categoryId] as ThemeAppearance}
				key={status.id}
			>
				{status.name}
			</Lozenge>
		),
		count: status.count,
	}));

	return (
		<FlyoutContent
			header={messages.statusTitle}
			content={content}
			label={label}
			total={value.length}
			testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.status-cell.status-flyout"
		/>
	);
};

export default StatusFlyout;
