import React, { type ComponentPropsWithRef, type ElementRef, useRef } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { Field } from '@atlaskit/form';
import { xcss, Box } from '@atlaskit/primitives';
import { Radio } from '@atlaskit/radio';
import { useIntl } from '@atlassian/jira-intl';
import type { CustomDateRange } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/types.tsx';
import TextField from '@atlassian/jira-portfolio-3-common/src/textfield/index.tsx';
import { DelayedAnnouncer } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/common/delayed-announcer/index.tsx';
import CustomTimelineRange from '../../custom-timeline-range/view.tsx';
import type { ExportPngTimelineWidthOptions } from '../types.tsx';
import { EXPORT_PNG_TIMELINE_WIDTH_OPTIONS } from '../util.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';

type Props = {
	isDisabled: boolean;
	selection: ExportPngTimelineWidthOptions;
	setSelection: (type: ExportPngTimelineWidthOptions, analyticsEvent: UIAnalyticsEvent) => void;
	customWidth: string;
	setCustomWidth: (width: string) => void;
	customDateRange: CustomDateRange;
	setCustomDateRange: (range: CustomDateRange) => void;
};

const ExportTimelineSettings = ({
	isDisabled,
	selection,
	setSelection,
	customWidth,
	setCustomWidth,
	customDateRange,
	setCustomDateRange,
}: Props) => {
	const { formatMessage } = useIntl();

	const announcerRef = useRef<ElementRef<typeof DelayedAnnouncer>>(null);

	const createSelectionSetter =
		(type: ExportPngTimelineWidthOptions): ComponentPropsWithRef<typeof Radio>['onChange'] =>
		(_, analyticsEvent) =>
			setSelection(type, analyticsEvent);

	const timelineSettingsContainer = xcss({
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'wrap',
		gap: 'space.100',
	});

	const timelineSettingsField = xcss({
		marginTop: 'space.negative.075',
		gap: 'space.025',
	});

	const timelineWidthContainer = xcss({
		display: 'flex',
		alignItems: 'center',
		gap: 'space.100',
	});

	const customTextBoxContainer = xcss({
		alignItems: 'center',
		caretColor: 'color.icon.accent.gray.subtle',
		display: 'flex',
		marginLeft: 'space.100',
	});

	const customTextBoxContainerLabel = xcss({
		marginLeft: 'space.050',
		color: 'color.text.subtle',
		font: 'font.body.large',
	});

	return (
		<>
			{/* eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage */}
			<Box xcss={timelineSettingsContainer}>
				{/* eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage */}
				<Box xcss={timelineSettingsField}>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<fieldset className={styles.timelineSettingsFieldset}>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<legend className={styles.timelineSettingsLegend}>
							{formatMessage(messages.widthLabel)}
						</legend>
						{/* eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage */}
						<Box xcss={timelineWidthContainer}>
							<Radio
								testId="portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.png-export.timeline-settings.small-timeline"
								isDisabled={isDisabled}
								isChecked={selection === EXPORT_PNG_TIMELINE_WIDTH_OPTIONS.SMALL}
								onChange={createSelectionSetter(EXPORT_PNG_TIMELINE_WIDTH_OPTIONS.SMALL)}
								label={formatMessage(messages.smallLabel)}
							/>
							<Radio
								testId="portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.png-export.timeline-settings.medium-timeline"
								isDisabled={isDisabled}
								isChecked={selection === EXPORT_PNG_TIMELINE_WIDTH_OPTIONS.MEDIUM}
								onChange={createSelectionSetter(EXPORT_PNG_TIMELINE_WIDTH_OPTIONS.MEDIUM)}
								label={formatMessage(messages.mediumLabel)}
							/>
							<Radio
								testId="portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.png-export.timeline-settings.large-timeline"
								isDisabled={isDisabled}
								isChecked={selection === EXPORT_PNG_TIMELINE_WIDTH_OPTIONS.LARGE}
								onChange={createSelectionSetter(EXPORT_PNG_TIMELINE_WIDTH_OPTIONS.LARGE)}
								label={formatMessage(messages.largeLabel)}
							/>
							<Radio
								testId="portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.png-export.timeline-settings.custom-timeline"
								isDisabled={isDisabled}
								isChecked={selection === EXPORT_PNG_TIMELINE_WIDTH_OPTIONS.CUSTOM}
								onChange={createSelectionSetter(EXPORT_PNG_TIMELINE_WIDTH_OPTIONS.CUSTOM)}
								label={formatMessage(messages.customLabel)}
							/>
							{/* eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage */}
							<Box xcss={customTextBoxContainer}>
								<TextField
									testId="portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.png-export.timeline-settings.custom-timeline-width"
									isCompact
									isDisabled={selection !== EXPORT_PNG_TIMELINE_WIDTH_OPTIONS.CUSTOM || isDisabled}
									type="number"
									value={customWidth}
									min={400}
									max={5000}
									onChange={(e) => {
										setCustomWidth(e.target.value);
										announcerRef.current?.announce();
									}}
									width="80"
									aria-label={formatMessage(messages.customWidthPixelLabelText)}
								/>
								<DelayedAnnouncer ref={announcerRef} label={customWidth} />
								{/* eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage */}
								<Box xcss={customTextBoxContainerLabel}>
									{formatMessage(messages.customWidthPixelLabel)}
								</Box>
							</Box>
						</Box>
					</fieldset>
				</Box>
				{/* eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage */}
				<Box xcss={timelineSettingsField}>
					<Field
						label={formatMessage(messages.timelineRangeLabel)}
						name="timelineRange"
						defaultValue=""
					>
						{({ fieldProps }) => (
							<CustomTimelineRange
								fieldProps={fieldProps}
								customDateRange={customDateRange}
								onCustomDatesChange={setCustomDateRange}
								isDisabled={selection === EXPORT_PNG_TIMELINE_WIDTH_OPTIONS.NONE || isDisabled}
							/>
						)}
					</Field>
				</Box>
			</Box>
		</>
	);
};

export default ExportTimelineSettings;
