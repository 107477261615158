import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	sprintDatesDetail: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.sprint-flyout.flyout.sprint-dates-detail',
		defaultMessage: 'Detail of the sprint dates',
		description: 'Label for the sprint dates icon',
	},
	dateRange: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.sprint-flyout.flyout.date-range',
		defaultMessage: '<p>{startTime}</p> <p>- {endTime}</p>',
		description: 'Date and time range for the sprint',
	},
	sprintPreview: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.sprint-flyout.flyout.sprint-preview',
		defaultMessage: 'Sprint preview - {name}',
		description: 'Accessible label for the sprint preview dialog.',
	},
	kanbanIterationPreview: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.sprint-flyout.flyout.kanban-iteration-preview',
		defaultMessage: 'Capacity preview - {name}',
		description: 'Accessible label for the iteration preview dialog.',
	},
});
