import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	numberOfTeams: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.estimate-cell.sum-time-estimate-tooltip',
		defaultMessage: '{count, plural, one {# team} other {# teams}}',
		description:
			'Text to show the count of the total number of teams associated with a group of issues.',
	},
});
