import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	emptyPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.reporter-filter.empty-placeholder',
		defaultMessage: 'All',
		description: 'This is a placeholder for no filter set',
	},
	searchReporterPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.reporter-filter.search-reporter-placeholder',
		defaultMessage: 'Show issues from reporters',
		description: 'This is a placeholder for search',
	},
	searchReporterLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.reporter-filter.search-reporter-label',
		defaultMessage: 'Choose from reporters',
		description: 'Aria label for reporters search filed that appears on activating Filters button',
	},
	searchReporterPlaceholderIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.reporter-filter.search-reporter-placeholder-issue-term-refresh',
		defaultMessage: 'Show work items from reporters',
		description: 'This is a placeholder for search',
	},
});
