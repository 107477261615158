import { isConfluenceMacroProxyForIdeas } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app/index.tsx';
import { getCustomFieldsWithFilteringAllowed } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/custom-field-filter/index.tsx';
import { getFilterCount } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/index.tsx';
import { isAtlasConnectInstalled } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/system/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { StateProps } from './types.tsx';

export default createStructuredSelector<State, {}, StateProps>({
	customFieldsWithFilteringAllowed: getCustomFieldsWithFilteringAllowed,
	filtersAppliedCount: getFilterCount,
	isAtlasConnectInstalled,
	isConfluenceMacroProxyMode: isConfluenceMacroProxyForIdeas,
});
