import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	interactionLayerLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.warnings.issue-link.interaction-layer-label',
		defaultMessage: '{issueKey} {issueSummary}. Use the enter key to highlight the issue.',
		description: 'aria-label for when a warnings issue card is focused.',
	},
	interactionLayerLabelIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.warnings.issue-link.interaction-layer-label-issue-term-refresh',
		defaultMessage: '{issueKey} {issueSummary}. Use the enter key to highlight the work item.',
		description: 'aria-label for when a warnings issue card is focused.',
	},
});
