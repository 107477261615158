import { getIssuesWithOriginalsAndOptimizedByGroup } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/index.tsx';
import type { IssuesByGroup } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/types.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import {
	createSelector,
	createStructuredSelector,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import {
	getDateConfiguration,
	getPlanningUnit,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/plan/index.tsx';
import { getStartDateCustomField } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/custom-fields/index.tsx';
import type { DateConfiguration } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/plan/types.tsx';
import type { CustomField } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/custom-fields/types.tsx';
import { PlanningUnits } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import type {
	OwnProps,
	AggregateValuesMap,
	MapStateToProps,
	FieldKey,
	AggregateField,
} from './types.tsx';

import {
	aggregateStartDate,
	aggregateDueDate,
	aggregateTargetStart,
	aggregateTargetEnd,
	aggregateStatus,
	aggregateStoryPoints,
	aggregateTimeEstimate,
	aggregateAssignee,
	aggregatePriority,
} from './utils.tsx';

export const getAggregatedValuesMapPure = (
	issuesByGroup: IssuesByGroup,
	groupName: string,
	dateConfiguration: DateConfiguration,
	startDateCustomField: CustomField | undefined,
): AggregateValuesMap | undefined => {
	const group = issuesByGroup[groupName];

	if (!group?.issues) return undefined;

	const issues = Array.from(group.issues.values());

	const status = aggregateStatus(issues);

	return {
		startDate: aggregateStartDate(issues, dateConfiguration, startDateCustomField),
		dueDate: aggregateDueDate(issues, dateConfiguration),
		targetStart: aggregateTargetStart(issues, dateConfiguration),
		targetEnd: aggregateTargetEnd(issues, dateConfiguration),
		storyPoints: aggregateStoryPoints(issues),
		timeEstimate: aggregateTimeEstimate(issues),
		assignee: aggregateAssignee(issues),
		priority: aggregatePriority(issues),
		status,
		breakdown: { ...status, fieldKey: 'breakdown' },
	};
};

const getAggregatedValuesMap = createSelector(
	[
		getIssuesWithOriginalsAndOptimizedByGroup,
		(_: State, { groupName }: OwnProps) => groupName,
		getDateConfiguration,
		getStartDateCustomField,
	],
	getAggregatedValuesMapPure,
);

// Map the field key to the aggregate field key.
const getFieldKey = createSelector(
	[getStartDateCustomField, getPlanningUnit, (_: State, { id }: OwnProps) => id],
	(startDateCustomField, planningUnit, id): FieldKey | undefined => {
		switch (id) {
			case startDateCustomField?.id.toString():
				return 'startDate';
			case 'estimate':
				return planningUnit === PlanningUnits.storyPoints ? 'storyPoints' : 'timeEstimate';
			case 'issueStatus':
				return 'status';
			case 'startDate':
			case 'dueDate':
			case 'targetStart':
			case 'targetEnd':
			case 'storyPoints':
			case 'timeEstimate':
			case 'assignee':
			case 'breakdown':
			case 'priority':
				return id;
			default:
				return undefined;
		}
	},
);

const getField = createSelector(
	[getAggregatedValuesMap, getFieldKey],
	(valuesMap, fieldKey): AggregateField | undefined => valuesMap && fieldKey && valuesMap[fieldKey],
);

const mapStateToProps: MapStateToProps = createStructuredSelector({
	field: getField,
});

export default mapStateToProps;
