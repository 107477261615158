import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	// Lozenge labels...
	createdLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.created-label',
		defaultMessage: 'CREATED',
		description: 'The label used on a lozenge to indicate that an item was created',
	},
	deletedLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.deleted-label',
		defaultMessage: 'DELETED',
		description: 'The label used on a lozenge to indicate that an item was deleted',
	},
	updatedLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.updated-label',
		defaultMessage: 'UPDATED',
		description: 'The label used on a lozenge to indicate that an item was updated',
	},
	excludedLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.excluded-label',
		defaultMessage: 'REMOVED',
		description: 'The label used on a lozenge to indicate that an item was excluded',
	},
	addedLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.added-label',
		defaultMessage: 'ADDED',
		description: 'The label used on a lozenge to indicate that an existing item is added',
	},
	// What changed labels...
	changeCount: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.change-count',
		defaultMessage: '{count, plural, one {{count} field} other {{count} fields}}',
		description: 'A label indicating the number of fields that changed, where count is the number',
	},
	description: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.description',
		defaultMessage: 'Description',
		description: 'The label indicating the description has changed',
	},
	end: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.end',
		defaultMessage: 'Release date',
		description: 'This label indicates a change in a release date of a release.',
	},
	fixVersions: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.fix-versions',
		defaultMessage: 'Release',
		description: 'The label indicating the assigned releases have changed',
	},
	lexoRank: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.lexo-rank',
		defaultMessage: 'Rank',
		description: 'The label indicating the issue has been re-ranked',
	},
	parent: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.parent',
		defaultMessage: 'Parent',
		description: 'The label indicating the issue has been moved to a new parent',
	},
	priority: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.priority',
		defaultMessage: 'Priority',
		description: 'The label indicating the issue priority has changed',
	},
	project: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.project',
		defaultMessage: 'Project',
		description: 'The label indicating the issue has been moved to a new project',
	},
	sprint: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.sprint',
		defaultMessage: 'Sprint',
		description: 'The label indicating the issue has been moved to a new project',
	},
	start: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.start',
		defaultMessage: 'Start date',
		description: 'This label indicates a change in a start date of a release.',
	},
	storyPoints: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.story-points',
		defaultMessage: 'Story points',
		description: 'The label indicating the issue has assigned a new story point estimate',
	},
	summary: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.summary',
		defaultMessage: 'Summary',
		description: 'The label indicating the issue has been given a new summary',
	},
	targetEnd: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.target-end',
		defaultMessage: 'Target end',
		description: 'The label indicating the target end date has changed',
	},
	targetStart: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.target-start',
		defaultMessage: 'Target start',
		description: 'The label indicating the target start date has changed',
	},
	timeEstimateDays: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.time-estimate-days',
		defaultMessage: 'Estimate (d)',
		description: 'The label indicating the issue has assigned a new time estimate (in days)',
	},
	timeEstimateHours: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.time-estimate-hours',
		defaultMessage: 'Estimate (h)',
		description: 'The label indicating the issue has assigned a new time estimate (in hours)',
	},
	team: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.team',
		defaultMessage: 'Team',
		description: 'The label indicating the issue has been assigned to a team',
	},
	velocity: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.velocity',
		defaultMessage: 'Velocity',
		description: 'The label indicating the velocity has been assigned to a team',
	},
	plannedCapacity: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.planned-capacity',
		defaultMessage: 'Sprint velocity',
		description: 'The label indicating the velocity has changed in a sprint',
	},
	iterationVelocity: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.iteration-velocity',
		defaultMessage: 'Iteration velocity',
		description: 'The label indicating the velocity has changed in a kanban iteration',
	},
	iterationLength: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.iteration-length',
		defaultMessage: 'Iteration length',
		description: 'The label indicating the iteration length has been assigned to a team',
	},
	issueSource: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.issue-source',
		defaultMessage: 'Issue source',
		description: 'The label indicating the issue source to a team',
	},
	removedMember: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.removed-member',
		defaultMessage: 'Removed member',
		description: 'The label indicating the removed member from a team',
	},
	addedMember: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.added-member',
		defaultMessage: 'Added member',
		description: 'The label indicating the added member from a team',
	},
	weeklyHours: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.weekly-hours',
		defaultMessage: 'Weekly hours',
		description: 'The label indicating the weekly hours of a team member',
	},
	schedulingMode: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.scheduling-mode',
		defaultMessage: 'Scheduling mode',
		description: 'The label indicating the scheduling mode of a team',
	},
	title: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.title',
		defaultMessage: 'Title',
		description: 'The label indicating the team title',
	},
	exceptions: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.exceptions',
		defaultMessage: 'Exceptions',
		description: 'The label indicating that the changes relate to exceptions of a resource',
	},
	issueLinkCreated: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.issue-link-created',
		defaultMessage: 'Dependency created',
		description: 'The label indicating the dependency has been created',
	},
	issueLinkRemoved: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.issue-link-removed',
		defaultMessage: 'Dependency removed',
		description: 'The label indicating the dependency has been removed',
	},
	ideasChanged: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.ideas-changed',
		defaultMessage: 'Ideas',
		description: 'The label indicating ideas associated with the issue have changes',
	},
	components: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.components',
		defaultMessage: 'Components',
		description: 'The label indicating that the components have changes',
	},
	addedComponents: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.added-components',
		defaultMessage: 'Components added',
		description: 'The label indicating the change is a component addition',
	},
	removedComponents: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.removed-components',
		defaultMessage: 'Components removed',
		description: 'The label indicating the change is a component removal',
	},
	addedLabels: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.added-labels',
		defaultMessage: 'Labels added',
		description: 'The label indicating the change is a label addition',
	},
	removedLabels: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.removed-labels',
		defaultMessage: 'Labels removed',
		description: 'The label indicating the change is a label removal',
	},
	labels: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.labels',
		defaultMessage: 'Labels',
		description: 'The label indicating the change is labels',
	},
	customFieldAdded: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.custom-field-added',
		defaultMessage: '{field} added',
		description: 'The label indicating the change is a custom-field value addition',
	},
	customFieldRemoved: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.custom-field-removed',
		defaultMessage: '{field} removed',
		description: 'The label indicating the change is a custom-field value removal',
	},
	goals: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.goals',
		defaultMessage: 'Goals',
		description: 'The label indicating the change is goals',
	},
	issueSourceIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed.issue-source-issue-term-refresh',
		defaultMessage: 'Work source',
		description: 'The label indicating the issue source to a team',
	},
});
