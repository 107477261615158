import {
	createSelector,
	createStructuredSelector,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { IssueStatusesById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-statuses/types.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { getIssuesStatusBreakdown } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/util/issue-helper.tsx';
import { getIssueStatusById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-statuses/index.tsx';
import type { OwnProps, MapStateToProps } from './types.tsx';

const getCategoryIds = createSelector(
	[getIssueStatusById, (_: State, { value }: OwnProps) => value],
	(issueStatuses: IssueStatusesById, value: string[]): Record<string | number, number> => {
		const statuses = value.map((statusId) => ({ status: statusId }));
		const breakdown = getIssuesStatusBreakdown(statuses, issueStatuses);
		return breakdown.byCategoryId;
	},
);

const mapStateToProps: MapStateToProps = createStructuredSelector({
	byCategoryId: getCategoryIds,
});

export default mapStateToProps;
