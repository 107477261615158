import type { Effect } from 'redux-saga';
import * as R from 'ramda';
import { fork, takeEvery, put, call, select } from 'redux-saga/effects';
import { fireErrorAnalytics } from '@atlassian/jira-portfolio-3-portfolio/src/common/error/index.tsx';
import type * as Api from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import fetch from '@atlassian/jira-portfolio-3-portfolio/src/common/fetch/index.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import {
	ERROR_REPORTING_TEAM,
	PACKAGE_NAME,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import appUrls from '@atlassian/jira-portfolio-3-portfolio/src/common/view/utils/urls.tsx';
import { redirect } from '@atlassian/jira-portfolio-3-portfolio/src/common/window/index.tsx';
import {
	type UpdateAutoSchedulerEnabledAction,
	type UpdateAutoSchedulerEnabledPayload,
	update,
	UPDATE_AUTO_SCHEDULER_ENABLED,
} from '../../state/domain/plan/actions.tsx';
import type { State } from '../../state/types.tsx';
import {
	ENABLE_DELETE,
	DISABLE_DELETE,
} from '../../state/ui/top/title-bar/plan-settings/actions.tsx';
import { POST, DELETE, GET } from '../api.tsx';
import { getBacklog } from '../backlog/index.tsx';
import { toErrorID } from '../util.tsx';
import {
	transformUpdatePayload,
	urls,
	type UpdatePlanPayload,
	type ToggleAutoSchedulerPayload,
} from './api.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { UpdatePlanPayload } from './api';

const DELETE_PLAN = 'command.plan.DELETE_PLAN' as const;
export const UPDATE_PLAN = 'command.plan.UPDATE_PLAN' as const;
const UPDATE_INCLUDED_COMPLETED_ISSUES_FOR_DAYS =
	'command.plan.UPDATE_INCLUDED_COMPLETED_ISSUES_FOR_DAYS' as const;

export type UpdatePlan = {
	type: string;
	payload: UpdatePlanPayload;
	onComplete?: () => void;
};
export type UpdateIncludeCompletedIssuesForDaysPayload = {
	days: number;
};
export type UpdateIncludeCompletedIssuesForDaysAction = {
	type: typeof UPDATE_INCLUDED_COMPLETED_ISSUES_FOR_DAYS;
	payload: UpdateIncludeCompletedIssuesForDaysPayload;
};
export const updateIncludeCompletedIssuesForDays = (
	payload: UpdateIncludeCompletedIssuesForDaysPayload,
) => ({
	type: UPDATE_INCLUDED_COMPLETED_ISSUES_FOR_DAYS,
	payload,
});
export const deletePlan = () => ({
	type: DELETE_PLAN,
	payload: {},
});

export const updatePlan = (payload: UpdatePlanPayload, onComplete?: () => void): UpdatePlan => ({
	type: UPDATE_PLAN,
	payload,
	onComplete,
});

export function* doUpdatePlan(
	{
		payload,
		onComplete,
	}: {
		payload: UpdatePlanPayload;
		onComplete?: () => void;
	}, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	const {
		domain: {
			plan: { id, autoScheduleConfiguration },
		},
	}: State = yield select(R.identity);
	if (!id) {
		throw new Error('No plan to update');
	}
	let updatePayload: UpdatePlanPayload = payload;
	if (isDefined(updatePayload.autoScheduleConfiguration)) {
		updatePayload = R.merge(updatePayload, {
			autoScheduleConfiguration: R.merge(
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				autoScheduleConfiguration!,
				updatePayload.autoScheduleConfiguration,
			),
		});
	}
	yield put(update(updatePayload));

	const url = urls.update(id);
	const body = transformUpdatePayload(updatePayload);
	const response = yield call(fetch, url, {
		method: POST,
		body,
	});

	if (!response.ok) {
		const error = new Error(yield call(response.text.bind(response)));
		fireErrorAnalytics({
			error,
			meta: {
				id: toErrorID(error, 'do-update-plan-fetch-failed'),
				packageName: PACKAGE_NAME,
				teamName: ERROR_REPORTING_TEAM,
			},
			sendToPrivacyUnsafeSplunk: true,
		});
	}

	if (onComplete) onComplete();
}

export function* doUpdateAutoSchedulerEnabled(
	{
		payload,
	}: {
		payload: UpdateAutoSchedulerEnabledPayload;
	}, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	const {
		domain: {
			plan: { id },
		},
	}: State = yield select(R.identity);

	if (id == null) return;

	// Need to upload autoSchedulerEnabled seperately, different api...
	const body: ToggleAutoSchedulerPayload = {
		planId: id,
		enabled: payload.autoSchedulerEnabled,
	};

	const res = yield call(fetch, urls.toggleAutoSchedule(), {
		method: POST,
		body,
	});

	if (!res.ok) {
		const error = new Error(yield call(res.text.bind(res)));
		fireErrorAnalytics({
			error,
			meta: {
				id: toErrorID(error, 'do-update-auto-scheduler-enabled-fetch-failed'),
				packageName: PACKAGE_NAME,
				teamName: ERROR_REPORTING_TEAM,
			},
			sendToPrivacyUnsafeSplunk: true,
		});
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doDeletePlan(): Generator<Effect, any, any> {
	const {
		domain: {
			app: { browserHistory },
			plan: { id },
		},
	}: State = yield select(R.identity);
	if (!id) {
		throw new Error('No plan to delete');
	}
	try {
		yield put({ type: DISABLE_DELETE });
		const url = urls.delete(id);
		const response = yield call(fetch, url, {
			method: DELETE,
		});
		if (response.ok) {
			if (isDefined(browserHistory)) {
				yield call(browserHistory.push, appUrls.managePortfolio);
			} else {
				yield call(redirect, appUrls.jiraHome);
			}
		} else {
			const error = new Error(yield call(response.text.bind(response)));
			fireErrorAnalytics({
				error,
				meta: {
					id: toErrorID(error, 'do-delete-plan-fetch-failed'),
					packageName: PACKAGE_NAME,
					teamName: ERROR_REPORTING_TEAM,
				},
				sendToPrivacyUnsafeSplunk: true,
			});
		}
	} finally {
		yield put({ type: ENABLE_DELETE });
	}
}
function* doUpdateIncludeCompletedIssuesForDays({
	payload: { days }, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: UpdateIncludeCompletedIssuesForDaysAction): Generator<Effect, any, any> {
	yield call(doUpdatePlan, { payload: { includeCompletedIssuesFor: days } });
	yield call(getBacklog);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* getPlanInfo(): Generator<Effect, Api.PlanInfo | null | undefined, any> {
	const {
		domain: {
			plan: { id, currentScenarioId },
		},
	}: State = yield select(R.identity);

	// Cancel if there is no plan Id, or scenario Id, this can happen when the chain of sagas are still running but the app has already navigated away from the page
	if (id == null || !currentScenarioId) return;

	const url = urls.get(id, currentScenarioId);
	const response = yield call(fetch, url, { method: GET });
	if (response.ok) {
		return yield call(response.json.bind(response));
	}
	if (!response.ok) {
		const error = new Error(yield call(response.text.bind(response)));
		fireErrorAnalytics({
			error,
			meta: {
				id: toErrorID(error, 'do-plan-info-fetch-failed'),
				packageName: PACKAGE_NAME,
				teamName: ERROR_REPORTING_TEAM,
			},
			sendToPrivacyUnsafeSplunk: true,
		});
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchDeletePlan(): Generator<Effect, any, any> {
	yield takeEvery(DELETE_PLAN, doDeletePlan);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchUpdatePlan(): Generator<Effect, any, any> {
	yield takeEvery<UpdatePlan>(UPDATE_PLAN, doUpdatePlan);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchUpdateAutoSchedulerEnabled(): Generator<Effect, any, any> {
	yield takeEvery<UpdateAutoSchedulerEnabledAction>(
		UPDATE_AUTO_SCHEDULER_ENABLED,
		doUpdateAutoSchedulerEnabled,
	);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchUpdateIncludeCompletedIssuesForDays(): Generator<Effect, any, any> {
	yield takeEvery(UPDATE_INCLUDED_COMPLETED_ISSUES_FOR_DAYS, doUpdateIncludeCompletedIssuesForDays);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default function* (): Generator<Effect, any, any> {
	yield fork(watchDeletePlan);
	yield fork(watchUpdatePlan);
	yield fork(watchUpdateIncludeCompletedIssuesForDays);
	yield fork(watchUpdateAutoSchedulerEnabled);
}
