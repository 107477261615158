import React from 'react';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import type { Props } from './types.tsx';
import { useOffsets } from './utils.tsx';

export default function MarkerBadgePosition({
	containerWidth,
	startOrEnd,
	issueBarPosition,
	active,
	children,
}: Props) {
	const offsets = useOffsets({
		containerWidth: containerWidth ?? 0,
		width: isVisualRefreshEnabled() ? 24 : 16,
		startOrEnd,
		issueBar: issueBarPosition,
	});

	if (offsets == null) {
		return null;
	}

	const { offsetActive, offsetInactive } = offsets;
	const translateX = active ? offsetActive - offsetInactive : 0;
	const left = offsetInactive;

	return <>{children({ left, translateX })}</>;
}
