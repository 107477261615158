// eslint-disable-next-line jira/restricted/ramda -- PLEASE FIX - ENABLING FLAT LINT CONFIG
import * as R from 'ramda';
import { fg } from '@atlassian/jira-feature-gating';
import {
	ISSUE_HIERARCHY_LEVEL_BASE,
	ISSUE_HIERARCHY_LEVEL_EPIC,
	ISSUE_HIERARCHY_LEVEL_INITIATIVE,
	ISSUE_HIERARCHY_LEVEL_SUBTASK,
} from '@atlassian/jira-issue-type-hierarchies/src/index.tsx';
import {
	VIEW_MODES,
	type ViewMode,
} from '@atlassian/jira-portfolio-3-common/src/common/types/view-mode.tsx';
import type { HierarchyLevel } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import {
	type Grouping,
	ASCENDING,
	GROUPING,
	SPRINT_STATES,
	STRING,
	TIMELINE_MODES,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { getPresetViewNames } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/preset-view-names/index.tsx';
import type { PlanType } from '../../state/domain/plan/types.tsx';
import type { Project } from '../../state/domain/projects/types.tsx';
import type { Sprint } from '../../state/domain/sprints/types.tsx';
import type {
	ColourByOption,
	ColourMaps,
} from '../../state/domain/view-settings/colour-by/types.tsx';
import type { ColumnIdV0 } from '../../state/domain/view-settings/field-columns/types.tsx';
import {
	HIERARCHY_FILTER_ID,
	HIERARCHY_RANGE_FILTER_ID,
	DEPENDENCIES_FILTER_ID,
	CUSTOM_FIELD_FILTER_ID,
	SPRINT_FILTER_ID,
} from '../../state/domain/view-settings/filters/types.tsx';
import type { ViewSettingsState } from '../../state/domain/view-settings/types.tsx';
import type { State } from '../../state/types.tsx';
import { getHierarchyLevels } from '../hierarchy/index.tsx';
import { getFirstParentIssueAndNestedDescendant } from '../issues/index.tsx';
import { getDateConfiguration, getIsSamplePlan, getPlanType } from '../plan/index.tsx';
import { getProjects } from '../projects/index.tsx';
import { getSprints } from '../sprints/index.tsx';
import { getTeams } from '../teams/index.tsx';
import { get3mthTimeScale } from '../timeline/index.tsx';
import { getColourMaps } from '../visualisations/index.tsx';

const DEFAULT_DEPENDENCY_VIEW_SETTINGS = 'lines';

const makePreset = (params: {
	groupBy: ViewSettingsState['visualisationsV1']['grouping'];
	colourBy: ColourByOption;
	colourMaps: ColourMaps;
	visibleFields: string[];
	visibleListFields: string[];
	timelineViewport: ViewSettingsState['timeScaleV1']['selection'];
	mode: ViewSettingsState['timeScaleV1']['mode'];
	filters: ViewSettingsState['filtersV1'];
	expandedItems: Partial<
		Record<
			Grouping,
			{
				[key: string]: boolean;
			}
		>
	>;
	rollupSettings?: ViewSettingsState['rollupSettingsV0'];
	viewMode: ViewMode;
	hierarchyLevels: HierarchyLevel[];
	defaultExpandedIssueIds: String[];
}): ViewSettingsState => {
	const {
		groupBy,
		expandedItems,
		colourBy,
		colourMaps,
		visibleFields,
		visibleListFields,
		timelineViewport,
		filters,
		mode,
		rollupSettings,
		viewMode,
		hierarchyLevels,
		defaultExpandedIssueIds,
	} = params;

	const { start: fromDate, end: toDate } = get3mthTimeScale(new Date());
	const maxHierarchyLevel = Math.max(
		...hierarchyLevels.map((hierarchyLevel) => hierarchyLevel.level),
	);
	const allLevelsExceptHighestAndSubtasksExpandedByDefault = hierarchyLevels.reduce<
		Record<string, boolean>
	>((acc, { level }) => {
		if (level >= ISSUE_HIERARCHY_LEVEL_BASE && level !== maxHierarchyLevel) {
			acc[`IssuesWithoutParent-${level}`] = true;
		}
		return acc;
	}, {});

	// Expanding the first parent and all its children
	const expandIssueIds = defaultExpandedIssueIds.reduce<Record<string, boolean>>((acc, id) => {
		acc[`${id}`] = true;
		return acc;
	}, {});

	return {
		dependencySettingsV0: {
			display: DEFAULT_DEPENDENCY_VIEW_SETTINGS,
		},
		visualisationsV1: {
			sorting: {
				field: 'lexoRank',
				type: STRING,
				direction: ASCENDING,
			},
			grouping: groupBy,
			expandedItems: R.mergeDeepRight(
				{
					ASSIGNEE: {},
					COMPONENT: {},
					LABEL: {},
					NONE: {},
					PROJECT: {},
					RELEASE: {},
					SPRINT: {},
					TEAM: {},
				},
				expandedItems,
			),
			showSprints: true,
			showAggregate: true,
		},
		colourByV2: {
			colourByValue: colourBy,
			colourMaps: R.mergeDeepRight(
				{
					none: {},
					project: {},
					status: {},
					team: {},
				},
				colourMaps,
			),
			colourComponents: [],
			colourIssueTypes: [],
			colourIssueTypeKeys: [],
			colourLabels: [],
			colourPriorities: [],
			colourSelects: {},
			lastUsed: 4,
		},
		filtersV1: {
			[CUSTOM_FIELD_FILTER_ID]: {
				id: CUSTOM_FIELD_FILTER_ID,
				value: {},
			},
			...filters,
		},
		timeScaleV1: {
			selection: timelineViewport,
			customDateRange: {
				fromDate,
				toDate,
			},
			mode,
		},
		issueExpansionsV0: {
			isExpanded: {
				IssuesWithoutParentHeader: true,
				...allLevelsExceptHighestAndSubtasksExpandedByDefault,
				...expandIssueIds,
			},
		},
		fieldColumnsV0: {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			columns: visibleFields.map((field) => ({ id: field as ColumnIdV0, isVisible: true })),
		},
		listFieldColumnsV0: {
			columns: visibleListFields.map((field) => ({
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				id: field as ColumnIdV0,
				isVisible: true,
			})),
		},
		filterOptionsV1: {
			includeAncestors: true,
			includeDescendants: true,
		},
		componentGroupsV0: [
			{
				id: 'all_other_issues',
				name: '',
				isExpanded: false,
				components: [],
			},
		],
		labelGroupsV0: [
			{
				id: 'all_other_issues',
				name: '',
				isExpanded: false,
				labels: [],
			},
		],
		customFieldValuesGroupsV0: {},
		highlightedVersionsV0: {
			versions: [],
		},
		rollupSettingsV0: {
			showingRolledUpDate: true,
			showRolledUpOthers: true,
			...rollupSettings,
		},
		warningSettingsV1: {
			showWarning: false,
			disabledWarnings: [],
		},
		dateConstraintsV0: {
			showDateConstraints: false,
		},
		viewModeV0: {
			mode: viewMode,
		},
	};
};

// This function is used to find out the maximum and minimum levels of issues in a plan
// It returns an object including "start" and "end" values used to preset the hierarchy filter as follows:
// "Hierarchy" <start> "To" <end>
// e.g. if start = 2 and end = 1 then Hierarchy: "Epic" to "Story"
// e.g. if start = 1 and end = 0 then Hierarchy: "Story" to "Sub-task"
// If there are no issues in the plan, the maximum and minimum levels of the hierarchy range are used
const getMinMaxIssuesLevels = (state: State) => {
	const { issues, hierarchy } = state.domain;
	// finding out the maximum and minimum level of issues in the plan (if any)
	if (issues.length) {
		const issuesLevels = R.map(R.prop('level'))(issues);
		return {
			start: R.apply(Math.max, issuesLevels),
			end: R.apply(Math.min, issuesLevels),
		};
	}
	// finding out the maximum and minimum levels in the hierarchy range
	// {level: SUB_TASK_LEVEL, title: "Sub-task"}, {level: STORY_LEVEL, title: "Story"}, {level: EPIC_LEVEL, title: "Epic"} and so on
	const hierarchyLevels = R.map(R.prop('level'))(hierarchy.levels);
	return {
		start: R.apply(Math.max, hierarchyLevels),
		end: R.apply(Math.min, hierarchyLevels),
	};
};

const getFutureSprints = (sprints: ReturnType<(state: State) => Array<Sprint>>) =>
	sprints.filter(({ state }) => state === SPRINT_STATES.FUTURE).map(({ id }) => id);

const getProjectExpansions = (projects: ReturnType<(state: State) => Array<Project>>) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	projects.reduce<Record<string, any>>(
		(acc, { id }) =>
			Object.assign(acc, {
				[`project-${id}`]: true,
			}),
		{},
	);

const getPresetsPure = (
	state: State,
	colourMaps: ReturnType<(state: State) => ColourMaps>,
	projects: ReturnType<(state: State) => Array<Project>>,
	sprints: ReturnType<(state: State) => Array<Sprint>>,
	planType: PlanType,
) => {
	const dateConfiguration = getDateConfiguration(state);
	const isCrossTeam = planType === 'CROSS_TEAM';
	const isCrossTeamPlanning = planType === 'CROSS_TEAM_PLANNING';
	const isSamplePlan = getIsSamplePlan(state);
	const hierarchyLevels = getHierarchyLevels(state);
	const defaultExpandedIssueIds = fg('new-plan-default-expand-first-parent')
		? getFirstParentIssueAndNestedDescendant(state)
		: [];

	const teamExpansions = (() => {
		const teams = getTeams(state);
		return teams
			.map(({ id }) => id)
			.reduce<{ [key: string]: boolean }>((acc, id) => {
				acc[`team-${id}`] = true;
				return acc;
			}, {});
	})();

	const getBasicFilters = () => {
		return {
			[HIERARCHY_FILTER_ID]: {
				id: HIERARCHY_FILTER_ID,
				value: isCrossTeam
					? { start: 3, end: 0 }
					: {
							start: getMinMaxIssuesLevels(state).start + 1,
							end: getMinMaxIssuesLevels(state).end + 1,
						},
			},
			[HIERARCHY_RANGE_FILTER_ID]: {
				id: HIERARCHY_RANGE_FILTER_ID,
				value: isCrossTeam
					? {
							start: ISSUE_HIERARCHY_LEVEL_INITIATIVE,
							end: ISSUE_HIERARCHY_LEVEL_SUBTASK,
						}
					: getMinMaxIssuesLevels(state),
			},
		};
	};
	// JPOS-4428 - adding a new default preset view named "Basic"
	const basic = makePreset({
		groupBy: GROUPING.NONE,
		expandedItems: {},
		colourBy: 'status',
		colourMaps,
		visibleFields: ['issueStatus'],
		visibleListFields: [
			'priority',
			dateConfiguration.baselineStartField.key,
			dateConfiguration.baselineEndField.key,
			'issueStatus',
			'progressByEstimation',
			'assignee',
		],
		timelineViewport: '3mth',
		mode: TIMELINE_MODES.MONTHS,
		viewMode: VIEW_MODES.LIST,
		filters: getBasicFilters(),
		...(isCrossTeam && {
			rollupSettings: {
				showingRolledUpDate: true,
				showRolledUpOthers: true,
			},
		}),
		hierarchyLevels,
		defaultExpandedIssueIds,
	});

	const getSprintCapacityManagementFilters = () => {
		if (isSamplePlan) {
			return {
				filters: {
					[HIERARCHY_FILTER_ID]: {
						id: HIERARCHY_FILTER_ID,
						value: {
							start: 2,
							end: 0,
						},
					},
					[HIERARCHY_RANGE_FILTER_ID]: {
						id: HIERARCHY_RANGE_FILTER_ID,
						value: {
							start: ISSUE_HIERARCHY_LEVEL_EPIC,
							end: ISSUE_HIERARCHY_LEVEL_SUBTASK,
						},
					},
				},
				rollupSettings: {
					showingRolledUpDate: true,
					showRolledUpOthers: false,
				},
			};
		}
		if (isCrossTeamPlanning) {
			return {
				filters: {
					[SPRINT_FILTER_ID]: {
						id: SPRINT_FILTER_ID,
						value: getFutureSprints(sprints),
					},
					[HIERARCHY_FILTER_ID]: {
						id: HIERARCHY_FILTER_ID,
						value: {
							start: 2,
							end: 2,
						},
					},
					[HIERARCHY_RANGE_FILTER_ID]: {
						id: HIERARCHY_RANGE_FILTER_ID,
						value: {
							start: ISSUE_HIERARCHY_LEVEL_EPIC,
							end: ISSUE_HIERARCHY_LEVEL_EPIC,
						},
					},
				},
			};
		}

		return {
			filters: {
				[SPRINT_FILTER_ID]: {
					id: SPRINT_FILTER_ID,
					value: getFutureSprints(sprints),
				},
				[HIERARCHY_FILTER_ID]: {
					id: HIERARCHY_FILTER_ID,
					value: {
						start: 1,
						end: 1,
					},
				},
				[HIERARCHY_RANGE_FILTER_ID]: {
					id: HIERARCHY_RANGE_FILTER_ID,
					value: {
						start: ISSUE_HIERARCHY_LEVEL_BASE,
						end: ISSUE_HIERARCHY_LEVEL_BASE,
					},
				},
			},
		};
	};

	const sprintCapacityManagement = makePreset({
		groupBy: GROUPING.TEAM,
		expandedItems: {
			TEAM: {
				UNDEFINED: true,
				...teamExpansions,
			},
		},
		colourBy: 'status',
		colourMaps,
		visibleFields: ['assignee', ...(fg('sprint_capacity_view_default_refactor') ? ['team'] : [])],
		visibleListFields: ['priority', 'sprint', 'estimate', 'team', 'release', 'assignee'],
		timelineViewport: '3mth',
		mode: TIMELINE_MODES.MONTHS,
		viewMode: fg('sprint_capacity_view_default_refactor') ? VIEW_MODES.TIMELINE : VIEW_MODES.LIST,
		hierarchyLevels,
		...getSprintCapacityManagementFilters(),
		defaultExpandedIssueIds,
	});

	const topLevelPlanning = makePreset({
		groupBy: GROUPING.PROJECT,
		expandedItems: {
			PROJECT: getProjectExpansions(projects),
		},
		colourBy: 'team',
		colourMaps,
		visibleFields: ['team'],
		visibleListFields: [
			'breakdown',
			'priority',
			dateConfiguration.baselineStartField.key,
			dateConfiguration.baselineEndField.key,
			'team',
			'release',
			'components',
		],
		timelineViewport: '1yr',
		mode: TIMELINE_MODES.QUARTERS,
		viewMode: VIEW_MODES.LIST,
		filters: {
			[HIERARCHY_FILTER_ID]: {
				id: HIERARCHY_FILTER_ID,
				value: {
					start: 9,
					end: 1,
				},
			},
			[HIERARCHY_RANGE_FILTER_ID]: {
				id: HIERARCHY_RANGE_FILTER_ID,
				value: {
					start: ISSUE_HIERARCHY_LEVEL_BASE + 8,
					end: ISSUE_HIERARCHY_LEVEL_BASE,
				},
			},
		},
		hierarchyLevels,
		defaultExpandedIssueIds,
	});

	const dependencyManagement = makePreset({
		groupBy: GROUPING.NONE,
		expandedItems: {},
		colourBy: 'status',
		colourMaps,
		visibleFields: ['team'],
		visibleListFields: [
			'incomingDependencies',
			'outgoingDependencies',
			'priority',
			dateConfiguration.baselineStartField.key,
			dateConfiguration.baselineEndField.key,
			'team',
		],
		timelineViewport: '1yr',
		mode: TIMELINE_MODES.QUARTERS,
		viewMode: VIEW_MODES.LIST,
		filters: {
			[DEPENDENCIES_FILTER_ID]: {
				id: DEPENDENCIES_FILTER_ID,
				value: {
					type: 'on',
				},
			},
			[HIERARCHY_FILTER_ID]: {
				id: HIERARCHY_FILTER_ID,
				value: {
					start: 2,
					end: 0,
				},
			},
			[HIERARCHY_RANGE_FILTER_ID]: {
				id: HIERARCHY_RANGE_FILTER_ID,
				value: {
					start: ISSUE_HIERARCHY_LEVEL_EPIC,
					end: ISSUE_HIERARCHY_LEVEL_SUBTASK,
				},
			},
		},
		hierarchyLevels,
		defaultExpandedIssueIds,
	});

	const presetNames = getPresetViewNames();

	return {
		[presetNames.BASIC]: basic,
		[presetNames.SPRINT_CAPACITY_MANAGEMENT]: sprintCapacityManagement,
		[presetNames.TOP_LEVEL_PLANNING]: topLevelPlanning,
		[presetNames.DEPENDENCY_MANAGEMENT]: dependencyManagement,
	};
};

const getPresets = createSelector(
	[R.identity, getColourMaps, getProjects, getSprints, getPlanType],
	getPresetsPure,
);

export default getPresets;
