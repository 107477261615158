import React, { Component, createRef, useCallback } from 'react';
import { withAnalyticsEvents } from '@atlaskit/analytics-next';
import LegacyButton from '@atlaskit/button';
import Button, { IconButton } from '@atlaskit/button/new';
import WarningIconMigration from '@atlaskit/icon/core/migration/warning';
import WarningIcon from '@atlaskit/icon/core/warning';
import Popup, { type TriggerProps } from '@atlaskit/popup'; // ignore-for-ENGHEALTH-17759
import { Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedMessage, injectIntl } from '@atlassian/jira-intl';
import colors from '@atlassian/jira-portfolio-3-common/src/colors/index.tsx';
import { isEmbedSmartLinkView } from '@atlassian/jira-portfolio-3-common/src/window/index.tsx';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types.tsx';
import {
	READONLY,
	REPORT,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/app/types.tsx';
import { WARNING_TYPE_NUMBER_MAP } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/warnings/types.tsx';
import {
	ContextualAnalyticsData,
	INLINE_DIALOG,
	fireUIAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import Content from './content/index.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props, InnerProps } from './types.tsx';

// eslint-disable-next-line jira/react/no-class-components
class WarningCenter extends Component<
	InnerProps,
	{
		isOpen: boolean;
		typeId: string | null;
	}
> {
	state = {
		isOpen: false,
		typeId: null,
	};

	buttonRef = createRef<HTMLButtonElement>();

	toggleWarningCenter = () => {
		if (this.state.isOpen) {
			this.setState({ isOpen: false }, () => {
				!this.state.isOpen && this.buttonRef.current?.focus();
			});
			return;
		}
		this.setState({ isOpen: true });
		const { createAnalyticsEvent = null } = this.props;
		if (!createAnalyticsEvent) return;
		const [actionSubject, action] = PRODUCT_ANALYTICS_EVENT_NAMES.OPENED_WARNING_CENTER.split(' ');
		const analyticsEvent = createAnalyticsEvent({ action, actionSubject });
		fireUIAnalytics(analyticsEvent);
	};

	onClose = () => {
		this.setState({ isOpen: false }, () => {
			!this.state.isOpen && this.buttonRef.current?.focus();
		});
	};

	handleTypeIdChange = (typeId: string | null) => {
		this.setState({ typeId });
		if (typeof typeId !== 'string') {
			return;
		}
		const { createAnalyticsEvent = null } = this.props;
		if (!createAnalyticsEvent) return;
		const [actionSubject, action] = PRODUCT_ANALYTICS_EVENT_NAMES.FILTERED_WARNING_TYPE.split(' ');
		const analyticsEvent = createAnalyticsEvent({ action, actionSubject });
		const warningType = WARNING_TYPE_NUMBER_MAP[typeId] || null;
		fireUIAnalytics(analyticsEvent, {
			warningType,
		});
	};

	renderTriggerButton = (triggerProps?: TriggerProps) => {
		const { intl, mode, showWarning } = this.props;
		const { ref, ...restTriggerProps } = triggerProps || { ref: null };
		const shouldRenderIconButton = fg('smart_links_for_plans')
			? isEmbedSmartLinkView() || (mode !== REPORT && mode !== READONLY)
			: mode !== REPORT && mode !== READONLY;

		const WarningIconComponent = useCallback(
			() =>
				isVisualRefreshEnabled() ? (
					<WarningIconMigration
						label=""
						color={showWarning ? token('color.icon.warning') : 'currentColor'}
					/>
				) : (
					<WarningIcon label="" color={showWarning ? token('color.icon.warning') : undefined} />
				),
			[showWarning],
		);

		return (
			<div
				data-testid="portfolio-3-portfolio.app-simple-plans.top.title-bar.warnings.warning-centre-button"
				ref={ref}
			>
				{isVisualRefreshEnabled() ? (
					<>
						{shouldRenderIconButton ? (
							<IconButton
								{...restTriggerProps}
								label={intl.formatMessage(messages.warningsButtonLabel)}
								onClick={this.toggleWarningCenter}
								ref={this.buttonRef}
								icon={WarningIconComponent}
								isTooltipDisabled={false}
								testId="portfolio-3-portfolio.app-simple-plans.top.title-bar.warnings.custom-theme-button"
							/>
						) : (
							/* in REPORT and READONLY mode, the Warnings button is always displayed with labels since there is enough space */
							<Button
								{...restTriggerProps}
								appearance="default"
								onClick={this.toggleWarningCenter}
								ref={this.buttonRef}
								iconBefore={showWarning ? WarningIconComponent : undefined}
							>
								<Text color={showWarning ? 'color.text.accent.orange' : undefined} weight="medium">
									{intl.formatMessage(messages.warningsButtonLabel)}
								</Text>
							</Button>
						)}
					</>
				) : (
					/* in REPORT and READONLY mode, the Warnings button is always displayed with labels since there is enough space */
					<>
						{mode !== REPORT && mode !== READONLY && (
							/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */
							<div className={styles.warningsButtonIconOnly}>
								<Tooltip content={<FormattedMessage {...messages.warningsButtonLabel} />}>
									<LegacyButton
										appearance={showWarning ? 'warning' : 'default'}
										{...restTriggerProps}
										ref={this.buttonRef}
										onClick={this.toggleWarningCenter}
										iconBefore={
											<WarningIconMigration
												label={intl.formatMessage(messages.warningsButtonLabel)}
												color={showWarning ? token('color.icon.warning') : undefined}
												LEGACY_primaryColor={
													showWarning
														? token('color.icon.warning', colors.Y300)
														: token('color.icon.subtle', colors.N200)
												}
												spacing="spacious"
												testId="portfolio-3-portfolio.app-simple-plans.top.title-bar.warnings.custom-theme-button"
											/>
										}
									/>
								</Tooltip>
							</div>
						)}
						<div
							/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */
							className={mode !== REPORT && mode !== READONLY ? styles.warningsButtonIconLabel : ''}
						>
							<LegacyButton
								appearance={showWarning ? 'warning' : 'default'}
								{...restTriggerProps}
								ref={this.buttonRef}
								onClick={this.toggleWarningCenter}
								iconBefore={
									showWarning ? (
										<WarningIconMigration
											label=""
											color={token('color.icon.warning')}
											LEGACY_primaryColor={token('color.icon.warning', colors.Y300)}
										/>
									) : undefined
								}
							>
								<FormattedMessage {...messages.warningsButtonLabel} />
							</LegacyButton>
						</div>
					</>
				)}
			</div>
		);
	};

	render() {
		const {
			warnings,
			showWarning,
			toggleWarningByType,
			disabledWarnings,

			issuesById,
			setSearchQuery,
			issueTypesById,
			projectsById,
			toggleShowWarning,
		} = this.props;

		return (
			<Popup
				content={({ setInitialFocusRef }) => (
					<Content
						focusRef={setInitialFocusRef}
						disabledWarnings={disabledWarnings}
						issueTypesById={issueTypesById}
						issuesById={issuesById}
						onTypeIdChange={this.handleTypeIdChange}
						projectsById={projectsById}
						setSearchQuery={setSearchQuery}
						showWarning={showWarning}
						toggleShowWarning={toggleShowWarning}
						toggleWarningByType={toggleWarningByType}
						typeId={this.state.typeId}
						warnings={warnings}
					/>
				)}
				isOpen={this.state.isOpen}
				trigger={this.renderTriggerButton}
				shouldRenderToParent
				role="dialog"
				onClose={this.onClose}
				placement="bottom-end"
			/>
		);
	}
}

const WarningCenterWithIntl = injectIntl(withAnalyticsEvents()(WarningCenter));

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (props: Props) => (
	<ContextualAnalyticsData sourceName="warningsCenter" sourceType={INLINE_DIALOG}>
		<WarningCenterWithIntl {...props} />
	</ContextualAnalyticsData>
);
