import type { Effect } from 'redux-saga';
import * as R from 'ramda';
import { call, put, select } from 'redux-saga/effects';
import { fg } from '@atlassian/jira-feature-gating';
import {
	ARJ_EARLY_PRELOAD_PREFIX,
	monitor,
} from '@atlassian/jira-portfolio-3-common/src/analytics/performance.tsx';
import { extractStartTime } from '@atlassian/jira-portfolio-3-common/src/analytics/utils.tsx';
import type { Backlog } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import { fireErrorAnalytics } from '@atlassian/jira-portfolio-3-portfolio/src/common/error/index.tsx';
import fetch from '@atlassian/jira-portfolio-3-portfolio/src/common/fetch/index.tsx';
import {
	ERROR_REPORTING_TEAM,
	PACKAGE_NAME,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { resetBacklog, afterResetBacklog } from '../../state/domain/actions.tsx';
import { setAdditionalTeams } from '../../state/domain/teams-additional/actions.tsx';
import { prepareTeam } from '../../state/domain/util.tsx';
import type { State } from '../../state/types.tsx';
import { POST } from '../api.tsx';
import batch from '../batch/index.tsx';
import { toErrorID } from '../util.tsx';
import { calculateStatusOfAllReleases } from '../versions/index.tsx';
import { calculateWarnings } from '../warnings/index.tsx';
import { urlsOld, backlogBody, urls } from './api.tsx';
import type { Marks } from './types.tsx';

const defaultHeaders = {
	'Content-Type': 'application/json',
	Accept: 'application/json',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* getBacklog(isInitialLoad = false): Generator<Effect, any, any> {
	const {
		domain: {
			plan: { id, currentScenarioId, includeCompletedIssuesFor },
		},
	}: State = yield select(R.identity);
	const url = fg('jsis_issue_search_for_initial_backlog_load')
		? urls(isInitialLoad).backlog
		: urlsOld.backlog;
	let response: Response;
	let body;

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const preloadedRequest = window.jpoPreloadedRequests && window.jpoPreloadedRequests.backlog;
	if (isInitialLoad && preloadedRequest) {
		body = preloadedRequest.request.body;
		response = yield preloadedRequest.request;

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		delete window.jpoPreloadedRequests.backlog;
	} else {
		body = yield call(backlogBody, {
			id,
			currentScenarioId,
			includeCompletedIssuesFor,
		});
		response = yield call(fetch, url, {
			method: POST,
			body,
			profile: url,
		});
	}
	if (response.ok) {
		yield* batch(function* () {
			const backlog: Backlog = yield call(response.json.bind(response));
			yield put(resetBacklog(backlog));
			const teams = backlog.teams?.map(prepareTeam) ?? [];
			yield put(setAdditionalTeams(teams));
			yield put(afterResetBacklog());
			yield put(calculateWarnings());
			yield put(calculateStatusOfAllReleases());
		});
	} else {
		const error = new Error(yield call(response.text.bind(response)));
		fireErrorAnalytics({
			error,
			meta: {
				id: toErrorID(error, 'backlog-fetch-failed'),
				packageName: PACKAGE_NAME,
				teamName: ERROR_REPORTING_TEAM,
			},
			sendToPrivacyUnsafeSplunk: true,
			// We're monitoring backlog endpoint in SFX and Splunk. No need to also send to Sentry.
			skipSentry: true,
		});
	}
}

function* fetchBacklogUrl(
	url: string,
	planId: number,
	scenarioId: number,
	isInitialLoad: boolean,
	marks: Marks,
	headers: Record<string, string> = defaultHeaders, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect | Promise<Response>, any, any> {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const earlyPreloadBacklogPromise = window.__ARJ_BACKLOG_PROMISE__;

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	delete window.__ARJ_BACKLOG_PROMISE__;
	if (isInitialLoad && earlyPreloadBacklogPromise) {
		const response: Response = yield earlyPreloadBacklogPromise;

		/**
		 * Recording the performance information of the API before returning
		 * the response.
		 */
		const start = Math.floor(extractStartTime(marks.startMark));
		const end = Math.ceil(extractStartTime(marks.endMark));
		monitor.recordCustomProfile(url, {
			start,
			end,
		});

		return response;
	}

	const body = yield call(backlogBody, {
		id: planId,
		currentScenarioId: scenarioId,
	});
	return yield call(fetch, url, {
		method: POST,
		body,
		profile: url,
		headers,
	});
}

export function* fetchBacklog(
	isInitialLoad = false,
	planId: number,
	scenarioId: number, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect | Promise<Response>, any, any> {
	const url = fg('jsis_issue_search_for_initial_backlog_load')
		? urls(isInitialLoad).backlog
		: urlsOld.backlog;
	return yield* fetchBacklogUrl(url, planId, scenarioId, isInitialLoad, {
		startMark: `${ARJ_EARLY_PRELOAD_PREFIX}.backlog:start`,
		endMark: `${ARJ_EARLY_PRELOAD_PREFIX}.backlog:end`,
	});
}
