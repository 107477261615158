import { useEffect } from 'react';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { getIsIssuesLoading } from '../../query/issues/index.tsx';
import store from '../../state/store.tsx';
import type { Props } from './types.tsx';
import { useReduxStateCache } from './utils.tsx';

export const ReduxStoreLifecycle = ({
	locale,
	initialLoad,
	restoreReduxStore,
	resetReduxStore,
}: Props) => {
	const [cachedState, cacheState] = useReduxStateCache();
	useEffect(() => {
		if (cachedState) {
			try {
				if (!cachedState.domain.plan.id) {
					log.safeErrorWithoutCustomerData(
						'plans.plan-id-not-available', // Using this name for easier querying
						'Plan id is not available while restoring Redux store state',
						{
							loading: getIsIssuesLoading(cachedState),
						},
					);
				}
			} catch (err) {
				// not necessary
			}
			restoreReduxStore(cachedState);
		} else {
			initialLoad({ locale });
		}
	}, [cachedState, initialLoad, locale, restoreReduxStore]);

	useEffect(
		() => () => {
			const state = store.getState();
			// Only cache when the plan is done loading
			cacheState(getIsIssuesLoading(state) ? undefined : state);
			try {
				if (!state.domain.plan.id) {
					log.safeErrorWithoutCustomerData(
						'plans.plan-id-not-available', // Using this name for easier querying
						'Plan id is not available while caching Redux store state',
						{
							loading: getIsIssuesLoading(state),
						},
					);
				}
			} catch (err) {
				// not necessary
			}
		},
		[cacheState],
	);

	// Reset the store on unmount to ensure we don't have data
	// of the current plan when switching into another plan.
	useEffect(
		() => () => {
			resetReduxStore();
		},
		[resetReduxStore],
	);

	return null;
};
