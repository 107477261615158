import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noIssueSources: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.capacities.no-issue-sources',
		defaultMessage: 'Associate this team with an issue source to view sprints',
		description:
			'Text to show in place of sprints when the team is not associated with an issue source.',
	},
	daysUnit: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.capacities.days-unit',
		defaultMessage: '{count, plural, other {# d}}',
		description: 'Label for days unit (abbreviated)',
	},
	hoursUnit: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.capacities.hours-unit',
		defaultMessage: '{count, plural, other {# h}}',
		description: 'Label for hours unit (abbreviated)',
	},
	noIssueSourcesIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.capacities.no-issue-sources-issue-term-refresh',
		defaultMessage: 'Associate this team with a work source to view sprints',
		description:
			'Text to show in place of sprints when the team is not associated with an issue source.',
	},
});
