import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	level: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issues-without-parent.level',
		defaultMessage: '{levelTitle} – {issuesCount, plural, one {# issue} other {# issues}}',
		description: 'Count of root issues without parent on the given level',
	},
	levelIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issues-without-parent.level-issue-term-refresh',
		defaultMessage: '{levelTitle} – {issuesCount, plural, one {# work item} other {# work items}}',
		description: 'Count of root issues without parent on the given level',
	},
});
