import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	flagMessageTitlePart: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-rank.info-message.flag-message-title-part',
		defaultMessage: 'When viewed in a hierarchy',
		description:
			'First constant part of message that indicates how many issues will appear lower than their actual rank',
	},
	flagMessageLowerPositionPart: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-rank.info-message.flag-message-lower-position-part',
		defaultMessage:
			'{issuesWithLowerPosition} will appear to be ranked lower than their actual rank.',
		description:
			'First constant part of message that indicates how many issues will appear lower than their actual rank',
	},
	flagMessageHigherPositionPart: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-rank.info-message.flag-message-higher-position-part',
		defaultMessage:
			'{issuesWithHigherPosition} will appear to be ranked higher than their actual rank.',
		description:
			'First constant part of message that indicates how many issues will appear lower than their actual rank',
	},
	linkLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-rank.info-message.link-label',
		defaultMessage: '{issueCount, plural, one {# issue} other {# issues}}',
		description: 'Label for link',
	},
	setRankInfoMessageCloseIconLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-rank.info-message.close-icon-label',
		defaultMessage: 'Set rank info message close icon',
		description: 'Label for set rank info message editor close icon',
	},
	linkLabelIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-rank.info-message.link-label-issue-term-refresh',
		defaultMessage: '{issueCount, plural, one {# work item} other {# work items}}',
		description: 'Label for link',
	},
});
