import type { Effect } from 'redux-saga';
import * as R from 'ramda';
import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { fireErrorAnalytics } from '@atlassian/jira-portfolio-3-portfolio/src/common/error/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { monitor } from '@atlassian/jira-portfolio-3-common/src/analytics/performance.tsx';
import type * as Api from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import fetch from '@atlassian/jira-portfolio-3-portfolio/src/common/fetch/index.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import type { IssueId } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/index.tsx';
import {
	ENTITY,
	SCENARIO_ISSUE_ID_PREFIX,
	PERFORMANCE_KEYS,
	PACKAGE_NAME,
	ERROR_REPORTING_TEAM,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import type { AnalyticsEventMeta } from '../../analytics/types.tsx';
import { getAssigneeList } from '../../query/assignees/index.tsx';
import {
	getOriginalCrossProjectVersions,
	getRemovedCrossProjectVersionsById,
} from '../../query/cross-project-versions/index.tsx';
import {
	getIssueLinks,
	getIssueLinkOriginals,
	getIssueLinkChangesData,
	getIssueLinkChangesDataPure,
	type IssueLinksDataMap,
} from '../../query/issue-links/index.tsx';
import {
	getOriginalIssues,
	getIssueChangesMetaData,
	toApiIssue,
} from '../../query/issues/index.tsx';
import { getIssueMapById, getScenarioRemovedIssueMapById } from '../../query/raw-issues/index.tsx';
import { getOriginalResources } from '../../query/resource/index.tsx';
import {
	getSprints,
	getPlannedCapacityChanges,
	getOriginalPlannedCapacities,
} from '../../query/sprints/index.tsx';
import type { PlannedCapacityChange } from '../../query/sprints/types.tsx';
import { getSprintById } from '../../query/sprints/utils.tsx';
import { getOriginalTeams } from '../../query/teams/index.tsx';
import {
	getFilteredReviewChanges,
	getReviewChanges,
	getIsOutOfSync,
	getIsSaving,
	getIsReverting,
	getIsSavingInterrupted,
	getIsRevertingInterrupted,
	getSelectedChanges,
	getSortedByHierarchySelectedIssues,
	getSelectedChangesCount,
	getAllChangesMetaData,
} from '../../query/update-jira/index.tsx';
import {
	getVersionsById,
	getRemovedVersionsById,
	getOriginalVersions,
} from '../../query/versions/index.tsx';
import * as domainActions from '../../state/domain/actions.tsx';
import * as crossProjectVersionsActions from '../../state/domain/cross-project-versions/actions.tsx';
import type { IssueLinks } from '../../state/domain/issue-links/types.tsx';
import {
	bulkUpdate as bulkIssueUpdate,
	removeWithoutIssueKey,
} from '../../state/domain/issues/actions.tsx';
import type { Issue } from '../../state/domain/issues/types.tsx';
import * as originalCrossProjectVersionsActions from '../../state/domain/original-cross-project-versions/actions.tsx';
import { reset as resetOriginalIssues } from '../../state/domain/original-issues/actions.tsx';
import type { OriginalIssues } from '../../state/domain/original-issues/types.tsx';
import { reset as resetOriginalPlannedCapacities } from '../../state/domain/original-planned-capacities/actions.tsx';
import { reset as resetOriginalResources } from '../../state/domain/original-resources/actions.tsx';
import { reset as resetOriginalTeams } from '../../state/domain/original-teams/actions.tsx';
import { reset as resetOriginalVersions } from '../../state/domain/original-versions/actions.tsx';
import {
	reset as resetChangesWarnings,
	addBulk as addBulkChangesWarnings,
	type AddActionPayload,
} from '../../state/domain/update-jira/changes-warnings/actions.tsx';
import * as changes from '../../state/domain/update-jira/changes/actions.tsx';
import type {
	EntityMetadata,
	ChangeMetadata,
} from '../../state/domain/update-jira/changes/types.tsx';
import * as commit from '../../state/domain/update-jira/commit/actions.tsx';
import * as revert from '../../state/domain/update-jira/revert/actions.tsx';
import { bulkUpdate as bulkVersionUpdate } from '../../state/domain/versions/actions.tsx';
import { updateExpandedIssueLinks } from '../../state/domain/view-settings/issue-expansions/actions.tsx';
import type { State } from '../../state/types.tsx';
import { toggleSelectedIssues } from '../../state/ui/main/tabs/roadmap/scope/issues/selectable-issue/actions.tsx';
import * as updateJiraActions from '../../state/ui/top/title-bar/update-jira/actions.tsx';
import {
	USER_FILTER_ID,
	type SelectedChanges,
} from '../../state/ui/top/title-bar/update-jira/types.tsx';

import { POST } from '../api.tsx';
import { fetchAssigneeListForKeys } from '../assignees/index.tsx';
import { getBacklog } from '../backlog/index.tsx';
import batch from '../batch/index.tsx';
import {
	executeBulkCommitRequest,
	createBulkCommitRequestEntityList,
} from '../commit-bulk/index.tsx';
import {
	type BulkCommitRequestEntity,
	type BulkCommitResponse,
	BulkCommitEntityType,
} from '../commit-bulk/types.tsx';
import * as crossProjectVersionsCommands from '../cross-project-versions/index.tsx';
import { populateUserPickerOptions } from '../custom-fields/index.tsx';
import { genericError } from '../errors/index.tsx';
import * as issueLinksCommands from '../issue-links/index.tsx';
import {
	revertChange as revertIssueChange,
	getHiddenIssues,
	handleIssueCommitResponse,
} from '../issue/index.tsx';
import {
	revertPlannedCapacityChange,
	handlePlannedCapacityBulkCommitResponse,
} from '../planned-capacities/index.tsx';
import {
	revertTeamChange,
	revertResourceChange,
	handleTeamBulkCommitResponse,
	handleResourceBulkCommitResponse,
} from '../teams/index.tsx';
import { toErrorID, tryParseJson } from '../util.tsx';
import * as versionsCommands from '../versions/index.tsx';
import { urls, getChangesMetadataBody } from './api.tsx';
import { getNotifyWatchersPreference, setNotifyWatchersPreference } from './utils.tsx';
import {
	getChangeWarning,
	ADDED_TO_ACTIVE_SPRINT,
	REMOVED_FROM_ACTIVE_SPRINT,
} from './warnings.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	expandChange,
	collapseChange,
	selectChange,
	deselectChange,
	selectAllChanges,
	deselectAllChanges,
	replaceSelectedChanges,
	sortChanges,
	changeFilter,
	clearFilter,
} from '../../state/ui/top/title-bar/update-jira/actions';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { USER_FILTER_ID } from '../../state/ui/top/title-bar/update-jira/types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { interrupt as interruptCommitting } from '../../state/domain/update-jira/commit/actions';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { interrupt as interruptReverting } from '../../state/domain/update-jira/revert/actions';

export const GET_CHANGES_METADATA = 'command.update-jira.GET_CHANGES_METADATA';
export const GET_NOTIFICATION_PREFERENCE = 'command.update-jira.GET_NOTIFICATION_PREFERENCE';
export const SAVE_NOTIFICATION_PREFERENCE = 'command.update-jira.SAVE_NOTIFICATION_PREFERENCE';
export const COMMIT_CHANGES = 'command.update-jira.COMMIT_CHANGES';
export const REVERT_CHANGES = 'command.update-jira.REVERT_CHANGES';
export const INTERRUPT = 'command.update-jira.INTERRUPT';
export const UPDATE_LAST_COMMITTED_TIMESTAMP =
	'command.update-jira.UPDATE_LAST_COMMITTED_TIMESTAMP';
const COMMIT_SCENARIO_CHANGES_BATCH_SIZE = 5;
const COMMIT_SCENARIO_CHANGES_LARGER_BATCH = 10;

type CommitChangesPayload = {
	actions: {
		success?: {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			type: any;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			payload?: any;
		}[];
		error?: {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			type: any;
		};
	};
	notifyWatchers?: boolean;
};

type IssueMap = Record<IssueId, Issue>;

export type CommitChangesAction = {
	type: typeof COMMIT_CHANGES;
	payload: CommitChangesPayload;
	meta: AnalyticsEventMeta;
};

export type SaveNotificationPreferencePayload = {
	shouldNotifyWatchers: boolean;
};

export type SaveNotificationPreferenceAction = {
	type: typeof SAVE_NOTIFICATION_PREFERENCE;
	payload: SaveNotificationPreferencePayload;
};

export const getChangesMetadata = () => ({ type: GET_CHANGES_METADATA });
export const commitChanges = (payload: CommitChangesPayload, meta: AnalyticsEventMeta) => ({
	type: COMMIT_CHANGES,
	payload,
	meta,
});
export const revertChanges = (payload: CommitChangesPayload) => ({ type: REVERT_CHANGES, payload });

export const getNotificationPreference = () => ({ type: GET_NOTIFICATION_PREFERENCE });
export const saveNotificationPreference = (payload: SaveNotificationPreferencePayload) => ({
	type: SAVE_NOTIFICATION_PREFERENCE,
	payload,
});

export const interrupt = () => ({ type: INTERRUPT });

export const updateLastCommittedTimestamp = () => ({
	type: UPDATE_LAST_COMMITTED_TIMESTAMP,
});

export const getKeysOfUsersWithNoMeta = (state: State, metaData: ChangeMetadata[]): string[] => {
	const requiredUserKeys = metaData.map((value) => value.lastChangeUser);
	const availableUserKeys = getAssigneeList(state).map((assignee) => assignee.personId);
	return R.difference(requiredUserKeys, availableUserKeys);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doGetChangeWarnings(): Generator<Effect, any, any> {
	const sprints = yield select(getSprints);
	const originalIssues = yield select(getOriginalIssues);
	const issuesById: IssueMap = yield select(getIssueMapById);

	yield put(resetChangesWarnings({ category: ENTITY.ISSUE }));

	const warnings: Array<AddActionPayload> = [];

	for (const issueId of Object.keys(originalIssues)) {
		const issue = issuesById[issueId];
		const originalIssue = originalIssues[issueId];
		if (issue) {
			if (issue.id == null) {
				log.safeErrorWithoutCustomerData(
					'plans.accessing-property-of-undefined',
					'doGetChangeWarnings originalIssues[issueId] is undefined',
				);
			}

			if (
				issue.id?.startsWith(SCENARIO_ISSUE_ID_PREFIX) ||
				(R.has('sprint', originalIssue) && issue.sprint !== originalIssue.sprint)
			) {
				const sprint = issue.sprint && getSprintById(sprints, issue.sprint);
				if (sprint && sprint.state === 'ACTIVE') {
					warnings.push({
						category: ENTITY.ISSUE,
						itemId: issueId,
						warnings: getChangeWarning(ADDED_TO_ACTIVE_SPRINT),
					});
				}
				const removedSprint = getSprintById(sprints, originalIssue.sprint);
				if (removedSprint && removedSprint.state === 'ACTIVE') {
					warnings.push({
						category: ENTITY.ISSUE,
						itemId: issueId,
						warnings: getChangeWarning(REMOVED_FROM_ACTIVE_SPRINT),
					});
				}
			}
		}
	}

	if (warnings.length) {
		yield put(addBulkChangesWarnings(warnings));
	}
}

export const constructIssueLinkChangesData = (
	issueLinksMetadata: EntityMetadata,
	issueLinksValuesForIssues: IssueLinks,
	issueLinkOriginalsForIssues: IssueLinks,
): Api.IssueLinkData[] => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const issueLinksValues: Record<string, any> = {};
	for (const issueId of Object.keys(issueLinksValuesForIssues)) {
		for (const issueLinkId of Object.keys(issueLinksValuesForIssues[issueId])) {
			issueLinksValues[issueLinkId] = issueLinksValuesForIssues[issueId][issueLinkId];
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const issueLinksOriginals: Record<string, any> = {};
	for (const issueId of Object.keys(issueLinkOriginalsForIssues)) {
		for (const issueLinkId of Object.keys(issueLinkOriginalsForIssues[issueId])) {
			issueLinksOriginals[issueLinkId] = issueLinkOriginalsForIssues[issueId][issueLinkId];
		}
	}

	return Object.keys(issueLinksMetadata)
		.filter((issueLinkId) => {
			if (
				!isDefined(issueLinksOriginals[issueLinkId]) &&
				!isDefined(issueLinksValues[issueLinkId])
			) {
				return false;
			}
			return true;
		})
		.map((issueLinkId) => {
			const issueLinkMetadata = issueLinksMetadata[issueLinkId];

			switch (issueLinkMetadata.scenarioType) {
				case 'DELETED': {
					const issueLink = issueLinksOriginals[issueLinkId];

					return {
						itemKey: issueLinkId,
						originals: {},
						scenarioType: 'DELETED',
						sourceItemKey: issueLink.sourceItemKey,
						targetItemKey: issueLink.targetItemKey,
						values: {
							type: issueLink.type,
						},
					};
				}
				default: {
					const issueLink = issueLinksValues[issueLinkId];

					return {
						itemKey: issueLinkId,
						originals: {},
						scenarioType: 'ADDED',
						sourceItemKey: issueLink.sourceItemKey,
						targetItemKey: issueLink.targetItemKey,
						values: {
							type: issueLink.type,
						},
					};
				}
			}
		});
};

export const constructIssueChangesData = (
	issueChangesMetadata: EntityMetadata,
	issuesById: IssueMap,
	scenarioRemovedIssuesById: IssueMap,
	originalIssues: OriginalIssues,
	issueLinks: IssueLinksDataMap,
): Api.Issue[] => {
	const allIssuesById: IssueMap = {
		...issuesById,
		...scenarioRemovedIssuesById,
	};

	/**
	 * Add issues that have only dependency changes. Such issues are not recorded in
	 * `originalIssues`
	 */
	const modifiedOriginalIssues = originalIssues;
	for (const issueId of Object.keys(issueLinks)) {
		if (!isDefined(modifiedOriginalIssues[issueId])) {
			modifiedOriginalIssues[issueId] = {};
		}
	}

	const definedIssuesIds = Object.keys(modifiedOriginalIssues).filter((id) => allIssuesById[id]);

	return definedIssuesIds.map((id) =>
		toApiIssue(
			allIssuesById[id],
			modifiedOriginalIssues[id],
			issueChangesMetadata[id]?.scenarioType,
		),
	);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doGetChangesMetadata(): Generator<Effect, any, any> {
	try {
		const state = yield select(R.identity);
		const {
			domain: {
				plan: { id, currentScenarioId },
				sequence,
			},
		}: State = state;
		const body = getChangesMetadataBody({ id, currentScenarioId }, sequence);

		yield put(changes.sent());
		const response = yield call(fetch, urls.getScenarioChangesMetadata, {
			profile: urls.getScenarioChangesMetadata,
			method: POST,
			body,
		});

		if (response.ok) {
			const json = yield call(response.json.bind(response));

			const issueLinkValues = yield select(getIssueLinks);
			const issueLinkOriginals = yield select(getIssueLinkOriginals);

			const issueLinks = yield call(
				constructIssueLinkChangesData,
				{ ...json.metaData.issueLinks },
				issueLinkValues,
				issueLinkOriginals,
			);

			const issuesMapById = yield select(getIssueMapById);
			const scenarioRemovedIssuesById = yield select(getScenarioRemovedIssueMapById);
			const originalIssues = yield select(getOriginalIssues);
			const issueLinkChangesData = yield call(getIssueLinkChangesDataPure, issueLinks);

			// Get all the API issues with that are related to changes
			const issues = yield call(
				constructIssueChangesData,
				json.metaData.issues,
				issuesMapById,
				scenarioRemovedIssuesById,
				originalIssues,
				issueLinkChangesData,
			);

			yield put(
				changes.success({
					...json,
					issues,
					issueLinks,
				}),
			);

			// check if all the users who did modifications have their meta data available
			// if not, let's fetch them because we need the meta data to display their display name and avatar
			const allChangesMetaData = yield select(getAllChangesMetaData);
			const keys = getKeysOfUsersWithNoMeta(state, allChangesMetaData);
			yield put(populateUserPickerOptions());

			// fetch all the assignee of issues and original issues, as we need to display them
			yield put(fetchAssigneeListForKeys({ keys, fetchAllAssignees: true }));

			yield call(doGetChangeWarnings);
			yield put(changes.afterSuccess());
		} else {
			// eslint-disable-next-line no-lonely-if
			if (fg('improve_redux_saga_error_reporting_plans_batch_4')) {
				const errorText = yield call(response.text.bind(response));

				yield put(
					changes.error({
						code: response.status,
						message: tryParseJson(errorText),
					}),
				);
			} else {
				yield put(
					changes.error({
						code: response.status,
						message: yield call(response.json.bind(response)),
					}),
				);
			}
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		yield put(changes.error({ code: 0, message: { error: e.message } }));

		if (fg('improve_redux_saga_error_reporting_plans_batch_4')) {
			fireErrorAnalytics({
				meta: {
					id: toErrorID(e, 'get-changes-metadata-failed'),
					packageName: PACKAGE_NAME,
					teamName: ERROR_REPORTING_TEAM,
				},
				error: e,
				sendToPrivacyUnsafeSplunk: true,
			});
		} else {
			yield put(genericError({ message: e.message, stackTrace: e.stack }));
		}
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doGetNotificationPreference(): Generator<Effect, any, any> {
	let shouldNotifyWatchers;
	try {
		shouldNotifyWatchers = yield call(getNotifyWatchersPreference);
	} catch (err) {
		// Call can fail with a 404 if user has not previously set userPreference key
	}

	yield put(updateJiraActions.updateNotificationPreference({ shouldNotifyWatchers }));
}

export function* doSaveNotificationPreference({
	payload: { shouldNotifyWatchers }, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: SaveNotificationPreferenceAction): Generator<Effect, any, any> {
	try {
		yield call(setNotifyWatchersPreference, shouldNotifyWatchers);
	} catch (err) {
		return undefined;
	}
}

export function* executeBatchOfCommitChangesNew(
	bulkCommitEntitiesBatch: BulkCommitRequestEntity[],
	plannedCapacityChangesByItemKey: Map<string, PlannedCapacityChange>,
	issueIdToProcessedId: Map<string, string>,
	processedIssueChanges: string[],
	issueLinkToIssueMap: Map<string, string>,
	notifyWatchers?: boolean,
): Generator<
	Effect,
	{
		issueIdToProcessedId: Map<string, string>;
		processedIssueChanges: string[];
		success: boolean;
		leftOverEntities: BulkCommitRequestEntity[];
	},
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	any
> {
	const bulkResponse: BulkCommitResponse = yield call(
		executeBulkCommitRequest,
		bulkCommitEntitiesBatch,
		notifyWatchers,
	);

	// handle the commit responses one by one
	const results = bulkResponse?.orderedCommitResults ?? [];
	let success = true;
	for (const entityResponse of results) {
		const id = entityResponse.itemKey;
		// since issue links are not a selectable entity in the commit UI (they come under issues),
		// we don't increment the count here
		if (entityResponse.entityType !== BulkCommitEntityType.ISSUE_LINK) {
			yield put(commit.incrementNumberOfCommittedChanges());
		}
		// issue has special success handling
		if (entityResponse.entityType !== BulkCommitEntityType.ISSUE && !entityResponse.success) {
			success = false;
		}

		switch (entityResponse.entityType) {
			case BulkCommitEntityType.X_PROJECT_VERSION: {
				yield call(
					crossProjectVersionsCommands.handleCrossProjectVersionCommitResponse,
					entityResponse,
				);
				break;
			}
			case BulkCommitEntityType.VERSION: {
				yield call(versionsCommands.handleVersionCommitResponse, entityResponse);
				break;
			}
			case BulkCommitEntityType.TEAM: {
				yield call(handleTeamBulkCommitResponse, entityResponse);
				break;
			}
			case BulkCommitEntityType.RESOURCE: {
				yield call(handleResourceBulkCommitResponse, entityResponse);
				break;
			}
			case BulkCommitEntityType.PLANNED_CAPACITY: {
				const plannedCapacityChange = plannedCapacityChangesByItemKey.get(id);
				if (plannedCapacityChange === undefined) {
					break;
				}
				yield call(handlePlannedCapacityBulkCommitResponse, entityResponse, plannedCapacityChange);
				break;
			}
			case BulkCommitEntityType.ISSUE: {
				const isJiraScreenDisplayed = yield call(
					handleIssueCommitResponse,
					entityResponse,
					notifyWatchers,
				);

				// if required fields for issue create were needed or the issue status transition screen required, its not actually a failure
				if (!entityResponse.success && !isJiraScreenDisplayed) {
					success = false;
				}

				const processedId = entityResponse.generatedId ?? id;
				processedIssueChanges.push(processedId);

				if (processedId !== id) {
					issueIdToProcessedId.set(id, processedId);
					yield put(domainActions.updateIssueReferences({ oldId: id, newId: processedId }));
				}
				break;
			}
			case BulkCommitEntityType.ISSUE_LINK: {
				const associatedIssueId = issueLinkToIssueMap.get(entityResponse.itemKey);
				if (associatedIssueId === undefined) {
					break;
				}
				// replace with processed id if it exists
				const associatedJiraIssueId: string =
					issueIdToProcessedId.get(associatedIssueId) ?? associatedIssueId;
				yield call(
					issueLinksCommands.handleIssueLinkCommitResponse,
					entityResponse,
					associatedJiraIssueId,
				);
				break;
			}
			default: {
				// not hittable
			}
		}
	}

	// if responses were all successful, but not events were processed, try leftovers in next request
	// generally this is only possible when we got blocked due to required fields in an issue create
	let leftOverEntities: BulkCommitRequestEntity[] = [];
	if (success && results.length < bulkCommitEntitiesBatch.length) {
		leftOverEntities = bulkCommitEntitiesBatch.filter(
			(entity) =>
				!results.some(
					(result) => result.itemKey === entity.itemKey && result.entityType === entity.entityType,
				),
		);
	}

	return { issueIdToProcessedId, processedIssueChanges, success, leftOverEntities };
}

export function* executeBatchOfCommitChanges(
	bulkCommitEntitiesBatch: BulkCommitRequestEntity[],
	plannedCapacityChangesByItemKey: Map<string, PlannedCapacityChange>,
	issueIdToProcessedId: Map<string, string>,
	processedIssueChanges: string[],
	issueLinkToIssueMap: Map<string, string>,
	notifyWatchers?: boolean,
): Generator<
	Effect,
	{
		issueIdToProcessedId: Map<string, string>;
		processedIssueChanges: string[];
		success: boolean;
		tryBatchAgainFromIndex: number | null;
	},
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	any
> {
	const bulkResponse: BulkCommitResponse = yield call(
		executeBulkCommitRequest,
		bulkCommitEntitiesBatch,
		notifyWatchers,
	);

	// handle the commit responses one by one
	let success = true;
	let tryBatchAgainFromIndex = null;
	for (const entityResponse of bulkResponse?.orderedCommitResults ?? []) {
		const id = entityResponse.itemKey;
		// since issue links are not a selectable entity in the commit UI (they come under issues),
		// we don't increment the count here
		if (entityResponse.entityType !== BulkCommitEntityType.ISSUE_LINK) {
			yield put(commit.incrementNumberOfCommittedChanges());
		}
		if (!entityResponse.success) {
			success = false;
		}

		switch (entityResponse.entityType) {
			case BulkCommitEntityType.X_PROJECT_VERSION: {
				yield call(
					crossProjectVersionsCommands.handleCrossProjectVersionCommitResponse,
					entityResponse,
				);
				break;
			}
			case BulkCommitEntityType.VERSION: {
				yield call(versionsCommands.handleVersionCommitResponse, entityResponse);
				break;
			}
			case BulkCommitEntityType.TEAM: {
				yield call(handleTeamBulkCommitResponse, entityResponse);
				break;
			}
			case BulkCommitEntityType.RESOURCE: {
				yield call(handleResourceBulkCommitResponse, entityResponse);
				break;
			}
			case BulkCommitEntityType.PLANNED_CAPACITY: {
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				const plannedCapacityChange = plannedCapacityChangesByItemKey.get(id)!;
				yield call(handlePlannedCapacityBulkCommitResponse, entityResponse, plannedCapacityChange);
				break;
			}
			case BulkCommitEntityType.ISSUE: {
				const isJiraScreenDisplayed = yield call(
					handleIssueCommitResponse,
					entityResponse,
					notifyWatchers,
				);

				// if required fields for issue create were needed or the issue status transition screen required, it means the batch was interrupted,
				// and we need to attempt to commit the rest of the batch
				if (isJiraScreenDisplayed) {
					success = true;
					if (bulkCommitEntitiesBatch.length > bulkResponse.orderedCommitResults.length) {
						tryBatchAgainFromIndex = bulkResponse.orderedCommitResults.length;
					}
				}

				const processedId = entityResponse.generatedId ?? id;
				processedIssueChanges.push(processedId);

				if (processedId !== id) {
					issueIdToProcessedId.set(id, processedId);
					yield put(domainActions.updateIssueReferences({ oldId: id, newId: processedId }));
				}
				break;
			}
			case BulkCommitEntityType.ISSUE_LINK: {
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				let associatedIssueId = issueLinkToIssueMap.get(entityResponse.itemKey)!;
				if (issueIdToProcessedId.has(associatedIssueId)) {
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					associatedIssueId = issueIdToProcessedId.get(associatedIssueId)!;
				}
				yield call(
					issueLinksCommands.handleIssueLinkCommitResponse,
					entityResponse,
					associatedIssueId,
				);
				break;
			}
			default: {
				// not hittable
			}
		}
		if (!success || tryBatchAgainFromIndex !== null) {
			break;
		}
	}

	return { issueIdToProcessedId, processedIssueChanges, success, tryBatchAgainFromIndex };
}

// gets the list of commit changes to send to the backend, splits them into batches
// and commits the batches one by one
export function* doBulkCommitChanges(
	selectedChanges: SelectedChanges,
	selectedIssues: string[],
	notifyWatchers?: boolean, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, void, any> {
	const allPlannedCapacityChanges: PlannedCapacityChange[] =
		yield select(getPlannedCapacityChanges);
	const plannedCapacityChangesByItemKey = allPlannedCapacityChanges.reduce((map, change) => {
		if (isDefined(change.values.itemKey)) {
			map.set(change.values.itemKey, change);
		}
		return map;
	}, new Map<string, PlannedCapacityChange>());

	const { bulkCommitEntities, issueLinkOnlyIssueChangesCount, issueLinkToIssueMap } = yield call(
		createBulkCommitRequestEntityList,
		selectedChanges,
		selectedIssues,
		allPlannedCapacityChanges,
	);

	let issueIdToProcessedId = new Map<string, string>();
	let processedIssueChanges: string[] = [];
	const issueChangesMetaData = yield select(getIssueChangesMetaData);

	if (fg('enable_parallelism_in_bulk_commit')) {
		for (let i = 0; i < bulkCommitEntities.length; i += COMMIT_SCENARIO_CHANGES_LARGER_BATCH) {
			let success = true;
			const bulkCommitEntitiesBatch = bulkCommitEntities.slice(
				i,
				i + COMMIT_SCENARIO_CHANGES_LARGER_BATCH,
			);
			if (yield select(getIsSavingInterrupted)) break;

			let leftOverEntities: BulkCommitRequestEntity[];
			({ issueIdToProcessedId, processedIssueChanges, success, leftOverEntities } = yield call(
				executeBatchOfCommitChangesNew,
				bulkCommitEntitiesBatch,
				plannedCapacityChangesByItemKey,
				issueIdToProcessedId,
				processedIssueChanges,
				issueLinkToIssueMap,
				notifyWatchers,
			));

			// these values should be from concurrent groups that were blocked by required field failures in a previous group
			while (success && leftOverEntities.length > 0) {
				let newLeftOverEntities: BulkCommitRequestEntity[];
				({
					issueIdToProcessedId,
					processedIssueChanges,
					success,
					leftOverEntities: newLeftOverEntities,
				} = yield call(
					executeBatchOfCommitChangesNew,
					leftOverEntities,
					plannedCapacityChangesByItemKey,
					issueIdToProcessedId,
					processedIssueChanges,
					issueLinkToIssueMap,
					notifyWatchers,
				));

				// only keep committing leftOverEntities if the number is decreasing, which means we are progressing through concurrent groups
				if (newLeftOverEntities.length >= leftOverEntities.length) {
					success = false;
					leftOverEntities = [];
				} else {
					leftOverEntities = newLeftOverEntities;
				}
			}

			if (!success) {
				break;
			}
		}
	} else {
		for (let i = 0; i < bulkCommitEntities.length; i += COMMIT_SCENARIO_CHANGES_BATCH_SIZE) {
			let tryBatchAgainFromIndex = null;
			let success = true;
			let bulkCommitEntitiesBatch = bulkCommitEntities.slice(
				i,
				i + COMMIT_SCENARIO_CHANGES_BATCH_SIZE,
			);
			if (yield select(getIsSavingInterrupted)) break;

			({ issueIdToProcessedId, processedIssueChanges, success, tryBatchAgainFromIndex } =
				yield call(
					executeBatchOfCommitChanges,
					bulkCommitEntitiesBatch,
					plannedCapacityChangesByItemKey,
					issueIdToProcessedId,
					processedIssueChanges,
					issueLinkToIssueMap,
					notifyWatchers,
				));

			// if the batch commit was interrupted part of the way due to required fields,
			// we should attempt to commit the rest of the batch
			while (tryBatchAgainFromIndex !== null) {
				bulkCommitEntitiesBatch = bulkCommitEntitiesBatch.slice(tryBatchAgainFromIndex);
				({ issueIdToProcessedId, processedIssueChanges, success, tryBatchAgainFromIndex } =
					yield call(
						executeBatchOfCommitChanges,
						bulkCommitEntitiesBatch,
						plannedCapacityChangesByItemKey,
						issueIdToProcessedId,
						processedIssueChanges,
						issueLinkToIssueMap,
						notifyWatchers,
					));
			}

			if (!success) {
				break;
			}
		}
	}

	// issues that only contain issue link changes are not committed (it is a issue link entity instead)
	// but as the issues still appear in the commit UI, we need to increment the change count to match.
	for (let i = 0; i < issueLinkOnlyIssueChangesCount; i++) {
		yield put(commit.incrementNumberOfCommittedChanges());
	}

	// issue post-commit work that is only meant to be run once
	yield put(toggleSelectedIssues({ isSelected: false, toggleAll: true, ids: [] }));
	yield put(updateExpandedIssueLinks(Object.fromEntries(issueIdToProcessedId)));

	yield call(
		getHiddenIssues,
		processedIssueChanges.filter(
			(id) => !issueChangesMetaData[id] || issueChangesMetaData[id].scenarioType !== 'DELETED',
		),
	);
	yield put(resetOriginalIssues({}));
}

export function* doCommitChanges({
	payload: { actions, notifyWatchers }, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: CommitChangesAction): Generator<Effect, any, any> {
	monitor.startTask(PERFORMANCE_KEYS.COMMIT_CHANGES_TIME_TO_SAVE);
	const data = yield select(getFilteredReviewChanges);
	if (!data.length) {
		if (fg('improve_redux_saga_error_reporting_plans_batch_4')) {
			const error = new Error('Get changes before committing them');

			fireErrorAnalytics({
				meta: {
					id: toErrorID(error, 'commit-changes-fetch-failed'),
					packageName: PACKAGE_NAME,
					teamName: ERROR_REPORTING_TEAM,
				},
				error,
				sendToPrivacyUnsafeSplunk: true,
			});
		} else {
			yield put(genericError({ message: 'Get changes before committing them' }));
		}

		return;
	}

	const selectedChanges: SelectedChanges = yield select(getSelectedChanges);
	const selectedChangesCount: number = yield select(getSelectedChangesCount);
	// we get the issues sorted by hierarchy as parents need to be created before their children,
	// since the child issue will contain a parent link referencing the parent
	const selectedIssues = yield select(getSortedByHierarchySelectedIssues);
	const committedChangesSets: Record<keyof typeof selectedChanges, Set<string>> = R.map(
		(items: string[]) => new Set(items),
		selectedChanges,
	);
	const countsByEntityType: Record<keyof typeof committedChangesSets, number> = R.map(
		(changeCategory) => changeCategory.size,
		committedChangesSets,
	);

	yield put(commit.start(selectedChangesCount));
	try {
		monitor.startTask(PERFORMANCE_KEYS.COMMIT_CHANGES_TIME_TO_PERSIST);

		yield call(doBulkCommitChanges, selectedChanges, selectedIssues, notifyWatchers);

		monitor.finishTask(PERFORMANCE_KEYS.COMMIT_CHANGES_TIME_TO_PERSIST, {
			scalingFactors: {
				...countsByEntityType,
				parallelCommit: fg('enable_parallelism_in_bulk_commit'),
			},
		});

		// NOTE getBacklog is duplicated in both branches (instead of one in `finally` block)
		// because we want to execute it (and wait for result) before success/error action
		yield call(getBacklog);

		if (actions.success) {
			for (const action of actions.success) {
				yield put(action);
			}
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		yield call(getBacklog);

		/* istanbul ignore else */
		if (actions.error) {
			yield put(actions.error);
		}

		if (fg('improve_redux_saga_error_reporting_plans_batch_4')) {
			fireErrorAnalytics({
				meta: {
					id: toErrorID(e, 'commit-changes-failed'),
					packageName: PACKAGE_NAME,
					teamName: ERROR_REPORTING_TEAM,
				},
				error: e,
				sendToPrivacyUnsafeSplunk: true,
			});
		} else {
			yield put(genericError({ message: e.message, stackTrace: e.stack }));
		}
	} finally {
		yield put(commit.stop());
		yield call(doGetChangesMetadata);

		// eslint-disable-next-line @typescript-eslint/no-shadow
		const data: ReturnType<typeof getReviewChanges> = yield select(getReviewChanges);
		const filteredData = yield select(getFilteredReviewChanges);
		const isOutOfSync = yield select(getIsOutOfSync);

		if (!filteredData.length && !isOutOfSync) {
			yield put(updateJiraActions.clearFilter(USER_FILTER_ID));
		}

		if (!data.length && !isOutOfSync) {
			yield put(updateJiraActions.closeUpdateJiraDialog());
		}
		data.forEach((uncommittedChange) =>
			committedChangesSets[uncommittedChange.category]?.delete(uncommittedChange.id),
		);
		monitor.finishTask(PERFORMANCE_KEYS.COMMIT_CHANGES_TIME_TO_SAVE, {
			scalingFactors: {
				...countsByEntityType,
				parallelCommit: fg('enable_parallelism_in_bulk_commit'),
			},
		});
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doRevertIssueChanges(selectedChanges: string[]): Generator<Effect, any, any> {
	const issueChangesMetaData = yield select(getIssueChangesMetaData);
	const issueLinkChanges = yield select(getIssueLinkChangesData);
	// Tracking reverted issue link changes is required because every issue link change
	// is present in issue link changes associated with both source and target issue,
	// but must be reverted only once.
	const revertedIssueLinkChanges = new Set();

	// It is necessary to revert issue links before actual issue changes to ensure
	// that created issues are reverted after reverting their links.
	// At the moment there is no good way to represent such compound changes
	// using Review changes dialog progress bar.
	// For this reason issue links are reverted without tracking any progress,
	// though user is still capable to interrupt this process as isSavingInterrupted
	// is checked inside the loop.
	/* eslint-disable no-labels, no-continue */
	loop: for (const id of selectedChanges) {
		for (const { itemKey } of issueLinkChanges[id] || []) {
			if (yield select(getIsRevertingInterrupted)) break loop;

			if (revertedIssueLinkChanges.has(itemKey)) continue;

			const response = yield call(issueLinksCommands.revertChange, itemKey, id);
			if (!response.ok) {
				yield put.resolve(interrupt());
				break;
			}
			revertedIssueLinkChanges.add(itemKey);
		}
	}

	for (const id of selectedChanges) {
		if (yield select(getIsRevertingInterrupted)) break;

		// If issue link change is the only change associated with this issue
		// then issue itself must not be reverted.
		if (issueChangesMetaData[id]) {
			const response = yield call(revertIssueChange, id);
			yield put(revert.incrementNumberOfRevertedChanges());
			if (!response.ok) {
				yield put.resolve(interrupt());
				break;
			}
		} else {
			yield put(revert.incrementNumberOfRevertedChanges());
		}
	}
	const patches = yield select(getOriginalIssues);

	yield put(toggleSelectedIssues({ isSelected: false, toggleAll: true, ids: [] }));
	yield put(bulkIssueUpdate(patches));
	yield put(removeWithoutIssueKey());
	yield put(resetOriginalIssues({}));
}

export function* doRevertCrossProjectVersionChanges(
	selectedChanges: string[], // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	for (const id of selectedChanges) {
		if (yield select(getIsRevertingInterrupted)) break;

		const response = yield call(crossProjectVersionsCommands.revertChange, id);
		yield put(revert.incrementNumberOfRevertedChanges());
		if (!response.ok) {
			yield put.resolve(interrupt());
			break;
		}
		yield* batch(function* () {
			const originals: ReturnType<typeof getOriginalCrossProjectVersions> = yield select(
				getOriginalCrossProjectVersions,
			);
			if (id.startsWith(SCENARIO_ISSUE_ID_PREFIX)) {
				yield call(crossProjectVersionsCommands.removeCrossProjectVersionFromStore, id);
			} else {
				const removedCrossProjectVersionsById: ReturnType<
					typeof getRemovedCrossProjectVersionsById
				> = yield select(getRemovedCrossProjectVersionsById);
				const removedCrossProjectVersion = removedCrossProjectVersionsById[id];
				/** We have to add a removed cross-project version back to main cross-project versions list
				 *  before reseting the values from originals, because the version id will not be found
				 *  until we add it to the list. Doing so will make sure that while reseting values,
				 *  it will find version in the list.
				 */
				if (isDefined(removedCrossProjectVersion)) {
					yield call(
						crossProjectVersionsCommands.addRemovedCrossProjectVersionToMainCrossProjectVersionsList,
						removedCrossProjectVersion,
					);
				}
				yield put(crossProjectVersionsActions.bulkUpdate({ [id]: originals[id] }));
				yield put(originalCrossProjectVersionsActions.reset(R.dissoc(id, originals)));
			}
		});
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doRevertVersionChanges(selectedChanges: string[]): Generator<Effect, any, any> {
	for (const id of selectedChanges) {
		if (yield select(getIsRevertingInterrupted)) break;

		const response = yield call(versionsCommands.revertChange, id);
		yield put(revert.incrementNumberOfRevertedChanges());
		if (!response.ok) {
			yield put.resolve(interrupt());
			break;
		}
		const originals = yield select(getOriginalVersions);
		const originalVersion = originals[id];

		if (id.startsWith(SCENARIO_ISSUE_ID_PREFIX)) {
			yield call(versionsCommands.removeVersionFromStore, id);
		} else {
			/** We have to add a removed version back to versions list before reseting the values from originals,
			 *  because the version id will not be found until we add it to the list. Doing so
			 *  will make sure that while reseting values, it will find version in the list.
			 */
			const removedVersionsById = yield select(getRemovedVersionsById);
			const removedVersion = removedVersionsById[id];
			if (isDefined(removedVersion)) {
				yield call(versionsCommands.addRemovedVersionToMainVersionsList, removedVersion);
			}

			const versionsById = yield select(getVersionsById);
			const version = versionsById[id];

			// Update cross-project version
			if (Object.keys(originalVersion).includes('crossProjectVersion')) {
				if (isDefined(version.crossProjectVersion)) {
					// Remove a release from crossProjectVersion
					yield call(
						crossProjectVersionsCommands.removeVersionFromCrossProjectVersion,
						id,
						version.crossProjectVersion,
					);
				}
				if (isDefined(originalVersion.crossProjectVersion)) {
					yield call(
						crossProjectVersionsCommands.addVersionInCrossProjectVersion,
						id,
						originalVersion.crossProjectVersion,
					);
				}
			}

			yield put(bulkVersionUpdate({ [id]: originals[id] }));
		}
		yield put(resetOriginalVersions(R.dissoc(id, originals)));
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doRevertResourceChanges(selectedChanges: string[]): Generator<Effect, any, any> {
	for (const id of selectedChanges) {
		if (yield select(getIsRevertingInterrupted)) break;

		const response = yield call(revertResourceChange, id);
		yield put(revert.incrementNumberOfRevertedChanges());
		if (!response.ok) {
			yield put.resolve(interrupt());
			break;
		}
		const originals = yield select(getOriginalResources);
		yield put(resetOriginalResources(R.dissoc(id, originals)));
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doRevertTeamChanges(selectedChanges: string[]): Generator<Effect, any, any> {
	for (const id of selectedChanges) {
		if (yield select(getIsRevertingInterrupted)) break;

		const response = yield call(revertTeamChange, id);
		yield put(revert.incrementNumberOfRevertedChanges());
		if (!response.ok) {
			yield put.resolve(interrupt());
			break;
		}
		const originals = yield select(getOriginalTeams);
		yield put(resetOriginalTeams(R.dissoc(id, originals)));
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doRevertSprintChanges(selectedChanges: string[]): Generator<Effect, any, any> {
	const allPlannedCapacityChanges: PlannedCapacityChange[] =
		yield select(getPlannedCapacityChanges);

	for (const id of selectedChanges) {
		if (yield select(getIsRevertingInterrupted)) {
			break;
		}

		const plannedCapacityChanges = allPlannedCapacityChanges.filter(
			(capacityChange) => capacityChange.iterationId === id,
		);

		for (const plannedCapacityChange of plannedCapacityChanges) {
			const { teamId, iterationId } = plannedCapacityChange.values;
			const response = yield call(revertPlannedCapacityChange, plannedCapacityChange);

			if (!response.ok) {
				yield put(interrupt());
				break;
			}

			const originals = yield select(getOriginalPlannedCapacities);
			yield put(resetOriginalPlannedCapacities(R.dissocPath([teamId, iterationId], originals)));
		}

		yield put(revert.incrementNumberOfRevertedChanges());
	}
}

export function* doRevertChanges({
	payload: { actions }, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: CommitChangesAction): Generator<Effect, any, any> {
	const data = yield select(getFilteredReviewChanges);
	if (!data.length) {
		if (fg('improve_redux_saga_error_reporting_plans_batch_4')) {
			const error = new Error('Get changes before reverting them');

			fireErrorAnalytics({
				meta: {
					id: toErrorID(error, 'revert-changes-fetch-failed'),
					packageName: PACKAGE_NAME,
					teamName: ERROR_REPORTING_TEAM,
				},
				error,
				sendToPrivacyUnsafeSplunk: true,
			});
		} else {
			yield put(genericError({ message: 'Get changes before reverting them' }));
		}

		return;
	}
	const selectedChanges: SelectedChanges = yield select(getSelectedChanges);
	const selectedChangesCount: number = yield select(getSelectedChangesCount);
	yield put(revert.start(selectedChangesCount));
	try {
		yield call(doRevertIssueChanges, selectedChanges[ENTITY.ISSUE]);
		yield call(doRevertVersionChanges, selectedChanges[ENTITY.RELEASE]);
		yield call(doRevertCrossProjectVersionChanges, selectedChanges[ENTITY.CROSS_PROJECT_RELEASE]);
		yield call(doRevertResourceChanges, selectedChanges[ENTITY.RESOURCE]);
		yield call(doRevertTeamChanges, selectedChanges[ENTITY.TEAM]);
		yield call(doRevertSprintChanges, selectedChanges[ENTITY.SPRINT]);
		// NOTE getBacklog is duplicated in both branches (instead of one in `finally` block)
		// because we want to execute it (and wait for result) before success/error action
		yield call(getBacklog);
		/* istanbul ignore else */
		if (actions.success) {
			for (const action of actions.success) {
				yield put(action);
			}
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		yield call(getBacklog);
		/* istanbul ignore else */
		if (actions.error) {
			yield put(actions.error);
		}

		if (fg('improve_redux_saga_error_reporting_plans_batch_4')) {
			fireErrorAnalytics({
				meta: {
					id: toErrorID(e, 'revert-changes-failed'),
					packageName: PACKAGE_NAME,
					teamName: ERROR_REPORTING_TEAM,
				},
				error: e,
				sendToPrivacyUnsafeSplunk: true,
			});
		} else {
			yield put(genericError({ message: e.message, stackTrace: e.stack }));
		}
	} finally {
		yield put(revert.stop());
		yield call(doGetChangesMetadata);

		// eslint-disable-next-line @typescript-eslint/no-shadow
		const data = yield select(getReviewChanges);
		const filteredData = yield select(getFilteredReviewChanges);
		const isOutOfSync = yield select(getIsOutOfSync);

		if (!filteredData.length && !isOutOfSync) {
			yield put(updateJiraActions.clearFilter(USER_FILTER_ID));
		}

		if (!data.length && !isOutOfSync) {
			yield put(updateJiraActions.closeUpdateJiraDialog());
		}
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doInterrupt(): Generator<Effect, any, any> {
	if (yield select(getIsSaving)) {
		yield put(commit.interrupt());
	} else if (yield select(getIsReverting)) {
		yield put(revert.interrupt());
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doUpdateLastCommitedTimestamp(): Generator<Effect, any, any> {
	const state = yield select(R.identity);
	const {
		domain: {
			plan: { id: planId },
		},
	}: State = state;

	if (!planId) {
		return;
	}

	const response = yield call(fetch, urls.updateLastCommittedTimestamp(planId), { method: POST });

	if (!response.ok) {
		throw new Error('There was an error when trying to update the plan');
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchGetChangesMetadata(): Generator<Effect, any, any> {
	yield takeLatest(GET_CHANGES_METADATA, doGetChangesMetadata);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchCommitChanges(): Generator<Effect, any, any> {
	yield takeLatest(COMMIT_CHANGES, doCommitChanges);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchRevertChanges(): Generator<Effect, any, any> {
	yield takeLatest(REVERT_CHANGES, doRevertChanges);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchUpdateLastCommitedTimestamp(): Generator<Effect, any, any> {
	yield takeLatest(UPDATE_LAST_COMMITTED_TIMESTAMP, doUpdateLastCommitedTimestamp);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchGetNotificationPreference(): Generator<Effect, any, any> {
	yield takeLatest(GET_NOTIFICATION_PREFERENCE, doGetNotificationPreference);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchSaveNotificationPreference(): Generator<Effect, any, any> {
	yield takeLatest(SAVE_NOTIFICATION_PREFERENCE, doSaveNotificationPreference);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default function* (): Generator<Effect, any, any> {
	yield fork(watchGetChangesMetadata);
	yield fork(watchCommitChanges);
	yield fork(watchRevertChanges);
	yield fork(watchUpdateLastCommitedTimestamp);
	yield fork(watchGetNotificationPreference);
	yield fork(watchSaveNotificationPreference);
	yield fork(function* () {
		yield takeLatest(INTERRUPT, doInterrupt);
	});
}
