import {
	isOptimizedMode,
	isExportMode,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app/index.tsx';
import { getHierarchyRangeFilter } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/hierarchy-range-filter/index.tsx';
import {
	getTypeToLevel,
	getHierarchyLevels,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/hierarchy/index.tsx';
import { getSelected } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issues/index.tsx';
import { getProjects } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects/index.tsx';
import {
	searchForIssues,
	getSearchQuery,
	getActiveSearchResultIndex,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/index.tsx';
import {
	getViewMode,
	getWarningViewSettings,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/view-settings/index.tsx';
import type { HierarchyRangeFilter } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import type { HierarchyLevel } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import {
	createSelector,
	createStructuredSelector,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { StateProps } from './types.tsx';

export const getFilteredHierarchyForTypePure =
	(
		hierarchyLevels: HierarchyLevel[],
		hierarchyFilter: HierarchyRangeFilter,
		typeToLevel: (arg1: number) => number,
	) =>
	(issueType: number): HierarchyLevel[] => {
		const { value } = hierarchyFilter;
		const start = Math.min(value.start, typeToLevel(issueType) - 1);
		const end = value.end;
		return hierarchyLevels.filter(({ level }) => level <= start && level >= end);
	};

const getFilteredHierarchyForType = createSelector(
	[getHierarchyLevels, getHierarchyRangeFilter, getTypeToLevel],
	getFilteredHierarchyForTypePure,
);

export default createStructuredSelector<State, StateProps>({
	activeSearchResultIndex: getActiveSearchResultIndex,
	hasSelectedIssues: (state) => getSelected(state).size > 0,
	hierarchyLevelsForType: getFilteredHierarchyForType,
	isExportMode,
	isOptimizedMode,
	issueSearchResults: searchForIssues,
	projects: getProjects,
	searchQuery: getSearchQuery,
	showWarning: (state) => getWarningViewSettings(state).showWarning,
	typeToLevel: getTypeToLevel,
	viewMode: getViewMode,
});
