import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	noLinkTypeFlagTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.dependency-lines.drag-preview.provider.no-link-type-flag-title',
		defaultMessage: "We couldn't create a dependency",
		description: 'Title of error flag stating the failure to create a dependency',
	},
	noLinkTypeFlagDescription: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.dependency-lines.drag-preview.provider.no-link-type-flag-description',
		defaultMessage:
			'Check your issue link Jira Setting or contact your Jira Administrator for help.',
		description: 'Description of create dependency error flag.',
	},
	noLinkTypeFlagLink: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.dependency-lines.drag-preview.provider.no-link-type-flag-link',
		defaultMessage: 'Configure dependencies for your plan',
		description: "Link to 'Configure Dependencies in Jira for your plan' support page",
	},
	noLinkTypeFlagDescriptionIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.dependency-lines.drag-preview.provider.no-link-type-flag-description-issue-term-refresh',
		defaultMessage: 'Check your work item link Jira settings, or contact your admin for help.',
		description: 'Description of create dependency error flag.',
	},
});
export default messages;
