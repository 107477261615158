import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	sortByRank: {
		id: 'portfolio-3-portfolio.app-simple-plans.flag-group.issue-rank-warning-flag.sort-by-rank',
		defaultMessage: 'Sort by Rank',
		description: 'The label for sort by rank button',
	},
	title: {
		id: 'portfolio-3-portfolio.app-simple-plans.flag-group.issue-rank-warning-flag.title',
		defaultMessage: 'Issue couldn’t be moved',
		description: 'The title for issue ranking warning flag',
	},
	sortedPreventIssueRankingDescription: {
		id: 'portfolio-3-portfolio.app-simple-plans.flag-group.issue-rank-warning-flag.sorted-prevent-issue-ranking-description',
		defaultMessage: 'Sort issues by <strong>Rank</strong> to enable drag and drop.',
		description: 'The description for sorted prevent issue ranking description',
	},
	titleIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.flag-group.issue-rank-warning-flag.title-issue-term-refresh',
		defaultMessage: 'Work item couldn’t be moved',
		description: 'The title for issue ranking warning flag',
	},
	sortedPreventIssueRankingDescriptionIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.flag-group.issue-rank-warning-flag.sorted-prevent-issue-ranking-description-issue-term-refresh',
		defaultMessage: 'Sort work items by <strong>Rank</strong> to enable drag and drop.',
		description: 'The description for sorted prevent issue ranking description',
	},
});
export default messages;
