import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	teamNameLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.team-form.team-name-label',
		defaultMessage: 'Team name',
		description: 'Label for team name field',
	},
	teamNameMaxLength: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.team-form.team-name-max-length',
		defaultMessage: 'Team names can be a maximum of 80 characters in length',
		description: 'Validation message for the max length',
	},
	teamNameEmpty: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.team-form.team-name-empty',
		defaultMessage: 'Please enter a team name',
		description: 'Validation message for the team name when it is empty',
	},
	issueSourceFieldPlaceholderNew: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.team-form.issue-source-field-placeholder-new',
		defaultMessage: 'Link the team to an issue source',
		description: 'Placeholder for issue source field',
	},
	noAccess: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.team-form.no-access',
		defaultMessage: 'No permission to view issue source',
		description: 'Label for issue source If the user does not have permission to view it',
	},
	issueSourceTooltipNotScrum: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.team-form.issue-source-tooltip-not-scrum',
		defaultMessage:
			'When auto-scheduling a plan, the issues of the selected source will be prioritized for this team.',
		description: 'Tooltip for issue source field when the selected board is not a scrum board.',
	},
	issueSourceTooltipScrumNew: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.team-form.issue-source-tooltip-scrum-new',
		defaultMessage: 'The sprints of the board will be associated with this team',
		description: 'Tooltip for issue source field when the selected board is a scrum board',
	},
	teamMembersLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.team-form.team-members-label',
		defaultMessage: 'Team members',
		description: 'Label for team members field',
	},
	planningStyleLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.team-form.planning-style-label',
		defaultMessage: 'Planning style',
		description: 'Label for planning style field',
	},
	planningStyleDisabledOptionTooltip: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.team-form.planning-style-disabled-option-tooltip',
		defaultMessage: 'Your plan uses story points, which can only be used with Scrum teams.',
		description:
			'Content of the tooltip displayed when hovering over the info icon of a disabled option in the "Planning style" picklist',
	},
	velocityValueValidation: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.team-form.velocity-value-validation',
		defaultMessage: 'Velocity cannot be less than 0 or greater than 9999',
		description:
			'Error message displayed when the value of the velocity field is lower than 0 or greater than 9999',
	},
	capacityPointsLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.team-form.capacity-points-label',
		defaultMessage: 'Capacity (points)',
		description: 'Label for capacity field',
	},
	capacityValidation: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.team-form.capacity-validation',
		defaultMessage: 'Capacity can only be numbers',
		description:
			'Error message displayed when there are non digits characters in the capacity field',
	},
	capacityValueValidation: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.team-form.capacity-value-validation',
		defaultMessage: 'Capacity cannot be less than 0 or greater than 9999',
		description:
			'Error message displayed when the value of the capacity field is lower than 0 or greater than 9999',
	},
	capacityEmptyValidation: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.team-form.capacity-empty-validation',
		defaultMessage: 'Please enter a capacity value',
		description: 'Error message displayed when the value of the capacity field is missing',
	},
	weeklyHoursLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.team-form.weekly-hours-label',
		defaultMessage: 'Capacity (weekly hours)',
		description: 'Label for the Capacity field',
	},
	sprintLengthLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.team-form.sprint-length-label',
		defaultMessage: 'Sprint length (weeks)',
		description: 'Label for the sprint length field',
	},
	iterationLengthValidation: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.team-form.iteration-length-validation',
		defaultMessage: 'Iteration length can only be in whole numbers',
		description:
			'Error message displayed when there are non whole numbers in the iteration length field',
	},
	iterationLengthValueValidation: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.team-form.iteration-length-value-validation',
		defaultMessage: 'Iteration length cannot be less than 1 or greater than 99',
		description:
			'Error message displayed when the value of the iteration length field is lower than 1 or greater than 99',
	},
	iterationEmptyValidation: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.team-form.iteration-empty-validation',
		defaultMessage: 'Please enter a sprint length value',
		description: 'Error message displayed when the value of the capacity field is missing',
	},
	startTyping: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.team-form.start-typing',
		defaultMessage: 'Start typing for users',
		description: 'Placeholder text for text field used to search for users to add to a team.',
	},
	teamInfoHeading: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.team-form.team-info-heading',
		defaultMessage: 'Team info',
		description: 'Heading displayed above team name and members',
	},
	planningHeading: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.team-form.planning-heading',
		defaultMessage: 'Planning',
		description:
			'Heading displayed above Issue source, planning style, and other planning related fields',
	},
	teamFormPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.team-form.team-form-placeholder',
		defaultMessage: 'e.g. {default}',
		description: 'Placeholder for the iteration length and velocity fields',
	},
	requiredFields: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.team-form.required-fields',
		defaultMessage: 'Required fields are marked with an asterisk',
		description:
			'Accessibility message to indicate what the asterisk means next to form field labels',
	},
	issueSourceTypeProjects: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.team-form.issue-source-type-projects',
		defaultMessage: 'Projects',
		description:
			'Label for projects issue source type, will be displayed as group name in the issue source dropdown',
	},
	issueSourceTypeFilters: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.team-form.issue-source-type-filters',
		defaultMessage: 'Filters',
		description:
			'Label for filters issue source type, will be displayed as group name in the issue source dropdown',
	},
	issueSourceTypeBoards: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.team-form.issue-source-type-boards',
		defaultMessage: 'Boards',
		description:
			'Label for boards issue source type, will be displayed as group name in the issue source dropdown',
	},
	issueSourceLink: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.team-form.issue-source-link',
		defaultMessage:
			'If you can’t find the board you’re looking for, you may need to link it to this plan <link>here</link>.',
		description:
			'This message will be displayed if the recommended scheduling is Scrum but no boards are present in the plan',
	},
	issueSourceFieldPlaceholderNewIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.team-form.issue-source-field-placeholder-new-issue-term-refresh',
		defaultMessage: 'Link the team to a work source',
		description: 'Placeholder for issue source field',
	},
	noAccessIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.team-form.no-access-issue-term-refresh',
		defaultMessage: 'No permission to view work source',
		description: 'Label for issue source If the user does not have permission to view it',
	},
	issueSourceTooltipNotScrumIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.team-form.issue-source-tooltip-not-scrum-issue-term-refresh',
		defaultMessage:
			'When auto-scheduling a plan, the work items of the selected source will be prioritized for this team.',
		description: 'Tooltip for issue source field when the selected board is not a scrum board.',
	},
});
