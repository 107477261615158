import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	searchPlaceholderOld: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.page-header.search.search-placeholder-old',
		defaultMessage: 'Search',
		description: 'Placeholder prompt for search bar when focused',
	},
	searchPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.page-header.search.search-field-placeholder',
		defaultMessage: 'Search timeline',
		description: 'Placeholder text for search field',
	},
	searchFieldAriaLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.page-header.search.search-field-aria-label',
		defaultMessage: 'Find Issue',
		description:
			'Aria Label text when the focus is moved to search field. This field is used to find an issue from the Timeline page.',
	},
	viewPreviousSearchDataArialabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.page-header.search.view-previous-search-data-arialabel',
		defaultMessage: 'View previous search data',
		description:
			'Aria Label text to view the previous search data, when search field has searchquery.',
	},
	viewNextSearchDataArialabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.page-header.search.view-next-search-data-arialabel',
		defaultMessage: 'View next search data',
		description: 'Aria Label text to view the next search data, when search field has searchquery.',
	},
	clearSearchDataArialabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.page-header.search.clear-search-data-arialabel',
		defaultMessage: 'Clear search data',
		description: 'Aria Label text to clear search data,when search field has searchquery.',
	},
	searchIconLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.page-header.search.search-icon-label',
		defaultMessage: 'Search',
		description: 'Label text to search icon, when search field is empty.',
	},
	searchFieldAriaLabelIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.page-header.search.search-field-aria-label-issue-term-refresh',
		defaultMessage: 'Find work item',
		description:
			'Aria Label text when the focus is moved to search field. This field is used to find an issue from the Timeline page.',
	},
});
