.actionGroup {
	padding-bottom: calc(var(--jpo-common-base-spacing) * 3);
}

.headersColor {
	color: var(--ds-text-subtlest, var(--adg3-color-N200));
}
.actionGroupHeader {
	font-weight: var(--ds-font-weight-semibold, 600);
	font-size: 12px;
}

.actionGroupRadios {
	margin-top: calc(var(--jpo-common-base-spacing) / 2);
}

.updatedInfoGroup {
	line-height: calc(var(--jpo-common-base-spacing) * 4);
	display: flex;
	justify-content: space-between;
	padding: calc(var(--jpo-common-base-spacing) * 2) 0;
}

.modifiedAt {
	min-width: 40%;
}
