import React, { useEffect } from 'react';
import noop from 'lodash/noop';
import Popup from '@atlaskit/popup'; // ignore-for-ENGHEALTH-17759
import { Box, Pressable, xcss } from '@atlaskit/primitives';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { SCHEDULE_MODE } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { useIsScrollingY } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/container/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { kanbanFlyoutAsyncEntryPoint } from './entrypoint/kanban/entrypoint.tsx';
import { scrumFlyoutAsyncEntryPoint } from './entrypoint/scrum/entrypoint.tsx';
import type { Props } from './types.tsx';
import SprintFlyoutOld from './view-old.tsx';
import messages from './flyout/messages.tsx';

function SprintFlyout({
	isOpen,
	onToggle = noop,
	sprint,
	plannedCapacity,
	plan,
	children,
	teamObject,
	isShowTimerangeInconsistent = false,
	ScrumFlyout,
	KanbanFlyout,
}: Props) {
	const intl = useIntl();
	// for scrum sprint, we don't display flyout for past sprints
	const isScrum = sprint.schedulingMode !== SCHEDULE_MODE.kanban;
	const [isScrollingY] = useIsScrollingY();
	const {
		entryPointActions: scrumEntryPointActions,
		entryPointReferenceSubject: scrumEntryPointReferenceSubject,
	} = useEntryPoint(scrumFlyoutAsyncEntryPoint, {});

	const {
		entryPointActions: kanbanEntryPointActions,
		entryPointReferenceSubject: kanbanEntryPointReferenceSubject,
	} = useEntryPoint(kanbanFlyoutAsyncEntryPoint, {});

	const entryPointActions = isScrum ? scrumEntryPointActions : kanbanEntryPointActions;

	const flyoutProps = {
		sprint,
		plannedCapacity,
		teamObject: teamObject || undefined, // Convert null to undefined
		plan,
		onCloseIconClick: onToggle,
	};

	useEffect(() => {
		if (isOpen && isScrollingY) {
			onToggle();
			entryPointActions.unload();
		}
	}, [isOpen, onToggle, isScrollingY, entryPointActions]);

	const ContentOverride = isScrum ? ScrumFlyout : KanbanFlyout;

	const handleClick = () => {
		entryPointActions.load();
		onToggle();
	};

	return (
		<Popup
			role="dialog"
			placement="bottom-start"
			isOpen={isOpen}
			label={intl.formatMessage(
				isScrum ? messages.sprintPreview : messages.kanbanIterationPreview,
				{
					name: sprint.label.display,
				},
			)}
			content={() =>
				ContentOverride ? (
					<ContentOverride />
				) : (
					<Box xcss={contentStyles}>
						{isScrum ? (
							<JiraEntryPointContainer
								entryPointReferenceSubject={scrumEntryPointReferenceSubject}
								id="async-advanced-roadmaps-sprint-flyout-scrum"
								packageName="jira-portfolio-3-portfolio"
								runtimeProps={{
									...flyoutProps,
									isShowTimerangeInconsistent,
								}}
							/>
						) : (
							<JiraEntryPointContainer
								entryPointReferenceSubject={kanbanEntryPointReferenceSubject}
								id="async-advanced-roadmaps-sprint-flyout-kanban"
								packageName="jira-portfolio-3-portfolio"
								runtimeProps={flyoutProps}
							/>
						)}
					</Box>
				)
			}
			onClose={onToggle}
			trigger={({ ref, ...triggerProps }) => (
				<Pressable
					{...triggerProps}
					ref={ref}
					xcss={triggerStyles}
					tabIndex={0}
					onClick={handleClick}
					onMouseEnter={entryPointActions.preload}
					onKeyDown={(e) => e.key === 'Escape' && onToggle()}
					role="button"
					testId={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.sprint-flyout.sprint-flyout-trigger.${
						(teamObject && teamObject.id) || ''
					}-${isScrum ? sprint.id || '' : sprint.startDate}`}
				>
					{children}
				</Pressable>
			)}
		/>
	);
}

const contentStyles = xcss({
	minWidth: '530px',
	padding: 'space.200',
});

const triggerStyles = xcss({
	cursor: 'pointer',
	backgroundColor: 'color.background.neutral.subtle',
	padding: 'space.0',
	width: '100%',
	textAlign: 'left',
});

export default componentWithFG(
	'entrypoint_migration_plans_sprint_flyout',
	SprintFlyout,
	SprintFlyoutOld,
);
