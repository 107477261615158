import React, { useEffect, useRef, useState } from 'react';
import Popup from '@atlaskit/popup'; // ignore-for-ENGHEALTH-17759
import { fg } from '@atlassian/jira-feature-gating';
import mergeRefs from '@atlassian/jira-merge-refs/src/index.tsx';
import {
	useZoomLevel,
	useHorizontalScrolling,
	useHorizontalPercentageOffset,
	useTimelineRuler,
} from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/controllers/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';
import { getTooltipStyle, useTooltipVisibility } from './utils.tsx';

function DateTooltip({ children, datePosition, width }: Props) {
	const [containerWidth, setContainerWidth] = useState(0);
	const [{ viewport }] = useHorizontalScrolling();
	const [{ pxToMs }] = useTimelineRuler();
	const visible = useTooltipVisibility();
	const elementRef = useRef<HTMLDivElement>(null);
	const { leftPositionPercentage, rightPositionPercentage } = datePosition;
	const [{ getPercentageOffset }] = useHorizontalPercentageOffset();
	const viewportLeftPercentageOffset = getPercentageOffset(viewport.offset);
	const viewportRightPercentageOffset = getPercentageOffset(
		viewport.offset + pxToMs(viewport.width),
	);
	const [zoomLevel] = useZoomLevel();
	const isScrollableTimeline = zoomLevel !== undefined;
	const style = getTooltipStyle(
		leftPositionPercentage,
		rightPositionPercentage,
		width,
		containerWidth,
		viewportLeftPercentageOffset,
		viewportRightPercentageOffset,
		isScrollableTimeline,
	);

	useEffect(() => {
		const el = elementRef.current;
		if (!el) {
			return;
		}
		// eslint-disable-next-line @typescript-eslint/no-shadow
		const children: HTMLCollection = el.children;
		// eslint-disable-next-line @typescript-eslint/no-shadow
		let width = 0;
		Array.from(children).forEach((child) => {
			if (child instanceof HTMLElement) {
				width += child.offsetWidth;
			}
		});

		setContainerWidth(width);
	}, []);

	if (!visible) {
		return null;
	}

	if (!fg('plan-timeline-non-transposed')) {
		return (
			<Popup
				placement="bottom"
				isOpen
				autoFocus={false}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				content={() => <div className={styles.dateTooltipContent}>{children}</div>}
				trigger={(triggerProps) => (
					<div
						key={style.left}
						{...triggerProps}
						ref={mergeRefs(triggerProps.ref, elementRef)}
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={styles.dateTooltipTrigger}
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						style={style}
					>
						{children}
					</div>
				)}
			/>
		);
	}

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
		<div ref={elementRef} className={styles.dateTooltip} style={style}>
			{children}
		</div>
	);
}

export default DateTooltip;
