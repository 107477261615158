import React, { useMemo } from 'react';
import { xcss, Box, Inline } from '@atlaskit/primitives';
import { getAppliedDecoration } from '@atlassian/jira-polaris-component-decorations/src/utils/index.tsx';
import { WeightTag } from '@atlassian/jira-polaris-component-weight-tag/src/ui/index.tsx';
import type { ValueDecoration } from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import type { FieldOption } from '@atlassian/jira-polaris-domain-field/src/field-option/types.tsx';
import { CommonDecoratedTag } from '@atlassian/jira-polaris-lib-decoration/src/ui/decoration/common/index.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import type { CommonFieldComponentProps } from '../../common/types.tsx';
import {
	useFieldOptions,
	useFieldValueDecorations,
	useFieldIsWeighted,
} from '../../controllers/fields/index.tsx';

export const isSingleSelectFieldComponentProps = (
	props: CommonFieldComponentProps,
): props is SingleSelectFieldComponentProps => 'value' in props && typeof props.value === 'number';

export type SingleSelectFieldComponentProps = CommonFieldComponentProps & {
	value: number;
};

export const SingleSelectField = ({ fieldKey, value }: SingleSelectFieldComponentProps) => {
	const [fieldOptions] = useFieldOptions(fieldKey);
	const [fieldValueDecorations] = useFieldValueDecorations(fieldKey);
	const [isWeighted] = useFieldIsWeighted(fieldKey);

	const decoration = getAppliedDecoration(fieldValueDecorations, `${value}`);

	const option = useMemo(
		() => fieldOptions?.find((o) => `${o.id}` === `${value}`),
		[fieldOptions, value],
	);

	if (!option) {
		return null;
	}

	return (
		<SingleSelectFieldDisplay option={option} decoration={decoration} isWeighted={isWeighted} />
	);
};

export const SingleSelectFieldDisplay = ({
	decoration,
	option,
	isWeighted,
}: {
	option: FieldOption;
	decoration: ValueDecoration | undefined;
	isWeighted: boolean;
}) => {
	const emojiDescription = useEmoji(decoration?.emoji);
	return (
		<Inline xcss={maxContentStyles}>
			{isWeighted && option && (
				<Box padding="space.050">
					<WeightTag options={[option]} />
				</Box>
			)}
			<CommonDecoratedTag
				value={option?.value}
				mainColor={decoration?.backgroundColor}
				emoji={emojiDescription}
			/>
		</Inline>
	);
};

const maxContentStyles = xcss({
	width: 'max-content',
});
