import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	typeHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies.flyout.type-header',
		defaultMessage: 'Type',
		description: 'A header text for Type column of dependencies flyout',
	},
	issueHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies.flyout.issue-header',
		defaultMessage: 'Issue',
		description: 'A header text for Issue column of dependencies flyout',
	},
	statusHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies.flyout.status-header',
		defaultMessage: 'Status',
		description: 'A header text for Status column of dependencies flyout',
	},
	leadTimeHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies.flyout.lead-time-header',
		defaultMessage: 'Lead time',
		description: 'A header text for Lead time column of dependencies flyout',
	},
	externalDependenciesHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies.flyout.external-dependencies-header',
		defaultMessage: 'Not in this plan',
		description: 'A header text for external dependencies section of dependencies flyout',
	},
	addQuickFilterText: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies.flyout.add-quick-filter-text',
		defaultMessage:
			'Filter by dependencies of {issueKey, select, ScenarioIssue {this issue} other {{issueKey}}}',
		description: 'Label for quick filter issues by dependencies link',
	},
	removeQuickFilterText: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies.flyout.remove-quick-filter-text',
		defaultMessage:
			'Clear filter for {issueKey, select, ScenarioIssue {this issue} other {{issueKey}}}',
		description: 'Label for removing quick filter by dependencies',
	},
	toggleDependencyViewButton: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies.flyout.toggle-dependency-view-button',
		defaultMessage: 'View dependencies as {value, select, lines {badges} other {lines}}',
		description: 'Label for switching the dependency view to lines.',
	},
	visualizeDependencies: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies.flyout.visualize-dependencies',
		defaultMessage:
			'Show dependencies for {issueKey, select, ScenarioIssue {this issue} other {{issueKey}}}',
		description: 'Label for filtering the dependency graph from the roadmap',
	},
	issueHeaderIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies.flyout.issue-header-issue-term-refresh',
		defaultMessage: 'Work item',
		description: 'A header text for Issue column of dependencies flyout',
	},
	addQuickFilterTextIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies.flyout.add-quick-filter-text-issue-term-refresh',
		defaultMessage:
			'Filter by dependencies of {issueKey, select, ScenarioIssue {this work item} other {{issueKey}}}',
		description: 'Label for quick filter issues by dependencies link',
	},
	removeQuickFilterTextIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies.flyout.remove-quick-filter-text-issue-term-refresh',
		defaultMessage:
			'Clear filter for {issueKey, select, ScenarioIssue {this work item} other {{issueKey}}}',
		description: 'Label for removing quick filter by dependencies',
	},
	visualizeDependenciesIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies.flyout.visualize-dependencies-issue-term-refresh',
		defaultMessage:
			'Show dependencies for {issueKey, select, ScenarioIssue {this work item} other {{issueKey}}}',
		description: 'Label for filtering the dependency graph from the roadmap',
	},
});
