import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	placeholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.summary-filter.placeholder',
		defaultMessage: 'Type issue keys, summaries',
		description:
			'Placeholder text for a text field that allows the user to filter displayed issues by their summary',
	},
	clearButtonLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.summary-filter.clear-button-label',
		defaultMessage: 'clear',
		description:
			'Aria label text when focus is switched to clear button that clears the text of a text field',
	},
	placeholderIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.summary-filter.placeholder-issue-term-refresh',
		defaultMessage: 'Type work item keys, summaries',
		description:
			'Placeholder text for a text field that allows the user to filter displayed issues by their summary',
	},
});
