import { calculateCyclicDependencies } from '@atlassian/jira-polaris-domain-field/src/formula/index.tsx';
import { formulaUsesFields } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/main.tsx';
import {
	INSIGHTS_COUNT_TERM,
	NUMBER_COMMENT_FORMULA_KEY,
	NUMBER_LINKED_ISSUES_FORMULA_KEY,
	type DynamicFieldFormula,
	type ExternalReferencePropertyFormula,
} from '@atlassian/jira-polaris-lib-formula/src/utils/formula/types.tsx';
import { GLOBAL_SYSTEM_FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { EXTERNAL_REFERENCE_PROPERTY_TEMPLATE } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/external-reference-property/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { JiraField } from '../../../../../services/types.tsx';

const analyzeFormulas = (formulas: Record<string, DynamicFieldFormula>) => {
	const formulaAnalysis: Record<string, string[]> = {};
	Object.entries(formulas).forEach(([key, formula]) => {
		formulaAnalysis[key] = formulaUsesFields(formula);
	});
	return calculateCyclicDependencies(formulaAnalysis);
};

const getFormulas = (jiraFields: JiraField[]) => {
	const formulas: Record<string, DynamicFieldFormula> = {};
	jiraFields.forEach((jiraField) => {
		if (fg('polaris_new_custom_types_global_system_fields')) {
			switch (jiraField.type) {
				case GLOBAL_SYSTEM_FIELD_TYPES.LINKED_ISSUES_GLOBAL:
					formulas[jiraField.key] = {
						template: NUMBER_LINKED_ISSUES_FORMULA_KEY,
					};
					break;
				case GLOBAL_SYSTEM_FIELD_TYPES.ISSUE_COMMENTS_GLOBAL:
					formulas[jiraField.key] = {
						template: NUMBER_COMMENT_FORMULA_KEY,
						parameters: {},
					};
					break;
				case GLOBAL_SYSTEM_FIELD_TYPES.INSIGHTS_GLOBAL:
					formulas[jiraField.key] = {
						template: INSIGHTS_COUNT_TERM,
					};
					break;
				case GLOBAL_SYSTEM_FIELD_TYPES.PLATFORM_PROJECT_STATUS: {
					const atlassianProjectField = jiraFields.find(
						(field) => field.type === GLOBAL_SYSTEM_FIELD_TYPES.PLATFORM_PROJECT,
					);
					if (atlassianProjectField) {
						const formula: ExternalReferencePropertyFormula = {
							template: EXTERNAL_REFERENCE_PROPERTY_TEMPLATE,
							parameters: {
								property: 'state',
								externalReferenceFieldKey: atlassianProjectField.key,
							},
						};
						formulas[jiraField.key] = formula;
					}
					break;
				}
				default:
					break;
			}
		}

		const formula = jiraField.configuration?.polarisFormula;
		if (formula) {
			formulas[jiraField.key] = formula;
		}
	});
	return formulas;
};

export const getFormulaData = (jiraFields: JiraField[]) => {
	const formulas = getFormulas(jiraFields);
	return {
		formulas,
		cyclicDependencies: analyzeFormulas(formulas),
	};
};
