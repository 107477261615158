import without from 'lodash/without';
import { fg } from '@atlassian/jira-feature-gating';
import type { FieldValueDecorations } from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import {
	FIELD_TYPES,
	JIRA_API_FIELD_TYPES,
} from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { isGlobalSystemField } from '@atlassian/jira-polaris-domain-field/src/field/utils.tsx';
import type { JiraApiFieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import type {
	FieldMap,
	FieldKey,
	Field,
} from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { JiraField } from '../../../../services/types.tsx';
import type { FetchResponse } from '../../types.tsx';
import { getFieldType } from '../../utils.tsx';
import { getFormulaData } from './formulas/index.tsx';
import {
	getArchivedOption,
	getFieldConfiguration,
	getOptions,
	getPlay,
	getPresentationType,
	isEditable,
	transformDecorations,
} from './utils.tsx';

export const transformResponse = (
	fields: JiraField[],
	issueTypesPerFieldKeys: Record<FieldKey, IssueTypeId[]>,
	overrideAllowList?: string[],
): FetchResponse => {
	const resolvedFieldMap: FieldMap = {};
	const resolvedFieldValueDecorations: FieldValueDecorations = {};

	// Jira always returns these fields, we don't want them unless explicitly requested by the consumer!
	const denyList = without(
		[
			'com.atlassian.jira.plugins.jira-development-integration-plugin:devsummarycf',
			'com.pyxis.greenhopper.jira:gh-lexo-rank',
			'comment',
			'issuelinks',
			'priority',
			'issuerestriction',
			'attachment',
			'resolution',
			'fixVersions',
			'timetracking',
			'environment',
			'duedate',
			'project',
			...(fg('jpd_issues_relationships') ? [] : [FIELD_TYPES.CONNECTION]),
		],
		...(overrideAllowList || []),
	);

	const filteredFilters = fields.filter(
		({ key, type }) => !denyList.includes(key) && !denyList.includes(type),
	);

	/*
        TODO: Refactor to issuekey
        These fields need to be remapped because their key and type in polaris does not match the type in the Jira api,
        but we can't easily adapt to the new type, because many parts of the code rely on the old type.
     */
	const remapList: { [key: string]: JiraApiFieldType } = {
		issuekey: JIRA_API_FIELD_TYPES.ISSUE_KEY,
	};
	const remappedFields = filteredFilters.map((field) =>
		remapList[field.key]
			? {
					...field,
					originalKey: field.key,
					key: remapList[field.key],
					type: remapList[field.key],
				}
			: field,
	);

	const formulaData = getFormulaData(remappedFields);

	remappedFields.forEach((field) => {
		const { key, name: label } = field;
		const originalKey = 'originalKey' in field ? field.originalKey : key;

		const type = getFieldType(field);
		const editable = isEditable(field);
		const presentation = getPresentationType(field);
		const options = getOptions(field);
		const description = field.description || '';
		const emoji = field.configuration?.emoji ?? undefined;
		const configuration = getFieldConfiguration(field);
		const archivedOption = getArchivedOption(field);
		const hasRestrictedContext = field.hasRestrictedContext;
		const issueTypes = issueTypesPerFieldKeys[originalKey];
		const play = getPlay(field);
		const global = field.global || false;
		const custom = field.custom || false;
		const stableId = field.stableId;

		const mappedField: Field = {
			key,
			type,
			options,
			label,
			editable,
			presentation,
			description,
			emoji,
			configuration,
			archivedOption,
			play,
			global,
			custom,
			issueTypes,
			hasRestrictedContext,
			stableId,
			formula: formulaData.formulas[key] || undefined,
			...(formulaData.cyclicDependencies[key] ? { hasCyclicFormulaDependency: true } : {}),
		};

		if (!isGlobalSystemField(mappedField) || fg('polaris_dont-filter-out-global-system-fields')) {
			resolvedFieldMap[key] = mappedField;
			resolvedFieldValueDecorations[key] = transformDecorations(field);
		}
	});

	return {
		fields: resolvedFieldMap,
		fieldValueDecorations: resolvedFieldValueDecorations,
	};
};
