import React, { forwardRef } from 'react';
import Badge from '@atlaskit/badge';
import Button from '@atlaskit/button/new';
import { SpotlightPulse, SpotlightTarget } from '@atlaskit/onboarding';
import { Manager, Popper, Reference } from '@atlaskit/popper'; // ignore-for-ENGHEALTH-17759
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import colors from '@atlassian/jira-portfolio-3-common/src/colors/index.tsx';
import KeyboardShortcutTooltip from '@atlassian/jira-portfolio-3-keyboard-shortcuts/src/ui/keyboard-shortcut-tooltip/index.tsx';
import { CttOnboardingProvider } from '@atlassian/jira-portfolio-3-onboarding/src/controllers/ctt-onboarding/index.tsx';
import { CttSpotlights } from '@atlassian/jira-portfolio-3-onboarding/src/controllers/ctt-spotlights/index.tsx';
import { Spotlights } from '@atlassian/jira-portfolio-3-onboarding/src/controllers/spotlights/index.tsx';
import { colourPaletteTextColoursMap } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/colours/index.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import messages from './messages.tsx';
import ReviewChangesSpotlightManager from './review-changes-spotlight-manager/index.tsx';
import type { Props } from './types.tsx';

const ReviewChangesButton = forwardRef<HTMLButtonElement, Props>(
	(
		{
			hasCountChanged,
			changesCount,
			isSpotlightActive,
			isOptimizing,
			currentScenarioColor,
			openDialog,
			closeSpotlight,
			activateSpotlight,
			toggleSetHasCountChanged,
		},
		ref,
	) => {
		const { formatMessage } = useIntl();
		const accountId = useAccountId();

		return (
			<Manager>
				<Reference>
					{({ ref: popperRef }) => (
						<SpotlightTarget name={CttSpotlights.ReviewChanges}>
							<SpotlightTarget name={Spotlights.SaveYourChanges}>
								<Tooltip content={<KeyboardShortcutTooltip letter="R" />} position="bottom">
									<Box xcss={buttonContainerStyles} ref={popperRef}>
										<SpotlightPulse radius={4} pulse={hasCountChanged && isSpotlightActive}>
											<Button
												onClick={openDialog}
												isDisabled={isOptimizing}
												appearance="primary"
												testId="portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.review-changes-button-wrapper.review-changes-button.review-changes-button"
												ref={ref}
											>
												<Box xcss={buttonLabelStyles}>
													{formatMessage(messages.updateJiraButtonText)}
													{changesCount > 0 && (
														<Box xcss={[changeCountBadgeStyles]}>
															<Badge
																style={{
																	backgroundColor: currentScenarioColor,
																	color: currentScenarioColor
																		? colourPaletteTextColoursMap[
																				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- Ignored via go/DSP-18766
																				currentScenarioColor as keyof typeof colourPaletteTextColoursMap
																				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
																			] || token('color.text.inverse', colors.N0)
																		: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
																			token('color.text.inverse', colors.N0),
																}}
																testId="portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.review-changes-button-wrapper.review-changes-button.change-count"
															>
																{changesCount}
															</Badge>
														</Box>
													)}
												</Box>
											</Button>
										</SpotlightPulse>
									</Box>
								</Tooltip>
							</SpotlightTarget>
						</SpotlightTarget>
					)}
				</Reference>

				<Popper placement="bottom-end">
					{({ ref: popperRef, style }) =>
						hasCountChanged && accountId ? (
							<CttOnboardingProvider isGlobal accountId={accountId}>
								{/*  eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop */}
								<Box ref={popperRef} style={style} xcss={spotlightContainerStyles}>
									<ReviewChangesSpotlightManager
										closeSpotlight={closeSpotlight}
										activateSpotlight={activateSpotlight}
										isSpotlightActive={isSpotlightActive}
										toggleSetHasCountChanged={toggleSetHasCountChanged}
									/>
								</Box>
							</CttOnboardingProvider>
						) : null
					}
				</Popper>
			</Manager>
		);
	},
);

const buttonLabelStyles = xcss({
	display: 'flex',
	alignItems: 'center',
});

const changeCountBadgeStyles = xcss({
	display: 'flex',
	marginTop: '0',
	marginRight: 'space.025',
	marginLeft: 'space.075',
	marginBottom: '0',
	outline: 0,
	borderRadius: '2em',
});

const buttonContainerStyles = xcss({
	width: 'min-content',
});

const spotlightContainerStyles = xcss({
	zIndex: 'spotlight',
});

export default ReviewChangesButton;
