import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import IssueGroup from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/scope/issues/group/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { Props } from './types.tsx';

export const Group = ({ item }: Props) =>
	fg('plans_performance_improvements_3')
		? item && <IssueGroup isStickyGroupHeader item={item} />
		: item && (
				<Box xcss={containerStyles}>
					<IssueGroup isStickyGroupHeader item={item} />
				</Box>
			);

const containerStyles = xcss({
	position: 'relative',
});
