import React, { useEffect, useState } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Calendar from '@atlaskit/calendar';
import { token } from '@atlaskit/tokens';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import colors from '@atlassian/jira-portfolio-3-common/src/colors/index.tsx';
import { IsoDateFormat } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/constants.tsx';
import { formatDateUTC } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/format.tsx';
import { getWeekStartDay } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/index.tsx';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import type { Props } from './types.tsx';

const CalendarInput = ({ date, onSelect }: Props) => {
	const locale = useLocale();
	const weekStartDay = getWeekStartDay(locale);

	return (
		<CalendarWrapper>
			<Calendar
				selected={[formatDateUTC(date.getTime(), IsoDateFormat)]}
				onSelect={onSelect}
				weekStartDay={weekStartDay}
				locale={locale}
				defaultDay={date.getDate()}
				defaultYear={date.getFullYear()}
				defaultMonth={date.getMonth() + 1}
			/>
		</CalendarWrapper>
	);
};

const CalendarInputOld = ({ date, onSelect }: Props) => {
	const [month, setMonth] = useState(date.getMonth());
	const [year, setYear] = useState(date.getFullYear());

	const locale = useLocale();
	const weekStartDay = getWeekStartDay(locale);

	useEffect(() => {
		setMonth(date.getMonth());
		setYear(date.getFullYear());
	}, [date]);

	const onChange = (
		event: {
			iso: string;
			type: 'left' | 'up' | 'right' | 'down' | 'prevMonth' | 'nextMonth' | 'prevYear' | 'nextYear';
			day: number;
			month: number;
			year: number;
		},
		_: UIAnalyticsEvent,
	) => {
		if (event.type === 'prevMonth') {
			if (month === 0) {
				setMonth(11);
				setYear(year - 1);
			} else {
				setMonth(month - 1);
			}
		} else if (event.type === 'nextMonth') {
			if (month === 11) {
				setMonth(0);
				setYear(year + 1);
			} else {
				setMonth(month + 1);
			}
		} else if (event.type === 'prevYear') {
			setYear(year - 1);
		} else if (event.type === 'nextYear') {
			setYear(year + 1);
		}
	};

	return (
		<CalendarWrapper>
			<Calendar
				selected={[formatDateUTC(date.getTime(), IsoDateFormat)]}
				month={month + 1}
				year={year}
				onSelect={onSelect}
				onChange={onChange}
				day={0}
				weekStartDay={weekStartDay}
				locale={locale}
			/>
		</CalendarWrapper>
	);
};

export default componentWithFG('smart_links_for_plans', CalendarInput, CalendarInputOld);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CalendarWrapper = styled.div({
	display: 'flex',
	justifyContent: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	boxShadow: token('elevation.shadow.raised', `0 3px 5px ${colors.N50A}, 0 0 1px ${colors.N40A}`),
	borderRadius: '3px',
});
