import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import mapDispatchToProps from './command.tsx';
import mapStateToProps from './query.tsx';
import type { DispatchProps, OwnProps, StateProps } from './types.tsx';
import ViewOld from './view-old.tsx';
import View from './view.tsx';

export default componentWithFG(
	'migrate_plan_filter_to_popup',
	connect<StateProps, DispatchProps, OwnProps, State>(mapStateToProps, mapDispatchToProps)(View),
	connect<StateProps, DispatchProps, OwnProps, State>(mapStateToProps, mapDispatchToProps)(ViewOld),
);
