import React from 'react';
import StatusViewLozenge from '@atlassian/jira-issue-field-status/src/ui/status-view-lozenge/index.tsx';
import type { ServerRestStatus as StatusValue } from '@atlassian/jira-issue-shared-types/src/common/types/status-type.tsx';
import type { CommonFieldComponentProps } from '../../common/types.tsx';

export const isStatusFieldComponentProps = (
	props: CommonFieldComponentProps,
): props is StatusFieldComponentProps =>
	'value' in props &&
	typeof props.value === 'number' &&
	'ideaKey' in props &&
	typeof props.ideaKey === 'string' &&
	'ideaId' in props &&
	'statuses' in props;

export type StatusFieldComponentProps = CommonFieldComponentProps & {
	value: number;
	statuses: { [key: number]: StatusValue };
	ideaKey: string;
	ideaId: string;
};

export const StatusField = ({ value, statuses, ideaId, ideaKey }: StatusFieldComponentProps) => {
	const statusValue = statuses[value];

	return <StatusFieldDisplay ideaId={ideaId} ideaKey={ideaKey} value={statusValue} />;
};

export const StatusFieldDisplay = ({
	ideaId: issueId,
	ideaKey: issueKey,
	value,
}: {
	ideaId: string;
	ideaKey: string;
	value: StatusValue;
}) => {
	return (
		<StatusViewLozenge
			isEditable={false}
			appearance="lozenge"
			initialValue={value}
			issueKey={issueKey}
			issueId={issueId}
			popperProps={{ strategy: 'absolute' }}
			projectType={null}
		/>
	);
};
