import React, {
	useCallback,
	useEffect,
	useRef,
	useState,
	type KeyboardEventHandler,
	type KeyboardEvent as ReactKeyboardEvent,
	type MouseEvent as ReactMouseEvent,
} from 'react';
import ReactFocusLock from 'react-focus-lock';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Badge from '@atlaskit/badge';
import { CustomThemeButton, type ThemeProps, type ThemeTokens } from '@atlaskit/button';
import Button, { IconButton } from '@atlaskit/button/new';
import CalendarPlusIcon from '@atlaskit/icon/core/migration/calendar-plus--schedule';
import { Box, Inline } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import OldButton from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import colors from '@atlassian/jira-portfolio-3-common/src/colors/index.tsx';
import InlineDialog from '@atlassian/jira-portfolio-3-common/src/inline-dialog/index.tsx';
import Spinner from '@atlassian/jira-portfolio-3-common/src/spinner/index.tsx';
import { getBody } from '@atlassian/jira-portfolio-3-portfolio/src/common/dom/index.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import ConfigurationDialog from './configuration-dialog/index.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

function Optimize({ existingTeams, onOptimize, selectedIssueCount, showSpinner }: Props) {
	const { formatMessage } = useIntl();
	const [isConfigureDialogOpen, setIsConfigureDialogOpen] = useState(false);

	const ref = useRef<HTMLDivElement>(null);

	const onWindowClick = useCallback(
		(e: MouseEvent) => {
			const node = e.target;
			if (
				!isConfigureDialogOpen ||
				!isDefined(ref.current) ||
				(node instanceof Element && ref.current.contains(node))
			)
				return;

			setIsConfigureDialogOpen(!isConfigureDialogOpen);
		},
		[isConfigureDialogOpen],
	);

	useEffect(() => {
		getBody().addEventListener('click', onWindowClick);
		return () => getBody().removeEventListener('click', onWindowClick);
	}, [onWindowClick]);

	const onClick = () => {
		setIsConfigureDialogOpen(!isConfigureDialogOpen);
	};

	const onPreviewResults = (
		_: ReactMouseEvent | ReactKeyboardEvent,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		setIsConfigureDialogOpen(!isConfigureDialogOpen);
		onOptimize(analyticsEvent);
	};

	const getTooltipMessage = () => {
		if (existingTeams.length === 0) {
			return formatMessage(
				fg('jira-issue-terminology-refresh-m3')
					? messages.noTeamsAvailableTooltipIssueTermRefresh
					: messages.noTeamsAvailableTooltip,
			);
		}
		return formatMessage(messages.optimizeTooltip);
	};

	const isButtonDisabled = showSpinner || existingTeams.length === 0;

	const disabledTheme = (current: (props: ThemeProps) => ThemeTokens, themeProps: ThemeProps) => ({
		...current(themeProps),
		buttonStyles: {
			...current(themeProps).buttonStyles,
			...{
				background: token('color.background.disabled', colors.N20A),
				color: token('color.text.disabled', colors.N70),
				cursor: 'not-allowed',
			},
		},
	});

	const buttonIconAfter = (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles.optimizeButtonAfterIcons}>
			{selectedIssueCount > 0 && <Badge>{selectedIssueCount}</Badge>}
			{showSpinner && (
				<span
					data-testid="portfolio-3-portfolio.app-simple-plans.top.title-bar.optimize.spinner"
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={styles.spinnerContainer}
				>
					<Spinner />
				</span>
			)}
		</div>
	);

	const buttonRef = useRef<HTMLButtonElement | null>(null);

	const handleKeyDownCapture: KeyboardEventHandler = (e) => {
		if (e.key === 'Escape') {
			setIsConfigureDialogOpen(false);
			setTimeout(() => {
				buttonRef.current?.focus();
			});
		}
	};
	const showIconAfter = selectedIssueCount > 0 || showSpinner;
	const getAutoscheduleButton = () => {
		if (getWillShowNav4()) {
			return (
				<div data-testid="portfolio-3-portfolio.app-simple-plans.top.title-bar.optimize.button">
					<Inline alignBlock="center">
						{showIconAfter ? (
							<Button
								iconBefore={(iconProps) => (
									<CalendarPlusIcon color={token('color.icon')} {...iconProps} />
								)}
								aria-label={formatMessage(messages.optimize)}
								appearance={isVisualRefreshEnabled() ? 'default' : 'subtle'}
								onClick={onClick}
								aria-haspopup="dialog"
								ref={buttonRef}
								isDisabled={isButtonDisabled}
								isSelected={isConfigureDialogOpen}
								aria-expanded={isConfigureDialogOpen}
							>
								{selectedIssueCount > 0 && <Badge>{selectedIssueCount}</Badge>}
								{showSpinner && (
									<Box
										paddingInlineStart="space.050"
										testId="portfolio-3-portfolio.app-simple-plans.top.title-bar.optimize.spinner"
									>
										<Spinner />
									</Box>
								)}
							</Button>
						) : (
							<IconButton
								icon={(iconProps) => (
									<CalendarPlusIcon color={token('color.icon')} {...iconProps} />
								)}
								label={formatMessage(messages.optimize)}
								appearance={isVisualRefreshEnabled() ? 'default' : 'subtle'}
								onClick={onClick}
								aria-haspopup="dialog"
								ref={buttonRef}
								isDisabled={isButtonDisabled}
								isSelected={isConfigureDialogOpen}
								aria-expanded={isConfigureDialogOpen}
							/>
						)}
					</Inline>
				</div>
			);
		}
		return (
			<div data-testid="portfolio-3-portfolio.app-simple-plans.top.title-bar.optimize.button">
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.optimizeButtonIconOnly}>
					{isButtonDisabled ? (
						<CustomThemeButton
							{...(showIconAfter && {
								iconAfter: buttonIconAfter,
							})}
							iconBefore={<CalendarPlusIcon label="" color="currentColor" />}
							theme={disabledTheme}
							ref={buttonRef}
							aria-expanded={isConfigureDialogOpen}
							aria-label={formatMessage(messages.optimize)}
						/>
					) : (
						<OldButton
							{...(showIconAfter && {
								iconAfter: buttonIconAfter,
							})}
							iconBefore={<CalendarPlusIcon label="" color={token('color.icon')} />}
							appearance="subtle"
							isSelected={isConfigureDialogOpen}
							onClick={onClick}
							ref={buttonRef}
							aria-expanded={isConfigureDialogOpen}
							ariaLabel={`${formatMessage(messages.optimize)} - ${getTooltipMessage()}`}
						/>
					)}
				</div>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.optimizeButtonLabelOnly}>
					{isButtonDisabled ? (
						<CustomThemeButton
							{...(showIconAfter && {
								iconAfter: buttonIconAfter,
							})}
							theme={disabledTheme}
							ref={buttonRef}
							aria-expanded={isConfigureDialogOpen}
						>
							<FormattedMessage {...messages.optimize} />
						</CustomThemeButton>
					) : (
						<OldButton
							{...(showIconAfter && {
								iconAfter: buttonIconAfter,
							})}
							isSelected={isConfigureDialogOpen}
							iconBefore={<CalendarPlusIcon label="" color={token('color.icon')} />}
							ref={buttonRef}
							aria-expanded={isConfigureDialogOpen}
							appearance="subtle"
							onClick={onClick}
						>
							<FormattedMessage {...messages.optimize} />
						</OldButton>
					)}
				</div>
			</div>
		);
	};
	return (
		<div ref={ref} onKeyDownCapture={handleKeyDownCapture}>
			<InlineDialog
				content={
					<ReactFocusLock>
						<ConfigurationDialog onPreviewResults={onPreviewResults} />
					</ReactFocusLock>
				}
				isOpen={isConfigureDialogOpen}
				onClose={() => {
					setTimeout(() => {
						buttonRef.current?.focus();
					});
				}}
				noPaddings
				placement="bottom-end"
				strategy="absolute"
			>
				<Tooltip content={getTooltipMessage()} position="bottom">
					{getAutoscheduleButton()}
				</Tooltip>
			</InlineDialog>
		</div>
	);
}

export default Optimize;
