import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	issueNotVisibleHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.hidden-issues.issue-not-visible-header',
		defaultMessage: '<span>{issueKey}</span> is no longer visible in your plan',
		description: 'Message for showing a single issue is hidden',
	},
	issueNotVisible: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.hidden-issues.issue-not-visible',
		defaultMessage:
			'This issue is missing some details that are specified in the issue sources of your plan. To make this issue visible, add these missing details to the issue.',
		description: 'Message for showing a single issue is hidden',
	},
	issuesNotVisibleHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.hidden-issues.issues-not-visible-header',
		defaultMessage: '<span>{numIssues} issues</span> are no longer visible in your plan',
		description: 'Message for showing multiple issues are hidden',
	},
	issuesNotVisible: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.hidden-issues.issues-not-visible',
		defaultMessage:
			'These issues are missing some details that are specified in the issue sources of your plan. To make these issues visible, add these missing details to the issues.',
		description: 'Message for showing multiple issues are hidden',
	},
	issueNotVisibleHeaderIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.hidden-issues.issue-not-visible-header-issue-term-refresh',
		defaultMessage: '<span>{issueKey}</span> is no longer visible in your plan',
		description: 'Message for showing a single issue is hidden',
	},
	issueNotVisibleIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.hidden-issues.issue-not-visible-issue-term-refresh',
		defaultMessage:
			'This work item is missing some details that are specified in the work sources of your plan. To make this work item visible, add these missing details to the work item.',
		description: 'Message for showing a single issue is hidden',
	},
	issuesNotVisibleHeaderIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.hidden-issues.issues-not-visible-header-issue-term-refresh',
		defaultMessage: '<span>{numIssues} work items</span> are no longer visible in your plan',
		description: 'Message for showing multiple issues are hidden',
	},
	issuesNotVisibleIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.hidden-issues.issues-not-visible-issue-term-refresh',
		defaultMessage:
			'These work items are missing some details that are specified in the work sources of your plan. To make these work items visible, add these missing details to the work item.',
		description: 'Message for showing multiple issues are hidden',
	},
});
