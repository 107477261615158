import {
	createSelector,
	createStructuredSelector,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { IssueStatusesById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-statuses/types.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { getEstimateBreakdown } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/util/issue-helper.tsx';
import type { PlanningUnit } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { getIssueStatusById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-statuses/index.tsx';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types.tsx';
import { getPlanningUnit } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/plan/index.tsx';
import { getJiraHoursPerDay } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/system/index.tsx';
import type { IssuesBreakdownByEstimate } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issues/types.tsx';
import type { OwnProps, MapStateToProps } from './types.tsx';

const getBreakdown = createSelector(
	[
		getPlanningUnit,
		getJiraHoursPerDay,
		getIssueStatusById,
		(_: State, { value }: OwnProps) => value,
	],
	(
		planningUnit: PlanningUnit,
		jiraHoursPerDay: number,
		issueStatuses: IssueStatusesById,
		issues: Issue[],
	): IssuesBreakdownByEstimate => {
		return getEstimateBreakdown(undefined, issues, issueStatuses, planningUnit, jiraHoursPerDay);
	},
);

const mapStateToProps: MapStateToProps = createStructuredSelector({
	breakdown: getBreakdown,
	planningUnit: getPlanningUnit,
});

export default mapStateToProps;
