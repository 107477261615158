import React from 'react';
import { type EmojiDescription, Emoji } from '@atlaskit/emoji';

import { type TextColor, Inline, Text } from '@atlaskit/primitives';

import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { useFieldName, useFieldEmoji } from '../../controllers/fields/index.tsx';

export const FieldLabel = ({ fieldKey, color }: { fieldKey: FieldKey; color?: TextColor }) => {
	const [fieldName] = useFieldName(fieldKey);
	const [emoji] = useFieldEmoji(fieldKey);
	const emojiDescription = useEmoji(emoji);

	return (
		<FieldLabelDisplay fieldName={fieldName} color={color} emojiDescription={emojiDescription} />
	);
};

export const FieldLabelDisplay = ({
	fieldName,
	color,
	emojiDescription,
}: {
	fieldName: string;
	color?: TextColor;
	emojiDescription?: EmojiDescription;
}) => {
	return (
		<Inline space="space.050" alignBlock="center">
			{emojiDescription && <Emoji emoji={emojiDescription} fitToHeight={16} />}
			<Text color={color || 'color.text.subtlest'} size="UNSAFE_small">
				{fieldName}
			</Text>
		</Inline>
	);
};

export const FieldLabelDisplayBold = ({
	fieldName,
	color,
	emojiDescription,
}: {
	fieldName: string;
	color?: TextColor;
	emojiDescription?: EmojiDescription;
}) => {
	return (
		<Inline space="space.050" alignBlock="center">
			{emojiDescription && <Emoji emoji={emojiDescription} fitToHeight={16} />}
			<Text color={color || 'color.text.subtle'} size="medium" weight="medium">
				{fieldName}
			</Text>
		</Inline>
	);
};
