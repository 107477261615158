.sections {
	display: flex;
	flex: 1 0 auto;
	height: 100%;
}

.section {
	overflow: hidden;
	display: flex;
	min-height: 100%;
	flex: 0 0 auto;
	/* Explicit height required for IE11 to properly grow height of flex children */
	height: 0;
}

.separator {
	position: relative;
	height: 100%;
	width: 10px;
	margin-right: -10px;
	left: -5px;
	cursor: ew-resize;
	user-select: none;
	overflow: visible;
	z-index: 2;
	flex: 0 0 auto;

	& > .handle {
		width: 0;
		border-right: thin solid var(--ds-border, var(--adg3-color-N50));
		margin-left: 4px;
		height: 100%;
	}
	& > .handle.drag-active,
	&:hover > .handle {
		border: thin solid var(--ds-border-brand, var(--adg3-color-B100));
		border-width: 0 thin 0;
	}
}

.story-title {
	font-family: sans-serif;
	font-size: 12px;
	position: absolute;
	right: 10px;
	top: 0;
}

.section.collapsedSection {
	cursor: pointer;
}

.title {
	display: flex;
	align-items: center;

	&.__header {
		justify-content: center;
	}

	& .truncate-text {
		overflow: hidden;
		text-overflow: ellipsis;
		display: inline-block;
	}

	& .title-icon {
		margin-left: 4px;
		display: flex;
		align-items: center;

		&.__collapsed {
			margin: 6px 0 0 0;
		}

		&.__collapsed > span {
			padding: 0;

			& > span {
				padding: 4px 3px;
			}
		}
	}
}

.columnTexts {
	display: flex;
	pointer-events: none;
}

.columnText {
	display: inline-flex;
	flex-direction: column;
	width: 100%;
	min-height: 100%;
}

.columnTextTitle {
	display: flex;
	align-items: center;
	writing-mode: vertical-rl;
	padding: 0 3px 0 0;
	margin-top: 16px;
	text-transform: uppercase;
	font-family: sans-serif;
	font-size: 10px;
	font-weight: var(--ds-font-weight-bold, bold);
	color: var(--ds-text-subtlest, var(--adg3-color-N200));
	background-color: transparent;
}
