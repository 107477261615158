import * as R from 'ramda';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { EPIC_LEVEL } from '@atlassian/jira-portfolio-3-common/src/hierarchy/index.tsx';
import type { ExternalIssue } from '../../common/api/types.tsx';
import { ISSUE_STATUS_CATEGORIES_CONST, PlanningUnits } from '../../common/view/constant.tsx';
import { getEstimateValue, getTimeSpentValue, isEstimated } from '../query/estimation/index.tsx';
import type { IssueStatusesById } from '../query/issue-statuses/types.tsx';
import type { IssuesStatusBreakdown, IssuesBreakdownByEstimate } from '../query/issues/types.tsx';
import type { IssueType } from '../state/domain/issue-types/types.tsx';
import type { Issue } from '../state/domain/issues/types.tsx';
import type { Project } from '../state/domain/projects/types.tsx';
import type { ScopeIssue } from '../state/domain/scope/types.tsx';

/**
 * First groups issues by the status category. The only recognised categories are TODO, INPROGRESS, and DONE.
 * Other statuses are ignored. Then, for each group of issues, calculate the total estimated work.
 * If a planningUniy and workingHours option is provided, then calculate based on that, otherwise use issue count.
 * @param issues
 * @param issueStatusesById
 * @param option Whether to calculate work based on planningUnit and workingHours
 */
export const getIssuesStatusBreakdown = (
	issues: Pick<Issue, 'storyPoints' | 'timeEstimate' | 'status'>[],
	issueStatusesById: IssueStatusesById,
	option?: {
		planningUnit: string;
		workingHours: number;
	},
): IssuesStatusBreakdown => {
	const getCategoryId = (statusId: string): string => {
		const issueStatus = issueStatusesById[statusId];
		if (issueStatus) {
			return String(issueStatus.categoryId);
		}
		log.safeInfoWithoutCustomerData(
			'portfolio.issue-helper',
			`Issue status not found: ${statusId}`,
		);
		return '';
	};

	const issuesGroupedByStatusCategory = issues.reduce<Record<string, Partial<Issue>[]>>(
		(acc, issue) => {
			if (issue.status) {
				const categoryId = getCategoryId(issue.status);
				if (categoryId !== '') {
					if (acc[categoryId]) {
						acc[categoryId].push(issue);
					} else {
						acc[categoryId] = [issue];
					}
				}
			}
			return acc;
		},
		{},
	);

	// If planningUnit and workingHours are provided, then we use those to calculate progress of each status category.
	// Otherwise, we simply use the issue count.
	let totalEstimatedWork = 0;
	const estimatedWorkGroupedByStatusCategory: Record<string, number> = Object.fromEntries(
		Object.entries(issuesGroupedByStatusCategory).map(
			([categoryId, issuesInThisCategory]): [string, number] => {
				if (option !== undefined) {
					const { planningUnit, workingHours } = option;
					const estimatedWork = issuesInThisCategory.reduce((acc, issue) => {
						switch (planningUnit) {
							case PlanningUnits.days:
							case PlanningUnits.hours:
								return (
									acc +
									// TODO: Current behaviour is to ignore timeSpent, but it doesn't really make sense
									// getTimeSpentValue(planningUnit, issue, workingHours) +
									getEstimateValue(planningUnit, issue, workingHours)
								);
							case PlanningUnits.storyPoints:
								return acc + (issue.storyPoints ?? 0);
							default:
								return acc + 1;
						}
					}, 0);
					// side-effecting but saves some work later
					totalEstimatedWork += estimatedWork;
					return [categoryId, estimatedWork];
				}
				// side-effecting but saves some work later
				totalEstimatedWork += issuesInThisCategory.length;
				return [categoryId, issuesInThisCategory.length];
			},
		),
	);

	const { TODO, INPROGRESS, DONE } = ISSUE_STATUS_CATEGORIES_CONST;
	return {
		byCategoryId: {
			[String(TODO)]: estimatedWorkGroupedByStatusCategory[TODO] ?? 0,
			[String(INPROGRESS)]: estimatedWorkGroupedByStatusCategory[INPROGRESS] ?? 0,
			[String(DONE)]: estimatedWorkGroupedByStatusCategory[DONE] ?? 0,
		},
		total: totalEstimatedWork,
	};
};

/**
 * Distribute estimation of issues by their categories.
 * Scenario issues doesn't have status and are intentionally ignored.
 */
export const getEstimateBreakdown = (
	issue: Issue | ScopeIssue | undefined,
	childIssues: Issue[],
	issueStatuses: IssueStatusesById,
	planningUnit: string,
	workingHours: number,
): IssuesBreakdownByEstimate => {
	const { TODO, INPROGRESS, DONE } = ISSUE_STATUS_CATEGORIES_CONST;
	const breakdown = {
		byCategoryId: {
			[TODO]: 0,
			[INPROGRESS]: 0,
			[DONE]: 0,
		},
		total: 0,
		unestimated: 0,
		issueCount: 0,
	};
	// eslint-disable-next-line @typescript-eslint/no-shadow
	const updateBreakdown = (issue: Issue | ScopeIssue) => {
		if (isEstimated(planningUnit, issue)) {
			// If the issue is scenario issue, there won't be an issue status associated so consider them as TODO.
			const categoryId: typeof TODO | typeof INPROGRESS | typeof DONE =
				R.path([issue.status || '', 'categoryId'], issueStatuses) || TODO;
			const remainingEstimate = getEstimateValue(planningUnit, issue, workingHours);
			const timeSpent = getTimeSpentValue(planningUnit, issue, workingHours);

			if (categoryId === DONE) {
				// Done should include both the time spent and the remaining time for the issue.
				breakdown.byCategoryId[categoryId] =
					(breakdown.byCategoryId[categoryId] || 0) + remainingEstimate + timeSpent;
			} else {
				// Inprogress and todo should include the remaining estimate only.
				// Time spent for these statuses will be attributed to 'done'.
				breakdown.byCategoryId[categoryId] =
					(breakdown.byCategoryId[categoryId] || 0) + remainingEstimate;
				breakdown.byCategoryId[DONE] = (breakdown.byCategoryId[DONE] || 0) + timeSpent;
			}
			breakdown.total += remainingEstimate + timeSpent;

			breakdown.issueCount += 1;
		} else {
			breakdown.unestimated += 1;
		}
	};

	childIssues.forEach(updateBreakdown);
	issue && updateBreakdown(issue);

	return breakdown;
};

export const isIssueSubTaskType = ({
	issue,
	issueTypesById,
}: {
	issue: ScopeIssue;
	issueTypesById: Record<number | string, IssueType>;
}): boolean => {
	const { type } = issue;
	const issueType = issueTypesById[type];
	return issueType && !!issueType.subTask;
};

export const getPossibleParentIssues = ({
	issue,
	issues,
	issueTypesById,
	projectsById,
}: {
	issue: ScopeIssue;
	issues: Issue[];
	issueTypesById: Record<number | string, IssueType>;
	projectsById: Record<number | string, Project>;
}): Issue[] => {
	const { level: issueLevel, project: issueProjectId } = issue;
	const isIssueSubtask = isIssueSubTaskType({ issue, issueTypesById });

	const { isSimplified: isIssueSimplified } = projectsById[issueProjectId] ?? {
		isSimplified: false,
	};

	const isIssueLevelOne = issueLevel === EPIC_LEVEL;

	return issues.filter((currentIssue) => {
		if (isIssueSimplified) {
			return (
				// TMP only allows reparent within project scope except for level one to level two (Epic to Initiative)
				currentIssue.level === issueLevel + 1 &&
				(currentIssue.project === issueProjectId || isIssueLevelOne)
			);
		}
		const { isSimplified: isCurrentIssueSimplified } = projectsById[currentIssue.project] ?? {
			isSimplified: false,
		};

		if (isCurrentIssueSimplified) {
			// Jira doesn't allow cross project-type reparenting
			return false;
		}

		return isIssueSubtask
			? /**
				 * [This case applies to CMP only]
				 * Allow reparenting subtask to issue of any non subtask-level
				 */
				currentIssue.level !== issueLevel
			: currentIssue.level === issueLevel + 1;
	});
};

// If baselineStart is after baselineEnd, swaps these dates, and returns the issue
export const swapIssueBaselineDatesIfInverted = <
	T extends Issue | ScopeIssue | ExternalIssue | null | undefined,
>(
	issue: T,
): T => {
	if (issue?.baselineStart && issue?.baselineEnd) {
		const { baselineStart, baselineEnd } = issue;
		return {
			...issue,
			baselineStart: Math.min(baselineStart, baselineEnd),
			baselineEnd: Math.max(baselineStart, baselineEnd),
		};
	}
	return issue;
};
