import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noIssueSourcesScrumTeam: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.sprints.no-issue-sources-scrum-team',
		defaultMessage: 'Associate this team with an issue source to view its sprints',
		description:
			'Text to show in place of sprints when the Scrum team is not associated with an issue source.',
	},
	noIssueSourcesKanbanTeam: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.sprints.no-issue-sources-kanban-team',
		defaultMessage: 'Associate this team with an issue source to view its iterations',
		description:
			'Text to show in place of sprints when the Kanban team is not associated with an issue source.',
	},
	kanbanNoTeam: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.sprints.kanban-no-team',
		defaultMessage:
			'<modal>To use capacity planning, add a team to this plan and link it to an issue source.</modal>',
		description: 'Message shown when there is no team in a kanban project.',
	},
	kanbanTeamNotLinked: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.sprints.kanban-team-not-linked',
		defaultMessage: '<modal>To use capacity planning, link this team to an issue source.</modal>',
		description:
			'Message shown when a team is added but not linked to an issue source in a kanban project.',
	},
	scrumNoBoardNoTeam: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.sprints.scrum-no-board-no-team',
		defaultMessage:
			'<modal>To use capacity planning, add a scrum board, then link it to a team.</modal>',
		description: 'Message shown when there is no board and no team in a scrum project.',
	},
	scrumNoBoardTeamAdded: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.sprints.scrum-no-board-team-added',
		defaultMessage:
			'<link>To use capacity planning, add a scrum board issue source to this plan and link it to this team.</link>',
		description: 'Message shown when a team is added but no board is present in a scrum project.',
	},
	scrumBoardNoTeam: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.sprints.scrum-board-no-team',
		defaultMessage:
			'<modal>To use capacity planning, link a team to the scrum board issue source of this plan.</modal>',
		description: 'Message shown when there is a scrum board but no team is linked to it.',
	},
	associateTeamToIssueSource: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.sprints.associate-team-to-issue-source',
		defaultMessage:
			'<modal>Associate this team to an issue source to view its capacity planning.</modal>',
		description: 'Default message prompting user to associate team with an issue source.',
	},
	noIssueSourcesScrumTeamIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.sprints.no-issue-sources-scrum-team-issue-term-refresh',
		defaultMessage: 'Associate this team with a work source to view its sprints',
		description:
			'Text to show in place of sprints when the Scrum team is not associated with an issue source.',
	},
	noIssueSourcesKanbanTeamIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.sprints.no-issue-sources-kanban-team-issue-term-refresh',
		defaultMessage: 'Associate this team with an work source to view its iterations',
		description:
			'Text to show in place of sprints when the Kanban team is not associated with an issue source.',
	},
});
