import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	priorities: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.priority-cell.priorities',
		defaultMessage: '{count} {priorityName} {count, plural, one {priority} other {priorities}}',
		description:
			'The label to show the number of issue priorities truncated within the group header.',
	},
});
