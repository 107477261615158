import React, { useState, useMemo, useCallback } from 'react';
import { Box, xcss, Inline, Pressable, Text } from '@atlaskit/primitives';
import Popup, { type TriggerProps } from '@atlaskit/popup';
import Image from '@atlaskit/image';
import { useIntl } from '@atlassian/jira-intl';
import { fg } from '@atlassian/jira-feature-gating';
import FlyoutContent from '../common/ui/flyout-content.tsx';
import commonMessages from '../common/ui/messages.tsx';
import type { Props } from './types.tsx';
import messages from './messages.tsx';

export const PriorityCell = (props: Props) => {
	const [isOpen, setIsOpen] = useState(false);
	const { formatMessage } = useIntl();
	const { priorities, groupName, fieldKey, value } = props;

	const label = useMemo(
		() =>
			fg('jira-issue-terminology-refresh-m3')
				? commonMessages.issueCountLabelIssueTermRefresh
				: commonMessages.issueCountLabel,
		[],
	);

	const content = useMemo(
		() =>
			priorities.map(({ count, name, iconUrl }) => ({
				title: (
					<Inline space="space.050" alignBlock="center">
						<Box as="span" xcss={iconWrapperStyles}>
							<Image src={iconUrl} alt={name} />
						</Box>
						{name}
					</Inline>
				),
				count,
			})),
		[priorities],
	);

	const message = useMemo(
		() =>
			formatMessage(messages.priorities, {
				// priorities are sorted by its sequence
				count: priorities[0].count,
				priorityName: priorities[0].name,
			}),
		[formatMessage, priorities],
	);

	const onToggle = useCallback(() => {
		setIsOpen(!isOpen);
	}, [isOpen]);

	const onClose = useCallback(() => {
		setIsOpen(false);
	}, []);

	const renderContent = useCallback(
		() => (
			<FlyoutContent
				header={commonMessages.priorityTitle}
				label={label}
				content={content}
				total={value.length}
				testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.priority-cell.priority-flyout"
			/>
		),
		[value, content, label],
	);

	const renderTrigger = useCallback(
		(triggerProps: TriggerProps) => (
			<Pressable
				xcss={[triggerButtonStyles, focusStyles]}
				padding="space.100"
				onClick={onToggle}
				{...triggerProps}
				aria-label={message}
				tabIndex={0}
				testId={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.priority-cell.${groupName}.${fieldKey}`}
			>
				<Text maxLines={1} weight="semibold">
					{message}
				</Text>
			</Pressable>
		),
		[onToggle, fieldKey, groupName, message],
	);

	return (
		<Popup
			isOpen={isOpen}
			onClose={onClose}
			trigger={renderTrigger}
			content={renderContent}
			placement="bottom-start"
		/>
	);
};

export default PriorityCell;

const triggerButtonStyles = xcss({
	background: 'none',
	width: 'calc(100% - 4px)',
	display: 'flex',
	alignItems: 'center',
	boxSizing: 'border-box',
	margin: 'space.025',
});

// Override the outline-offset in Pressable, that set to be 2px
const focusStyles = xcss({
	':focus': {
		outlineOffset: 'space.0',
		borderRadius: 'border.radius',
	},
	':focus-visible': {
		outlineOffset: 'space.0',
		borderRadius: 'border.radius',
	},
});

const iconWrapperStyles = xcss({
	width: '16px',
	height: '16px',
});
