import React, { Component } from 'react';
import * as R from 'ramda';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, { ModalHeader, ModalTitle, ModalBody } from '@atlaskit/modal-dialog'; // ignore-for-ENGHEALTH-17759
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { injectIntl } from '@atlassian/jira-intl';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import { MODAL_DIALOG_WIDTH, SET_TEAM } from '../../types.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

// eslint-disable-next-line jira/react/no-class-components
class SetTeamsDialog extends Component<Props> {
	getCurrentValue = () => {
		const {
			intl: { formatMessage },
			selectedIssues,
			teamsById,
		} = this.props;
		const firstValue = selectedIssues[0].team;
		const isSameValue = !selectedIssues.some((issue) => !R.equals(issue.team, firstValue));

		if (isSameValue && !firstValue) {
			return formatMessage(commonMessages.none);
		}

		if (isSameValue && firstValue) {
			return teamsById[firstValue].title;
		}
		return formatMessage(messages.variousValues);
	};

	render() {
		const {
			intl: { formatMessage },
			toggleModalDialog,
			bulkUpdateAttribute,
			teams,
			UpdateAttributeForm,
			bulkActionSuccess,
		} = this.props;
		return (
			<ShortcutScope>
				<ModalDialog
					autoFocus
					shouldScrollInViewport
					onClose={toggleModalDialog}
					width={MODAL_DIALOG_WIDTH}
					testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-teams"
				>
					<ModalHeader>
						<ModalTitle>{formatMessage(messages.setTeamHeader)}</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<UpdateAttributeForm
							updatedAttributeType={SET_TEAM}
							options={teams.map((el) => ({
								value: el.id,
								label: el.title,
							}))}
							currentValue={this.getCurrentValue()}
							onCancel={toggleModalDialog}
							onSubmit={(formData: { [key: string]: string }, analyticsEvent: UIAnalyticsEvent) => {
								bulkUpdateAttribute(formData);
								toggleModalDialog();
								bulkActionSuccess(analyticsEvent);
							}}
						/>
					</ModalBody>
				</ModalDialog>
			</ShortcutScope>
		);
	}
}

export default injectIntl(SetTeamsDialog);
