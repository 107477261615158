import React from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { DateCell } from './date-cell/index.tsx';
import EstimateCell from './estimate-cell/index.tsx';
import StatusCell from './status-cell/index.tsx';
import type { Props } from './types.tsx';
import UserCell from './user-cell/index.tsx';
import PriorityCell from './priority-cell/index.tsx';

export const AggregateField = ({ field, groupName }: Props) => {
	if (!field || field?.value === undefined) {
		// Leave blank if the value is not defined
		return null;
	}
	const { value, fieldKey, type } = field;

	switch (fieldKey) {
		case 'dueDate':
		case 'startDate':
		case 'targetStart':
		case 'targetEnd':
			return <DateCell fieldKey={fieldKey} groupName={groupName} value={value} type={type} />;
		case 'storyPoints':
		case 'timeEstimate':
			return (
				<EstimateCell fieldKey={fieldKey} groupName={groupName} rawValue={value} type={type} />
			);

		// second iteration
		case 'status':
		case 'breakdown':
			if (!fg('aggregate_fields_for_plan_m2')) {
				return null;
			}
			return <StatusCell fieldKey={fieldKey} groupName={groupName} value={value} />;

		case 'assignee':
			if (!fg('aggregate_fields_for_plan_m2')) {
				return null;
			}
			return <UserCell fieldKey={fieldKey} groupName={groupName} rawValues={value} />;

		case 'priority':
			if (!fg('aggregate_fields_for_plan_m2')) {
				return null;
			}
			return <PriorityCell fieldKey={fieldKey} groupName={groupName} value={value} />;

		default:
			return null;
	}
};

export default AggregateField;
