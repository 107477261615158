import React from 'react';
import { xcss, Box } from '@atlaskit/primitives';
import type { Locale } from '@atlassian/jira-common-constants/src/supported-locales.tsx';
import { useCurrentUser } from '@atlassian/jira-platform-services-user-current/src/main.tsx';
import { formatTimezonedWithLocale } from '@atlassian/jira-platform-utils-date-fns/src/main.tsx';
import { DATETIME_FORMAT } from '@atlassian/jira-polaris-lib-date-time/src/index.tsx';
import { CommonDecoratedTag } from '@atlassian/jira-polaris-lib-decoration/src/ui/decoration/common/index.tsx';
import type { TimeZone } from '@atlassian/jira-shared-types/src/general.tsx';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import type { CommonFieldComponentProps } from '../../common/types.tsx';

export const isDatetimeFieldComponentProps = (
	props: CommonFieldComponentProps,
): props is DatetimeFieldComponentProps => 'value' in props && typeof props.value === 'number';

export type DatetimeFieldComponentProps = CommonFieldComponentProps & {
	value: number;
};

export const DatetimeField = ({ value }: DatetimeFieldComponentProps) => {
	const locale = useLocale();
	const { data } = useCurrentUser();

	const currentUserTimeZone = ('timeZone' in data.user ? data.user.timeZone : 'UTC') || 'UTC';

	return <DatetimeFieldDisplay value={value} locale={locale} timezone={currentUserTimeZone} />;
};

export const DatetimeFieldDisplay = ({
	value,
	locale,
	timezone = 'UTC',
}: {
	value: number;
	locale: Locale;
	timezone: TimeZone;
}) => (
	<Box xcss={maxContentStyles}>
		<CommonDecoratedTag
			value={formatTimezonedWithLocale(value, DATETIME_FORMAT, locale, timezone)}
		/>
	</Box>
);

const maxContentStyles = xcss({
	width: 'max-content',
});
