import React, { forwardRef, type ReactNode, type Ref } from 'react';
import Tooltip from '@atlaskit/tooltip';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl, type MessageDescriptor } from '@atlassian/jira-intl';
import { useDateFormatter } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/format.tsx';
import { MIN, MAX, type AggregateType } from '../types.tsx';

import messages from './messages.tsx';

type Props = {
	fieldKey: string;
	groupName: string;
	value: number;
	type: AggregateType;
};

const getMessageKey = (fieldKey: string, type: AggregateType): MessageDescriptor => {
	switch (fieldKey) {
		case 'startDate':
			return type === MAX ? messages.latestStartDateTooltip : messages.earliestStartDateTooltip;
		case 'targetStart':
			return type === MAX ? messages.latestTargetStartTooltip : messages.earliestTargetStartTooltip;
		case 'dueDate':
			return type === MIN ? messages.earliestDueDateTooltip : messages.latestDueDateTooltip;
		case 'targetEnd':
			return type === MIN ? messages.earliestTargetEndTooltip : messages.latestTargetEndTooltip;
		default:
			// Not possible to run into this case
			return messages.earliestStartDateTooltip;
	}
};

const ToolTipContainer = forwardRef<HTMLElement, { children?: ReactNode }>((props, ref) => (
	<Box
		xcss={containerStyles}
		padding="space.100"
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		ref={ref as Ref<HTMLDivElement>}
		// eslint-disable-next-line react/jsx-props-no-spreading
		{...props}
		tabIndex={0}
	/>
));

export const DateCell = ({ value, fieldKey, type, groupName }: Props) => {
	const { formatTimestamp } = useDateFormatter();
	const { formatMessage } = useIntl();

	const messageKey = getMessageKey(fieldKey, type);
	const formattedDate = formatTimestamp(value);

	return (
		<Tooltip
			position="bottom"
			content={formatMessage(messageKey, { date: formattedDate })}
			tag={ToolTipContainer}
		>
			<Box
				as="span"
				testId={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.date-cell.${groupName}.${fieldKey}`}
				xcss={valueStyles}
			>
				{formattedDate}
			</Box>
		</Tooltip>
	);
};

const containerStyles = xcss({
	display: 'flex',
	cursor: 'default',
	':focus': {
		outlineColor: 'color.border.focused',
	},
	':focus-visible': {
		outlineColor: 'color.border.focused',
	},
});

const valueStyles = xcss({
	flex: '1',
	fontWeight: token('font.weight.semibold'),
});
