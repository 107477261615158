import React, { Component } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, { ModalHeader, ModalTitle, ModalBody } from '@atlaskit/modal-dialog'; // ignore-for-ENGHEALTH-17759
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { injectIntl } from '@atlassian/jira-intl';
import { MODAL_DIALOG_WIDTH } from '../../types.tsx';
import messages from './messages.tsx';
import SetRankForm from './set-rank-form/index.tsx';
import type { Props } from './types.tsx';

// eslint-disable-next-line jira/react/no-class-components
class SetRankDialog extends Component<Props> {
	static defaultProps = { SetRankForm };

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	submitForm = (formData: any, analyticsEvent: UIAnalyticsEvent) => {
		const { bulkUpdateRank, toggleModalDialog, bulkActionSuccess } = this.props;
		bulkUpdateRank(formData);
		toggleModalDialog();
		bulkActionSuccess(analyticsEvent);
	};

	render() {
		// eslint-disable-next-line @typescript-eslint/no-shadow
		const { intl, toggleModalDialog, SetRankForm } = this.props;
		return (
			<ShortcutScope>
				<ModalDialog
					autoFocus
					shouldScrollInViewport
					onClose={toggleModalDialog}
					width={MODAL_DIALOG_WIDTH}
					testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-rank"
				>
					<ModalHeader>
						<ModalTitle>{intl.formatMessage(messages.setHeader)}</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<SetRankForm onCancel={toggleModalDialog} onSubmit={this.submitForm} />
					</ModalBody>
				</ModalDialog>
			</ShortcutScope>
		);
	}
}

export default injectIntl(SetRankDialog);
