/**
 * @generated SignedSource<<33fa1597be30a61c5c17c0b8dfdb54f5>>
 * @relayHash 675d39a38067b8a148edff28d8a420eb
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 135fdce8d6055f49532e7ef266de2f0912c0d3a91406e493307c449578114519

import type { ConcreteRequest, Query } from 'relay-runtime';
export type servicesAssociatedIssuesDataQuery$variables = {
  cloudId: string;
  jql: string;
  limit: number;
};
export type servicesAssociatedIssuesDataQuery$data = {
  readonly jira: {
    readonly issueSearchStable: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly issueId: string;
          readonly issueTypeField: {
            readonly issueType: {
              readonly avatar: {
                readonly medium: string | null | undefined;
              } | null | undefined;
            } | null | undefined;
          } | null | undefined;
          readonly key: string;
          readonly projectField: {
            readonly project: {
              readonly projectId: string | null | undefined;
            } | null | undefined;
          } | null | undefined;
          readonly summaryField: {
            readonly text: string | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type servicesAssociatedIssuesDataQuery = {
  response: servicesAssociatedIssuesDataQuery$data;
  variables: servicesAssociatedIssuesDataQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "jql"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "limit"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "limit"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "jql",
        "variableName": "jql"
      }
    ],
    "kind": "ObjectValue",
    "name": "issueSearchInput"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "issueId"
},
v3 = {
  "kind": "ScalarField",
  "name": "key"
},
v4 = {
  "kind": "ScalarField",
  "name": "text"
},
v5 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "medium"
    }
  ]
},
v6 = {
  "kind": "ScalarField",
  "name": "projectId"
},
v7 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "servicesAssociatedIssuesDataQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraIssueConnection",
            "kind": "LinkedField",
            "name": "issueSearchStable",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraIssueEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "JiraIssue",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "concreteType": "JiraSingleLineTextField",
                        "kind": "LinkedField",
                        "name": "summaryField",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/)
                        ]
                      },
                      {
                        "concreteType": "JiraIssueTypeField",
                        "kind": "LinkedField",
                        "name": "issueTypeField",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "JiraIssueType",
                            "kind": "LinkedField",
                            "name": "issueType",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/)
                            ]
                          }
                        ]
                      },
                      {
                        "concreteType": "JiraProjectField",
                        "kind": "LinkedField",
                        "name": "projectField",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "JiraProject",
                            "kind": "LinkedField",
                            "name": "project",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "servicesAssociatedIssuesDataQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraIssueConnection",
            "kind": "LinkedField",
            "name": "issueSearchStable",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraIssueEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "JiraIssue",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "concreteType": "JiraSingleLineTextField",
                        "kind": "LinkedField",
                        "name": "summaryField",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v7/*: any*/)
                        ]
                      },
                      {
                        "concreteType": "JiraIssueTypeField",
                        "kind": "LinkedField",
                        "name": "issueTypeField",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "JiraIssueType",
                            "kind": "LinkedField",
                            "name": "issueType",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v7/*: any*/)
                            ]
                          },
                          (v7/*: any*/)
                        ]
                      },
                      {
                        "concreteType": "JiraProjectField",
                        "kind": "LinkedField",
                        "name": "projectField",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "JiraProject",
                            "kind": "LinkedField",
                            "name": "project",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              (v7/*: any*/)
                            ]
                          },
                          (v7/*: any*/)
                        ]
                      },
                      (v7/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "135fdce8d6055f49532e7ef266de2f0912c0d3a91406e493307c449578114519",
    "metadata": {},
    "name": "servicesAssociatedIssuesDataQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "cb5f56f0e093c1e3e3b9c42f34c85ed9";

export default node;
