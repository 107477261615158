import React from 'react';
import { Box } from '@atlaskit/primitives';
import StatusBreakdown from '@atlassian/jira-portfolio-3-portfolio/src/common/view/status-breakdown/index.tsx';
import { BREAKDOWN_SPRINT_CAPACITY_BY } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import commonMessages from '../common/ui/messages.tsx';
import type { Props } from './types.tsx';

import StatusFlyout from './status-flyout/index.tsx';

export const StatusCell = (props: Props) => {
	const { byCategoryId, value, fieldKey, groupName } = props;
	const flyout = fieldKey === 'status' ? <StatusFlyout value={value} /> : undefined;
	const flyoutHeader =
		fieldKey === 'status' ? commonMessages.statusTitle : commonMessages.breakdownTitle;

	return (
		<Box paddingInlineStart="space.200" paddingInlineEnd="space.200">
			<StatusBreakdown
				planningUnit="issueCount"
				breakdownBy={BREAKDOWN_SPRINT_CAPACITY_BY.STATUS}
				byCategoryId={byCategoryId}
				total={value.length}
				testId={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.status-cell.${groupName}.${fieldKey}`}
				flyout={flyout}
				flyoutHeader={flyoutHeader}
			/>
		</Box>
	);
};

export default StatusCell;
