import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	emptyPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.status-key-filter.empty-placeholder',
		defaultMessage: 'All',
		description: 'This is a placeholder for no filter set',
	},
	searchStatusPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.status-key-filter.search-status-placeholder',
		defaultMessage: 'Show issues from statuses',
		description: 'This is a placeholder for search',
	},
	categoriesLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.status-key-filter.categories-label',
		defaultMessage: 'All {status} statuses',
		description: 'This is a label for category statuses',
	},
	unsavedIssues: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.status-key-filter.unsaved-issues',
		defaultMessage: 'Unsaved issues',
		description: 'This is a label for unsaved issues',
	},
	searchStatusLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.status-key-filter.search-status-label',
		defaultMessage: 'Choose from statuses',
		description: 'Aria label for statuses search filed that appears on activating Filters button',
	},
	searchStatusPlaceholderIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.status-key-filter.search-status-placeholder-issue-term-refresh',
		defaultMessage: 'Show work items from statuses',
		description: 'This is a placeholder for search',
	},
	unsavedIssuesIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.status-key-filter.unsaved-issues-issue-term-refresh',
		defaultMessage: 'Unsaved work items',
		description: 'This is a label for unsaved issues',
	},
});
