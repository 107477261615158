import React, { useRef, useLayoutEffect } from 'react';
import ArrowLeftIcon from '@atlaskit/icon/core/arrow-left';
import ArrowRightIcon from '@atlaskit/icon/core/arrow-right';
import ChevronLeftLargeIcon from '@atlaskit/icon/glyph/chevron-left-large';
import ChevronRightLargeIcon from '@atlaskit/icon/glyph/chevron-right-large';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import { useSmooth } from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/controllers/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

/**
 * A button when clicked will shift the timeline left or right based on props
 */
export default function PanningControl({ direction }: Props) {
	const { formatMessage } = useIntl();
	const [smooth, { shiftTimeline, setSmooth }] = useSmooth();
	const triggeredRef = useRef(false);

	useLayoutEffect(() => {
		if (smooth && triggeredRef.current) {
			setSmooth(false);
			triggeredRef.current = false;
		}
	}, [setSmooth, smooth]);

	let label = formatMessage(messages.shiftTimelineLeft);
	let icon = isVisualRefreshEnabled() ? (
		<ArrowLeftIcon label={label} color="currentColor" />
	) : (
		<ChevronLeftLargeIcon label={label} />
	);

	if (direction === 'right') {
		label = formatMessage(messages.shiftTimelineRight);
		icon = isVisualRefreshEnabled() ? (
			<ArrowRightIcon label={label} color="currentColor" />
		) : (
			<ChevronRightLargeIcon label={label} />
		);
	}

	return (
		<Tooltip content={label}>
			<Button
				appearance="subtle"
				iconBefore={icon}
				onClick={() => {
					setSmooth(true);
					shiftTimeline(direction, 'scrollButton');
					triggeredRef.current = true;
				}}
				testId={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.panning-control.ui.${direction}`}
			/>
		</Tooltip>
	);
}
