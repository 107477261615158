import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const kanbanFlyoutAsyncEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		// Migrated from async-advanced-roadmaps-sprint-flyout
		() => import(/* webpackChunkName: "plans-sprint-flyout-kanban" */ './ui'),
	),
	getPreloadProps: () => ({}),
});
