import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types.tsx';

export const MIN = 'MIN' as const;
export const MAX = 'MAX' as const;
export const SUM = 'SUM' as const;
export const MAP = 'MAP' as const;

export type AggregateType = typeof MIN | typeof MAX | typeof SUM | typeof MAP;

export type OwnProps = {
	groupName: string;
	id: string;
};

export type AggregateFieldTypes = {
	startDate: number | undefined;
	dueDate: number | undefined;
	targetStart: number | undefined;
	targetEnd: number | undefined;
	storyPoints: number | undefined;
	timeEstimate: number | undefined;
	status: string[];
	assignee: string[];
	breakdown: string[];
	team: string[];
	priority: string[];
	progressByEstimation: Issue[];
};

export type FieldKey = keyof AggregateFieldTypes;

export type NumberFieldKey =
	| 'dueDate'
	| 'targetStart'
	| 'targetEnd'
	| 'storyPoints'
	| 'timeEstimate';

export type StringFieldKey = 'status' | 'assignee' | 'priority' | 'team';

export type AggregateValue<K extends FieldKey> = {
	fieldKey: K;
	value: AggregateFieldTypes[K];
	type: AggregateType;
};

export type AggregateValuesMap = {
	[K in FieldKey]: AggregateValue<K>;
};

export type AggregateField = AggregateValuesMap[FieldKey];

export type StateProps = {
	field: AggregateField | undefined;
};

export type MapStateToProps = (state: State, ownProps: OwnProps) => StateProps;
export type Props = StateProps & OwnProps;
