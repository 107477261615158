import React, { Fragment } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { TABLE_ISSUE } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/table/index.tsx';
import { OPTIMIZED } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/app/types.tsx';
import { swapIssueBaselineDatesIfInverted } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/util/issue-helper.tsx';
import {
	getCurrentValue,
	hasValueChanged,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/util.tsx';
import { OPTIMIZED_COLOR } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/colours/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import Arrow from './arrow/view.tsx';
import Tooltip from './tooltip/view.tsx';
import type { Props } from './types.tsx';
import {
	useViewportTimelineRange,
	useContainerWidth,
	useViewportWidth,
	useArrowClickHandler,
} from './utils.tsx';

/** The vertical scrollbar width. */
const SCROLLBAR_WIDTH = 18;

/** The width of the arrow icons. */
const ARROW_WIDTH = 24;

/** The padding to allow space for the active dependency marker. */
const DEPENDENCY_MARKER_PADDING_OLD = 24;
const DEPENDENCY_MARKER_PADDING = 32;

const margin = {
	left: 0,
	right: SCROLLBAR_WIDTH,
} as const;

const getRowOffset = (index: number, rowHeight: Props['rowHeight']) => {
	let result = !fg('plan-timeline-non-transposed') ? 90 : 0;
	for (let i = 0; i < index; i++) {
		result += rowHeight(i);
	}

	return result;
};

/** Renders all the issue arrows onto the timeline. */
export default function Arrows({
	rowHeight,
	containerWidth: containerWidthFromProps,
	scope,
	timelineRange,
	onTimeScaleCustomDatesChange,
	onTimeScaleOptionChange,
	verticalRenderingWindow,
	mode,
	virtualizedRows,
}: Props) {
	const containerWidth = useContainerWidth(containerWidthFromProps);
	const viewportWidth = useViewportWidth(containerWidth);
	const viewportTimelineRange = useViewportTimelineRange(timelineRange);
	const isOptimized = mode === OPTIMIZED;

	const onArrowClick = useArrowClickHandler({
		changeTimeScaleCustomDates: onTimeScaleCustomDatesChange,
		changeTimeScaleOption: onTimeScaleOptionChange,
		viewportWidth,
		containerWidth,
		viewport: viewportTimelineRange,
	});

	const msPerPx = (viewportTimelineRange.end - viewportTimelineRange.start) / viewportWidth;
	const start = viewportTimelineRange.start;
	const end = viewportTimelineRange.end - SCROLLBAR_WIDTH * msPerPx;

	const innerRange = { start, end };

	const isArrowOnTheBar = (
		direction: 'left' | 'right',
		baselineStart?: number | null,
		baselineEnd?: number | null,
	) => {
		switch (direction) {
			case 'left':
				return baselineEnd != null && baselineEnd > innerRange.start + ARROW_WIDTH * msPerPx;

			case 'right':
				return baselineStart != null && baselineStart < innerRange.end - ARROW_WIDTH * msPerPx;

			default:
				// prettier-ignore
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				return (direction as never);
		}
	};

	const renderArrow =
		({
			offsetY,
			color,
			baselineStart,
			baselineEnd,
			issueKey,
			summary,
		}: {
			offsetY: number;
			color: string | undefined;
			baselineStart: number | null | undefined;
			baselineEnd: number | null | undefined;
			issueKey?: number;
			summary?: string;
		}) =>
		// False-positive :/
		// eslint-disable-next-line react/no-unstable-nested-components
		(direction: 'left' | 'right') => (
			<Tooltip
				direction={direction}
				baselineStart={baselineStart}
				baselineEnd={baselineEnd}
				timelineRange={innerRange}
			>
				<Arrow
					color={color}
					issueKey={issueKey}
					transparent={isArrowOnTheBar(direction, baselineStart, baselineEnd)}
					direction={direction}
					width={ARROW_WIDTH}
					offsetY={offsetY}
					onClick={() =>
						onArrowClick(
							direction,
							{
								start: baselineStart,
								end: baselineEnd,
							},
							margin[direction] +
								(isVisualRefreshEnabled()
									? DEPENDENCY_MARKER_PADDING
									: DEPENDENCY_MARKER_PADDING_OLD),
						)
					}
					margin={margin}
					issueSummary={summary}
				/>
			</Tooltip>
		);

	const renderRow = (
		index: number,
		{
			offsetY,
		}: {
			offsetY: number;
		},
	) => {
		const issue = scope[index];

		if (issue == null) {
			return null;
		}

		switch (issue.tag) {
			case TABLE_ISSUE: {
				const { baselineStart, baselineEnd, issueKey, optimized, summary } = fg(
					'due_date_warning_on_plan_timeline',
				)
					? // Ensure correct order of baselineStart & baselineEnd for rendering & scroll-to-bar behavior
						swapIssueBaselineDatesIfInverted(issue.value)
					: issue.value;

				let hasOptimizedChanges = false;
				if (isOptimized && optimized) {
					for (const [optimizedKey, optimizedValue] of Object.entries(optimized)) {
						if (hasValueChanged(getCurrentValue(issue.value, optimizedKey), optimizedValue)) {
							hasOptimizedChanges = true;
							break;
						}
					}
				}

				const minDate = baselineStart ?? baselineEnd;
				const maxDate = baselineEnd ?? baselineStart;

				const hasLeftArrow = minDate != null && minDate < innerRange.start;
				const hasRightArrow = maxDate != null && maxDate > innerRange.end;
				const arrow = renderArrow({
					offsetY,
					color: hasOptimizedChanges ? OPTIMIZED_COLOR : undefined,
					baselineStart,
					baselineEnd,
					issueKey,
					summary,
				});

				return (
					<Fragment key={index}>
						{hasLeftArrow && arrow('left')}
						{hasRightArrow && arrow('right')}
					</Fragment>
				);
			}
			default:
				return null;
		}
	};

	if (!Number.isFinite(verticalRenderingWindow.overscanStopIndex)) {
		return null;
	}

	const rows = [];

	if (!fg('plan-timeline-non-transposed')) {
		virtualizedRows?.forEach((rowIndex) => {
			rows.push(renderRow(rowIndex, { offsetY: getRowOffset(rowIndex, rowHeight) }));
		});
	} else {
		for (
			let i = verticalRenderingWindow.overscanStartIndex;
			i <= verticalRenderingWindow.overscanStopIndex;
			i++
		) {
			rows.push(renderRow(i, { offsetY: getRowOffset(i, rowHeight) }));
		}
	}

	return <>{rows}</>;
}
