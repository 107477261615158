import * as R from 'ramda';
import { VIEW_MODES } from '@atlassian/jira-portfolio-3-common/src/common/types/view-mode.tsx';
import { indexBy } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import { colourByOptions } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/colours/index.tsx';
import {
	type Grouping,
	CustomFields,
	GROUPING,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import {
	getCustomFieldIdFromCustomFieldGrouping,
	isRoadmapGroupedByCustomField,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/custom-fields/index.tsx';
import type { CustomField } from '../../state/domain/custom-fields/types.tsx';
import type { Issue } from '../../state/domain/issues/types.tsx';
import type {
	ColourComponent,
	ColourIssueType,
	ColourLabel,
	ColourPriority,
	ColourSelect,
} from '../../state/domain/view-settings/colour-by/types.tsx';
import {
	type CustomFieldValuesGroup,
	ALL_OTHER_ISSUES,
} from '../../state/domain/view-settings/custom-field-values-groups/types.tsx';
import type { Sorting } from '../../state/domain/view-settings/visualisations/types.tsx';
import type { State } from '../../state/types.tsx';
import {
	getCustomFields,
	getCustomFieldById,
	getCustomFieldsById,
} from '../custom-fields/index.tsx';
import { getIssues } from '../raw-issues/index.tsx';
import {
	getVisualisationViewSettings,
	getColorByViewSettings,
	getCustomFieldValuesGroupsViewSettings,
	getViewMode,
} from '../view-settings/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { changeGroup } from '../../state/domain/view-settings/visualisations/actions';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { defaultSorting } from '../../state/domain/view-settings/visualisations/types';

export const getStickyHeaderScrollTop = (state: State) =>
	state.ui.Main.Tabs.Roadmap.StickyHeader.scrollTop;

export const getVisualisationGrouping = (state: State) =>
	getVisualisationViewSettings(state).grouping;

export const getVisualisationSorting = (state: State): Sorting =>
	getVisualisationViewSettings(state).sorting;

export const getShowSprints = (state: State) => getVisualisationViewSettings(state).showSprints;

export const getShowAggregate = (state: State) => getVisualisationViewSettings(state).showAggregate;

export const getSprintsAreShown = (state: State) => {
	if (getViewMode(state) !== VIEW_MODES.TIMELINE) return false;
	return (
		getShowSprints(state) &&
		(getVisualisationGrouping(state) === GROUPING.TEAM ||
			getVisualisationGrouping(state) === GROUPING.SPRINT)
	);
};

export const getColorByValue = (state: State) => getColorByViewSettings(state).colourByValue;
export const getColourMaps = (state: State) => getColorByViewSettings(state).colourMaps;
export const getLastUsedColour = (state: State) => getColorByViewSettings(state).lastUsed;

export const getColourIssueTypes = (state: State) => getColorByViewSettings(state).colourIssueTypes;

export const getColourLabels = (state: State) => getColorByViewSettings(state).colourLabels;

export const getColourComponents = (state: State) => getColorByViewSettings(state).colourComponents;

export const getColourPriorities = (state: State) => getColorByViewSettings(state).colourPriorities;

export const getColourSelects = (state: State) => getColorByViewSettings(state).colourSelects;

export const getColourByIssueTypeColourMapPure = (
	colourIssueTypes: ColourIssueType[],
): {
	[key: string]: string;
} => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const result: Record<string, any> = {};
	for (const { colour, issueTypes } of colourIssueTypes) {
		if (colour && issueTypes) {
			for (const issueType of issueTypes) {
				result[issueType] = colour;
			}
		}
	}
	return result;
};

// Convert colourLabels entries into {[label]: colour} map
export const getColourByLabelColourMapPure = (
	colourLabels: ColourLabel[],
): {
	[key: string]: string;
} => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const result: Record<string, any> = {};
	for (const { colour, labels } of colourLabels) {
		if (colour) {
			for (const label of labels) {
				result[label] = colour;
			}
		}
	}
	return result;
};

export const getColourByComponentColourMapPure = (
	colourComponents: ColourComponent[],
): {
	[key: string]: string;
} => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const result: Record<string, any> = {};
	for (const { colour, components } of colourComponents) {
		if (colour) {
			for (const component of components) {
				result[component] = colour;
			}
		}
	}
	return result;
};

export const getColourByPriorityColourMapPure = (
	colourPriorities: ColourPriority[] = [],
): {
	[key: string]: string;
} => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const result: Record<string, any> = {};
	for (const { colour, priorities } of colourPriorities) {
		if (colour && priorities) {
			for (const priority of priorities) {
				result[priority] = colour;
			}
		}
	}
	return result;
};

export const getColourByIssueTypeColourMap = createSelector(
	[getColourIssueTypes],
	getColourByIssueTypeColourMapPure,
);

export const getColourByLabelColourMap = createSelector(
	[getColourLabels],
	getColourByLabelColourMapPure,
);

export const getColourByComponentColourMap = createSelector(
	[getColourComponents],
	getColourByComponentColourMapPure,
);

export const getColourByPriorityColourMap = createSelector(
	[getColourPriorities],
	getColourByPriorityColourMapPure,
);

export const getColourBySelectColourMapPure = (
	colorByValue: string,
	colourOptions: {
		[key: string]: ColourSelect[];
	},
): {
	[key: string]: string;
} => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const result: Record<string, any> = {};
	for (const { colour, options } of colourOptions[colorByValue] || []) {
		if (colour) {
			for (const option of options) {
				result[option] = colour;
			}
		}
	}
	return result;
};

export const getColourBySelectColourMap = createSelector(
	[getColorByValue, getColourSelects],
	getColourBySelectColourMapPure,
);

export const isColorByCustomFieldOption = (id?: string): id is NonNullable<string> =>
	!R.isNil(id) &&
	![
		colourByOptions.COMPONENT,
		colourByOptions.EPIC,
		colourByOptions.ISSUE_TYPE,
		colourByOptions.LABEL,
		colourByOptions.NONE,
		colourByOptions.PRIORITY,
		colourByOptions.PROJECT,
		colourByOptions.STATUS,
		colourByOptions.TEAM,
	] // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
		.includes(id as any);

export const getSelectOptionsFromIssuesPure = (
	colorByValue: string,
	availableCustomFields: CustomField[],
	issues: Issue[],
): string[] => {
	const field = getCustomFieldById(Number(colorByValue), availableCustomFields)[0];
	if (!field) {
		return [];
	}

	// get all the issues which has the custom field used by color-by
	const issuesWithColorByCustomFields = issues.filter(({ customFields = {} }: Issue) =>
		R.has(colorByValue, customFields),
	);

	// get the values of the custom field used by color-by
	const options = issuesWithColorByCustomFields // eslint-disable-next-line @typescript-eslint/no-explicit-any
		.reduce<Array<any>>((ids, { customFields = {} }: Issue) => {
			const fieldValue = customFields[field.id];
			Array.prototype.push.apply(ids, Array.isArray(fieldValue) ? fieldValue : [fieldValue]);
			return ids;
		}, [])
		.filter((id) => !R.isNil(id))
		.map((id) => id && id.toString());

	return [...new Set(options)];
};

export const getSelectOptionsFromIssues = createSelector(
	[getColorByValue, getCustomFields, getIssues],
	getSelectOptionsFromIssuesPure,
);

const getAllOtherIssuesGroup = (grouping: string) => ({
	id: ALL_OTHER_ISSUES,
	name: '',
	isExpanded: false,
	[grouping]: [],
});

export const getCustomFieldValuesGroups = createSelector(
	[getCustomFieldValuesGroupsViewSettings, getVisualisationGrouping],
	(customFieldValuesGroupsState, grouping): CustomFieldValuesGroup[] =>
		customFieldValuesGroupsState[grouping] || [getAllOtherIssuesGroup(grouping)],
);

export const isGroupByMultiValueCustomFieldPure = (
	grouping: string,
	customFieldsById: { [id: string]: CustomField },
): boolean => {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	if (!isRoadmapGroupedByCustomField(grouping as Grouping)) {
		return false;
	}

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const fieldId = getCustomFieldIdFromCustomFieldGrouping(grouping as Grouping);

	if (!customFieldsById[fieldId]) {
		return false;
	}
	return CustomFields.GroupableMultiValueFields.includes(customFieldsById[fieldId].type.key);
};

export const isGroupByMultiValueCustomField = createSelector(
	[getVisualisationGrouping, getCustomFieldsById],
	isGroupByMultiValueCustomFieldPure,
);

export const getCustomFieldValuesGroupsById = createSelector(
	[getCustomFieldValuesGroups],
	(customFieldValuesGroups): { [id: string]: CustomFieldValuesGroup } =>
		indexBy(R.prop('id'), customFieldValuesGroups),
);
