import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	betaLozenge: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.ai-suggest-child-issues.beta-lozenge',
		defaultMessage: 'Beta',
		description: 'The text is shown in a lozenge to indicate that this feature is in Beta release.',
	},
	suggestChildIssues: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.ai-suggest-child-issues.suggest-child-issues',
		defaultMessage: 'Suggest child issues',
		description: 'Label for drop menu to let atlassian intelligence suggest child issues',
	},
	suggestSubtasks: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.ai-suggest-child-issues.suggest-subtasks',
		defaultMessage: 'Suggest subtasks',
		description: 'Label for drop menu to let atlassian intelligence suggest subtasks',
	},
	tooltipSuggestChildIssues: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.ai-suggest-child-issues.tooltip-suggest-child-issues',
		defaultMessage: 'Ask Atlassian Intelligence to suggest child issues for you',
		description: 'Tooltip for drop menu item to let atlassian intelligence suggest child issues',
	},
	tooltipSuggestSubtasks: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.ai-suggest-child-issues.tooltip-suggest-subtasks',
		defaultMessage: 'Ask Atlassian Intelligence to suggest subtasks for you',
		description: 'Tooltip for drop menu item to let atlassian intelligence suggest subtasks',
	},
	tooltipForDisabledSuggestChildIssues: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.ai-suggest-child-issues.tooltip-for-disabled-suggest-child-issues',
		defaultMessage: 'Save changes to {issueKey} using the Unsaved changes button first.',
		description:
			'Tooltip for disabled drop menu item to prompt users to save the changes of an issue to Jira first, so they can then use AI to generate child issues for that parent issue. The issue key of the parent issue is passed as a parameter a placeholder to help users understand which issue needs to be saved to Jira.',
	},
});
