import React, { forwardRef, type ReactNode, type Ref, useState, useCallback, useMemo } from 'react';
import Tooltip from '@atlaskit/tooltip';
import { Box, xcss, Pressable, Text, Inline } from '@atlaskit/primitives';
import Image from '@atlaskit/image';
import PeopleGroupIcon from '@atlaskit/icon/core/people-group';
import Popup, { type TriggerProps } from '@atlaskit/popup';
import { useIntl } from '@atlassian/jira-intl';
import { fg } from '@atlassian/jira-feature-gating';
import commonMessages from '../common/ui/messages.tsx';
import FlyoutContent from '../common/ui/flyout-content.tsx';
import type { Props } from './types.tsx';
import messages from './messages.tsx';

const ToolTipContainer = forwardRef<HTMLElement, { children?: ReactNode }>((props, ref) => (
	<Box
		xcss={containerStyles}
		padding="space.100"
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		ref={ref as Ref<HTMLDivElement>}
		// eslint-disable-next-line react/jsx-props-no-spreading
		{...props}
	/>
));

const TeamCell = ({ fieldKey, values, groupName, rawValues }: Props) => {
	const [isOpen, setIsOpen] = useState(false);
	const { formatMessage } = useIntl();
	const tooltipContent = values.map(({ title }) => title).join(', ');

	const onToggle = useCallback(() => {
		setIsOpen(!isOpen);
	}, [isOpen]);

	const onClose = useCallback(() => {
		setIsOpen(false);
	}, []);

	const label = useMemo(
		() =>
			fg('jira-issue-terminology-refresh-m3')
				? commonMessages.issueCountLabelIssueTermRefresh
				: commonMessages.issueCountLabel,
		[],
	);

	const message = useMemo(
		() => formatMessage(messages.numberOfTeams, { count: values.length }),
		[formatMessage, values.length],
	);

	const content = useMemo(
		() =>
			values.map(({ title, avatarUrl, count }) => ({
				title: (
					<Inline space="space.050" alignBlock="center">
						<Inline
							as="span"
							alignBlock="center"
							alignInline="center"
							xcss={[iconWrapperStyles, !avatarUrl && defaultIconWrapperStyles]}
						>
							{avatarUrl ? (
								<Image src={avatarUrl} alt={title} />
							) : (
								<PeopleGroupIcon label={title} />
							)}
						</Inline>
						{title}
					</Inline>
				),
				count,
			})),
		[values],
	);

	const renderContent = useCallback(
		() => (
			<FlyoutContent
				header={commonMessages.teamTitle}
				label={label}
				content={content}
				total={rawValues.length}
				testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.team-cell.team-flyout"
			/>
		),
		[content, label, rawValues],
	);

	const renderTrigger = useCallback(
		(triggerProps: TriggerProps) => (
			<Tooltip position="bottom" content={tooltipContent} tag={ToolTipContainer}>
				<Pressable
					xcss={triggerButtonStyles}
					padding="space.100"
					onClick={onToggle}
					{...triggerProps}
					aria-label={message}
					tabIndex={0}
					isDisabled={!values.length}
					testId={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.team-cell.${groupName}.${fieldKey}`}
				>
					<Text maxLines={1} weight="semibold">
						{message}
					</Text>
				</Pressable>
			</Tooltip>
		),
		[tooltipContent, onToggle, message, groupName, fieldKey, values],
	);

	return (
		<Popup
			isOpen={isOpen}
			onClose={onClose}
			trigger={renderTrigger}
			content={renderContent}
			placement="bottom-start"
		/>
	);
};

export default TeamCell;

const triggerButtonStyles = xcss({
	background: 'none',
	width: 'calc(100% - 4px)',
	display: 'flex',
	alignItems: 'center',
	boxSizing: 'border-box',
	margin: 'space.025',
	// Override the browser native focused color
	':focus': {
		outlineColor: 'color.border.focused',
	},
	':focus-visible': {
		outlineColor: 'color.border.focused',
	},
});

const iconWrapperStyles = xcss({
	width: '24px',
	height: '24px',
	marginRight: 'space.050',
});

const defaultIconWrapperStyles = xcss({
	backgroundColor: 'color.background.neutral',
	borderRadius: '50%',
});

const containerStyles = xcss({
	display: 'flex',
	padding: 'space.0',
});
