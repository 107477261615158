import React from 'react';
import AggregateField from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/scope/issues/group/aggregate-field/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { Props } from './types.tsx';

const isAggregateSupported = (columnId: string, startDateCustomFieldId: number | undefined) => {
	if (startDateCustomFieldId && startDateCustomFieldId.toString() === columnId) {
		return true;
	}

	switch (columnId) {
		// first iteration
		case 'dueDate':
		case 'targetStart':
		case 'targetEnd':
		case 'estimate':
			return true;

		// second iteration
		case 'issueStatus':
		case 'assignee':
		case 'breakdown':
		case 'team':
		case 'priority':
		case 'progressByEstimation':
			if (!fg('aggregate_fields_for_plan_m2')) {
				return false;
			}
			return true;

		default:
			return false;
	}
};

export const GroupHeaderField = ({
	showAggregate,
	startDateCustomField,
	columnId,
	group,
}: Props) =>
	showAggregate && columnId && group && isAggregateSupported(columnId, startDateCustomField?.id) ? (
		<AggregateField id={columnId} groupName={group} />
	) : null;
