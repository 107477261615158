import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	notifyWatchers: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.notification-toggle.notify-watchers',
		defaultMessage: 'Send update notifications',
		description: 'Notification toggle label',
	},
	notifyWatchersDesc: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.notification-toggle.notify-watchers-desc',
		defaultMessage:
			'Users associated with these issues will be notified of the change as per their notification settings.',
		description: 'Notification toggle more information',
	},
	notifyWatchersTooltipAriaLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.notification-toggle.notify-watchers-tooltip-aria-label',
		defaultMessage: 'More info',
		description:
			'Aria label text for send update notification tooltip icon when used with Screen reader, inside Review Changes dialog',
	},
	notifyWatchersDescIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.notification-toggle.notify-watchers-desc-issue-term-refresh',
		defaultMessage:
			'Users associated with these work items will be notified of the change as per their notification settings.',
		description: 'Notification toggle more information',
	},
});
export default messages;
