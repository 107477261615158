import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	externalTeamLozenge: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.external-team-lozenge',
		defaultMessage: 'EXTERNAL',
		description: 'Lozenge text for the external team',
	},
	externalTeamTooltipTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.external-team-tooltip-title',
		defaultMessage: 'Team currently not in this plan',
		description: 'Tooltip title for the external team',
	},
	externalTeamTooltipContent: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.external-team-tooltip-content',
		defaultMessage:
			"While this team isn't in this plan, it's appearing because it's assigned to issues in the plan.",
		description: 'Tooltip content for the external team',
	},
	unknownTeam: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.unknown-team',
		defaultMessage: 'Unknown team (not included in plan)',
		description:
			'Label to show for a group of issues that are assigned to a team outside of the plan',
	},
	completedSprintsGroup: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.completed-sprints-group',
		defaultMessage: '{count} completed {count, plural, one {sprint} other {sprints}}',
		description: 'Label for completed sprints group',
	},
	completedReleasesGroup: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.completed-releases-group',
		defaultMessage: '{count} completed {count, plural, one {release} other {releases}}',
		description: 'Label for completed releases group',
	},
	folderIconLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.folder-icon-label',
		defaultMessage: 'Folder icon',
		description: 'Label for folder icon',
	},
	peopleIconLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.people-icon-label',
		defaultMessage: 'People icon',
		description: 'Label for people icon',
	},
	avatarLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.avatar-label',
		defaultMessage: 'Team avatar',
		description: 'Label for team avatar',
	},
	externalTeamTooltipContentIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.external-team-tooltip-content-issue-term-refresh',
		defaultMessage:
			"While this team isn't in this plan, it's appearing because it's assigned to work items in the plan.",
		description: 'Tooltip content for the external team',
	},
});
