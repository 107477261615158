import { POLARIS_SUB_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/constants.tsx';
import {
	FIELD_TYPES,
	GLOBAL_SYSTEM_FIELD_TYPES,
	JIRA_API_FIELD_TYPES,
} from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { STABLE_IDS } from '@atlassian/jira-polaris-domain-field/src/stable-ids/index.tsx';
import type { JiraField } from '../../services/types.tsx';

export const getFieldType = ({
	type,
	configuration,
	stableId,
}: Pick<JiraField, 'type' | 'configuration' | 'stableId'>): FieldType => {
	if (fg('polaris_new_custom_types_global_system_fields')) {
		switch (type) {
			case GLOBAL_SYSTEM_FIELD_TYPES.PLATFORM_GOALS:
				return FIELD_TYPES.PLATFORM_GOALS;
			case GLOBAL_SYSTEM_FIELD_TYPES.PLATFORM_PROJECT:
				return FIELD_TYPES.ATLAS_PROJECT;
			case GLOBAL_SYSTEM_FIELD_TYPES.PLATFORM_PROJECT_STATUS:
				return FIELD_TYPES.ATLAS_PROJECT_STATUS;
			case GLOBAL_SYSTEM_FIELD_TYPES.DELIVERY_PROGRESS_GLOBAL:
				return FIELD_TYPES.DELIVERY_PROGRESS;
			case GLOBAL_SYSTEM_FIELD_TYPES.DELIVERY_STATUS_GLOBAL:
				return FIELD_TYPES.DELIVERY_STATUS;
			case GLOBAL_SYSTEM_FIELD_TYPES.INSIGHTS_GLOBAL:
				return FIELD_TYPES.INSIGHTS;
			case GLOBAL_SYSTEM_FIELD_TYPES.ISSUE_COMMENTS_GLOBAL:
				return FIELD_TYPES.ISSUE_COMMENTS;
			case GLOBAL_SYSTEM_FIELD_TYPES.LINKED_ISSUES_GLOBAL:
				return FIELD_TYPES.LINKED_ISSUES;
			default:
				break;
		}

		switch (stableId) {
			case STABLE_IDS.ARCHIVED:
				return FIELD_TYPES.ARCHIVED;
			case STABLE_IDS.ARCHIVED_BY:
				return FIELD_TYPES.ARCHIVED_BY;
			case STABLE_IDS.ARCHIVED_ON:
				return FIELD_TYPES.ARCHIVED_ON;
			default:
				break;
		}
	}

	// Frontend only: the jira type is not specific enough
	switch (configuration?.polarisSubType) {
		case POLARIS_SUB_TYPES.ARCHIVED:
			return FIELD_TYPES.ARCHIVED;
		case POLARIS_SUB_TYPES.ARCHIVED_BY:
			return FIELD_TYPES.ARCHIVED_BY;
		case POLARIS_SUB_TYPES.ARCHIVED_ON:
			return FIELD_TYPES.ARCHIVED_ON;
		case POLARIS_SUB_TYPES.ATLAS_GOAL:
			return FIELD_TYPES.ATLAS_GOAL;
		case POLARIS_SUB_TYPES.ATLAS_PROJECT:
			return FIELD_TYPES.ATLAS_PROJECT;
		case POLARIS_SUB_TYPES.ATLAS_PROJECT_STATUS:
			return FIELD_TYPES.ATLAS_PROJECT_STATUS;
		case POLARIS_SUB_TYPES.DELIVERY_STATUS:
			return FIELD_TYPES.DELIVERY_STATUS;
		case POLARIS_SUB_TYPES.DELIVERY_PROGRESS:
			return FIELD_TYPES.DELIVERY_PROGRESS;
		case POLARIS_SUB_TYPES.ISSUE_COMMENTS:
			return FIELD_TYPES.ISSUE_COMMENTS;
		case POLARIS_SUB_TYPES.LINKED_ISSUES:
			return FIELD_TYPES.LINKED_ISSUES;
		case POLARIS_SUB_TYPES.NUM_DATA_POINTS:
			return FIELD_TYPES.INSIGHTS;
		default:
			break;
	}

	// useless as it's already covered with polarisSubType check above
	// but typescript can't get it
	switch (type) {
		case JIRA_API_FIELD_TYPES.EXTERNAL_REFERENCE:
			return FIELD_TYPES.ATLAS_PROJECT;
		case JIRA_API_FIELD_TYPES.DATE_TIME:
			return FIELD_TYPES.ARCHIVED_ON;
		default:
			break;
	}

	return type;
};
