import React, { useMemo } from 'react';
import { Inline, xcss, Box } from '@atlaskit/primitives';
import { getAppliedDecoration } from '@atlassian/jira-polaris-component-decorations/src/utils/index.tsx';
import { WeightTag } from '@atlassian/jira-polaris-component-weight-tag/src/ui/index.tsx';
import type { ValueDecoration } from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import type { FieldOption } from '@atlassian/jira-polaris-domain-field/src/field-option/types.tsx';
import { CommonDecoratedTag } from '@atlassian/jira-polaris-lib-decoration/src/ui/decoration/common/index.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import type { CommonFieldComponentProps } from '../../common/types.tsx';
import {
	useFieldOptions,
	useFieldValueDecorations,
	useFieldIsWeighted,
} from '../../controllers/fields/index.tsx';

export const isMultiSelectFieldComponentProps = (
	props: CommonFieldComponentProps,
): props is MultiSelectFieldComponentProps =>
	'value' in props && Array.isArray(props.value) && props.value.every((v) => typeof v === 'number');

export type MultiSelectFieldComponentProps = CommonFieldComponentProps & {
	value: number[];
};

export const MultiSelectField = ({
	fieldKey,
	value,
	isMultiline,
}: MultiSelectFieldComponentProps) => {
	const [fieldOptions] = useFieldOptions(fieldKey);
	const [fieldValueDecorations] = useFieldValueDecorations(fieldKey);
	const [isWeighted] = useFieldIsWeighted(fieldKey);

	const tags = useMemo(
		() =>
			value
				.map((v) => {
					const option = fieldOptions?.find((o) => `${o.id}` === `${v}`);
					if (!option) {
						return null;
					}
					const decoration = getAppliedDecoration(fieldValueDecorations, `${v}`);
					return { option, decoration };
				})
				.filter(Boolean),
		[fieldOptions, fieldValueDecorations, value],
	);

	return (
		<MultiSelectFieldDisplay
			tags={tags}
			isWeighted={isWeighted}
			isMultiline={isMultiline ?? false}
		/>
	);
};

export const MultiSelectFieldDisplay = ({
	tags,
	isWeighted,
	isMultiline,
}: {
	tags: {
		option: FieldOption;
		decoration: ValueDecoration | undefined;
	}[];
	isWeighted: boolean;
	isMultiline: boolean;
}) => {
	const options = useMemo(() => tags.map((tag) => tag.option), [tags]);

	return (
		<Inline xcss={isMultiline ? multilineStyles : maxContentStyles}>
			{isWeighted && (
				<Box padding="space.050">
					<WeightTag options={options} />
				</Box>
			)}
			{tags.map((tag) => (
				<MultiSelectFieldTagDisplay
					key={tag.option.id}
					option={tag.option}
					decoration={tag.decoration}
				/>
			))}
		</Inline>
	);
};

const MultiSelectFieldTagDisplay = ({
	option,
	decoration,
}: {
	option: FieldOption;
	decoration: ValueDecoration | undefined;
}) => {
	const emojiDescription = useEmoji(decoration?.emoji);
	return (
		<CommonDecoratedTag
			value={option.value}
			mainColor={decoration?.backgroundColor}
			emoji={emojiDescription}
		/>
	);
};

const maxContentStyles = xcss({
	width: 'max-content',
});

const multilineStyles = xcss({
	flexWrap: 'wrap',
});
