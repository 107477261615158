import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	emptyPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.issue-warning-filter.empty-placeholder',
		defaultMessage: 'All issues',
		description: 'This is a placeholder for no filter set',
	},
	emptyPlaceholderIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.issue-warning-filter.empty-placeholder-issue-term-refresh',
		defaultMessage: 'All work items',
		description: 'This is a placeholder for no filter set',
	},
});
