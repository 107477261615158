import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	percentage: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.common.ui.percentage',
		defaultMessage: '({percentage}%)',
		description: 'Text for percentage value of progress',
	},
	issueCountLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.common.ui.issue-count-label',
		defaultMessage: '{count, plural, one {{count} issue} other {{count} issues}}',
		description: 'Issue count label',
	},
	issueCountLabelIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.common.ui.issue-count-label-issue-term-refresh',
		defaultMessage: '{count, plural, one {{count} work item} other {{count} work items}}',
		description: 'Issue count label',
	},
	total: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.common.ui.total',
		defaultMessage: 'Total',
		description: 'Label for the total number',
	},
	priorityTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.common.ui.priority-title',
		defaultMessage: 'Priority',
		description: 'The header on the flyout after user clicks on the priority field.',
	},
	statusTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.common.ui.status-title',
		defaultMessage: 'Status',
		description: 'The header on the flyout after user clicks on the status field.',
	},
	breakdownTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.common.ui.breakdown-title',
		defaultMessage: 'Progress (issue count)',
		description: 'The header on the flyout after user clicks on the progress field.',
	},
});
