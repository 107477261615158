import React, { Component, createRef, type KeyboardEvent, type ReactNode } from 'react';
import * as R from 'ramda';
import Popup from '@atlaskit/popup'; // ignore-for-ENGHEALTH-17759
import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import FixedDialog from '@atlassian/jira-portfolio-3-common/src/fixed-dialog/index.tsx';
import InlineDialog from '@atlassian/jira-portfolio-3-common/src/inline-dialog/index.tsx';
import type { EnrichedVersion } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/versions/types.tsx';
import { OPTIMIZED } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/app/types.tsx';
import type { EnrichedCrossProjectVersion } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/cross-project-versions/types.tsx';
import { OVERVIEW_RELEASES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/main/tabs/roadmap/timeline/release-bar/types.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import {
	RELEASE,
	CROSS_PROJECT_RELEASE,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { getFlyoutPosition } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/utils/flyouts.tsx';
import { Z_INDEX_LAYER } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/z-index/types.tsx';
import { ZIndex } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/z-index/view.tsx';
import {
	calculateLeftPositionPercentage,
	calculateNewTimelineRange,
	calculateSlippage,
	calculateEndVersionsDate,
	getMarkerTypeIcon,
	getVersionFlyoutType,
	isVersionCrossProject,
} from '../utils.tsx';
import {
	Navigation,
	CrossProjectRelease as CrossProjectReleaseFlyout,
	OverviewReleases as OverviewReleasesFlyout,
	ProjectRelease as ReleaseFlyout,
	preload as preloadFlyouts,
} from './flyout/async.tsx';
import ReleaseMarkerIcon from './icon/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props, MappedVersions, State } from './types.tsx';

const DIALOG_MIN_WIDTH = 480;

// eslint-disable-next-line jira/react/no-class-components
export default class ReleaseMarker extends Component<Props, State> {
	static defaultProps = {
		ReleaseFlyout,
		CrossProjectReleaseFlyout,
		OverviewReleasesFlyout,
		ZIndex,
	};

	state = {
		flyoutWidth: DIALOG_MIN_WIDTH,
	};

	ref: HTMLDivElement | null | undefined;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	flyoutRef = createRef<any>();

	isKeyboardEventEnter = (event: KeyboardEvent) => {
		const { key } = event;
		return !event.nativeEvent.isComposing && key.trim().toLowerCase() === 'enter';
	};

	isKeyboardEventSpace = (event: KeyboardEvent) => {
		const { key = '' } = event;
		return !event.nativeEvent.isComposing && (key === ' ' || key.trim().toLowerCase() === 'space');
	};

	onKeyDown = (event: KeyboardEvent) => {
		const { toggleFlyout, versionIdsMapByDate, endDate, type } = this.props;
		if (this.isKeyboardEventEnter(event) || this.isKeyboardEventSpace(event)) {
			toggleFlyout(versionIdsMapByDate[endDate], type);
		}
	};

	handleBack = (prevVersionIds: string[]) => {
		const { updateFlyout } = this.props;
		this.getFlyoutWidth();
		updateFlyout(prevVersionIds, OVERVIEW_RELEASES, []);
	};

	navigateTo = (date: string, direction: 'next' | 'prev') => {
		const { versionIdsMapByDate, updateFlyout, timelineRange, scrollToViewRelease } = this.props;
		const dates = Object.keys(versionIdsMapByDate).sort((a, b) => Number(a) - Number(b));
		const nextDate =
			direction === 'next' ? dates[dates.indexOf(date) + 1] : dates[dates.indexOf(date) - 1];
		const { versions, crossProjectVersions } = this.mapVersionsById(versionIdsMapByDate[nextDate]);
		const nextTimelineRange =
			Number(nextDate) < timelineRange.start || Number(nextDate) > timelineRange.end
				? calculateNewTimelineRange(Number(nextDate), timelineRange)
				: undefined;

		this.getFlyoutWidth();
		scrollToViewRelease?.call(null, { timestamp: parseInt(nextDate, 10) });
		setTimeout(
			() =>
				updateFlyout(
					versionIdsMapByDate[nextDate],
					getVersionFlyoutType([...versions, ...crossProjectVersions]),
					[],
					nextTimelineRange,
				),
			1,
		);
	};

	getReleaseFlyout = (version: EnrichedVersion | EnrichedCrossProjectVersion) => {
		if (!version) {
			return null;
		}
		// eslint-disable-next-line @typescript-eslint/no-shadow
		const { solutionVersionsById, CrossProjectReleaseFlyout, ReleaseFlyout, mode } = this.props;
		const versionType =
			isVersionCrossProject(version) && version.versions ? CROSS_PROJECT_RELEASE : RELEASE;
		if (versionType === RELEASE && version.end) {
			const { id, end } = version;
			const solutionVersion = solutionVersionsById[id];
			const solutionEnd = isDefined(solutionVersion) ? solutionVersion.end : null;
			const slippage =
				versionType === RELEASE && mode === OPTIMIZED ? calculateSlippage(solutionEnd, end) : null;

			return ReleaseFlyout ? (
				<ReleaseFlyout
					version={version}
					solutionVersion={solutionVersion}
					slippage={slippage}
					onBackHandler={this.handleBack}
				/>
			) : null;
		}

		return CrossProjectReleaseFlyout ? (
			<CrossProjectReleaseFlyout crossProjectVersion={version} onBackHandler={this.handleBack} />
		) : null;
	};

	handleFlyoutRequestClose = () => {
		const { toggleFlyout, versionIdsMapByDate, endDate, type } = this.props;
		toggleFlyout(versionIdsMapByDate[endDate], type);
	};

	renderFlyout = () => {
		const {
			endDate,
			currentDayIndex,
			totalDays,
			releaseBarState: { mode: flyoutType, versionIds },
			// eslint-disable-next-line @typescript-eslint/no-shadow
			OverviewReleasesFlyout,
		} = this.props;
		const { versions, crossProjectVersions } = this.mapVersionsById(versionIds);
		const allVersions = [...versions, ...crossProjectVersions];

		return (
			<div ref={this.flyoutRef}>
				<Navigation
					onPrev={() => {
						this.navigateTo(endDate, 'prev');
					}}
					onNext={() => {
						this.navigateTo(endDate, 'next');
					}}
					currentDayIndex={currentDayIndex}
					totalDays={totalDays}
					markerRef={this.ref}
				/>
				{flyoutType === OVERVIEW_RELEASES && OverviewReleasesFlyout ? (
					<OverviewReleasesFlyout
						versions={allVersions}
						date={Number(endDate)}
						onPreviewRelease={(
							id: string,
							versionType: typeof CROSS_PROJECT_RELEASE | typeof RELEASE | typeof OVERVIEW_RELEASES,
						) => {
							this.props.updateFlyout([id], versionType, versionIds);
						}}
					/>
				) : (
					this.getReleaseFlyout(allVersions[0])
				)}
			</div>
		);
	};

	mapVersionsById = (ids: string[]): MappedVersions => {
		const { crossProjectVersionsById, versionsById, solutionVersionsById, mode } = this.props;
		const crossProjectVersions: Array<EnrichedCrossProjectVersion> = [];
		const versions: Array<EnrichedVersion> = [];
		ids.forEach((id) => {
			const version = versionsById[id];
			if (version) {
				const end = version.end || calculateEndVersionsDate(version, solutionVersionsById, mode);
				versions.push({ ...version, end });
			} else {
				crossProjectVersions.push(crossProjectVersionsById[id]);
			}
		});

		return { versions, crossProjectVersions };
	};

	getFlyoutWidth = () => {
		// use max-width for calculating flyout position when flyout width is not defined
		const { width: flyoutWidth = DIALOG_MIN_WIDTH } = this.flyoutRef.current
			? this.flyoutRef.current.getBoundingClientRect()
			: {};
		this.setState(() => ({ flyoutWidth }));
	};

	render() {
		const {
			mode,
			endDate,
			versionIdsMapByDate,
			timelineRange,
			width: timelineWidth,
			showFlyout,
			toggleFlyout,
			solutionVersionsById,
			highlightedVersionIds,
			// eslint-disable-next-line @typescript-eslint/no-shadow
			ZIndex,
		} = this.props;
		if (!isDefined(endDate)) {
			return null;
		}
		const mappedVersions: MappedVersions = this.mapVersionsById(versionIdsMapByDate[endDate]);
		const releaseId = R.head(versionIdsMapByDate[endDate]);
		const { versions, crossProjectVersions } = mappedVersions;

		const versionFlyoutType = getVersionFlyoutType([...versions, ...crossProjectVersions]);

		const leftPositionPercentage = calculateLeftPositionPercentage(Number(endDate), timelineRange);

		const isHighlighted = !!versionIdsMapByDate[endDate].find((versionId) =>
			highlightedVersionIds.some((version) => version === versionId),
		);

		// Consider remove this function when cleaning up plan-timeline-non-transposed
		const withZIndexOverride = (children: (zIndex: number | string | undefined) => ReactNode) => {
			if (fg('plan-timeline-non-transposed')) {
				return children(undefined);
			}

			return ZIndex ? (
				<ZIndex layer={Z_INDEX_LAYER.ISSUE_BARS}>
					{(zIndex) => children(zIndex ?? 'initial')}
				</ZIndex>
			) : null;
		};

		return withZIndexOverride((zIndex) => (
			<div
				ref={(node) => {
					if (node) {
						this.ref = node;
					}
				}}
				// the <ReleaseMarkerIcon /> triggering the fixed dialog already has a focusable button
				// so we set the container tabIndex to -1 to disable it and avoid double-focus
				tabIndex={-1}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={styles.main}
				style={{
					left: `calc(${leftPositionPercentage}% - ${token('space.150', '12px')})`,
					zIndex,
				}}
				/**
				 * When handling keyboard events for the "Enter" and "Space" keys, it's generally better to use the onKeyDown event rather than onKeyUp.
				 * The onKeyDown event is fired as soon as the user presses a key, while the onKeyUp event is fired after the user releases a key.
				 * If we were to use onKeyUp, there might be a noticeable delay between when the user presses the key and when the action is performed, especially if the user holds down the key.
				 */
				onKeyDown={this.onKeyDown}
				role="button"
				data-name={releaseId && `release-marker-${releaseId}`}
				data-testid={releaseId && `release-marker-${releaseId}`}
				onMouseEnter={preloadFlyouts}
			>
				{fg('_jira-concurrent-state-update-hasnt-mounted-yet') ? (
					<Popup
						key={getFlyoutPosition(timelineWidth, leftPositionPercentage, this.state.flyoutWidth)}
						isOpen={showFlyout}
						content={() => (
							<Box
								style={{
									// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
									minWidth: DIALOG_MIN_WIDTH,
								}}
								testId={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.release-bar.marker.inline-dialog.${releaseId}`}
							>
								{this.renderFlyout()}
							</Box>
						)}
						placement={getFlyoutPosition(
							timelineWidth,
							leftPositionPercentage,
							this.state.flyoutWidth,
						)}
						onClose={this.handleFlyoutRequestClose}
						trigger={(triggerProps) => (
							<ReleaseMarkerIcon
								endDate={endDate}
								isHighlighted={isHighlighted}
								onClick={() => {
									this.getFlyoutWidth();
									toggleFlyout(versionIdsMapByDate[endDate], versionFlyoutType);
								}}
								release={this.props.versionsById[versionIdsMapByDate[endDate][0]]}
								totalCount={versionIdsMapByDate[endDate].length}
								showTooltip
								type={getMarkerTypeIcon(
									mappedVersions,
									mode,
									versionFlyoutType,
									solutionVersionsById,
								)}
								triggerProps={triggerProps}
							/>
						)}
					/>
				) : (
					<FixedDialog
						isOpen={showFlyout}
						minLeft={0}
						renderDialog={(element) => (
							<InlineDialog
								content={this.renderFlyout()}
								isOpen
								placement={getFlyoutPosition(
									timelineWidth,
									leftPositionPercentage,
									this.state.flyoutWidth,
								)}
								onClose={this.handleFlyoutRequestClose}
								noPaddings
								noHorizontalScroll
								closeOnScroll
								minWidth={DIALOG_MIN_WIDTH}
								testId={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.release-bar.marker.inline-dialog.${releaseId}`}
							>
								{element}
							</InlineDialog>
						)}
						renderTrigger={(ref) => (
							<ReleaseMarkerIcon
								endDate={endDate}
								innerRef={ref}
								isHighlighted={isHighlighted}
								onClick={() => {
									this.getFlyoutWidth();
									toggleFlyout(versionIdsMapByDate[endDate], versionFlyoutType);
								}}
								release={this.props.versionsById[versionIdsMapByDate[endDate][0]]}
								totalCount={versionIdsMapByDate[endDate].length}
								showTooltip
								type={getMarkerTypeIcon(
									mappedVersions,
									mode,
									versionFlyoutType,
									solutionVersionsById,
								)}
							/>
						)}
					/>
				)}
			</div>
		));
	}
}
