import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import type { RemoteJPDIssue } from '@atlassian/jira-polaris-remote-issue/src/controllers/crud/types.tsx';
import type { IssueId, ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { StatusDetails } from '@atlassian/jira-shared-types/src/rest/jira/status.tsx';
import type { User } from '@atlassian/jira-shared-types/src/rest/jira/user.tsx';

import {
	createStore,
	createActionsHook,
	createContainer,
	createHook,
	createSelector,
} from '@atlassian/react-sweet-state';

type State = {
	idea: RemoteJPDIssue | undefined;
	users: { [key: string]: User };
	statuses: { [key: number]: StatusDetails };
	meta: {
		isLoading: boolean;
		error: Error | undefined;
		loaded: boolean;
	};
};

const initialState: State = {
	idea: undefined,
	users: {},
	statuses: {},
	meta: {
		isLoading: false,
		error: undefined,
		loaded: false,
	},
};

export const IdeaContainer = createContainer();

const Store = createStore({
	containedBy: IdeaContainer,
	initialState,
	actions: {
		loadIdeaByIssueId:
			(issueId: IssueId, projectId: ProjectId) =>
			async ({ getState, setState }) => {
				if (getState().meta.loaded) {
					// if already loaded, do not load again. Use refresh action instead.
					return;
				}

				setState({
					...getState(),
					meta: {
						isLoading: true,
						error: undefined,
						loaded: false,
					},
				});

				try {
					const ideaResponse = await fetchJson('/rest/polaris/issues/get', {
						// does not include field configurations/decorations etc.
						method: 'POST',
						body: JSON.stringify({
							includeAllFields: true,
							includeProjectData: true,
							issueIdOrKey: issueId,
							projectId,
						}),
					});

					setState({
						...getState(),
						idea: ideaResponse.issue,
						users: ideaResponse.users,
						statuses: ideaResponse.statuses,
						meta: {
							isLoading: false,
							error: undefined,
							loaded: true,
						},
					});
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
				} catch (error: any) {
					if (!isClientFetchError(error)) {
						experience.ideaViewBentoSidebar.pageSegmentLoad.failure(error);
					}

					setState({
						...getState(),
						meta: {
							isLoading: false,
							error,
							loaded: false,
						},
					});
				}
			},
	},
	name: 'jpd-implant-kit-container',
});

export const useIdeaActions = createActionsHook(Store);

const getIsLoading = (state: State) => state.meta.isLoading;
const getLoadError = (state: State) => state.meta.error;
const getIdeaLoaded = (state: State) => state.meta.loaded;

const getIdeaUsers = (state: State) => state.users;
const getIdeaStatues = (state: State) => state.statuses;
const getIdeaUserByAccountId = (state: State, accountId: string) => state.users[accountId];

const getIdeaIssueType = (state: State) => state.idea?.fields.issuetype;
const getIdeaIssueTypeIconUrl = createSelector(getIdeaIssueType, (issueType) => issueType?.iconUrl);

const getIdeaFieldValueByKey = (state: State, fieldKey: string) => state.idea?.fields[fieldKey];

const getIdeaKey = (state: State) => state.idea?.key;
const getIdeaId = (state: State) => state.idea?.id;

const getIdea = (state: State) => state.idea;

const getIdeaNonEmptyFieldKeys = createSelector(getIdea, (idea) => {
	if (!idea) {
		return [];
	}

	return Object.keys(idea.fields).filter((fieldKey) => {
		const fieldValue = idea.fields[fieldKey];
		return fieldValue !== null && fieldValue !== undefined;
	});
});

export const useIdeaIsLoading = createHook(Store, {
	selector: getIsLoading,
});

export const useIdeaLoadError = createHook(Store, {
	selector: getLoadError,
});

export const useIdeaLoaded = createHook(Store, {
	selector: getIdeaLoaded,
});

export const useIdeaKey = createHook(Store, {
	selector: getIdeaKey,
});

export const useIdeaId = createHook(Store, {
	selector: getIdeaId,
});

export const useIdeaNonEmptyFieldKeys = createHook(Store, {
	selector: getIdeaNonEmptyFieldKeys,
});

export const useIdeaFieldValueByKey = createHook(Store, {
	selector: getIdeaFieldValueByKey,
});

export const useIdeaIssueTypeIconUrl = createHook(Store, {
	selector: getIdeaIssueTypeIconUrl,
});

export const useIdeaUsers = createHook(Store, {
	selector: getIdeaUsers,
});

export const useIdeaStatuses = createHook(Store, {
	selector: getIdeaStatues,
});

export const useIdeaUserByAccountId = createHook(Store, {
	selector: getIdeaUserByAccountId,
});

export const useIdeaDescription = () => useIdeaFieldValueByKey('description');

export const useIdeaSummary = () => useIdeaFieldValueByKey('summary');
