import {
	getMode,
	isConfluenceMacro,
	isEmbed,
	isReportMode,
	isOptimizedMode,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app/index.tsx';
import {
	getGridRows,
	getGridRowIds,
	getGridColumns,
	getRowConfigs,
	getColumnConfigs,
	getRowStickiness,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/grid/index.tsx';
import { getExportAppWidth } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/section-widths/index.tsx';
import { getViewMode } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/view-settings/index.tsx';
import { getActiveViewId } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/views/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import { getColumnsMap } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/fields/query.tsx';
import { getTableItems } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/table/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import type { StateProps } from './types.tsx';

const selector = createStructuredSelector<State, StateProps>({
	rows: getGridRows,
	rowIds: getGridRowIds,
	columns: getGridColumns,
	rowConfigs: getRowConfigs,
	columnConfigs: getColumnConfigs,
	mode: getMode,
	stickyRows: getRowStickiness,
	viewMode: getViewMode,
	viewId: getActiveViewId,
	exportAppWidth: getExportAppWidth,
	isConfluenceMacro,
	isEmbed,
	isReportMode,
	fieldColumns: getColumnsMap,
	showOptimizations: isOptimizedMode,
	items: getTableItems,
});

const selectorOld = createStructuredSelector<State, StateProps>({
	rows: getGridRows,
	rowIds: getGridRowIds,
	columns: getGridColumns,
	rowConfigs: getRowConfigs,
	columnConfigs: getColumnConfigs,
	mode: getMode,
	stickyRows: getRowStickiness,
	viewMode: getViewMode,
	viewId: getActiveViewId,
	exportAppWidth: getExportAppWidth,
	isConfluenceMacro,
	isEmbed,
	isReportMode,
});

export default functionWithCondition(
	() => fg('plans_performance_improvements_3'),
	selector,
	selectorOld,
);
