import React from 'react';
import { xcss, Pressable, Box, type Space } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import IssueLink from '@atlassian/jira-portfolio-3-common/src/issue-link/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';
import messages from './messages.tsx';

export default function IssueCell(props: Props) {
	const { formatMessage } = useIntl();
	const { issue, issueType, projectKey, onClick } = props;
	const { issueKey, summary } = issue;
	const fullIssueKey = issueKey ? `${projectKey}-${issueKey}` : projectKey;

	const innerContent = (
		<>
			<div
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={styles.issueIcon}
				style={{ backgroundImage: issueType && `url(${issueType.iconUrl})` }}
			/>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={styles.issueSummary} title={summary}>
				<IssueLink
					issueKey={issueKey}
					projectKey={projectKey}
					xcssStyles={fg('project_timeline_a11y_fix_jtran2') ? issueLinkStyles : issueLinkStylesOld}
				/>
				{issue.summary}
			</div>
		</>
	);

	return fg('project_timeline_a11y_fix_jtran2') ? (
		<Box xcss={containerStyles}>
			<Pressable
				backgroundColor="color.background.neutral.subtle"
				xcss={pressableStyles}
				onClick={onClick}
				onKeyPress={onClick}
				padding="space.0"
				aria-label={formatMessage(
					fg('jira-issue-terminology-refresh-m3')
						? messages.interactionLayerLabelIssueTermRefresh
						: messages.interactionLayerLabel,
					{
						issueKey: fullIssueKey,
						issueSummary: summary,
					},
				)}
			/>
			<Box xcss={innerContentStyles}>{innerContent}</Box>
		</Box>
	) : (
		// eslint-disable-next-line @atlaskit/design-system/no-html-button -- remove with cleanup project_timeline_a11y_fix_jtran2
		<div
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={styles.issueTitle}
			onClick={onClick}
			tabIndex={0}
			role="button"
			onKeyPress={onClick}
		>
			{innerContent}
		</div>
	);
}

const issueLinkStylesOld = xcss({
	whiteSpace: 'nowrap',
	marginRight: 'space.075',
});

const issueLinkStyles = xcss({
	whiteSpace: 'nowrap',
	marginRight: 'space.075',
	position: 'relative',
});

const pressableStyles = xcss({
	position: 'absolute',
	left: 'space.0',
	right: 'space.0',
	top: 'space.0',
	bottom: 'space.0',
	borderRadius: 'border.radius',
	':focus-visible': {
		// there is no NegativeSpace type export and xcss will be deprecated soon for @atlaskit/css so this eslint disable is required
		// eslint-disable-next-line  @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values, @typescript-eslint/consistent-type-assertions -- inset focus style
		outlineOffset: token('space.negative.025') as Space,
	},
});

const innerContentStyles = xcss({
	display: 'flex',
});

const containerStyles = xcss({
	display: 'flex',
	backgroundColor: 'color.background.neutral',
	borderRadius: '3px',
	marginTop: 'space.075',
	marginBottom: 'space.075',
	padding: 'space.100',
	cursor: 'pointer',
	position: 'relative',
});
