import { token } from '@atlaskit/tokens';
import type { CommonProps } from '@atlassian/jira-portfolio-3-common/src/select/types.tsx';
import type { LazyGoal } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issue-goals/types.tsx';

export const selectStyles = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	container: (styles: any, { selectProps: { menuIsOpen } }: CommonProps) => ({
		...styles,
		...(menuIsOpen ? { zIndex: 1 } : {}),
	}),
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	control: (styles: any, { hasValue, selectProps: { menuIsOpen } }: CommonProps) =>
		menuIsOpen
			? { ...styles }
			: {
					...styles,
					borderColor: 'transparent',
					backgroundColor: 'transparent',
					// eslint-disable-next-line no-useless-computed-key
					[':hover']: {
						...styles[':hover'],
						background: 'none',
						...(hasValue ? { borderColor: 'transparent' } : {}),
					},
				},
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	valueContainer: (styles: any) => ({
		...styles,
		maxHeight: 150,
		overflow: 'auto',
		flex: '1',
	}),
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	multiValue: (styles: any) => ({
		...styles,
		borderWidth: token('border.width', '1px'),
		borderStyle: 'solid',
		borderColor: token('color.border', '#091e4221'),
		borderRadius: token('border.radius', '3px'),
		backgroundColor: token('elevation.surface', '#FFFFFF'),
	}),
} as const;

export const partitionByExternal = (goals: LazyGoal[]) =>
	goals.reduce<{ internal: LazyGoal[]; external: LazyGoal[] }>(
		(acc, lazyGoal) => {
			if (lazyGoal.isExternal) {
				acc.external.push(lazyGoal);
			} else {
				acc.internal.push(lazyGoal);
			}
			return acc;
		},
		{ internal: [], external: [] },
	);
