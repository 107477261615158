import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	emptyPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.team-filter.empty-placeholder',
		defaultMessage: 'All',
		description: 'This is a placeholder for no filter set',
	},
	searchTeamPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.team-filter.search-team-placeholder',
		defaultMessage: 'Show issues from teams',
		description: 'This is a placeholder for search',
	},
	noTeamFilterText: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.team-filter.no-team-filter-text',
		defaultMessage: 'No team',
		description: 'Text for selecting issues with no assigned team',
	},
	searchTeamLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.team-filter.search-team-label',
		defaultMessage: 'Choose from teams',
		description: 'Aria label for teams search filed that appears on activating Filters button',
	},
	searchTeamPlaceholderIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.team-filter.search-team-placeholder-issue-term-refresh',
		defaultMessage: 'Show work items from teams',
		description: 'This is a placeholder for search',
	},
});
