import React, { type SyntheticEvent, type ReactNode } from 'react';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import { Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { AkFlag as Flag } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { FormattedMessage } from '@atlassian/jira-intl';
import colors from '@atlassian/jira-portfolio-3-common/src/colors/index.tsx';
import { defaultSorting } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/visualisations/types.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

export const getIssueRankWarningFlag = ({ sortByRank }: Props) => {
	const handleSortByRankButtonClick = (_: SyntheticEvent) => {
		sortByRank({
			sorting: defaultSorting,
		});
	};
	const actions = [
		{
			content: <FormattedMessage {...messages.sortByRank} />,
			onClick: handleSortByRankButtonClick,
		},
	];

	return (
		<Flag
			key="issue-rank-warning-flag"
			id="issue-rank-warning-flag"
			title={
				<FormattedMessage
					{...(fg('jira-issue-terminology-refresh-m3')
						? messages.titleIssueTermRefresh
						: messages.title)}
				/>
			}
			description={
				<div>
					<FormattedMessage
						{...(fg('jira-issue-terminology-refresh-m3')
							? messages.sortedPreventIssueRankingDescriptionIssueTermRefresh
							: messages.sortedPreventIssueRankingDescription)}
						values={{
							strong: (chunks: ReactNode) => <Text as="strong">{chunks}</Text>,
						}}
					/>
				</div>
			}
			icon={<WarningIcon label="" primaryColor={token('color.icon.warning', colors.Y300)} />}
			actions={actions}
		/>
	);
};
