import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	scope: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.subheader.scope.scope',
		defaultMessage: 'Scope',
		description: 'Header for the Scope region',
	},
	selectedIssues: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.subheader.scope.selected-issues',
		defaultMessage: '{totalSelected, plural, one {# issue} other {# issues}} selected',
		description: 'Bulk actions dropdown button',
	},
	selectAllIssueLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.subheader.scope.select-all-issue-label',
		defaultMessage: 'Select all issues',
		description: 'AriaLabel text for checkbox which selects all issues.',
	},
	selectedIssuesIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.subheader.scope.selected-issues-issue-term-refresh',
		defaultMessage: '{totalSelected, plural, one {# work item} other {# work items}} selected',
		description: 'Bulk actions dropdown button',
	},
});
