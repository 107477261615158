/** @jsx jsx */

/* eslint-disable jira/react/no-style-attribute, @atlaskit/ui-styling-standard/no-classname-prop */
import React, { type Ref, forwardRef } from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { CellProviderNew } from '../../controllers/cell/index.tsx';
import { useColumnStickyOffset } from '../../controllers/grid/index.tsx';
import type { Props } from './types.tsx';

export const Cell = forwardRef(
	(
		{ column, colSpan, style, top, className, ...restProps }: Props,
		ref: Ref<HTMLTableCellElement>,
	) => {
		const [left] = useColumnStickyOffset({ column, preview: true });

		const isSticky = top !== undefined || left !== undefined;

		return (
			<CellProviderNew column={column} colSpan={colSpan ?? 1}>
				<td
					ref={ref}
					colSpan={colSpan}
					className={className}
					css={[cellStyles, isSticky && stickyStyles]}
					style={{
						top,
						left,
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						...style,
					}}
					// This spreading is to allow the consumer to customize the tr attributes

					{...restProps}
				/>
			</CellProviderNew>
		);
	},
);

const cellStyles = css({
	marginTop: token('space.0'),
	marginRight: token('space.0'),
	marginBottom: token('space.0'),
	marginLeft: token('space.0'),
	paddingTop: token('space.0'),
	paddingRight: token('space.0'),
	paddingBottom: token('space.0'),
	paddingLeft: token('space.0'),
	position: 'relative',
});

const stickyStyles = css({
	position: 'sticky',
});
