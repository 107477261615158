import React, { forwardRef, useCallback, useContext, useMemo } from 'react';
import Button from '@atlaskit/button/new';
import Popup, { type PopupComponentProps } from '@atlaskit/popup'; // ignore-for-ENGHEALTH-17759
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { SuggestedChildIssue } from '@atlassian/jira-issue-fetch-services/src/services/issue-breakdown-data-fetcher/types.tsx';
import { useFetchSuggestedChildIssues } from '@atlassian/jira-issue-view-services/src/child-issues/use-fetch-suggested-child-issues/index.tsx';
import { useAiWorkBreakDownAnalytics } from '@atlassian/jira-portfolio-3-ai-work-breakdown/src/common/analytics/index.tsx';
import {
	steps,
	reasons,
	useIssueBreakdown,
} from '@atlassian/jira-portfolio-3-ai-work-breakdown/src/controllers/context.tsx';
import { IssueBreakdownEntryPointContext } from '@atlassian/jira-portfolio-3-ai-work-breakdown/src/controllers/entrypoint-context.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import type { Props } from './types.tsx';

const CustomPopupContainer = forwardRef<HTMLDivElement, PopupComponentProps>(
	({ children, 'data-testid': testId, xcss: _xcss, ...props }, ref) => (
		// Note: this is the recommended way from AK documentation: https://atlassian.design/components/popup/examples#customization
		// eslint-disable-next-line react/jsx-props-no-spreading
		<Box xcss={customPopupContainerStyles} testId={testId} {...props} ref={ref}>
			{children}
		</Box>
	),
);

const AiWorkBreakdownDialog = ({
	groupCombination,
	isOpen,
	issue,
	issueTypes,
	renderIssueSummary,
	toggleAiWorkBreakdownPopup,
	bulkInlineCreateIssues,
	isCreatingIssues,
	issueTypesFromProject,
}: Props) => {
	const intl = useIntl();

	const [
		{ channelId, currentStep, promptValue },
		{
			clearError,
			getSuggestedLength,
			removeSuggestedIssue,
			resetIssueBreakdownState,
			setHeaderPromptValue,
			setIssueBreakdownStep,
			setPromptValue,
			updateStreamingStatus,
		},
	] = useIssueBreakdown();
	const { analyticsEvent, fireTrack } = useAiWorkBreakDownAnalytics({ channelId });
	const { entryPointReferenceSubject } = useContext(IssueBreakdownEntryPointContext);

	const onFetchSuggestedIssuesSuccess = useCallback(() => {
		setIssueBreakdownStep(steps.draftListStep);
		setPromptValue(''); // clear the prompt if fetching was successful
	}, [setIssueBreakdownStep, setPromptValue]);

	const onFetchSuggestedIssuesFailure = useCallback(() => {
		setIssueBreakdownStep(steps.errorStep);
	}, [setIssueBreakdownStep]);

	const { isFetchingSuggestedChildIssues } = useFetchSuggestedChildIssues(
		onFetchSuggestedIssuesSuccess,
		onFetchSuggestedIssuesFailure,
	);

	/**
	 * Reset and close issue breakdown whenever it is cancelled.
	 */
	const cancelIssueBreakdown = useCallback(() => {
		resetIssueBreakdownState(analyticsEvent);
		toggleAiWorkBreakdownPopup({ isOpen: false });
	}, [analyticsEvent, toggleAiWorkBreakdownPopup, resetIssueBreakdownState]);

	/**
	 * Reset and close issue breakdown whenever the popup is closed.
	 */
	const handleCloseAiWorkBreakdownPopup = () => {
		resetIssueBreakdownState(analyticsEvent);
		toggleAiWorkBreakdownPopup({ isOpen: false });
	};

	const onSubmitFetchSuggestedChildIssues = useCallback(() => {
		if (isFetchingSuggestedChildIssues) {
			return;
		}

		// Only change the header prompt if there's a prompt value
		if (promptValue) {
			fireTrack('aiResult actioned', 'promptValueChanged', { aiResultAction: 'promptRefined' });
			setHeaderPromptValue(promptValue);
		}

		updateStreamingStatus(true);
		clearError();
		currentStep !== steps.draftListStep && setIssueBreakdownStep(steps.draftListStep);
	}, [
		clearError,
		currentStep,
		fireTrack,
		isFetchingSuggestedChildIssues,
		promptValue,
		setHeaderPromptValue,
		setIssueBreakdownStep,
		updateStreamingStatus,
	]);

	const onCreateSuggestedChildIssues = useCallback(
		(issues: Array<SuggestedChildIssue>) => {
			let resolveCallback;

			new Promise<string[]>((resolve: (ids: string[]) => void) => {
				resolveCallback = resolve;
			}).then((createdIssueIds: string[]) => {
				/**
				 * Once we have created the issue(s), we need to remove the suggestion(s) that was (were) accepted.
				 * If there are no remaining suggestions, we should close the AIWB dialog.
				 */
				removeSuggestedIssue(createdIssueIds, reasons.accept);

				if (getSuggestedLength() === 0) {
					cancelIssueBreakdown();
				}
			});

			bulkInlineCreateIssues({
				resolve: resolveCallback,
				parentIssue: {
					group: issue.group,
					groupCombination,
					hierarchyLevel: issue.level,
					parentId: issue.id,
					projectId: issue.project.id,
				},
				suggestedIssues: issues.map((i) => ({
					title: i.summary,
					description: i.description,
					issueTypeId: Number(i.issueTypeId),
					id: i.id,
				})),
			});
		},
		[
			bulkInlineCreateIssues,
			cancelIssueBreakdown,
			getSuggestedLength,
			groupCombination,
			issue.group,
			issue.id,
			issue.level,
			issue.project.id,
			removeSuggestedIssue,
		],
	);

	const childIssueTypes = issueTypes
		.filter(({ id, level }) => level === issue.level - 1 && issueTypesFromProject.includes(id))
		.map(({ id, iconUrl, name }) => ({
			description: '',
			hasEpicLinkField: false,
			hasRequiredField: false,
			hasWorkflowValidators: false,
			iconUrl,
			id: String(id),
			name,
			requiredFields: [],
		}));

	const runtimeProps = useMemo(
		() => ({
			childIssueTypes,
			onChangePrompt: setPromptValue,
			onCreate: onCreateSuggestedChildIssues,
			onDiscard: cancelIssueBreakdown,
			onRetry: onSubmitFetchSuggestedChildIssues,
			onSubmit: onSubmitFetchSuggestedChildIssues,
			parentIssueDetails: {
				id: issue.id,
				issueKey: issue.issueKey,
				level: issue.level,
				projectKey: issue.project.key,
			},
			isCreatingIssues,
		}),
		[
			cancelIssueBreakdown,
			childIssueTypes,
			issue.id,
			issue.issueKey,
			issue.level,
			issue.project.key,
			onCreateSuggestedChildIssues,
			onSubmitFetchSuggestedChildIssues,
			setPromptValue,
			isCreatingIssues,
		],
	);

	return (
		<Popup
			autoFocus={false}
			content={() => (
				<JiraEntryPointContainer
					entryPointReferenceSubject={entryPointReferenceSubject}
					id="jira-plan-ai-work-breakdown"
					packageName="jiraPlanAiWorkBreakdown"
					runtimeProps={runtimeProps}
					fallback={<Button isLoading>{intl.formatMessage(commonMessages.loading)}</Button>}
				/>
			)}
			isOpen={isOpen}
			onClose={handleCloseAiWorkBreakdownPopup}
			placement="bottom-end"
			popupComponent={CustomPopupContainer}
			trigger={renderIssueSummary}
		/>
	);
};

export default AiWorkBreakdownDialog;

const customPopupContainerStyles = xcss({
	borderRadius: token('border.radius', '6px'),
});
