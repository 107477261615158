import sum from 'lodash/fp/sum';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import {
	type ViewMode,
	VIEW_MODES,
} from '@atlassian/jira-portfolio-3-common/src/common/types/view-mode.tsx';
import { DEFAULT_SCROLLBAR_OFFSET } from '@atlassian/jira-portfolio-3-common/src/custom-scrollbar/constants.tsx';
import {
	SCOPE,
	TIMELINE,
	ADD_FIELDS,
	FIELD,
	EMPTY,
	SECTION_COLLAPSE_THRESHOLD,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/grid/constants.tsx';
import type { GridColumn } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/grid/types.tsx';
import type { RangeExtractor } from '@atlassian/jira-portfolio-3-treegrid/src/common/types.tsx';
import type { ScopeIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import type { ParentIndicator } from '@atlassian/jira-portfolio-3-issue-drag-and-drop/src/types.tsx';

const uniq = (a: number[]): number[] => Array.from(new Set(a));

export const getDefaultStickyOffset = () => ({
	vertical: 0,
	horizontal: 0,
});

/**
 * Used for virtualization:
 * - Always includes row 0 and 1 which are always visible (HEADER and SUBHEADER)
 * - Including the nearest table body header
 */
export const makeRangeExtractor =
	(stickyRows: boolean[]): RangeExtractor =>
	({ above, within, below }) => {
		for (let cursor = (within[0] ?? 0) - 1; cursor >= 0; cursor--) {
			if (stickyRows[cursor]) {
				const additional = above.includes(cursor) ? [] : [cursor];
				return uniq([0, 1, ...additional, ...above, ...within, ...below]);
			}
		}
		return uniq([0, 1, ...above, ...within, ...below]);
	};

/**
 * Returns the GetColspan for the treegrid
 *
 * Cells not being visible is not rendered on the DOM, increasing the colspan value for the first-visible cell to the left.
 */
export const makeColspan =
	(viewMode: ViewMode, columns: GridColumn[]) =>
	({
		column,
		row,
		columnWidths,
	}: {
		column: number;
		row: number;
		columnWidths: number[];
	}): number => {
		const numOfFields =
			columns.length -
			2; /* First column is always SCOPE, last column is always ADD_FIELDS or TIMELINE */

		if (row === 0) {
			const isFieldColumn = ({ tag }: GridColumn) =>
				tag === FIELD || tag === ADD_FIELDS || tag === EMPTY;

			if (column === 1) {
				if (viewMode === VIEW_MODES.TIMELINE) {
					return numOfFields;
				}
				return numOfFields + 1 /* The ADD_FIELDS column */;
			}

			return isFieldColumn(columns[column]) ? 0 : 1;
		}

		if (viewMode === VIEW_MODES.TIMELINE) {
			if (columnWidths[column] === 0) {
				return 0;
			}

			const fieldsTotalWidth = sum(columnWidths.slice(1, columnWidths.length - 1));
			const fieldsCollapsed = fieldsTotalWidth <= SECTION_COLLAPSE_THRESHOLD;

			if (fieldsCollapsed) {
				if (column === 1) {
					return numOfFields;
				}

				if (column > 1 && column < 1 /* SCOPE */ + numOfFields) {
					return 0;
				}
			} else {
				let numberOfNextZeroColumns = 0;
				while (columnWidths[column + numberOfNextZeroColumns + 1] === 0) {
					numberOfNextZeroColumns++;
				}

				return numberOfNextZeroColumns + 1;
			}
		}

		return 1;
	};

/** Returns unique identifies for columns for differentiation internally. */
export const getColumnIds = (columns: GridColumn[]) =>
	columns.map((column) => {
		switch (column.tag) {
			case SCOPE:
			case ADD_FIELDS:
			case EMPTY:
			case TIMELINE:
				return column.tag;
			default:
				return column.payload.id;
		}
	});

/**
 * Returns the inset needed to hide auto-hide horizontal scrolling bar.
 */
export const getViewportInset = (nativeScrollbarHeight: number) => {
	const bottom = nativeScrollbarHeight > 0 ? 0 : DEFAULT_SCROLLBAR_OFFSET;
	return { top: 0, right: 0, bottom, left: 0 };
};

/** Returns the additional height in order to hide the browser horizontal scrollbar. */
export const getAdditionalHeightToHideNativeScrollbar = (nativeScrollbarHeight: number) => {
	if (nativeScrollbarHeight > 0) {
		return nativeScrollbarHeight;
	}

	return DEFAULT_SCROLLBAR_OFFSET;
};

export const getBackgroundColor = ({
	issue,
	isOptimized,
	parentIndicator,
}: {
	issue?: ScopeIssue;
	isOptimized?: boolean;
	parentIndicator?: ParentIndicator;
}) => {
	const isEven = issue?.rootIndex === undefined ? false : issue.rootIndex % 2 === 0;

	if (parentIndicator)
		return parentIndicator.blocked
			? { base: token('color.background.danger', colors.R50) }
			: { base: token('color.background.selected', colors.B50) };

	if (!issue) return { base: 'transparent' };

	if (isOptimized)
		return {
			base: isEven ? 'transparent' : token('color.background.accent.gray.subtlest', colors.N20),
			hover: token('elevation.surface.sunken', '#f7f8f9'),
		};

	return {
		base: isEven
			? token('elevation.surface', colors.N0)
			: token('color.background.accent.gray.subtlest', colors.N20),
		hover: token('elevation.surface.sunken', '#f7f8f9'),
	};
};
