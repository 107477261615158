import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	issues: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies-cells.outgoing-dependencies.issues',
		defaultMessage: '{count, plural, one {# issue} other {# issues}}',
		description: "Number of issue's outward links",
	},
	addDependency: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies-cells.outgoing-dependencies.add-dependency',
		defaultMessage: '+ Add dependency',
		description: 'Label for button to add outgoing dependency',
	},
	issuesIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies-cells.outgoing-dependencies.issues-issue-term-refresh',
		defaultMessage: '{count, plural, one {# work item} other {# work items}}',
		description: "Number of issue's outward links",
	},
});
