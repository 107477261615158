import React from 'react';
import { Card } from '@atlaskit/smart-card';
import { xcss, Anchor } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import type { CommonFieldComponentProps } from '../../common/types.tsx';

export const isHyperlinkFieldComponentProps = (
	props: CommonFieldComponentProps,
): props is HyperlinkFieldComponentProps => 'value' in props && typeof props.value === 'string';

export type HyperlinkFieldComponentProps = CommonFieldComponentProps & {
	value: string;
};

export const HyperlinkField = ({ value }: HyperlinkFieldComponentProps) => (
	<HyperlinkFieldDisplay value={value} />
);

export const HyperlinkFieldDisplay = ({ value }: { value: string }) => {
	return fg('polaris-issueview-hyperlink-fallback') ? (
		<Card
			key={value}
			url={value}
			appearance="inline"
			showHoverPreview
			fallbackComponent={() => HyperlinkFallback(value)}
		/>
	) : (
		<Card key={value} url={value} appearance="inline" showHoverPreview />
	);
};

const HyperlinkFallback = (value: string) => {
	return (
		<Anchor href={value} xcss={hyperlinkFallbackStyles}>
			{value}
		</Anchor>
	);
};

const hyperlinkFallbackStyles = xcss({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	width: '100%',
});
