import React, { useCallback } from 'react';
import includes from 'lodash/includes';
import intersection from 'lodash/intersection';
import difference from 'lodash/difference';
import sortBy from 'lodash/sortBy';
import union from 'lodash/union';
import uniqBy from 'lodash/uniqBy';
import Popup from '@atlaskit/popup'; // ignore-for-ENGHEALTH-17759
import { Text } from '@atlaskit/primitives';
import { useIntl, FormattedMessage } from '@atlassian/jira-intl';
import Checkbox from '@atlassian/jira-portfolio-3-common/src/checkbox/index.tsx';
import { NO_CUSTOM_FIELD_VALUE_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/custom-field-filter/index.tsx';
import type { SelectOption } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/select-options/types.tsx';
import EllipsedWithTooltip from '@atlassian/jira-portfolio-3-portfolio/src/common/view/ellipsed-with-tooltip/index.tsx';
import SearchField from '@atlassian/jira-portfolio-3-portfolio/src/common/view/search-field/index.tsx';
import { ContentWrapper, ItemWrapper } from '../../common/index.tsx';
import ClearFilterButton from '../../common/clear-filter/index.tsx';
import FilterText from '../../common/filter-text/index.tsx';
import TriggerButton from '../../common/trigger-button/index.tsx';
import filterMessages from '../../messages.tsx';
import messages from '../messages.tsx';

import type { Props } from './types.tsx';

const SingleSelectFilterInner = (
	props: Props & { setInitialFocusRef?: (elem: HTMLElement | null) => void },
) => {
	const { formatMessage } = useIntl();
	const {
		value,
		options,
		onRequestClear,
		customField,
		searchQuery,
		onQueryChange,
		setInitialFocusRef,
		onChange,
	} = props;

	const isOptionSelected = useCallback(
		(optionValue: string) => {
			if (optionValue === NO_CUSTOM_FIELD_VALUE_ID) {
				return includes(value, optionValue);
			}
			const matchingOptionIds = options
				.filter((option) => option.value === optionValue)
				.map((option) => option.id.toString());
			// eg if id=1, value='red', id=2, value='red', id=3, value='blue' and value=[2,3] is saved in the filter
			return intersection(matchingOptionIds, value).length > 0;
		},
		[value, options],
	);

	const handleOptionClick = useCallback(
		(optionValue: string) => {
			const matchingOptionIds =
				optionValue === NO_CUSTOM_FIELD_VALUE_ID
					? [NO_CUSTOM_FIELD_VALUE_ID]
					: options
							.filter((option) => option.value === optionValue)
							.map((option) => option.id.toString());

			// if option is already selected, remove it
			const isSelected = value.some((opt) => matchingOptionIds.includes(opt));
			if (isSelected) {
				// by sending the set without these options
				onChange(difference(value, matchingOptionIds));
			} else {
				// otherwise include all the matching ids
				onChange(union(matchingOptionIds, value));
			}
		},
		[value, options, onChange],
	);

	const renderOptions = useCallback(() => {
		const filteredOptions: SelectOption[] = sortBy(
			options.filter((option) => option.value.toLowerCase().includes(searchQuery.toLowerCase())),
			['value'],
		);

		/**
		 * it's possible to have multiple options defined with the same name (value) when there are
		 * multiple contexts defined for the field.
		 * We don't want to show all these as the are indistinguishable duplicates to the user.
		 * Instead, just select based on the value and later re-map this to all the matching ids to
		 * perform the issue filtering using the ids.
		 */
		const dedupedOptions = uniqBy(filteredOptions, 'value');

		return (
			<>
				{options.length > 0 ? (
					<ItemWrapper>
						<Checkbox
							id={NO_CUSTOM_FIELD_VALUE_ID}
							key={NO_CUSTOM_FIELD_VALUE_ID}
							isChecked={isOptionSelected(NO_CUSTOM_FIELD_VALUE_ID)}
							onChange={() => handleOptionClick(NO_CUSTOM_FIELD_VALUE_ID)}
							label={formatMessage(messages.noValue)}
						/>
					</ItemWrapper>
				) : null}
				{dedupedOptions.map(({ value: optionValue }: { value: string }) => (
					<ItemWrapper key={optionValue}>
						<Checkbox
							id={`${optionValue}`}
							key={optionValue}
							isChecked={isOptionSelected(`${optionValue}`)}
							onChange={() => handleOptionClick(`${optionValue}`)}
							label={
								<EllipsedWithTooltip id={optionValue} content={optionValue}>
									{}
									<span>{optionValue}</span>
								</EllipsedWithTooltip>
							}
						/>
					</ItemWrapper>
				))}
				{filteredOptions.length === 0 && (
					<Text color="color.text.disabled">
						<FormattedMessage {...messages.noMatchFoundText} />
					</Text>
				)}
			</>
		);
	}, [options, searchQuery, isOptionSelected, handleOptionClick, formatMessage]);

	return (
		<ContentWrapper>
			<ClearFilterButton isVisible={!!value.length} onClearClick={onRequestClear} />
			<SearchField
				placeholder={formatMessage(messages.searchPlaceholder, {
					fieldName: customField.title,
				})}
				searchQuery={searchQuery}
				onQueryChange={onQueryChange}
				setInitialFocusRef={setInitialFocusRef}
			/>
			{renderOptions()}
		</ContentWrapper>
	);
};

const SingleSelectFilter = (props: Props) => {
	const { formatMessage } = useIntl();
	const { isOpen, onOpenChange, value, options, customField } = props;

	const constructFilterText = () => {
		const noValueOption = {
			id: NO_CUSTOM_FIELD_VALUE_ID,
			value: formatMessage(messages.noValue),
		};

		const uniqueLabels = [noValueOption].concat(options).reduce((acc, option) => {
			if (value.includes(`${option.id}`)) {
				return acc.add(option.value);
			}
			return acc;
		}, new Set<string>());

		return [...uniqueLabels].join(', ');
	};

	const filterText = () => {
		return value.length > 0 ? (
			<FilterText text={constructFilterText()} />
		) : (
			<FormattedMessage {...messages.emptyPlaceholder} />
		);
	};

	const ariaText = () => {
		return `${customField.title}, ${
			value.length > 0 ? constructFilterText() : formatMessage(messages.emptyPlaceholder)
		} ${formatMessage(filterMessages.selected)}`;
	};

	return (
		<Popup
			isOpen={isOpen}
			placement="bottom-start"
			onClose={() => {
				onOpenChange({ isOpen: false });
			}}
			content={(contentProps) => (
				<SingleSelectFilterInner {...props} setInitialFocusRef={contentProps.setInitialFocusRef} />
			)}
			testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.custom-field-filter.single-select.popup"
			trigger={(triggerProps) => (
				<TriggerButton
					{...triggerProps}
					isOpen={isOpen}
					onOpenChange={onOpenChange}
					triggerButtonText={filterText()}
					testId={`portfolio-3-portfolio.app-simple-plans.top.filter-bar.custom-field-filter.single-select.trigger-btn.${customField.id}`}
					ariaLabel={ariaText()}
				/>
			)}
		/>
	);
};

export default SingleSelectFilter;
