import pick from 'lodash/pick';
import { VIEW_MODES } from '@atlassian/jira-portfolio-3-common/src/common/types/view-mode.tsx';
import { mergeDeepLeft } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import {
	combineReducersOpen,
	composeReducers,
	dynamicKeyReducer,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/redux/index.tsx';
import { RESET, type ResetAction } from './actions.tsx';
import colourBy from './colour-by/reducer.tsx';
import componentGroups from './component-groups/reducer.tsx';
import customFieldValuesGroups from './custom-field-values-groups/reducer.tsx';
import dateConstraints from './date-constraints-setting/reducer.tsx';
import dependencySettings from './dependency-settings/reducer.tsx';
import fieldColumns from './field-columns/reducer.tsx';
import filterOptions from './filter-options/reducer.tsx';
import filters from './filters/reducer.tsx';
import highlightedVersions from './highlighted-versions/reducer.tsx';
import issueExpansions from './issue-expansions/reducer.tsx';
import labelGroups from './label-groups/reducer.tsx';
import rollupSettings from './rollup-settings/reducer.tsx';
import timeScale from './time-scale/reducer.tsx';
import type { ViewSettingsState } from './types.tsx';
import viewMode from './view-mode/reducer.tsx';
import visualisations from './visualisations/reducer.tsx';
import warningSettings from './warning-settings/reducer.tsx';

const fieldColumnsReducer = dynamicKeyReducer(
	fieldColumns,
	(state?: ViewSettingsState) =>
		state?.viewModeV0?.mode === VIEW_MODES.LIST ? 'listFieldColumnsV0' : 'fieldColumnsV0',
	['fieldColumnsV0', 'listFieldColumnsV0'],
);

const staticKeyReducers = combineReducersOpen({
	filtersV1: filters,
	colourByV2: colourBy,
	timeScaleV1: timeScale,
	issueExpansionsV0: issueExpansions,
	filterOptionsV1: filterOptions,
	rollupSettingsV0: rollupSettings,
	highlightedVersionsV0: highlightedVersions,
	componentGroupsV0: componentGroups,
	labelGroupsV0: labelGroups,
	customFieldValuesGroupsV0: customFieldValuesGroups,
	warningSettingsV1: warningSettings,
	dateConstraintsV0: dateConstraints,
	visualisationsV1: visualisations,
	dependencySettingsV0: dependencySettings,
	viewModeV0: viewMode,
});

const combined = composeReducers(staticKeyReducers, fieldColumnsReducer);

const resetState = combined(undefined, { type: '@@INIT' });
const resetKeys = Object.keys(resetState);

const finalReducer = (
	state: ViewSettingsState = resetState,
	action: ResetAction,
): ViewSettingsState => {
	switch (action.type) {
		case RESET: {
			return mergeDeepLeft(pick(action.payload, resetKeys), resetState);
		}
		default: {
			return combined(state, action);
		}
	}
};

export default finalReducer;
