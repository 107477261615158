import React, { useEffect } from 'react';
import { Stack, Box, Grid, xcss, Inline } from '@atlaskit/primitives';
import {
	useFieldKeysForProject,
	useFieldsActions,
	useFieldType,
} from '@atlassian/jira-polaris-component-jpd-implant-kit/src/controllers/fields/index.tsx';
import { SkeletonCompiled } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';
import { FieldLabel } from '@atlassian/jira-polaris-component-jpd-implant-kit/src/ui/field-label/index.tsx';
import {
	isDisabledField,
	isVisibleField,
} from '@atlassian/jira-polaris-component-jpd-implant-kit/src/ui/utils.tsx';
import { FieldComponent } from '@atlassian/jira-polaris-component-jpd-implant-kit/src/ui/index.tsx';
import { EmojiContainer } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import {
	useIdeaActions,
	useIdeaFieldValueByKey,
	useIdeaUsers,
	useIdeaKey,
	useIdeaId,
	useIdeaStatuses,
	useIdeaIsLoading,
} from '@atlassian/jira-polaris-component-jpd-implant-kit/src/controllers/ideas/index.tsx';
import { usePinnedFieldsActions, usePinnedFieldsByProjectId } from '../controllers/index.tsx';
import type {
	IdeaViewFieldListProps,
	IdeaViewFieldItemRendererProps,
	IdeaViewFieldListSectionProps,
	IssueCardPinnedFieldsProps,
} from './types.tsx';

export const IssueCardPinnedFields = ({ projectId, issueKey }: IssueCardPinnedFieldsProps) => {
	const cloudId = useCloudId();
	const accountId = useAccountId();

	return (
		<EmojiContainer cloudId={cloudId} currentUser={accountId ?? undefined}>
			<IdeaViewFieldList issueKey={issueKey} projectId={projectId} />
		</EmojiContainer>
	);
};

const IdeaViewFieldList = ({ issueKey, projectId }: IdeaViewFieldListProps) => {
	const { loadFieldsForProject } = useFieldsActions();
	const { loadIdeaByIssueId } = useIdeaActions();
	const { loadPinnedFieldsByProjectId } = usePinnedFieldsActions();
	const [fieldKeys] = useFieldKeysForProject(projectId);
	const [pinnedFields] = usePinnedFieldsByProjectId(projectId);
	const [isIdeaLoading] = useIdeaIsLoading();

	useEffect(() => {
		if (projectId) {
			loadFieldsForProject(projectId);
			loadPinnedFieldsByProjectId(projectId);
		}
	}, [projectId, loadFieldsForProject, loadPinnedFieldsByProjectId]);

	useEffect(() => {
		loadIdeaByIssueId(issueKey, projectId);
	}, [issueKey, loadIdeaByIssueId, projectId]);

	return (
		<Box xcss={fieldListStyles}>
			{fieldKeys && pinnedFields && !isIdeaLoading ? (
				<IdeaViewFieldListSection fieldKeys={fieldKeys} pinnedFields={pinnedFields} />
			) : (
				<SkeletonCompiled width="100%" height="20px" />
			)}
		</Box>
	);
};

const IdeaViewFieldListSection = ({ fieldKeys, pinnedFields }: IdeaViewFieldListSectionProps) => {
	return (
		<Stack space="space.100">
			{fieldKeys
				.filter((key) => pinnedFields.includes(key))
				.map((key) => <IdeaViewFieldItemRenderer key={key} fieldKey={key} />)
				.filter(Boolean)}
		</Stack>
	);
};

const IdeaViewFieldItemRenderer = ({ fieldKey }: IdeaViewFieldItemRendererProps) => {
	const [users] = useIdeaUsers();
	const [statuses] = useIdeaStatuses();
	const [ideaKey] = useIdeaKey();
	const [ideaId] = useIdeaId();
	const [fieldType] = useFieldType(fieldKey);
	const [value] = useIdeaFieldValueByKey(fieldKey);
	const isDisabled = isDisabledField(fieldType);

	if (!fieldType) {
		return null;
	}

	if (!isVisibleField(fieldType)) {
		return null;
	}

	return (
		<Grid
			templateColumns="[title] minmax(100px, 0.6fr) [content] 1fr"
			gap="space.100"
			alignItems="center"
			xcss={gridStyling}
		>
			<Inline space="space.050" alignBlock="center">
				<FieldLabel
					color={isDisabled ? 'color.text.disabled' : 'color.text.subtle'}
					fieldKey={fieldKey}
				/>
			</Inline>

			<FieldComponent
				fieldKey={fieldKey}
				value={value}
				users={users}
				statuses={statuses}
				isMultiline
				ideaId={ideaId}
				ideaKey={ideaKey}
			/>
		</Grid>
	);
};

const gridStyling = xcss({
	gridAutoRows: 'auto',
});

const fieldListStyles = xcss({
	paddingTop: 'space.200',
});
