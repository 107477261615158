import * as R from 'ramda';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { PlanningUnits } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import type { HistoryIssue } from '../../state/domain/history-issues/types.tsx';
import type { Issue } from '../../state/domain/issues/types.tsx';
import type { ScopeIssue } from '../../state/domain/scope/types.tsx';

export const convertSecondsToHours = (seconds: number): number => seconds / 3600;

export const convertSecondsToDays = (seconds: number, hoursPerDay: number): number =>
	convertSecondsToHours(seconds) / hoursPerDay;

export const isEstimated = (
	planningUnit: string,
	issue: Issue | HistoryIssue | ScopeIssue,
): boolean => {
	switch (planningUnit) {
		case PlanningUnits.storyPoints:
			return isDefined(issue.storyPoints) && !R.isEmpty(issue.storyPoints);
		case PlanningUnits.days:
		case PlanningUnits.hours:
			return isDefined(issue.timeEstimate) && !R.isEmpty(issue.timeEstimate);
		default:
			throw new Error(`Unexpected planningUnit: ${planningUnit}`);
	}
};

export const getEstimateRollupValue = (
	estimate: number | null,
	planningUnit: string,
	workingHours: number,
) => {
	if (estimate === null) {
		return '';
	}

	switch (planningUnit) {
		case PlanningUnits.days:
			return Math.round(convertSecondsToDays(estimate, workingHours) * 100) / 100;
		case PlanningUnits.hours:
			return Math.round(convertSecondsToHours(estimate) * 100) / 100;
		default:
			return Math.round(estimate * 10) / 10;
	}
};

export const getEstimateValue = (
	planningUnit: string,
	issue: Pick<Issue, 'storyPoints' | 'timeEstimate' | 'status'> | ScopeIssue | HistoryIssue,
	hoursPerDay: number,
): number => {
	switch (planningUnit) {
		case PlanningUnits.storyPoints:
			return issue.storyPoints ? issue.storyPoints : 0;
		case PlanningUnits.days:
			return issue.timeEstimate ? convertSecondsToDays(issue.timeEstimate, hoursPerDay) : 0;
		case PlanningUnits.hours:
			return issue.timeEstimate ? convertSecondsToHours(issue.timeEstimate) : 0;
		default:
			throw new Error(`Unexpected planningUnit: ${planningUnit}`);
	}
};

export const getTimeSpentValue = (
	planningUnit: string,
	issue: Issue | ScopeIssue | HistoryIssue,
	hoursPerDay: number,
): number => {
	switch (planningUnit) {
		case PlanningUnits.storyPoints:
			return 0;
		case PlanningUnits.days:
			return issue.timeSpent ? convertSecondsToDays(issue.timeSpent, hoursPerDay) : 0;
		case PlanningUnits.hours:
			return issue.timeSpent ? convertSecondsToHours(issue.timeSpent) : 0;
		default:
			throw new Error(`Unexpected planningUnit: ${planningUnit}`);
	}
};

const convertHoursToSeconds = (hours: number): number => hours * 3600;
const convertDaysToSeconds = (days: number, workingHours: number): number =>
	days * workingHours * 3600;
const round = (number: number): number => Math.round(number * 1000) / 1000;

const floorSecondsToNearestMinute = (seconds: number): number => Math.floor(seconds / 60) * 60;

export const convertEstimate = (
	planningUnit: string,
	value: number,
	workingHours: number,
): number => {
	switch (planningUnit) {
		case PlanningUnits.storyPoints:
			return round(value);
		case PlanningUnits.days:
			return floorSecondsToNearestMinute(convertDaysToSeconds(value, workingHours));
		case PlanningUnits.hours:
			return floorSecondsToNearestMinute(convertHoursToSeconds(value));
		default:
			throw new Error(`Unexpected value: ${value}`);
	}
};
