.barFlyout {
	display: inline-block;

	& tbody {
		border: none;
	}

	& table td {
		padding: 3px 5px;
		max-width: 150px;
	}

	& table tr {
		vertical-align: text-bottom;
		line-height: 20px;
	}

	/* Make the label column wide enough to fit in one line (assuming english i18n) */
	& table td:nth-child(1) {
		min-width: 70px;
	}

	/* Make the optimized with delta column wider */
	& table td:nth-child(4) {
		max-width: 250px;
	}

	& .label {
		color: var(--ds-text-subtlest, var(--adg3-color-N200));
		font-size: 12px;
		font-weight: var(--ds-font-weight-semibold, 600);
		line-height: 16px;
	}

	& .none,
	& .delta {
		color: var(--ds-text-subtlest, var(--adg3-color-N200));
	}
	& .valueWithIcon {
		display: flex;
		align-items: baseline;
	}
	& .rollUpIcon {
		position: relative;
		top: 5px;
	}
	& .sprintIcon {
		padding-right: 6px;
	}
	& .inferredText {
		font-style: italic;
		color: var(--ds-text-subtlest, var(--adg3-color-N200));
	}
}
