import { initialLoad } from '../../command/index.tsx';
import { restoreReduxStore, resetReduxStore } from '../../state/actions.tsx';

const mapDispatchToProps = {
	initialLoad,
	restoreReduxStore,
	resetReduxStore,
} as const;

export default mapDispatchToProps;
