import React from 'react';
import { Inline, xcss } from '@atlaskit/primitives';
import { Checkbox } from '@atlassian/jira-polaris-lib-checkbox/src/ui/index.tsx';
import type { CommonFieldComponentProps } from '../../common/types.tsx';
import { useFieldName } from '../../controllers/fields/index.tsx';

export const isCheckboxFieldComponentProps = (
	props: CommonFieldComponentProps,
): props is CheckboxFieldComponentProps =>
	'value' in props && (typeof props.value === 'number' || props.value === null);

export type CheckboxFieldComponentProps = CommonFieldComponentProps & {
	value: number | null;
};

export const CheckboxField = ({ fieldKey, value }: CheckboxFieldComponentProps) => {
	const [fieldName] = useFieldName(fieldKey);
	return <CheckboxFieldDisplay value={value} fieldName={fieldName} />;
};

export const CheckboxFieldDisplay = ({
	value,
	fieldName,
	showFieldName = false,
}: {
	value: number | null;
	fieldName: string;
	showFieldName?: boolean;
}) => (
	<Inline space="space.100" alignBlock="center" xcss={maxContentStyles}>
		{showFieldName && fieldName}
		<Checkbox isChecked={!!value} isDisabled />
	</Inline>
);

const maxContentStyles = xcss({
	width: 'max-content',
});
