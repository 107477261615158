import React from 'react';
import AvatarGroup from '@atlaskit/avatar-group';
import type { User } from '@atlassian/jira-shared-types/src/rest/jira/user.tsx';
import type { CommonFieldComponentProps } from '../../common/types.tsx';

export const isPeopleFieldComponentProps = (
	props: CommonFieldComponentProps,
): props is PeopleFieldComponentProps =>
	'value' in props &&
	Array.isArray(props.value) &&
	props.value.every((item) => typeof item === 'string');

export type PeopleFieldComponentProps = CommonFieldComponentProps & {
	value: string[];
	users: { [key: string]: User };
};

export const PeopleField = ({ value, users }: PeopleFieldComponentProps) => {
	const userAvatars = value.map((uid) => {
		const user = users[uid];

		return {
			key: user.key,
			name: user.displayName,
			src: user.avatarUrls['48x48'],
		};
	});

	return <PeopleFieldDisplay userAvatars={userAvatars} />;
};

export const PeopleFieldDisplay = ({
	userAvatars,
}: {
	userAvatars: { key: string | undefined; name: string; src: string }[];
}) => <AvatarGroup data={userAvatars} appearance="stack" size="small" />;
