import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	addColour: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.colour-by-issue-type.add-colour',
		defaultMessage: 'Add color',
		description: 'Label for a button to add a new colour',
	},
	chooseColour: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.colour-by-issue-type.choose-colour',
		defaultMessage: 'Choose color',
		description: 'Placeholder for the issue type select when colour is not picked',
	},
	selectColour: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.colour-by-issue-type.select-colour',
		defaultMessage: 'Select color',
		description:
			'The text is shown as label for the issue type select when colour is not picked which is displayed inside the view settings modal',
	},
	chooseIssueType: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.colour-by-issue-type.choose-issue-type',
		defaultMessage: 'Choose issue type',
		description: 'Placeholder for the issue type select when colour is picked',
	},
	selectIssueType: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.colour-by-issue-type.select-issue-type',
		defaultMessage: 'Select issue type',
		description:
			'The text is shown as label for the issue type select when colour is picked which is displayed inside the view settings modal',
	},
	companyManagedProjectsLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.colour-by-issue-type.company-managed-projects-label',
		defaultMessage: 'Company-managed projects',
		description: 'Group option heading in the issue type dropdown list',
	},
	allIssueTypesOption: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.colour-by-issue-type.all-issue-types-option',
		defaultMessage: 'All {issueTypeName} issue types',
		description: 'Option representing all issue types with the name {issueTypeName}.',
	},
	chooseIssueTypeIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.colour-by-issue-type.choose-issue-type-issue-term-refresh',
		defaultMessage: 'Choose work type',
		description: 'Placeholder for the issue type select when colour is picked',
	},
	selectIssueTypeIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.colour-by-issue-type.select-issue-type-issue-term-refresh',
		defaultMessage: 'Select work type',
		description:
			'The text is shown as label for the issue type select when colour is picked which is displayed inside the view settings modal',
	},
	allIssueTypesOptionIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.colour-by-issue-type.all-issue-types-option-issue-term-refresh',
		defaultMessage: 'All {issueTypeName} work types',
		description: 'Option representing all issue types with the name {issueTypeName}.',
	},
});
