import React, { useMemo } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { defaultLocale } from '@atlassian/jira-common-constants/src/supported-locales.tsx';
import { getAppliedIntervalDecoration } from '@atlassian/jira-polaris-component-decorations/src/utils/index.tsx';
import type { ValueDecoration } from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import { renderDateString } from '@atlassian/jira-polaris-lib-date-time/src/index.tsx';
import { CommonDecoratedTag } from '@atlassian/jira-polaris-lib-decoration/src/ui/decoration/common/index.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import type { CommonFieldComponentProps } from '../../common/types.tsx';
import { useFieldValueDecorations } from '../../controllers/fields/index.tsx';

export const isIntervalFieldComponentProps = (
	props: CommonFieldComponentProps,
): props is IntervalFieldComponentProps => 'value' in props && typeof props.value === 'string';

export type IntervalFieldComponentProps = CommonFieldComponentProps & {
	value: string;
};

export const IntervalField = ({ fieldKey, value }: IntervalFieldComponentProps) => {
	const locale = useLocale();

	const parsedValue = useMemo(() => {
		return JSON.parse(value);
	}, [value]);

	const intervalFormatted = useMemo(() => {
		return renderDateString(parsedValue);
	}, [parsedValue]);

	const [fieldValueDecorations] = useFieldValueDecorations(fieldKey);

	const decoration = getAppliedIntervalDecoration(
		fieldValueDecorations,
		parsedValue,
		locale || defaultLocale,
	);

	if (!intervalFormatted) {
		return null;
	}

	return <IntervalFieldDisplay value={intervalFormatted} decoration={decoration} />;
};

export const IntervalFieldDisplay = ({
	value,
	decoration,
}: {
	value: string;
	decoration: ValueDecoration | undefined;
}) => {
	const emojiDescription = useEmoji(decoration?.emoji);

	return (
		<Box xcss={maxContentStyles}>
			<CommonDecoratedTag
				value={value}
				mainColor={decoration?.backgroundColor}
				emoji={emojiDescription}
			/>
		</Box>
	);
};

const maxContentStyles = xcss({
	width: 'max-content',
});
