import { fetchQuery } from 'react-relay';
import { v4 as uuid } from 'uuid';
import { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type {
	Goal,
	LazyGoalsByARI,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issue-goals/types.tsx';
import getRelayEnvironment from '@atlassian/jira-relay-environment/src/index.tsx';
import { startCaptureGraphQlErrors } from '@atlassian/jira-relay-errors/src/index.tsx';
import fetchAtlasGoalsCompiledQuery, {
	type fetchAtlasGoalsQuery,
} from '@atlassian/jira-relay/src/__generated__/fetchAtlasGoalsQuery.graphql';
import searchAtlasGoalsCompiledQuery, {
	type searchAtlasGoalsQuery,
} from '@atlassian/jira-relay/src/__generated__/searchAtlasGoalsQuery.graphql';
import {
	startCapturingTraceIds,
	stopCapturingTraceIds,
	getTraceIds,
} from '@atlassian/relay-traceid';
import type { Node, FetchGoalsByARIResult, SearchGoalsByNameResult } from './types.tsx';
import { sendOperationalEvent, getGraphQLErrorsForOperation, getErrorDetails } from './utils.tsx';

const analyticKey = 'jira.frontend.fe.jpo.fetch-goals';
const performanceMarkPrefix = 'jpo-fetch-goals';
const operationName = searchAtlasGoalsCompiledQuery.params.name;
const goalsQueryByAriOperationName = fetchAtlasGoalsCompiledQuery.params.name;
const sloThreshold = 1000;
const maxPage = 10;

const lastPerformanceMarkIndexes: number[] = [0];

const extractNodesFromQuery = (data: searchAtlasGoalsQuery['response']): Node[] =>
	(data.townsquare.goalTql?.edges ?? [])
		.filter((edge): edge is { node: Node } => Boolean(edge))
		.map(({ node }) => node);

const extractNodes = (data: fetchAtlasGoalsQuery['response']): Node[] =>
	(data.townsquare.goalsByAri ?? []).filter((goal): goal is Node => Boolean(goal));

const getErrorsAndStopCapture = (
	errorCollector: string,
	graphQLOperationName?: string,
): {
	isError: boolean;
	isPermissionError: boolean;
	cause: string;
	statusCode: number | undefined;
} => {
	const relevantErrors = getGraphQLErrorsForOperation(
		errorCollector,
		graphQLOperationName ?? operationName,
	);
	const isError = relevantErrors.length > 0;
	const isPermissionError =
		isError &&
		relevantErrors.some((relevantError) => relevantError?.extensions?.statusCode === 403);
	return {
		isError,
		isPermissionError,
		cause: relevantErrors[0]?.message ?? '',
		statusCode: relevantErrors[0]?.extensions?.statusCode,
	};
};

const getNextPageCursor = (data: searchAtlasGoalsQuery['response'], pageCount: number): string => {
	if (data.townsquare.goalTql?.pageInfo.hasNextPage && pageCount < maxPage) {
		return data.townsquare.goalTql?.pageInfo.endCursor ?? '';
	}
	return '';
};

// eslint-disable-next-line @typescript-eslint/no-shadow
const getTraceIdAndStopCapture = (operationName: string): string => {
	const traceIds = getTraceIds(operationName);
	const traceId = traceIds.length > 0 ? traceIds[traceIds.length - 1] : '';
	stopCapturingTraceIds(operationName);
	return traceId;
};

const measureAndClearPerformance = (pageCount: number, markIndex: number): number => {
	const performanceMark = `${performanceMarkPrefix}-${pageCount}`;
	const { duration } = performance.measure(`${performanceMark}-measure`, performanceMark);
	// Only the last performance measure needs to clear the mark
	// Trying to clear the same mark again will cause an error
	if (markIndex === lastPerformanceMarkIndexes[pageCount - 1]) {
		performance.clearMarks(performanceMark);
	}
	// In practice, it isn't necessary to reset the performance mark index because it won't exceed max number
	return duration;
};

const cleanUpPerformanceAndLog = (
	statusCode: number | undefined,
	cause: string,
	pageCount: number,
	group: string,
	markIndex: number,
	traceId: string | undefined,
): void => {
	sendOperationalEvent({
		analyticKey,
		statusCode,
		cause,
		duration: measureAndClearPerformance(pageCount, markIndex),
		sloThreshold,
		pages: pageCount,
		group,
		traceId,
	});
};

const cleanUpAndLog = (
	// eslint-disable-next-line @typescript-eslint/no-shadow
	operationName: string,
	statusCode: number | undefined,
	cause: string,
	pageCount: number,
	group: string,
	markIndex: number,
): void => {
	cleanUpPerformanceAndLog(
		statusCode,
		cause,
		pageCount,
		group,
		markIndex,
		getTraceIdAndStopCapture(operationName),
	);
};

export const fetchGoalPagesByARI = (goalARIs: string[], chunkSize: number, maxChunks: number) => {
	const createFetchGoalsPromise = (goalARIChunk: string[], pageCount: number): Promise<Node[]> =>
		new Promise((resolve, reject) => {
			const performanceMark = `${performanceMarkPrefix}-${pageCount}`;
			performance.mark(performanceMark);
			lastPerformanceMarkIndexes[pageCount - 1] += 1;
			const currentMarkIndex = lastPerformanceMarkIndexes[pageCount - 1];

			// will automatically clean up error collector after a while if not cleaned up explicitly
			const errorCollector = startCaptureGraphQlErrors();

			fetchQuery<fetchAtlasGoalsQuery>(getRelayEnvironment(), fetchAtlasGoalsCompiledQuery, {
				goalARIs: goalARIChunk,
			})
				.toPromise()
				.then((data: fetchAtlasGoalsQuery['response'] | undefined) => {
					const { isError, isPermissionError, cause, statusCode } = getErrorsAndStopCapture(
						errorCollector,
						goalsQueryByAriOperationName,
					);

					const traceIds = getTraceIds(goalsQueryByAriOperationName);
					const traceId = traceIds.length > 0 ? traceIds[traceIds.length - 1] : undefined;

					if (!isError && data?.townsquare.goalsByAri) {
						cleanUpPerformanceAndLog(200, '', pageCount, 'nogroup', currentMarkIndex, traceId);
						const goalNodes: Node[] = extractNodes(data);
						resolve(goalNodes);
					} else if (isPermissionError) {
						cleanUpPerformanceAndLog(403, '', pageCount, 'nogroup', currentMarkIndex, traceId);
						reject(new ValidationError('Permission', [], 403));
					} else {
						cleanUpPerformanceAndLog(statusCode, cause, 1, 'nogroup', currentMarkIndex, traceId);
						reject(new ValidationError(cause ?? 'Error fetching goals', [], statusCode));
					}
				})
				.catch((error: Error) => {
					const { statusCode, cause } = getErrorDetails(error);

					const traceId = getTraceIds(goalsQueryByAriOperationName)[pageCount - 1];

					cleanUpPerformanceAndLog(
						statusCode,
						cause,
						pageCount,
						'nogroup',
						currentMarkIndex,
						traceId,
					);
					reject(error);
				});
		});

	const goalARIChunks: string[][] = [];
	const maxSize = chunkSize * maxChunks;

	for (let i = 0; i < goalARIs.length && i < maxSize; i += chunkSize) {
		goalARIChunks.push(goalARIs.slice(i, i + chunkSize));
	}

	while (lastPerformanceMarkIndexes.length < goalARIChunks.length) {
		lastPerformanceMarkIndexes.push(0);
	}

	startCapturingTraceIds(goalsQueryByAriOperationName);

	return Promise.allSettled(
		goalARIChunks.map((chunk, i) => createFetchGoalsPromise(chunk, i + 1)),
	).finally(() => stopCapturingTraceIds(goalsQueryByAriOperationName));
};

export const fetchGoalPagesByQuery = (
	query: string,
	cloudId: string,
	pageCursor: string | null = null,
	pageCount = 1,
	group: string = uuid(),
): Promise<Node[]> => {
	performance.mark(`${performanceMarkPrefix}-${pageCount}`);
	lastPerformanceMarkIndexes[pageCount - 1] += 1;
	const currentMarkIndex = lastPerformanceMarkIndexes[pageCount - 1];
	// safe to call startCapturingTraceIds multiple times
	startCapturingTraceIds(operationName);
	// will automatically clean up error collector after a while if not cleaned up explicitly
	const errorCollector = startCaptureGraphQlErrors();
	return new Promise((resolve, reject) => {
		fetchQuery<searchAtlasGoalsQuery>(getRelayEnvironment(), searchAtlasGoalsCompiledQuery, {
			query,
			cloudId,
			pageCursor,
		})
			.toPromise()
			.then((data: searchAtlasGoalsQuery['response'] | undefined) => {
				const { isError, isPermissionError, cause, statusCode } =
					getErrorsAndStopCapture(errorCollector);
				if (!isError && data?.townsquare.goalTql) {
					cleanUpAndLog(operationName, 200, '', pageCount, group, currentMarkIndex);
					const goalNodes: Node[] = extractNodesFromQuery(data);
					const nextPageCursor = getNextPageCursor(data, pageCount);
					if (nextPageCursor) {
						lastPerformanceMarkIndexes.push(0);
						return fetchGoalPagesByQuery(query, cloudId, nextPageCursor, pageCount + 1, group)
							.then((nextPageNodes) => {
								resolve(goalNodes.concat(nextPageNodes));
							})
							.catch((error: Error) => {
								// no need to handle the error right now, just bubble it up
								reject(error);
							});
					}
					resolve(goalNodes);
				} else if (isPermissionError) {
					cleanUpAndLog(operationName, 403, '', pageCount, group, currentMarkIndex);
					reject(new ValidationError('Permission', [], 403));
				} else {
					cleanUpAndLog(operationName, statusCode, cause, pageCount, group, currentMarkIndex);
					reject(new ValidationError(cause ?? 'Error fetching goals', [], statusCode));
				}
			})
			.catch((error: Error) => {
				const { statusCode, cause } = getErrorDetails(error);
				cleanUpAndLog(operationName, statusCode, cause, pageCount, group, currentMarkIndex);
				reject(error);
			});
	});
};

export const transformToGoal = ({ id, key, name, state, url }: Node): Goal => ({
	id,
	key,
	name,
	url,
	state: {
		...state,
		score: state.score ?? undefined, // convert null to undefined
	},
});

export const transformFetchGoalsByARI = (
	goalPromises: PromiseSettledResult<Node[]>[],
): FetchGoalsByARIResult => {
	const isError = goalPromises.some(
		(goalPromise) =>
			goalPromise.status === 'rejected' &&
			goalPromise.reason instanceof Error &&
			goalPromise.reason.message !== 'Permission',
	);

	const goals = goalPromises
		.filter((promise): promise is PromiseFulfilledResult<Node[]> => promise.status === 'fulfilled')
		.flatMap((promise) => promise.value)
		.map(transformToGoal);

	const lazyGoalsByARI: LazyGoalsByARI = Object.fromEntries(
		goals.map((goal) => [goal.id, { isLoading: false, goal }]),
	);

	return {
		lazyGoalsByARI,
		isError,
	};
};

export const transformSearchGoalsByName = (nodes: Node[]): SearchGoalsByNameResult => {
	const goals = nodes.map(transformToGoal);

	const lazyGoalsByARI: LazyGoalsByARI = Object.fromEntries(
		goals.map((goal) => [goal.id, { isLoading: false, goal }]),
	);

	return {
		lazyGoalsByARI,
	};
};

export const fetchGoalsByARI = (
	sourceARIs: string[],
	chunkSize = 200,
	maxChunks = 10,
): Promise<FetchGoalsByARIResult> =>
	fetchGoalPagesByARI(sourceARIs, chunkSize, maxChunks).then(transformFetchGoalsByARI);

// NOTE: This action is called from a text input onChange.
// Even though the usage is debounced, it's still possible for the next fetch to start before the previous fetch has completed.
// In this case, the performance mark is restarted, as performance.mark takes the most recent occurrence of a mark.
// This is fine; it just means we measure the timing of the final fetch, and discard all previous fetches that never completed (in time).
export const searchGoalsByName = (
	searchString: string,
	cloudId: string,
): Promise<SearchGoalsByNameResult> => {
	const query = `name like "${searchString}"`; // will be case-insensitive
	// only need to fetch a few goals for fuzzy name search so unnecessary to fetch additional pages
	return fetchGoalPagesByQuery(query, cloudId).then(transformSearchGoalsByName);
};
