// Note: We use block comment at top of the file because we get strange error in `SSR Image Build`
/* eslint-disable @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-unsafe-style-overrides */

import React from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import Heading from '@atlaskit/heading';
import { Anchor, Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip, { type TooltipPrimitiveProps, TooltipPrimitive } from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import type { AssociatedIssue } from '@atlassian/jira-portfolio-3-associated-issues/src/common/types.tsx';
import { IssueCardContent } from './issue-card-content/index.tsx';

const IssueCardContentOld = ({ data }: { data: AssociatedIssue }) => (
	<Box>
		<Box xcss={ideaContentCardTitle}>
			<Box xcss={ideaContentCardAvatar}>
				<Avatar src={data.iconUrl} size="xsmall" />
			</Box>
			<Anchor href={`/browse/${data.key}`} target="_blank" xcss={anchorStyles}>
				{data.key}
			</Anchor>
		</Box>
		<Heading as="div" size="xsmall">
			{data.summary}
		</Heading>
	</Box>
);

type IdeaIssueProps = {
	data: AssociatedIssue;
	flexShrink: number;
	projectId: string;
};

const IdeaIssue = ({ data, flexShrink, projectId }: IdeaIssueProps) => (
	<Box xcss={container} style={{ flexShrink }}>
		<Tooltip
			content={() =>
				fg('polaris-pinned-fields-for-ideas-in-plans') ? (
					<IssueCardContent data={data} projectId={projectId} issueKey={data.key} />
				) : (
					<IssueCardContentOld data={data} />
				)
			}
			component={TooltipLikeInlineDialog}
		>
			{(tooltipProps) => (
				// eslint-disable-next-line react/jsx-props-no-spreading
				<Box {...tooltipProps} xcss={contentWrapper}>
					<Box xcss={avatar}>
						<Avatar src={data.iconUrl} size="xsmall" />
					</Box>
					<Box xcss={summary}>{data.summary}</Box>
				</Box>
			)}
		</Tooltip>
	</Box>
);

export default IdeaIssue;

const container = xcss({
	display: 'flex',
	alignItems: 'center',
	paddingTop: 'space.100',
	paddingBottom: 'space.100',
	minWidth: 'size.300',
	height: '100%',
	flexBasis: 'content',
});

const avatar = xcss({
	paddingLeft: 'space.050',
	flexShrink: 0,
});

const summary = xcss({
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
});

const contentWrapper = xcss({
	display: 'flex',
	width: '100%',
});

const ideaContentCardTitle = xcss({
	display: 'flex',
	marginBottom: 'space.150',
});

const ideaContentCardAvatar = xcss({
	marginRight: 'space.100',
});

const TooltipLikeInlineDialog = styled<TooltipPrimitiveProps>(TooltipPrimitive)({
	background: 'white',
	borderRadius: token('border.radius', '4px'),
	boxShadow: '0 1px 2px rgba(0, 0, 0, 0.2)',
	boxSizing: 'content-box',
	paddingTop: token('space.100', '8px'),
	paddingRight: token('space.150', '12px'),
	paddingBottom: token('space.100', '8px'),
	paddingLeft: token('space.150', '12px'),
	width: '308px',
});

const anchorStyles = xcss({
	textDecoration: 'none',

	':hover': {
		textDecoration: 'underline',
	},
});
