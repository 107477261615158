import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	infoMessageBeforeLink: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-date.info-message.info-message-before-link',
		defaultMessage: 'To best represent child issues without start or end dates, ',
		description:
			'First constant part of message that indicates how many parent issues will loose their dates.',
	},
	linkLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-date.info-message.link-label',
		defaultMessage: '{issueCount, plural, one {# parent issue} other {# parent issues}}',
		description: 'Label for link',
	},
	infoMessageAfterLink: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-date.info-message.info-message-after-link',
		defaultMessage: '{issuesWithoutDates} will have their start or end dates removed.',
		description: 'Describes that the issues will loose their start or end date',
	},
	infoMessageBeforeLinkIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-date.info-message.info-message-before-link-issue-term-refresh',
		defaultMessage: 'To best represent child work items without start or end dates,',
		description:
			'First constant part of message that indicates how many parent issues will loose their dates.',
	},
	linkLabelIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-date.info-message.link-label-issue-term-refresh',
		defaultMessage: '{issueCount, plural, one {# parent work item} other {# parent work items}}',
		description: 'Label for link',
	},
});
