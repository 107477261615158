import isNil from 'lodash/isNil';
import type { CustomField } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/custom-fields/types.tsx';
import type { DateConfiguration } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/plan/types.tsx';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types.tsx';
import {
	MIN,
	MAX,
	SUM,
	MAP,
	type AggregateValue,
	type NumberFieldKey,
	type StringFieldKey,
} from './types.tsx';

const max = (
	acc: number | null | undefined,
	curr: number | null | undefined,
): number | undefined => {
	if (isNil(acc)) return curr ?? undefined;
	if (isNil(curr)) return acc;

	return Math.max(acc, curr);
};

const min = (
	acc: number | null | undefined,
	curr: number | null | undefined,
): number | undefined => {
	if (isNil(acc)) return curr ?? undefined;
	if (isNil(curr)) return acc;

	return Math.min(acc, curr);
};

const sum = (
	acc: number | null | undefined,
	curr: number | null | undefined,
): number | undefined => {
	if (isNil(acc) && isNil(curr)) return undefined;

	return (acc ?? 0) + (curr ?? 0);
};

const map = <T,>(acc: NonNullable<T>[], curr: T): NonNullable<T>[] => {
	if (curr !== undefined && curr !== null) acc.push(curr);

	return acc;
};

const getUseEarliestOrLatest = (
	objectKey: string,
	dateConfiguration: DateConfiguration,
	preferEarliest: boolean,
): boolean => {
	const { baselineStartField, baselineEndField } = dateConfiguration;
	const isBaselineStartField = baselineStartField.key === objectKey;
	const isBaselineEndField = baselineEndField.key === objectKey;

	// If both or neither are true, return the preferred reducer
	if (isBaselineStartField === isBaselineEndField) {
		return preferEarliest;
	}

	// If one is true and the other is false, return the associated reducer
	return isBaselineStartField;
};

const getBaselineDate = (issue: Issue, fieldKey: string, dateConfiguration: DateConfiguration) => {
	const { baselineStartField, baselineEndField } = dateConfiguration;
	const isBaselineStartField = baselineStartField.key === fieldKey;
	const isBaselineEndField = baselineEndField.key === fieldKey;

	if (isBaselineStartField) return issue.baselineStart;
	if (isBaselineEndField) return issue.baselineEnd;

	return undefined;
};

const aggregateDate = <K extends NumberFieldKey>(
	issues: Issue[],
	fieldKey: K,
	extractor: (issue: Issue) => Issue[K],
	dateConfiguration: DateConfiguration,
	preferEarliest: boolean,
): AggregateValue<K> => {
	const useEarliestOrLatest = getUseEarliestOrLatest(fieldKey, dateConfiguration, preferEarliest);
	const dateReducer = useEarliestOrLatest ? min : max;

	return {
		fieldKey,
		value: issues.reduce<number | undefined>(
			(acc, curr) =>
				dateReducer(acc, getBaselineDate(curr, fieldKey, dateConfiguration) ?? extractor(curr)),
			undefined,
		),
		type: useEarliestOrLatest ? MIN : MAX,
	};
};

const aggregateSum = <K extends NumberFieldKey>(
	issues: Issue[],
	fieldKey: K,
	extractor: (issue: Issue) => Issue[K],
): AggregateValue<K> => {
	return {
		fieldKey,
		value: issues.reduce<number | undefined>((acc, curr) => sum(acc, extractor(curr)), undefined),
		type: SUM,
	};
};

const aggregateMap = <K extends StringFieldKey>(
	issues: Issue[],
	fieldKey: K,
	extractor: (issue: Issue) => Issue[K],
): AggregateValue<K> => {
	return {
		fieldKey,
		value: issues.reduce<NonNullable<Issue[K]>[]>((acc, curr) => map(acc, extractor(curr)), []),
		type: MAP,
	};
};

export const aggregateStartDate = (
	issues: Issue[],
	dateConfiguration: DateConfiguration,
	startDateCustomField: CustomField | undefined,
): AggregateValue<'startDate'> => {
	if (!startDateCustomField)
		return {
			fieldKey: 'startDate',
			value: undefined,
			type: MIN,
		};

	const startDateFieldKey = startDateCustomField.id.toString();
	const useEarliestOrLatest = getUseEarliestOrLatest(startDateFieldKey, dateConfiguration, true);
	const dateReducer = useEarliestOrLatest ? min : max;

	return {
		fieldKey: 'startDate',
		value: issues.reduce<number | undefined>(
			(acc, curr) =>
				dateReducer(
					acc,
					getBaselineDate(curr, startDateFieldKey, dateConfiguration) ??
						curr.customFields?.[startDateFieldKey],
				),
			undefined,
		),
		type: useEarliestOrLatest ? MIN : MAX,
	};
};

export const aggregateDueDate = (issues: Issue[], dateConfiguration: DateConfiguration) =>
	aggregateDate(issues, 'dueDate', (issue) => issue.dueDate, dateConfiguration, false);

export const aggregateTargetStart = (issues: Issue[], dateConfiguration: DateConfiguration) =>
	aggregateDate(issues, 'targetStart', (issue) => issue.targetStart, dateConfiguration, true);

export const aggregateTargetEnd = (issues: Issue[], dateConfiguration: DateConfiguration) =>
	aggregateDate(issues, 'targetEnd', (issue) => issue.targetEnd, dateConfiguration, false);

export const aggregateStoryPoints = (issues: Issue[]): AggregateValue<'storyPoints'> =>
	aggregateSum(issues, 'storyPoints', (issue) => issue.storyPoints);

export const aggregateTimeEstimate = (issues: Issue[]): AggregateValue<'timeEstimate'> =>
	aggregateSum(issues, 'timeEstimate', (issue) => issue.timeEstimate);

export const aggregateStatus = (issues: Issue[]): AggregateValue<'status'> =>
	aggregateMap(issues, 'status', (issue) => issue.status);

export const aggregateAssignee = (issues: Issue[]): AggregateValue<'assignee'> =>
	aggregateMap(issues, 'assignee', (issue) => issue.assignee);

export const aggregateTeam = (issues: Issue[]): AggregateValue<'team'> =>
	aggregateMap(issues, 'team', (issue) => issue.team);

export const aggregatePriority = (issues: Issue[]): AggregateValue<'priority'> =>
	aggregateMap(issues, 'priority', (issue) => issue.priority);

export const aggregateProgressByEstimation = (
	issues: Issue[],
): AggregateValue<'progressByEstimation'> => {
	return {
		fieldKey: 'progressByEstimation',
		value: issues,
		type: MAP,
	};
};
