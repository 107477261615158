import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	earliestStartDateTooltip: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.date-cell.earliest-start-date-tooltip',
		defaultMessage: 'Earliest start date: {date}',
		description:
			'Label shown in the tooltip when hover the Start Date field in the group header row',
	},
	latestStartDateTooltip: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.date-cell.latest-start-date-tooltip',
		defaultMessage: 'Latest start date: {date}',
		description:
			'Label shown in the tooltip when hover the Start Date field in the group header row',
	},
	earliestTargetStartTooltip: {
		id: 'should be portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.date-cell.earliest-target-start-tooltip',
		defaultMessage: 'Earliest target start: {date}',
		description:
			'Label shown in the tooltip when hover the Target Start field in the group header row',
	},
	latestTargetStartTooltip: {
		id: 'should be portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.date-cell.latest-target-start-tooltip',
		defaultMessage: 'Latest target start: {date}',
		description:
			'Label shown in the tooltip when hover the Target Start field in the group header row',
	},
	earliestDueDateTooltip: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.date-cell.earliest-due-date-tooltip',
		defaultMessage: 'Earliest due date: {date}',
		description: 'Label shown in the tooltip when hover the Due Date field in the group header row',
	},
	latestDueDateTooltip: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.date-cell.latest-due-date-tooltip',
		defaultMessage: 'Latest due date: {date}',
		description: 'Label shown in the tooltip when hover the Due Date field in the group header row',
	},
	earliestTargetEndTooltip: {
		id: 'should be portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.date-cell.earliest-target-end-tooltip',
		defaultMessage: 'Earliest target end: {date}',
		description:
			'Label shown in the tooltip when hover the Target End field in the group header row',
	},
	latestTargetEndTooltip: {
		id: 'should be portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.date-cell.latest-target-end-tooltip',
		defaultMessage: 'Latest target end: {date}',
		description:
			'Label shown in the tooltip when hover the Target End field in the group header row',
	},
});
