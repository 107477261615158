import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	emptyPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.goal-filter.empty-placeholder',
		defaultMessage: 'All',
		description: 'This is a placeholder for no filter set',
	},
	searchGoalsPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.goal-filter.search-goals-placeholder',
		defaultMessage: 'Show issues from goals',
		description: 'This is a placeholder for search',
	},
	searchGoalsPlaceholderIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.goal-filter.search-goals-placeholder-issue-term-refresh',
		defaultMessage: 'Show work items from goals',
		description: 'This is a placeholder for search',
	},
});
