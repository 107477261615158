import React from 'react';
import { Box } from '@atlaskit/primitives';
import StatusBreakdown from '@atlassian/jira-portfolio-3-portfolio/src/common/view/status-breakdown/index.tsx';
import { BREAKDOWN_SPRINT_CAPACITY_BY } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import type { Props } from './types.tsx';

export const ProgressEstimateCell = (props: Props) => {
	const { breakdown, planningUnit, fieldKey, groupName } = props;

	return (
		<Box paddingInlineStart="space.200" paddingInlineEnd="space.200">
			<StatusBreakdown
				planningUnit={planningUnit}
				breakdownBy={BREAKDOWN_SPRINT_CAPACITY_BY.ESTIMATE}
				testId={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.progress-estimate-cell.${groupName}.${fieldKey}`}
				{...breakdown}
			/>
		</Box>
	);
};

export default ProgressEstimateCell;
