/** @jsx jsx */
import React, { type ReactNode } from 'react';
import { css, jsx } from '@compiled/react';
import { Box, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl, type MessageDescriptorV2 } from '@atlassian/jira-intl';
import Percentage from './percentage.tsx';
import messages from './messages.tsx';

type Props = {
	content: { title: ReactNode; count: number }[];
	header?: MessageDescriptorV2;
	label: MessageDescriptorV2;
	total: number;
	testId: string;
};

const FlyoutContent = (props: Props) => {
	const { content, label, total, testId, header } = props;
	const { formatMessage } = useIntl();

	const getLabel = (count: number) => {
		return formatMessage(label, { count });
	};

	return (
		<Box xcss={flyoutStyle} paddingBlock="space.200" paddingInline="space.300" testId={testId}>
			<table>
				{header && (
					<thead css={noBorderStyle}>
						<tr>
							<th colSpan={2} css={headerStyle}>
								<Text size="small" color="color.text.subtlest" weight="semibold">
									{formatMessage(header)}
								</Text>
							</th>
						</tr>
					</thead>
				)}
				<tbody css={noBorderStyle}>
					{content.map(({ title, count }, index) => (
						<tr key={index}>
							<td>{title}</td>
							<td>
								<Percentage count={count} total={total} label={getLabel(count)} />
							</td>
						</tr>
					))}
					{total && (
						<tr>
							<td>{formatMessage(messages.total)}</td>
							<td>
								<Box xcss={countLabel} as="span">
									{getLabel(total)}
								</Box>
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</Box>
	);
};

export default FlyoutContent;

const headerStyle = css({
	textTransform: 'uppercase',
	paddingBottom: token('space.075', '6px'),
});

const noBorderStyle = css({
	borderBottom: 'none',
});

const flyoutStyle = xcss({
	color: 'color.text.subtlest',
	width: '100%',
	maxWidth: '800px',
	maxHeight: '400px',
	boxSizing: 'border-box',
});

const countLabel = xcss({
	fontWeight: token('font.weight.semibold'),
	color: 'color.text',
});
