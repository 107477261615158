import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	storyPointsWarningHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.story-points-warning-header',
		defaultMessage: "Unable to commit the item's story points.",
		description: 'Message to show in the header when story points could not be committed',
	},
	storyPointsWarningContent: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.story-points-warning-content',
		defaultMessage: 'Please check that story points are enabled for this issue type.',
		description: 'Message to show in the content when story points could not be committed',
	},
	assigneeWarningHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.assignee-warning-header',
		defaultMessage: "Unable to commit the item's assignee.",
		description: 'Message to show in the header when assignee could not be committed',
	},
	assigneeWarningContent: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.assignee-warning-content',
		defaultMessage: 'Please check that you have permission to assign issues in this project.',
		description: 'Message to show in the content when assignee could not be committed',
	},
	lexoRankWarningHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.lexo-rank-warning-header',
		defaultMessage: "Unable to commit the item's LexoRank.",
		description: 'Message to show in the header when lexo rank could not be committed',
	},
	lexoRankWarningContent: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.lexo-rank-warning-content',
		defaultMessage: 'Please check that you have permission to rank issues in this project.',
		description: 'Message to show in the content when lexo rank could not be committed',
	},
	uncommittedParentHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.uncommitted-parent-header',
		defaultMessage: 'Cannot save child issue',
		description:
			'Message to show in the header when an error occurs due to parent not committed to Jira (still a scenario)',
	},
	uncommittedParentContent: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.uncommitted-parent-content',
		defaultMessage: 'Save the parent issue first, then try again.',
		description:
			'Message to show in the content when an error occurs due to parent not committed to Jira (still a scenario)',
	},
	subTaskParentNotDefinedHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.sub-task-parent-not-defined-header',
		defaultMessage: 'Uncommitted parent',
		description: 'Message to show in the header when an error occurs due to uncommitted parent',
	},
	subTaskParentNotDefinedContent: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.sub-task-parent-not-defined-content',
		defaultMessage:
			'Issue cannot be created as a sub-task of an uncommitted parent. Please commit the parent issue and retry.',
		description: 'Message to show in the content when an error occurs due to uncommitted parent',
	},
	subTaskInWrongProjectHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.sub-task-in-wrong-project-header',
		defaultMessage: 'Sub-task in wrong project',
		description:
			'Message to show in the header when an error occurs due to a subtask being in the wrong project',
	},
	subTaskInWrongProjectContent: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.sub-task-in-wrong-project-content',
		defaultMessage: 'An issue and its sub-task must both belong to the same project.',
		description:
			'Message to show in the content when an error occurs due to a subtask being in the wrong project',
	},
	defaultWarningHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.default-warning-header',
		defaultMessage: 'Could not be committed for unknown reasons.',
		description: 'Message to show in the header for unknown commit failures',
	},
	defaultWarningContent: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.default-warning-content',
		defaultMessage:
			'Item could not be committed for unknown reasons. Please contact your administrator.',
		description: 'Message to show in the content for unknown commit failures',
	},
	defaultPartialCommitWarningHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.default-partial-commit-warning-header',
		defaultMessage: "Unable to commit the item's {key}",
		description: 'Message to show in the header for partial commit failures',
	},
	scheduleIssuesPermission: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.schedule-issues-permission',
		defaultMessage: 'Schedule Issues',
		description: 'JIRA Permissions setting - Schedule Issues',
	},
	permissionMissingHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.permission-missing-header',
		defaultMessage: 'Missing permissions',
		description: 'Message to show in the header for partial commit failures',
	},
	permissionMissingContent: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.permission-missing-content',
		defaultMessage:
			'Your changes could not be saved because you don’t have the {permissionName} permission. An admin needs to grant you this permission. {learnMoreAboutPermissions}',
		description: 'Message to show in the content for partial commit failures',
	},
	learnMoreAboutPermissions: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.learn-more-about-permissions',
		defaultMessage: 'Learn more about these permissions.',
		description:
			'This is message for a hyperlink to show in the content for learn more about permissions',
	},
	teamWarningHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.team-warning-header',
		defaultMessage: "Unable to commit the item's team",
		description: 'Message to show in the header for partial commit failures',
	},
	teamWarningContent: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.team-warning-content',
		defaultMessage: 'Please ensure that the team has been committed.',
		description: 'Message to show in the content for partial commit failures',
	},
	originalStoryPointsHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.original-story-points-header',
		defaultMessage: "Unable to commit the item's original story points",
		description: 'Message to show in the header for partial commit failures',
	},
	originalTimeEstimateHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.original-time-estimate-header',
		defaultMessage: "Unable to commit the item's original time estimate",
		description: 'Message to show in the header for partial commit failures',
	},
	timeEstimateHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.time-estimate-header',
		defaultMessage: "Unable to commit the item's time estimate",
		description: 'Message to show in the header for partial commit failures',
	},
	releaseWarningHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.release-warning-header',
		defaultMessage: "Unable to commit the item's release",
		description: 'Message to show in the header for partial commit failures',
	},
	releaseWarningContent: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.release-warning-content',
		defaultMessage: 'Please ensure that the release has been committed.',
		description: 'Message to show in the content for partial commit failures',
	},
	notFoundWarningHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.not-found-warning-header',
		defaultMessage: 'Issue could not be found',
		description:
			'Message to show in the header when an issue cannot be committed because it cannot be found.',
	},
	notFoundWarningContent: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.not-found-warning-content',
		defaultMessage: 'Please refresh to update your plan',
		description: 'Message to show in the content when issue to be committed is not found',
	},
	requiredFieldsMissingHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.required-fields-missing-header',
		defaultMessage: 'The issue cannot be updated as required fields are missing',
		description: 'Message to show in the header when error occurs due to required fields missing',
	},
	requiredFieldsMissingContent: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.required-fields-missing-content',
		defaultMessage: 'Please define a value or mark the field as optional to resolve the problem',
		description: 'Message to show in the content when error occurs due to required fields missing.',
	},
	requiredTransitionScreenMissingHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.required-transition-screen-missing-header',
		defaultMessage: 'The issue cannot be updated as required transition screen are missing',
		description:
			'Message to show in the header when error occurs due to user cancel the transition screen',
	},
	requiredTransitionScreenMissingContent: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.required-transition-screen-missing-content',
		defaultMessage:
			'Please fill the transition screen fields or remove the transition screen when update the issue status to resolve the problem',
		description:
			'Message to show in the content when error occurs due to user cancel the transition screen.',
	},
	unknownErrorHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.unknown-error-header',
		defaultMessage: 'Issue could not be committed for unknown reasons',
		description: 'Message to show in the header when error occurs due to unknown reasons',
	},
	unknownErrorContent: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.unknown-error-content',
		defaultMessage: 'Please contact your administrator',
		description: 'Message to show in the content when error occurs due to unknown reasons.',
	},
	apiExceptionContent: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.api-exception-content',
		defaultMessage: 'Please contact your administrator. {messages}',
		description:
			'Message to show when an unknown error occurred, but we can show API exception messages',
	},
	customFieldNotSupportedHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.custom-field-not-supported-header',
		defaultMessage: 'Cannot commit {key} for this issue',
		description: 'Message to show when a custom field is not supported',
	},
	customFieldNotSupportedContent: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.custom-field-not-supported-content',
		defaultMessage:
			'The custom field {key} is not supported. Discard this change, or ask your Jira admin to make sure the issue type and project of the issue are supported.',
		description: 'Message to show when a custom field is not supported',
	},
	storyPointsWarningContentIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.story-points-warning-content-issue-term-refresh',
		defaultMessage: 'Please check that story points are enabled for this work type.',
		description: 'Message to show in the content when story points could not be committed',
	},
	assigneeWarningContentIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.assignee-warning-content-issue-term-refresh',
		defaultMessage: 'Check you have the permission to assign work in this project',
		description: 'Message to show in the content when assignee could not be committed',
	},
	lexoRankWarningContentIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.lexo-rank-warning-content-issue-term-refresh',
		defaultMessage: 'Check you have the permission to rank work items in this project',
		description: 'Message to show in the content when lexo rank could not be committed',
	},
	uncommittedParentHeaderIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.uncommitted-parent-header-issue-term-refresh',
		defaultMessage: 'Cannot save child work item',
		description:
			'Message to show in the header when an error occurs due to parent not committed to Jira (still a scenario)',
	},
	uncommittedParentContentIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.uncommitted-parent-content-issue-term-refresh',
		defaultMessage: 'Save the parent work item first, then try again.',
		description:
			'Message to show in the content when an error occurs due to parent not committed to Jira (still a scenario)',
	},
	subTaskParentNotDefinedContentIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.sub-task-parent-not-defined-content-issue-term-refresh',
		defaultMessage:
			'Work item cannot be created as a subtask of an uncommitted parent. Please commit the parent work item and retry.',
		description: 'Message to show in the content when an error occurs due to uncommitted parent',
	},
	subTaskInWrongProjectContentIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.sub-task-in-wrong-project-content-issue-term-refresh',
		defaultMessage: 'A work item and its subtask need to belong to the same project.',
		description:
			'Message to show in the content when an error occurs due to a subtask being in the wrong project',
	},
	scheduleIssuesPermissionIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.schedule-issues-permission-issue-term-refresh',
		defaultMessage: 'Schedule work items',
		description: 'JIRA Permissions setting - Schedule Issues',
	},
	notFoundWarningHeaderIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.not-found-warning-header-issue-term-refresh',
		defaultMessage: 'Work item could not be found',
		description:
			'Message to show in the header when an issue cannot be committed because it cannot be found.',
	},
	requiredFieldsMissingHeaderIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.required-fields-missing-header-issue-term-refresh',
		defaultMessage: "The work item can't be updated as required fields are missing",
		description: 'Message to show in the header when error occurs due to required fields missing',
	},
	requiredTransitionScreenMissingHeaderIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.required-transition-screen-missing-header-issue-term-refresh',
		defaultMessage: "The work item can't be updated as required transition screen is missing",
		description:
			'Message to show in the header when error occurs due to user cancel the transition screen',
	},
	requiredTransitionScreenMissingContentIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.required-transition-screen-missing-content-issue-term-refresh',
		defaultMessage:
			'Fill in the transition screen fields or remove the transition screen when update the status of the work item.',
		description:
			'Message to show in the content when error occurs due to user cancel the transition screen.',
	},
	unknownErrorHeaderIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.unknown-error-header-issue-term-refresh',
		defaultMessage: 'Work item could not be committed for unknown reasons',
		description: 'Message to show in the header when error occurs due to unknown reasons',
	},
	customFieldNotSupportedHeaderIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.custom-field-not-supported-header-issue-term-refresh',
		defaultMessage: "Can't commit {key} for this work item",
		description: 'Message to show when a custom field is not supported',
	},
	customFieldNotSupportedContentIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.commit.custom-field-not-supported-content-issue-term-refresh',
		defaultMessage:
			'The custom field {key} is not supported. Discard this change, or ask your admin to make sure the work type and project of the work item are supported.',
		description: 'Message to show when a custom field is not supported',
	},
});
