.formItemWrapper {
	margin-top: calc(var(--jpo-common-base-spacing) * 2);

	&:first-child {
		margin-top: 0;
	}
}

.fieldLabel {
	color: var(--ds-text-subtlest, var(--adg3-color-N200));
	font-size: 12px;
	font-weight: var(--ds-font-weight-semibold, 600);
	line-height: 16px;
	margin: 20px 0px 4px 0px;
}

.fieldHint {
	color: var(--ds-text-subtlest, var(--adg3-color-N200));
	font-size: 12px;
	margin-top: calc(var(--jpo-common-base-spacing) / 2);
}

.checkboxHint {
	margin-left: calc(var(--jpo-common-base-spacing) * 4);
}
