import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import type { ValueDecoration } from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import type {
	FieldKey,
	Field,
	FieldMap,
} from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { transformResponse } from '@atlassian/jira-polaris-remote-field/src/controllers/crud/jira/transform/index.tsx';
import type { JiraFieldDict } from '@atlassian/jira-polaris-remote-field/src/services/jira/types.tsx';
import type { JiraField } from '@atlassian/jira-polaris-remote-field/src/services/types.tsx';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	createStore,
	createActionsHook,
	createHook,
	createSelector,
} from '@atlassian/react-sweet-state';

type State = {
	fields: FieldMap;
	fieldValueDecorations: Record<FieldKey, ValueDecoration[]>;
	loadedProjects: string[];
	projectFieldKeys: Record<string, FieldKey[]>;
	meta: {
		isLoading: boolean;
		error: Error | undefined;
		loaded: boolean;
	};
};

const initialState: State = {
	fields: {},
	fieldValueDecorations: {},
	loadedProjects: [],
	projectFieldKeys: {},
	meta: {
		isLoading: false,
		error: undefined,
		loaded: false,
	},
};

const getIssueTypesPerFieldKeys = (
	fields: JiraField[],
	issueTypeId: IssueTypeId,
): Record<FieldKey, IssueTypeId[]> => {
	const issueTypesPerFieldKeys: Record<FieldKey, IssueTypeId[]> = {};

	fields.forEach((field) => {
		issueTypesPerFieldKeys[field.key] = [issueTypeId];
	});

	return issueTypesPerFieldKeys;
};

const Store = createStore({
	initialState,
	actions: {
		loadFieldsForProject:
			(projectId: string) =>
			async ({ getState, setState }) => {
				if (getState().loadedProjects.includes(projectId)) {
					return;
				}

				setState({
					meta: {
						isLoading: true,
						error: undefined,
						loaded: false,
					},
				});

				try {
					const jiraFields: JiraFieldDict = await fetchJson(
						`/rest/polaris/v2/projects/${projectId}/fields`,
					);
					const [issueTypeId, ideaIssueTypeFields] = Object.entries(jiraFields)[0];
					const { fields, fieldValueDecorations } = transformResponse(
						ideaIssueTypeFields,
						getIssueTypesPerFieldKeys(ideaIssueTypeFields, issueTypeId),
					);

					const fieldsDict = { ...getState().fields };
					const fieldValueDecorationsDict = { ...getState().fieldValueDecorations };

					Object.keys(fieldValueDecorations).forEach((fieldKey: FieldKey) => {
						const fieldDecorations = fieldValueDecorations[fieldKey];
						if (fieldDecorations.length > 0) {
							fieldValueDecorationsDict[fieldKey] = fieldDecorations;
						}
					});

					Object.keys(fields).forEach((fieldKey: FieldKey) => {
						const field: Field = fields[fieldKey];
						fieldsDict[fieldKey] = field;
					});

					setState({
						meta: {
							isLoading: false,
							error: undefined,
							loaded: true,
						},
						fields: fieldsDict,
						loadedProjects: [...getState().loadedProjects, projectId],
						fieldValueDecorations: fieldValueDecorationsDict,
						projectFieldKeys: {
							...getState().projectFieldKeys,
							[projectId]: Object.keys(fields),
						},
					});
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
				} catch (error: any) {
					if (!isClientFetchError(error)) {
						experience.ideaViewBentoSidebar.pageSegmentLoad.failure(error);
					}

					setState({
						meta: {
							isLoading: false,
							error,
							loaded: false,
						},
					});
				}
			},
	},
	name: 'jpd-implant-kit-fields',
});

export const useFieldsActions = createActionsHook(Store);

const getFieldsAreLoading = (state: State) => state.meta.isLoading;
const getFieldsLoadError = (state: State) => state.meta.error;
const getFieldsLoaded = (state: State) => state.meta.loaded;

const getField = (state: State, fieldKey: FieldKey) => state.fields[fieldKey];
const getFieldValueDecorations = (state: State) => state.fieldValueDecorations;
const getFieldValueDecoration = createSelector(
	getFieldValueDecorations,
	(_, fieldKey: FieldKey) => fieldKey,
	(fieldValueDecorations, fieldKey) => fieldValueDecorations[fieldKey],
);

const getFieldName = createSelector(getField, (field) => field?.label);
const getFieldType = createSelector(getField, (field) => field?.type);
const getFieldConfiguration = createSelector(getField, (field) => field?.configuration);
const getFieldEmoji = createSelector(getField, (field) => field?.emoji);

const getFieldOptions = createSelector(getField, (field) => field?.options);

const getFieldKeysForProject = (state: State, projectId: string | undefined) =>
	projectId ? state.projectFieldKeys[projectId] : undefined;

const getFirstWeightedOption = createSelector(getFieldOptions, (options) =>
	options?.find((option) => option.weight !== 1),
);

const getIsFieldWeighted = createSelector(
	getFirstWeightedOption,
	(weightedOption) => weightedOption !== undefined,
);

export const useFieldsAreLoading = createHook(Store, {
	selector: getFieldsAreLoading,
});

export const useFieldsLoadError = createHook(Store, {
	selector: getFieldsLoadError,
});

export const useFieldsLoaded = createHook(Store, {
	selector: getFieldsLoaded,
});

export const useFieldName = createHook(Store, {
	selector: getFieldName,
});

export const useFieldType = createHook(Store, {
	selector: getFieldType,
});

export const useFieldConfiguration = createHook(Store, {
	selector: getFieldConfiguration,
});

export const useFieldValueDecorations = createHook(Store, {
	selector: getFieldValueDecoration,
});

export const useFieldEmoji = createHook(Store, {
	selector: getFieldEmoji,
});

export const useField = createHook(Store, {
	selector: getField,
});

export const useFieldKeysForProject = createHook(Store, {
	selector: getFieldKeysForProject,
});

export const useFieldOptions = createHook(Store, {
	selector: getFieldOptions,
});

export const useFieldIsWeighted = createHook(Store, {
	selector: getIsFieldWeighted,
});
