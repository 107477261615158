import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	selectSingleAndDescendants: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.select-issue.select-single-and-descendants',
		defaultMessage: 'Select this and all descendant issues',
		description: 'Label for drop menu to select the issue including its descendants',
	},
	selectDescendants: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.select-issue.select-descendants',
		defaultMessage: 'Select only descendant issues',
		description: 'Label for drop menu to select descendant issues',
	},
	selectSingleAndDescendantsIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.select-issue.select-single-and-descendants-issue-term-refresh',
		defaultMessage: 'Select this and all descendant work items',
		description: 'Label for drop menu to select the issue including its descendants',
	},
	selectDescendantsIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.select-issue.select-descendants-issue-term-refresh',
		defaultMessage: 'Select only descendant work items',
		description: 'Label for drop menu to select descendant issues',
	},
});
