.spaPreviewResultsOverlay {
	display: flex;
	flex-direction: column;
	background-color: var(--ds-background-accent-gray-bolder, var(--adg3-color-N800));
	padding: calc(var(--jpo-common-base-spacing) * 3);
	margin-bottom: calc(var(--jpo-common-base-spacing) * 3);
	gap: calc(var(--jpo-common-base-spacing) * 1.5);
	border-radius: calc(var(--jpo-common-base-spacing) * 0.5);
}

.spaPreviewHeaderSection {
	display: flex;
}

.previewTitle {
	flex: 1 1 0;
	font-size: 20px;
	font-weight: var(--ds-font-weight-medium, 500);
	line-height: 24px;
	color: var(--ds-text-inverse, var(--adg3-color-N0));
}

.actionButtons {
	flex: 1 1 0;
	text-align: right;
}

.cancelButton {
	color: var(--ds-text-inverse, var(--adg3-color-DN700));
}

.spaPreviewDescription {
	color: var(--ds-text-inverse, var(--adg3-color-N0));
	font-size: 16px;
	line-height: 20px;
}
