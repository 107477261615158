/** @jsx jsx */
import React, { type ReactNode } from 'react';
import { css, jsx } from '@compiled/react';
import * as R from 'ramda';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import {
	ONE_DAY,
	dateDiffFromUTC,
} from '@atlassian/jira-portfolio-3-common/src/date-manipulation/index.tsx';
import {
	useContainer,
	useHorizontalPercentageOffset,
	useZoomLevel,
} from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/controllers/index.tsx';
import { Z_INDEX_LAYER } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/z-index/types.tsx';
import { ZIndex } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/z-index/view.tsx';
// Remove styles on cleanup of jsw_roadmaps_timeline-fix-a11y-rain
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

export default function NowLine({
	isExportMode = false,
	noMarker = false,
	showInCustomRangeDialog = false,
	staticAxisPosition = {},
	topOffset = 0,
}: Props) {
	const [{ getPercentageOffset }] = useHorizontalPercentageOffset();
	const [container] = useContainer();
	const [zoomLevel] = useZoomLevel();

	if (isExportMode) {
		// Don't render now line in export mode as it only renders on the group headings and not on the issue rows
		return null;
	}

	// Consider remove this function when cleaning up plan-timeline-non-transposed
	const withZIndexOverride = (children: (zIndex: number | string | undefined) => ReactNode) => {
		if (fg('plan-timeline-non-transposed')) {
			return children(undefined);
		}

		return (
			<ZIndex layer={Z_INDEX_LAYER.ISSUE_BARS}>{(zIndex) => children(zIndex ?? 'initial')}</ZIndex>
		);
	};

	// this is the now line rendered when the timeline mode is "weeks", "months", "quarters", or "years"
	if (zoomLevel) {
		const offsetInMs = dateDiffFromUTC() * ONE_DAY;
		const todayOffset = getPercentageOffset(offsetInMs);
		const width = getPercentageOffset(offsetInMs + ONE_DAY) - todayOffset;

		return withZIndexOverride((zIndex) => (
			<div
				data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.now"
				// note: the "marker" style renders a triangle on top of the NowLine
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={
					!fg('jsw_roadmaps_timeline-fix-a11y-rain')
						? `${styles.nowLine} ${!noMarker ? styles.marker : ''}`
						: undefined
				}
				css={[
					fg('jsw_roadmaps_timeline-fix-a11y-rain') && nowLineStyles,
					!noMarker && fg('jsw_roadmaps_timeline-fix-a11y-rain') && markerStyles,
				]}
				style={{
					left: `calc(${todayOffset}% + ${(container.width * (width / 100)) / 2}px)`,
					top: topOffset,
					zIndex,
				}}
			/>
		));
	}

	// this is the now line rendered when the timeline mode is a custom fixed / relative date range
	if (!R.isEmpty(staticAxisPosition)) {
		const { offset = 0, widthPercentage = 0 } = staticAxisPosition;
		return withZIndexOverride((zIndex) => (
			<div
				data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.now"
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={
					!fg('jsw_roadmaps_timeline-fix-a11y-rain') ? styles.nowLineStaticAxisWrapper : undefined
				}
				css={[fg('jsw_roadmaps_timeline-fix-a11y-rain') && nowLineStaticAxisWrapper]}
				style={{
					left: `calc(${offset}% + ${token('space.025', '2px')})`,
					width: `${widthPercentage}%`,
				}}
			>
				<div
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={
						!fg('jsw_roadmaps_timeline-fix-a11y-rain')
							? styles.nowLineStaticAxisSubWrapper
							: undefined
					}
					css={[fg('jsw_roadmaps_timeline-fix-a11y-rain') && nowLineStaticAxisSubWrapper]}
				>
					<div
						// note: the "marker" style renders a triangle on top of the NowLine
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={
							!fg('jsw_roadmaps_timeline-fix-a11y-rain')
								? `${styles.nowLine} ${!noMarker ? styles.marker : ''}`
								: undefined
						}
						css={[
							fg('jsw_roadmaps_timeline-fix-a11y-rain') && nowLineStyles,
							!noMarker && fg('jsw_roadmaps_timeline-fix-a11y-rain') && markerStyles,
						]}
						style={{ top: topOffset, zIndex }}
					/>
				</div>
			</div>
		));
	}

	// this is the now line rendered in the custom relative date range modal dialog
	if (showInCustomRangeDialog) {
		return (
			<div
				data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.now"
				// note: the "marker" style renders a triangle on top of the NowLine
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={
					!fg('jsw_roadmaps_timeline-fix-a11y-rain')
						? `${styles.nowLine} ${!noMarker ? styles.marker : ''}`
						: undefined
				}
				css={[
					fg('jsw_roadmaps_timeline-fix-a11y-rain') && nowLineStyles,
					!noMarker && fg('jsw_roadmaps_timeline-fix-a11y-rain') && markerStyles,
				]}
				style={{
					top: topOffset,
				}}
			/>
		);
	}

	return null;
}

const nowLineStaticAxisWrapper = css({
	position: 'absolute',
	top: 0,
	bottom: 0,
});

const nowLineStaticAxisSubWrapper = css({
	display: 'flex',
	justifyContent: 'center',
});

const nowLineStyles = css({
	position: 'absolute',
	top: 0,
	bottom: 0,
	zIndex: 4, // remove this when cleaning up plan-timeline-non-transposed
	pointerEvents: 'none',
	borderLeft: `2px solid ${token('color.chart.information')}`,
	width: '2px',
});

const markerStyles = css({
	'&::before': {
		content: "''",
		position: 'sticky',
		height: 0,
		width: 0,
		top: 0,
		// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
		marginLeft: '-5px',
		borderLeft: '4px solid transparent',
		borderRight: '4px solid transparent',
		borderTop: `8px solid ${token('color.chart.information')}`,
	},
});
