import chunk from 'lodash/chunk';
import take from 'lodash/take';
import uniq from 'lodash/uniq';
import { fetchQuery, graphql } from 'react-relay';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import getRelayEnvironment from '@atlassian/jira-relay-environment/src/index.tsx';
import { startCaptureGraphQlErrors } from '@atlassian/jira-relay-errors/src/index.tsx';
import servicesAssociatedIssuesCompiledQuery, {
	type servicesAssociatedIssuesDataQuery,
	type servicesAssociatedIssuesDataQuery$data,
} from '@atlassian/jira-relay/src/__generated__/servicesAssociatedIssuesDataQuery.graphql';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { AssociatedIssue } from '../common/types.tsx';
import { createJqlQuery, getErrorsAndStopCapture, PROJECT_TYPE } from './utils.tsx';

// this is the maximum number of issues that can be fetched in one request because AGG limits
const MAX_ISSUES = 50;

const handleResponse = (data?: servicesAssociatedIssuesDataQuery$data) => {
	const associatedIssues: Record<IssueId, AssociatedIssue> = {};
	data?.jira?.issueSearchStable?.edges?.forEach((edge) => {
		const id = edge?.node?.issueId;
		const key = edge?.node?.key;
		const summary = edge?.node?.summaryField?.text;
		const iconUrl = edge?.node?.issueTypeField?.issueType?.avatar?.medium;
		const projectId = edge?.node?.projectField?.project?.projectId;

		if (fg('polaris-pinned-fields-for-ideas-in-plans')) {
			if (!id || !key || !summary || !iconUrl || !projectId) {
				log.safeErrorWithoutCustomerData(
					'portfolio-3.associated-issues.invalid-data',
					'Invalid data received.',
				);
				return;
			}

			associatedIssues[id] = { id, key, summary, iconUrl, projectId };
		} else {
			if (!id || !key || !summary || !iconUrl) {
				log.safeErrorWithoutCustomerData(
					'portfolio-3.associated-issues.invalid-data',
					'Invalid data received.',
				);
				return;
			}

			associatedIssues[id] = { id, key, summary, iconUrl };
		}
	});
	return associatedIssues;
};

const fetchAssociatedIssuesChunk = (
	cloudId: string,
	jql: string,
): Promise<Record<IssueId, AssociatedIssue>> =>
	new Promise((resolve, reject) => {
		const errorCollector = startCaptureGraphQlErrors();

		fetchQuery<servicesAssociatedIssuesDataQuery>(
			getRelayEnvironment(),
			graphql`
				query servicesAssociatedIssuesDataQuery($cloudId: ID!, $jql: String!, $limit: Int!) {
					jira {
						issueSearchStable(cloudId: $cloudId, issueSearchInput: { jql: $jql }, first: $limit) {
							edges {
								node {
									issueId
									key
									summaryField {
										text
									}
									issueTypeField {
										issueType {
											avatar {
												medium
											}
										}
									}
									projectField {
										project {
											projectId
										}
									}
								}
							}
						}
					}
				}
			`,
			{
				cloudId,
				jql,
				limit: MAX_ISSUES,
			},
		)
			.toPromise()
			.then((data) => {
				const servicesAssociatedIssueOperationName =
					servicesAssociatedIssuesCompiledQuery.params.name;

				const { isError, isPermissionError, cause, statusCode } = getErrorsAndStopCapture(
					errorCollector,
					servicesAssociatedIssueOperationName,
				);

				if (!isError && data?.jira?.issueSearchStable) {
					const associatedIssuesData = handleResponse(data);
					resolve(associatedIssuesData);
				} else if (isPermissionError) {
					resolve({});
				} else {
					reject(new ValidationError(cause ?? 'Error fetching ideas', [], statusCode));
				}
			});
	});

export type AssociatedIssuesFetchResult = {
	associatedIssues: Record<IssueId, AssociatedIssue>;
	appliedLimit?: number | undefined;
};

export const fetchAssociatedIssuesById = (
	ids: string[],
	cloudId: string,
): Promise<AssociatedIssuesFetchResult> => {
	if (ids.length === 0) {
		return Promise.resolve({ associatedIssues: {} });
	}

	const issueFetchLimit =
		MAX_ISSUES * expVal<number>('polaris-limit-ideas-loaded-in-arj-plan-integration', 'value', 20);
	const uniqueIds = uniq(ids);

	return Promise.all(
		chunk(take(uniqueIds, issueFetchLimit), MAX_ISSUES).map((idChunk) =>
			fetchAssociatedIssuesChunk(
				cloudId,
				`id in (${idChunk.join(',')}) AND projectType = ${PROJECT_TYPE}`,
			),
		),
	).then((results: Record<IssueId, AssociatedIssue>[]) => {
		const associatedIssues: Record<IssueId, AssociatedIssue> = {};
		results.forEach((result) => {
			Object.assign(associatedIssues, result);
		});

		return {
			associatedIssues,
			appliedLimit: uniqueIds.length > issueFetchLimit ? issueFetchLimit : undefined,
		};
	});
};

export const fetchAssociatedIssuesForSelectOptions = (
	cloudId: string,
	query = '',
): Promise<Record<IssueId, AssociatedIssue>> =>
	fetchAssociatedIssuesChunk(cloudId, createJqlQuery(query));
