import type { Effect } from 'redux-saga';
import * as R from 'ramda';
import { put, call, select, fork, takeEvery, all } from 'redux-saga/effects';
import { fireErrorAnalytics } from '@atlassian/jira-portfolio-3-portfolio/src/common/error/index.tsx';
import type { Team as ApiTeam } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import fetch from '@atlassian/jira-portfolio-3-portfolio/src/common/fetch/index.tsx';
import {
	isDefined,
	filterMap,
	pickBy,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import {
	ERROR_REPORTING_TEAM,
	PACKAGE_NAME,
	ENTITY,
	SCENARIO_TYPE,
	SCHEDULE_MODE,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { getTeamsFilterPure } from '../../query/filters/team-filter/index.tsx';
import { getPlan } from '../../query/plan/index.tsx';
import { getOriginalResources } from '../../query/resource/index.tsx';
import {
	getTeamById,
	getOriginalTeams,
	findTeamAssociatedWithResource,
	findResourceFromTeam,
	getTeams,
} from '../../query/teams/index.tsx';
import { updateTeamId as updateTeamIdForOriginalPlannedCapacities } from '../../state/domain/original-planned-capacities/actions.tsx';
import {
	reset as resetOriginalResources,
	update as updateOriginalResource,
	reset as resetOriginalResource,
} from '../../state/domain/original-resources/actions.tsx';
import {
	update as updateOriginalTeam,
	reset as resetOriginalTeams,
} from '../../state/domain/original-teams/actions.tsx';
import { type AddActionPayload, add as addPerson } from '../../state/domain/persons/actions.tsx';
import { updateTeamId as updateTeamIdForPlannedCapacities } from '../../state/domain/planned-capacities/actions.tsx';
import { update as updateSequence } from '../../state/domain/sequence/actions.tsx';
import {
	update as updateTeam,
	add as addTeam,
	remove as removeTeam,
	updateResource,
	addMember,
	removeMember,
	setShared,
} from '../../state/domain/teams/actions.tsx';
import type { Team } from '../../state/domain/teams/types.tsx';
import { add as addCommitWarning } from '../../state/domain/update-jira/warnings/actions.tsx';
import { prepareTeam } from '../../state/domain/util.tsx';
import {
	updateTeamId as updateTeamIdForColourBy,
	setColourForNewTeam,
	removeTeamId as removeTeamIdFromColourBy,
} from '../../state/domain/view-settings/colour-by/actions.tsx';
import { TEAM_FILTER_ID } from '../../state/domain/view-settings/filters/types.tsx';
import type { State } from '../../state/types.tsx';
import {
	storeAddedTwpTeam,
	resetAddedTwpTeam,
} from '../../state/ui/main/tabs/teams/create-team/actions.tsx';
import {
	closeTeamsDialog,
	initiateRequest,
	resetRequest,
	type DialogMode,
	type EditedTeam,
} from '../../state/ui/main/tabs/teams/list/actions.tsx';

import { POST } from '../api.tsx';
import { fetchTeamsAssignees } from '../assignees/index.tsx';
import { getBacklog } from '../backlog/index.tsx';
import batch from '../batch/index.tsx';
import type { BulkCommitResponseEntity } from '../commit-bulk/types.tsx';
import { revertBody } from '../commit/api.tsx';
import { change as changeFilter } from '../filters/index.tsx';
import * as http from '../http/index.tsx';
import { toErrorID } from '../util.tsx';
import {
	urls,
	getTeamDescriptionPayload,
	type AtlassianTeamPayload,
	type TeamPayload,
	type EditTeamPayload,
	type DeleteTeamPayload,
	type AddTeamMemberPayload,
	type DeleteTeamMemberPayload,
	type Member,
	type AddMembersAndShareTeamPayload,
} from './api.tsx';
import { inspectForCommitWarnings, inspectForResourceCommitWarnings } from './warnings.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { DialogMode, EditedTeam };

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { Team } from '../../state/domain/teams/types.tsx';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { EDIT_MODE, CREATE_MODE } from '../../state/ui/main/tabs/teams/list/actions';

export const CREATE_TEAM = 'command.teams.CREATE_TEAM';
export const ADD_ATLASSIAN_TEAM = 'command.teams.ADD_ATLASSIAN_TEAM';
export const UPDATE_TEAM = 'command.teams.UPDATE_TEAM';
export const DELETE_TEAM = 'command.teams.DELETE_TEAM';
export const ADD_TEAM_MEMBER = 'command.teams.ADD_TEAM_MEMBER';
export const DELETE_TEAM_MEMBER = 'command.teams.DELETE_TEAM_MEMBER';
export const FETCH_ADDITIONAL_TEAMS = 'command.teams.FETCH_ADDITIONAL_TEAMS';
export const SHARE_TEAM = 'command.teams.SHARE_TEAM';
export const ADD_MEMBERS_AND_SHARE_TEAM = 'command.teams.ADD_MEMBERS_AND_SHARE_TEAM';

export type CreateTeamAction = {
	type: typeof CREATE_TEAM;
	payload: TeamPayload;
};

export type AddAtlassianTeamAction = {
	type: typeof ADD_ATLASSIAN_TEAM;
	payload: AtlassianTeamPayload;
};

export type UpdateTeamAction = {
	type: typeof UPDATE_TEAM;
	payload: EditTeamPayload;
};

export type DeleteTeamAction = {
	type: typeof DELETE_TEAM;
	payload: DeleteTeamPayload;
};

export type ShareTeamAction = {
	type: typeof SHARE_TEAM;
	teamId: string;
};

export type AddMembersAndShareTeamAction = {
	type: typeof ADD_MEMBERS_AND_SHARE_TEAM;
	payload: AddMembersAndShareTeamPayload;
};

export const createTeam = (payload: TeamPayload): CreateTeamAction => ({
	type: CREATE_TEAM,
	payload,
});

export const editTeam = (payload: EditTeamPayload): UpdateTeamAction => ({
	type: UPDATE_TEAM,
	payload,
});

export const addAtlassianTeam = (payload: AtlassianTeamPayload): AddAtlassianTeamAction => ({
	type: ADD_ATLASSIAN_TEAM,
	payload,
});

export const deleteTeam = (payload: DeleteTeamPayload): DeleteTeamAction => ({
	type: DELETE_TEAM,
	payload,
});

export const shareTeam = (teamId: string): ShareTeamAction => ({
	type: SHARE_TEAM,
	teamId,
});

export const addMembersAndShareTeam = (
	payload: AddMembersAndShareTeamPayload,
): AddMembersAndShareTeamAction => ({
	type: ADD_MEMBERS_AND_SHARE_TEAM,
	payload,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* updateTeamId(oldId: string, newId?: string): Generator<Effect, any, any> {
	yield* batch(function* () {
		if (newId) {
			yield put(
				updateTeam({
					teamId: oldId,
					patch: { id: newId },
				}),
			);
			yield put(updateTeamIdForColourBy({ oldId, newId }));
			yield put(updateTeamIdForPlannedCapacities({ oldId, newId }));
			yield put(updateTeamIdForOriginalPlannedCapacities({ oldId, newId }));
		}

		const { value }: ReturnType<typeof getTeamsFilterPure> = yield select(getTeamsFilterPure);

		if (isDefined(value) && R.contains(oldId, value)) {
			const newValue = value.filter((team) => team !== oldId);
			if (newId) {
				newValue.push(newId);
			}

			yield put(changeFilter({ id: TEAM_FILTER_ID, value: newValue }));
		}
	});
}

export type AddTeamMemberAction = {
	type: typeof ADD_TEAM_MEMBER;
	payload: AddTeamMemberPayload;
};

export type DeleteTeamMemberAction = {
	type: typeof DELETE_TEAM_MEMBER;
	payload: DeleteTeamMemberPayload;
};

export const addTeamMember = (payload: AddTeamMemberPayload): AddTeamMemberAction => ({
	type: ADD_TEAM_MEMBER,
	payload,
});

export const deleteTeamMember = (payload: DeleteTeamMemberPayload): DeleteTeamMemberAction => ({
	type: DELETE_TEAM_MEMBER,
	payload,
});

export function* handleTeamBulkCommitResponse(
	entityResponse: BulkCommitResponseEntity, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, void, any> {
	const teamId = entityResponse.itemKey;
	const generatedId = entityResponse.generatedId;
	const error = entityResponse.error;

	const team = yield select(getTeamById, teamId);

	if (team.scenarioType === SCENARIO_TYPE.DELETED) {
		yield put(
			removeTeam({
				id: teamId,
				action: 'COMMIT',
			}),
		);
		yield put(removeTeamIdFromColourBy({ id: teamId }));
	} else {
		yield put(
			updateTeam({
				teamId,
				patch: {
					scenarioType: SCENARIO_TYPE.NONE,
				},
			}),
		);
		yield call(updateTeamId, teamId, generatedId);
	}

	const warnings = inspectForCommitWarnings(error);
	if (warnings.length) {
		yield put(
			addCommitWarning({
				category: ENTITY.TEAM,
				itemId: teamId,
				warnings,
			}),
		);
	}

	const originals = yield select(getOriginalTeams);
	yield put(resetOriginalTeams(R.dissoc(teamId, originals)));
}

export function* handleResourceBulkCommitResponse(
	entityResponse: BulkCommitResponseEntity, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, void, any> {
	const resourceId = entityResponse.itemKey;

	const newResourceId = entityResponse.generatedId;

	const teams = yield select(getTeams);
	const team = findTeamAssociatedWithResource(teams, resourceId);
	if (team && newResourceId) {
		yield put(
			updateResource({
				teamId: team.id,
				resourceId,
				itemKey: newResourceId,
			}),
		);
	}

	const warnings = yield call(
		inspectForResourceCommitWarnings,
		entityResponse.error,
		newResourceId ?? resourceId,
	);
	if (warnings.length) {
		yield put(
			addCommitWarning({
				category: ENTITY.RESOURCE,
				itemId: newResourceId ?? resourceId,
				warnings,
			}),
		);
	}

	const originals = yield select(getOriginalResources);
	yield put(resetOriginalResources(R.dissoc(resourceId, originals)));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* revertTeamChange(teamId: string): Generator<Effect, http.JsonResponse<any>, any> {
	const {
		domain: {
			plan: { id: planId, currentScenarioId },
			sequence,
		},
	}: State = yield select(R.identity);
	const body = revertBody({ id: planId, currentScenarioId }, sequence, teamId);

	const response = yield* http.json({ url: urls.revertTeamChanges, method: POST, body });

	if (response.ok) {
		const {
			// eslint-disable-next-line @typescript-eslint/no-shadow
			change: { sequence },
			scenarioType,
		} = response.data;
		yield put(updateSequence(sequence));

		const team = yield select(getTeamById, teamId);

		if (team.scenarioType === SCENARIO_TYPE.ADDED) {
			yield put(
				removeTeam({
					id: teamId,
					action: 'REVERT',
				}),
			);
			yield put(removeTeamIdFromColourBy({ id: teamId }));
		} else {
			const originalTeams = yield select(getOriginalTeams);
			const originalValues = originalTeams[teamId];
			yield put(
				updateTeam({
					teamId,
					patch: { ...team.originals, scenarioType, originals: {}, ...originalValues },
				}),
			);
		}
	}

	return response;
}

export function* revertResourceChange(
	resourceId: string, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, http.JsonResponse<any>, any> {
	const {
		domain: {
			plan: { id: planId, currentScenarioId },
			sequence,
		},
	}: State = yield select(R.identity);
	const body = revertBody({ id: planId, currentScenarioId }, sequence, resourceId);

	const response = yield* http.json({ url: urls.revertResourceChanges, method: POST, body });

	if (response.ok) {
		const {
			// eslint-disable-next-line @typescript-eslint/no-shadow
			change: { sequence },
		} = response.data;
		yield put(updateSequence(sequence));
		const teams = yield select(getTeams);
		const team = findTeamAssociatedWithResource(teams, resourceId);
		if (team) {
			const resource = findResourceFromTeam(team, resourceId);
			if (resource && resource.scenarioType === SCENARIO_TYPE.ADDED) {
				yield put(
					updateTeam({
						teamId: team.id,
						patch: {
							resources: team.resources.filter(
								// eslint-disable-next-line @typescript-eslint/no-shadow
								(resource) => resource.itemKey !== resourceId,
							),
						},
					}),
				);
			} else {
				yield put(
					updateResource({
						itemKey: resourceId,
						resourceId,
						teamId: team.id,
					}),
				);
			}
		}
	}

	return response;
}

export function* doAddMember({
	payload: teamMemberPayload, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: AddTeamMemberAction): Generator<Effect, any, any> {
	const { weeklyHours, personItemKey, teamItemKey } = teamMemberPayload;
	const {
		domain: {
			plan: { id: planId, currentScenarioId },
		},
	}: State = yield select(R.identity);
	const body = {
		planId,
		scenarioId: currentScenarioId,
		description: { weeklyHours: isDefined(weeklyHours) ? { value: weeklyHours } : {} },
		personItemKey,
		teamItemKey,
	};
	const response = yield call(fetch, urls.addTeamMember, {
		method: POST,
		body,
	});
	if (response.ok) {
		const {
			change: { sequence },
			person,
			resourceItemKey,
		} = yield call(response.json.bind(response));
		yield put(updateSequence(sequence));
		yield put(addPerson(person));
		yield put(fetchTeamsAssignees());
		const resource = {
			itemKey: resourceItemKey,
			scenarioType: SCENARIO_TYPE.ADDED,
			originals: person.originals,
			personItemKey: person.itemKey,
			values: {
				weeklyHours: isDefined(weeklyHours) ? weeklyHours : null,
			},
		};
		yield put(addMember({ teamId: teamItemKey, resource }));
		yield put(updateOriginalResource({ itemKey: resourceItemKey }));
	} else {
		const message = yield call(response.text.bind(response));
		const error = new Error(message);
		fireErrorAnalytics({
			error,
			meta: {
				id: toErrorID(error, 'do-add-member-fetch-failed'),
				packageName: PACKAGE_NAME,
				teamName: ERROR_REPORTING_TEAM,
			},
			sendToPrivacyUnsafeSplunk: true,
		});
	}
}

export function* doDeleteMember({
	payload: { removedResources, teamId }, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: DeleteTeamMemberAction): Generator<Effect, any, any> {
	const {
		domain: {
			plan: { id: planId, currentScenarioId },
		},
	}: State = yield select(R.identity);
	const body = {
		planId,
		scenarioId: currentScenarioId,
		itemKeys: removedResources.map((el) => el.itemKey),
	};

	const response = yield call(fetch, urls.deleteTeamMember, {
		method: POST,
		body,
	});
	if (response.ok) {
		for (const resource of removedResources) {
			if (resource.scenarioType !== SCENARIO_TYPE.ADDED) {
				yield put(updateOriginalResource({ itemKey: resource.itemKey }));
			}
			yield put(
				removeMember({
					teamId,
					resource,
				}),
			);
		}
		const originalResources = yield select(getOriginalResources);

		const resets = filterMap(
			({ scenarioType }) => scenarioType === SCENARIO_TYPE.ADDED,
			(resource) => resource.itemKey,
			removedResources,
		);
		if (resets.length) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const resetOriginals = Object.keys(originalResources).reduce<Record<string, any>>(
				(acc, key) => {
					if (!resets.includes(key)) {
						acc[key] = originalResources[key];
					}
					return acc;
				},
				{},
			);
			yield put(resetOriginalResource(resetOriginals));
		}

		const {
			change: { sequence },
		} = yield call(response.json.bind(response));
		yield put(updateSequence(sequence));
	} else {
		const message = yield call(response.text.bind(response));
		const error = new Error(message);
		fireErrorAnalytics({
			error,
			meta: {
				id: toErrorID(error, 'do-delete-member-fetch-failed'),
				packageName: PACKAGE_NAME,
				teamName: ERROR_REPORTING_TEAM,
			},
			sendToPrivacyUnsafeSplunk: true,
		});
	}
}

export function* doUpdateMembers(
	{
		members,
		team,
	}: {
		members: Member[];
		team: Team;
	}, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	const { resources } = team;

	const addedResources = members.filter(
		(el) =>
			!resources.some(
				(res) =>
					res.scenarioType !== SCENARIO_TYPE.DELETED && res.personItemKey === el.value.toString(),
			),
	);
	const removedResources = resources.filter(
		(el) =>
			el.scenarioType === SCENARIO_TYPE.DELETED ||
			!members.some((member) => member.value.toString() === el.personItemKey),
	);
	for (const teamMember of addedResources) {
		yield call(
			doAddMember,
			addTeamMember({
				personItemKey: teamMember.value.toString(),
				teamItemKey: team.id,
			}),
		);
	}
	if (removedResources.length) {
		yield call(doDeleteMember, deleteTeamMember({ removedResources, teamId: team.id }));
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doAddTeam(team: ApiTeam): Generator<Effect, any, any> {
	const newTeam: Team = prepareTeam(team);
	yield put(addTeam(newTeam));
	yield put(updateOriginalTeam({ id: newTeam.id }));
	yield put(
		updateTeam({
			teamId: newTeam.id,
			patch: { scenarioType: SCENARIO_TYPE.ADDED },
		}),
	);
	yield put(setColourForNewTeam({ id: team.itemKey }));
}

export function* doCreateTeam({
	payload: teamPayload, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: CreateTeamAction): Generator<Effect, any, any> {
	yield put(initiateRequest());
	const {
		domain: {
			plan: { id: planId, currentScenarioId: scenarioId },
		},
	}: State = yield select(R.identity);
	const body = {
		planId,
		scenarioId,
		description: getTeamDescriptionPayload(teamPayload),
	};
	const { members = [] } = teamPayload;

	try {
		const url = urls.addNewTeam;
		const response = yield call(fetch, url, {
			method: POST,
			body,
			profile: url,
		});
		if (response.ok) {
			const {
				change: { sequence },
				team,
			} = yield call(response.json.bind(response));
			yield put(updateSequence(sequence));
			yield call(doAddTeam, team);
			for (const teamMember of members) {
				yield call(
					doAddMember,
					addTeamMember({
						personItemKey: teamMember.value.toString(),
						teamItemKey: team.itemKey,
					}),
				);
			}
			yield put(closeTeamsDialog());
		} else {
			const message = yield call(response.text.bind(response));
			const error = new Error(message);
			fireErrorAnalytics({
				error,
				meta: {
					id: toErrorID(error, 'do-create-team-fetch-failed'),
					packageName: PACKAGE_NAME,
					teamName: ERROR_REPORTING_TEAM,
				},
				sendToPrivacyUnsafeSplunk: true,
			});
		}
	} finally {
		yield put(resetRequest());
	}
}

export function* doAddAtlassianTeam({
	payload: atlassianTeamPayload, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: AddAtlassianTeamAction): Generator<Effect, any, any> {
	yield put(initiateRequest());

	yield put(resetAddedTwpTeam());
	const {
		domain: {
			plan: { id: planId, currentScenarioId: scenarioId },
		},
	}: State = yield select(R.identity);
	const body = {
		planId,
		scenarioId,
		teamId: atlassianTeamPayload.id,
		members: atlassianTeamPayload.members,
	};
	const response = yield call(fetch, urls.addAtlassianTeam, {
		method: POST,
		body,
	});
	if (response.ok) {
		const {
			change: { sequence },
			team,
			people,
		} = yield call(response.json.bind(response));
		yield put(updateSequence(sequence));

		// if the Atlassian team is newly created, it may take some time for the data to become available, so
		// use the team name we supplied
		const teamToAdd = atlassianTeamPayload.title
			? R.assocPath(['values', 'title'], atlassianTeamPayload.title, team)
			: team;

		// Initialise team settings to make sure review changes are correct on the subsequent update
		teamToAdd.values.issueSource = null;
		teamToAdd.values.iterationLength = null;
		teamToAdd.values.schedulingMode = SCHEDULE_MODE.scrum;
		teamToAdd.values.velocity = null;
		teamToAdd.values.weeklyHours = null;

		yield call(doAddTeam, teamToAdd);

		for (const person of people) {
			yield put(addPerson(person));
		}

		yield put(
			storeAddedTwpTeam({
				teamTitle: teamToAdd.values.title,
				teamId: teamToAdd.itemKey,
			}),
		);
	} else {
		const message = yield call(response.text.bind(response));
		const error = new Error(message);
		fireErrorAnalytics({
			error,
			meta: {
				id: toErrorID(error, 'do-add-atlassian-team-fetch-failed'),
				packageName: PACKAGE_NAME,
				teamName: ERROR_REPORTING_TEAM,
			},
			sendToPrivacyUnsafeSplunk: true,
		});
	}
	yield put(resetRequest());
}

export function* doUpdateTeam({
	payload: { enhancedTeam, formFields, teamId }, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: UpdateTeamAction): Generator<Effect, any, any> {
	yield put(initiateRequest());
	const {
		domain: {
			plan: { id: planId, currentScenarioId: scenarioId },
		},
	}: State = yield select(R.identity);

	const { members = [], ...rest } = formFields;
	const { members: enhancedMembers = [], ...enhancedRest } = enhancedTeam;
	const patch: Partial<TeamPayload> = pickBy((el, key) => !R.equals(el, enhancedRest[key]), rest);
	const isEqualMembers =
		members.length === enhancedMembers.length &&
		members.every((member) =>
			enhancedMembers.some(
				(enhancedMember) => member.value.toString() === enhancedMember.value.toString(),
			),
		);

	if (R.isEmpty(patch) && isEqualMembers) {
		yield put(resetRequest());
		yield put(closeTeamsDialog());
		return;
	}

	const body = {
		planId,
		scenarioId,
		itemKeys: [teamId],
		description: getTeamDescriptionPayload(patch),
	};

	try {
		const url = urls.updateTeam;
		const response = yield call(fetch, url, {
			method: POST,
			body,
			profile: url,
		});

		if (response.ok) {
			const {
				change: { sequence },
			} = yield call(response.json.bind(response));
			yield put(updateSequence(sequence));

			const team = yield select(getTeamById, teamId);
			const originals = R.pick(
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				(Object.keys(patch) as Array<keyof TeamPayload>).filter((key) => patch[key] !== team[key]),
				team,
			);

			if (!isEqualMembers) {
				yield call(doUpdateMembers, { members, team });
			}

			const scenarioType =
				team.scenarioType === SCENARIO_TYPE.ADDED ? SCENARIO_TYPE.ADDED : SCENARIO_TYPE.UPDATED;
			yield put(updateOriginalTeam({ ...originals, id: teamId }));
			yield put(
				updateTeam({
					teamId,
					patch: {
						scenarioType,
						...(scenarioType === SCENARIO_TYPE.UPDATED ? { originals } : {}),
						...patch,
					},
				}),
			);
			yield put(closeTeamsDialog());
		} else {
			const message = yield call(response.text.bind(response));
			const error = new Error(message);
			fireErrorAnalytics({
				error,
				meta: {
					id: toErrorID(error, 'do-update-team-fetch-failed'),
					packageName: PACKAGE_NAME,
					teamName: ERROR_REPORTING_TEAM,
				},
				sendToPrivacyUnsafeSplunk: true,
			});
		}
	} finally {
		yield put(resetRequest());
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doDeleteTeam({ payload }: DeleteTeamAction): Generator<Effect, any, any> {
	const { currentScenarioId: scenarioId, id: planId } = yield select(getPlan);
	const body = { itemKeys: [payload.id], planId, scenarioId };
	const response = yield call(fetch, urls.deleteTeam, {
		method: POST,
		body,
	});
	if (response.ok) {
		const {
			change: { sequence },
		} = yield call(response.json.bind(response));
		yield put(updateSequence(sequence));

		const team = yield select(getTeamById, payload.id);

		if (team.scenarioType === SCENARIO_TYPE.ADDED) {
			yield put(removeTeam({ id: payload.id, action: 'DELETE' }));
			yield put(removeTeamIdFromColourBy({ id: payload.id }));

			const originals = yield select(getOriginalTeams);

			yield put(resetOriginalTeams(R.dissoc(payload.id, originals)));
		} else {
			yield put(updateOriginalTeam({ ...payload }));
			yield put(
				updateTeam({
					teamId: payload.id,
					patch: { scenarioType: SCENARIO_TYPE.DELETED },
				}),
			);
		}
		yield call(updateTeamId, payload.id);
		yield call(getBacklog); // revert value of issues' team cell
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doShareTeam({ teamId }: ShareTeamAction): Generator<Effect, any, any> {
	const { id: planId } = yield select(getPlan);
	const response = yield call(fetch, urls.setSharedNew, {
		method: POST,
		body: {
			planId,
			teamId,
		},
	});
	if (response.ok) {
		const { externalId } = yield call(response.json.bind(response));
		yield put(setShared(teamId, externalId));
	} else {
		const message = yield call(response.text.bind(response));
		const error = new Error(message);
		fireErrorAnalytics({
			error,
			meta: {
				id: toErrorID(error, 'do-share-team-fetch-failed'),
				packageName: PACKAGE_NAME,
				teamName: ERROR_REPORTING_TEAM,
			},
			sendToPrivacyUnsafeSplunk: true,
		});
	}
}

export function* doAddMemberAndShareTeam({
	payload: { teamId, personIds }, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: AddMembersAndShareTeamAction): Generator<Effect, any, any> {
	const { id: planId } = yield select(getPlan);
	const response = yield call(fetch, urls.addTeamMemberWithoutScenarioChange, {
		method: POST,
		body: {
			planId,
			teamId,
			persons: personIds.map((aId) => ({
				personId: aId,
			})),
		},
	});
	if (response.ok) {
		const { resources } = yield call(response.json.bind(response));

		yield all(
			resources.map(function* ({
				person,
				resourceItemKey,
			}: {
				person: AddActionPayload;
				resourceItemKey: string;
			}) {
				yield put(addPerson(person));
				const resource = {
					itemKey: resourceItemKey,
					scenarioType: SCENARIO_TYPE.ADDED,
					originals: person.originals,
					personItemKey: person.itemKey,
					values: {
						weeklyHours: null,
					},
				};
				yield put(addMember({ teamId, resource }));
			}),
		);
		// if the new added persons doesn't existed in the team assignee, need to fetch them
		yield put(fetchTeamsAssignees());
		yield put(shareTeam(teamId));
	} else {
		const message = yield call(response.text.bind(response));
		const error = new Error(message);
		fireErrorAnalytics({
			error,
			meta: {
				id: toErrorID(error, 'do-add-member-and-share-team-fetch-failed'),
				packageName: PACKAGE_NAME,
				teamName: ERROR_REPORTING_TEAM,
			},
			sendToPrivacyUnsafeSplunk: true,
		});
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchCreateNewTeam(): Generator<Effect, any, any> {
	yield takeEvery(CREATE_TEAM, doCreateTeam);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchAddAtlassianTeam(): Generator<Effect, any, any> {
	yield takeEvery(ADD_ATLASSIAN_TEAM, doAddAtlassianTeam);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchUpdateTeam(): Generator<Effect, any, any> {
	yield takeEvery(UPDATE_TEAM, doUpdateTeam);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchDeleteTeam(): Generator<Effect, any, any> {
	yield takeEvery(DELETE_TEAM, doDeleteTeam);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchAddTeamMember(): Generator<Effect, any, any> {
	yield takeEvery(ADD_TEAM_MEMBER, doAddMember);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchDeleteTeamMember(): Generator<Effect, any, any> {
	yield takeEvery(DELETE_TEAM_MEMBER, doDeleteMember);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchShareTeam(): Generator<Effect, any, any> {
	yield takeEvery(SHARE_TEAM, doShareTeam);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchAddMemberAndShareTeam(): Generator<Effect, any, any> {
	yield takeEvery(ADD_MEMBERS_AND_SHARE_TEAM, doAddMemberAndShareTeam);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default function* (): Generator<Effect, any, any> {
	yield fork(watchCreateNewTeam);
	yield fork(watchAddAtlassianTeam);
	yield fork(watchUpdateTeam);
	yield fork(watchDeleteTeam);
	yield fork(watchAddTeamMember);
	yield fork(watchDeleteTeamMember);
	yield fork(watchShareTeam);
	yield fork(watchAddMemberAndShareTeam);
}
