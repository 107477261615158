import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	triggerButtonLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.trigger-button-label',
		defaultMessage: 'View settings',
		description:
			'Label for a button to open a dialog where the settings of a view can be updated. "View" should be considered as a noun and not as a verb in this context.',
	},
	clearAllColours: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.clear-all-colours',
		defaultMessage: 'Clear all colors',
		description: 'Label for a button to clear all colours for labels',
	},
	clearAllGroups: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.clear-all-groups',
		defaultMessage: 'Clear all groups',
		description: 'Label for a button to clear all groups for components in group by',
	},
	showSprintsOnTimeline: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.show-sprints-on-timeline',
		defaultMessage: 'Show capacity on timeline',
		description: 'Label for checkbox to show capacity on the timeline',
	},
	onlyAvailableForTeamOrSprint: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.only-available-for-team-or-sprint',
		defaultMessage: 'Only available when grouping by team or sprint',
		description: 'Label to show underneath show capacity checkbox when it is disabled',
	},
	rollupLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.rollup-label',
		defaultMessage: 'Roll-up',
		description: 'Label for field roll-up setting',
	},
	dependencySettingsLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.dependency-settings-label',
		defaultMessage: 'Dependency style',
		description: 'Label for dependency settings',
	},
	allOtherIssuesButtonLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.all-other-issues-button-label',
		defaultMessage: 'Gray',
		description: 'Label for All other issue button in colour by filters',
	},
	showAggregateValue: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.show-aggregate-value-non-final',
		defaultMessage: 'Show aggregate value',
		description:
			'Label for checkbox to show aggregate value on the timeline when group-by view is active',
	},
});
