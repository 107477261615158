import {
	createStore,
	createActionsHook,
	createContainer,
	createHook,
} from '@atlassian/react-sweet-state';

import { loadPinnedFieldsByProjectId } from '../actions/index.tsx';
import { getLoadError, getPinnedFieldsByProjectId } from '../selectors/index.tsx';

type State = {
	pinnedFields?: { [key: string]: string[] };
	error: Error | undefined;
};

const initialState: State = {
	pinnedFields: undefined,
	error: undefined,
};

export const PinnedFieldsContainer = createContainer();

const Store = createStore({
	containedBy: PinnedFieldsContainer,
	initialState,
	actions: {
		loadPinnedFieldsByProjectId,
	},
	name: 'jpd-idas-cell-pinned-fields-container',
});

export const usePinnedFieldsActions = createActionsHook(Store);

export const usePinnedFieldsByProjectId = createHook(Store, {
	selector: getPinnedFieldsByProjectId,
});

export const usePinnedFieldLoadError = createHook(Store, {
	selector: getLoadError,
});
