import React from 'react';
import AvatarGroup from '@atlaskit/avatar-group';
import { Box, xcss } from '@atlaskit/primitives';
import type { Props } from './types.tsx';

const UserCell = ({ fieldKey, values, groupName }: Props) => {
	return (
		<Box
			paddingInline="space.100"
			xcss={containerStyles}
			testId={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.user-cell.${groupName}.${fieldKey}`}
		>
			<AvatarGroup
				size="small"
				data={values.map((value) => ({
					key: value.personId,
					src: value.jiraUser.avatarUrl,
					name: value.jiraUser.title,
				}))}
			/>
		</Box>
	);
};

export default UserCell;

const containerStyles = xcss({
	height: '100%',
	display: 'flex',
	alignItems: 'center',
});
