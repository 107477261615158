.form-group {
	display: flex;
}

.current-value {
	padding-top: calc(var(--jpo-common-base-spacing) + 2px);
}

.icon {
	vertical-align: -5px;
	padding: 0 calc(var(--jpo-common-base-spacing) * 2);
	margin-top: 40px;
}

.value-label {
	color: var(--ds-text-subtlest, var(--adg3-color-N200));
	font-size: 12px;
	font-weight: var(--ds-font-weight-semibold, 600);
}

.value-placeholder {
	padding-top: calc(var(--jpo-common-base-spacing) / 2);
	line-height: 40px;
	max-width: 260px;
	overflow: hidden;
	text-overflow: ellipsis;
	box-sizing: border-box;
	white-space: nowrap;
}

.operation-type {
	flex-basis: 30%;
}

.anchor {
	flex-basis: 50%;
	padding: 23px 0 0 8px;
	display: block;
}

.form-footer {
	display: flex;
	padding: 16px 0px 20px;
	justify-content: flex-end;
	& button {
		margin: 0 4px;
	}
}
