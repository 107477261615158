import { createMigrate, incremental } from '../common/migrate.tsx';
import type { VisualisationsStateV0, VisualisationsStateV1 } from './types.tsx';

export default createMigrate(
	...incremental(
		{
			V0: function fromV0ToV0(prevState: VisualisationsStateV0): VisualisationsStateV0 {
				return {
					...prevState,
				};
			},
			V1: function fromV0ToV1(prevState: VisualisationsStateV0): VisualisationsStateV1 {
				return {
					...prevState,
					// enabled by default
					showAggregate: true,
				};
			},
		},
		{ prefix: 'visualisations', from: 'V0' },
	),
);
