import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	header: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-parent.header',
		defaultMessage: 'Move',
		description: 'Header for the move issue dialog',
	},
	variousParents: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-parent.various-parents',
		defaultMessage: 'Various parents',
		description: 'Label for various parent values',
	},
	crossProjectReparentingWarning: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-parent.cross-project-reparenting-warning',
		defaultMessage: "Issues from team-managed projects can't be moved to another project.",
		description:
			'A warning underneath the select dropdown explaining why there are no valid options due to TMP to CMP reparenting restrictions.',
	},
	crossProjectReparentingWarningIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-parent.cross-project-reparenting-warning-issue-term-refresh',
		defaultMessage: "Work items from team-managed projects can't be moved to another project.",
		description:
			'A warning underneath the select dropdown explaining why there are no valid options due to TMP to CMP reparenting restrictions.',
	},
});
