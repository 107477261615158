import type { Effect } from 'redux-saga';
import partition from 'lodash/partition';
import * as R from 'ramda';
import { all, fork, takeEvery, take, put, call, select } from 'redux-saga/effects';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import {
	fireErrorAnalytics,
	RESPONSE_ERROR_CODE,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/error/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	SUB_TASK_LEVEL,
	STORY_LEVEL,
} from '@atlassian/jira-portfolio-3-common/src/hierarchy/index.tsx';
import { DATEFIELD_TYPES } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';

import fetch from '@atlassian/jira-portfolio-3-portfolio/src/common/fetch/index.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import {
	ERROR_REPORTING_TEAM,
	PACKAGE_NAME,
	ENTITY,
	PlanningUnits,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { isScenarioIssue } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/utils/issue.tsx';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { DESCRIPTION_FETCH_ERROR } from '@atlassian/jira-plan-issue-modal/src/ui/constants.tsx';
import isArjGicPrefilledDataHandlingEnabled from '../../feature-flags/is-arj-gic-prefilled-data-handling-enabled.tsx';
import { getAnalyticsEvent } from '../../query/app/index.tsx';
import { getCrossProjectVersionsById } from '../../query/cross-project-versions/index.tsx';
import { getCustomLabelFieldIds } from '../../query/custom-fields/index.tsx';
import { convertEstimate } from '../../query/estimation/index.tsx';
import { getHierarchyRangeFilter } from '../../query/filters/hierarchy-range-filter/index.tsx';
import { getIssueLabels } from '../../query/issue-labels/index.tsx';
import {
	getAllSortedIssues,
	getDescendantIdsByParent,
	getAncestors,
	getSelectedIssues,
	getSelected,
	getSelectedIssuesByProjectId,
} from '../../query/issues/index.tsx';
import { getPlan, getDateConfiguration } from '../../query/plan/index.tsx';
import { getProjectsById } from '../../query/projects/index.tsx';
import {
	type IssueMap,
	getIssues,
	getIssueMapById,
	createIssueMapByIdPure,
} from '../../query/raw-issues/index.tsx';
import { getIsExpanded, getSortedIssues } from '../../query/scope/index.tsx';
import { getJiraHoursPerDay } from '../../query/system/index.tsx';
import * as domainActions from '../../state/domain/actions.tsx';
import { add as addCustomLabels } from '../../state/domain/custom-labels/actions.tsx';
import {
	reset as resetHistoryIssues,
	updateParent,
} from '../../state/domain/history-issues/actions.tsx';
import * as issueLabelActions from '../../state/domain/issue-labels/actions.tsx';
import {
	SET_ISSUE_BEING_EDITED,
	type SetIssueBeingEditedAction,
	setLoadingIssueDescription,
} from '../../state/domain/issue-modal/actions.tsx';
import {
	add,
	update,
	rank,
	bulkRank,
	reset,
	bulkUpdate,
	bulkLabelUpdate,
} from '../../state/domain/issues/actions.tsx';
import type { Issue } from '../../state/domain/issues/types.tsx';
import { adjustLexoRank, bulkAdjustLexoRank } from '../../state/domain/lexorank/actions.tsx';
import {
	update as updateOriginalIssueValues,
	remove as removeOriginalIssues,
	type UpdateActionPayload as UpdateOriginalIssueActionPayload,
} from '../../state/domain/original-issues/actions.tsx';
import type { DateConfiguration } from '../../state/domain/plan/types.tsx';
import type { Project } from '../../state/domain/projects/types.tsx';
import * as scenarioRemovedIssuesActions from '../../state/domain/scenario-removed-issues/actions.tsx';
import {
	update as updateSequence,
	increment as incrementSequence,
} from '../../state/domain/sequence/actions.tsx';
import {
	load as loadChangeStatusScreen,
	CANCEL as CANCEL_CHANGE_STATUS,
	SUCCESS as SUCCESS_CHANGE_STATUS,
	FAIL as FAIL_CHANGE_STATUS,
} from '../../state/domain/update-jira/change-status/actions.tsx';
import { interrupt as interruptCommit } from '../../state/domain/update-jira/commit/actions.tsx';
import { reset as resetHiddenIssues } from '../../state/domain/update-jira/hidden-issues/actions.tsx';
import {
	type LoadError,
	load as loadRequiredFields,
	CANCEL as CANCEL_REQUIRED_FIELDS,
	SUBMIT as SUBMIT_REQUIRED_FIELDS,
	FAIL as FAIL_REQUIRED_FIELDS,
} from '../../state/domain/update-jira/required-fields/actions.tsx';
import { add as addCommitWarning } from '../../state/domain/update-jira/warnings/actions.tsx';
import type { HierarchyRangeFilter } from '../../state/domain/view-settings/filters/types.tsx';
import { setIsExpanded } from '../../state/domain/view-settings/issue-expansions/actions.tsx';
import type { State } from '../../state/types.tsx';
import {
	updateInlineCreate,
	setSiblingId,
} from '../../state/ui/main/tabs/roadmap/scope/inline-create/actions.tsx';
import { INLINE_CREATE_ID } from '../../state/ui/main/tabs/roadmap/scope/inline-create/reducer.tsx';
import {
	setIsLoadingHistory,
	setIsSaving,
} from '../../state/ui/main/tabs/roadmap/scope/issues/actions.tsx';
import { toggleSelectedIssues } from '../../state/ui/main/tabs/roadmap/scope/issues/selectable-issue/actions.tsx';
import { OPEN_HIDDEN_ISSUES_DIALOG } from '../../state/ui/top/title-bar/hidden-issues/actions.tsx';
import { POST, GET, parseError } from '../api.tsx';
import batch from '../batch/index.tsx';
import type { BulkCommitResponseEntity } from '../commit-bulk/types.tsx';
import { commitBody, revertBody } from '../commit/api.tsx';
import type { CommitResponse } from '../commit/types.tsx';
import { inspectForCommitWarnings, defaultWarning } from '../commit/warnings.tsx';
import { genericError as deprecatedGenericError } from '../errors/index.tsx';
import * as http from '../http/index.tsx';
import { toErrorID } from '../util.tsx';
import { calculateReleaseStatus } from '../versions/index.tsx';
import { calculateWarnings } from '../warnings/index.tsx';
import {
	urls,
	addIssueBody,
	updateIssueBody,
	rankIssueBody,
	hiddenIssuesBody,
	getDescription,
} from './api.tsx';
import type {
	ActionWithExternalPromise,
	AddIssueAction,
	AddIssuePayload,
	BulkRankIssueAction,
	BulkRankIssueActionPayload,
	BulkUpdateIssueAction,
	BulkUpdateIssueCPRAction,
	CommitResponseAction,
	RemoveSingleIssuePayload,
	RemoveSingleIssueAction,
	RemoveIssuesPayload,
	DeleteIssuesPayload,
	DeleteSingleIssuePayload,
	DeleteSingleIssueAction,
	ExpandOrCollapseAllAnalyticsPayload,
	FailCommitRequestAction,
	MoveIssueAction,
	MoveIssuePayload,
	RankIssueAction,
	RankIssuePayload,
	RankRequest,
	RankRequestResponse,
	RefreshSubtaskAction,
	RefreshSubtaskPayload,
	SubtaskUpdate,
	UpdateIssueAction,
	UpdateIssuePayload,
	UpdateIssueRequest,
	UpdateIssueDatesAction,
	UpdateIssueDatesPayload,
	UpdateOriginalIssueAction,
	UpdateMultipleIssuesAction,
	UpdateMultipleIssuesPayload,
	UpdateIssueKeyResponse,
} from './types.tsx';

import * as constants from './types.tsx';
import { getAddedIssueRankPayload, getOriginalProperties, getBulkRankPayload } from './utils.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { toggleSelectedIssues } from '../../state/ui/main/tabs/roadmap/scope/issues/selectable-issue/actions.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { setIsExpanded } from '../../state/domain/view-settings/issue-expansions/actions';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { ToggleSelectedIssuesActionPayload } from '../../state/ui/main/tabs/roadmap/scope/issues/selectable-issue/actions.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { SetIsExpandedActionPayload } from '../../state/domain/view-settings/issue-expansions/actions';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	ADD_ISSUE,
	BULK_ISSUES_CPR_UPDATE,
	BULK_ISSUES_UPDATE,
	BULK_RANK_ISSUES,
	COLLAPSE_ALL_ANALYTICS_ACTION,
	REMOVE_SELECTED_ISSUES,
	EXPAND_ALL_ANALYTICS_ACTION,
	FAIL_COMMIT_REQUEST,
	FETCH_HISTORICAL_DONE_ISSUES,
	HANDLE_COMMIT_RESPONSE,
	MOVE_ISSUE,
	RANK_ISSUE,
	REFRESH_SUBTASK,
	UPDATE_ISSUE,
	UPDATE_MULTIPLE_ISSUES,
	UPDATE_ORIGINAL_ISSUE,
	type AddIssueAction,
	type AddIssuePayload,
	type BulkRankIssueAction,
	type BulkRankIssueActionPayload,
	type BulkUpdateIssueAction,
	type BulkUpdateIssueCPRAction,
	type CollapseAllAnalyticsAction,
	type CommitResponseAction,
	type RemoveIssuesPayload,
	type ExpandAllAnalyticsAction,
	type ExpandOrCollapseAllAnalyticsPayload,
	type FailCommitRequestAction,
	type HiddenIssuesBody,
	type MoveIssueAction,
	type MoveIssuePayload,
	type NewIssue,
	type Operation,
	type RankIssueAction,
	type RankIssuePayload,
	type RankRequest,
	type RefreshSubtaskAction,
	type RefreshSubtaskPayload,
	type SubtaskUpdate,
	type UpdateIssueAction,
	type UpdateIssuePayload,
	type UpdateIssueRequest,
	type UpdateMultipleIssuesAction,
	type UpdateMultipleIssuesPayload,
	type UpdateOriginalIssueAction,
	type UpdateIssueDatesPayload,
} from './types';

export const UPDATE_INLINE_CREATE_ISSUE = 'command.issue.UPDATE_INLINE_CREATE_ISSUE' as const;

type HandleCommitResponseFunc = (payload: CommitResponse) => CommitResponseAction;
type handleCommitResponseText = {
	text: Function;
};
function isHandleCommitResponseMethod(
	res: HandleCommitResponseFunc | handleCommitResponseText,
): res is HandleCommitResponseFunc {
	return typeof res === 'function';
}

const handleCommitResponse: HandleCommitResponseFunc | handleCommitResponseText = (payload) => ({
	type: constants.HANDLE_COMMIT_RESPONSE,
	payload,
});

export type UpdateIssueOrInlineCreateActionPayload = {
	type: typeof UPDATE_INLINE_CREATE_ISSUE;
	payload: UpdateIssuePayload;
};

export type UpdateIssueRawAction = {
	type: typeof constants.UPDATE_ISSUE;
	payload: UpdateIssuePayload;
};

export const expandAllAnalytics = (payload: ExpandOrCollapseAllAnalyticsPayload) => ({
	type: constants.EXPAND_ALL_ANALYTICS_ACTION,
	payload,
});
export const collapseAllAnalytics = (payload: ExpandOrCollapseAllAnalyticsPayload) => ({
	type: constants.COLLAPSE_ALL_ANALYTICS_ACTION,
	payload,
});
export const addIssue = (payload: AddIssuePayload) => ({
	type: constants.ADD_ISSUE,
	payload,
});

export const updateIssueRaw = (payload: UpdateIssuePayload) => ({
	type: constants.UPDATE_ISSUE,
	payload,
});

export const updateIssueDatesOptimistic = (payload: UpdateIssueDatesPayload) => ({
	type: constants.UPDATE_ISSUE_DATES_OPTIMISTIC,
	payload,
});

export const updateMultipleIssues = (payload: UpdateMultipleIssuesPayload) => ({
	type: constants.UPDATE_MULTIPLE_ISSUES,
	payload,
});

export const rankIssue = (payload: RankIssuePayload) => ({
	type: constants.RANK_ISSUE,
	payload,
});

export const bulkRankIssue = (payload: BulkRankIssueActionPayload) => ({
	type: constants.BULK_RANK_ISSUES,
	payload,
});

export const moveIssue = (payload: MoveIssuePayload) => ({
	type: constants.MOVE_ISSUE,
	payload,
});

export const updateOriginalIssue = (payload: UpdateOriginalIssueActionPayload) => ({
	type: constants.UPDATE_ORIGINAL_ISSUE,
	payload,
});

export const fetchHistoricalDoneIssues = () => ({
	type: constants.FETCH_HISTORICAL_DONE_ISSUES,
});

export const removeSingleIssue = (payload: RemoveSingleIssuePayload) => ({
	type: constants.REMOVE_SINGLE_ISSUE,
	payload,
});

export const removeSelectedIssues = () => ({
	type: constants.REMOVE_SELECTED_ISSUES,
});

export const deleteSingleIssue = (payload: DeleteSingleIssuePayload) => ({
	type: constants.DELETE_SINGLE_ISSUE,
	payload,
});

export const bulkIssuesUpdate = (payload: UpdateIssuePayload) => ({
	type: constants.BULK_ISSUES_UPDATE,
	payload,
});

export const bulkIssuesCRPUpdate = (cprId: string): BulkUpdateIssueCPRAction => ({
	type: constants.BULK_ISSUES_CPR_UPDATE,
	cprId,
});

export const updateIssueOrInlineCreate = (
	payload: UpdateIssuePayload,
): UpdateIssueOrInlineCreateActionPayload => ({
	type: UPDATE_INLINE_CREATE_ISSUE,
	payload,
});

export const refreshSubtask = (payload: RefreshSubtaskPayload) => ({
	type: constants.REFRESH_SUBTASK,
	payload,
});

export function* withExternalPromiseResolve(
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	block: () => Generator<Effect, any, any>,
	promise: { resolve: Function; reject: Function } | undefined, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	if (!promise || !promise?.resolve || !promise?.reject) {
		return yield call(block);
	}

	try {
		const result = yield call(block);
		promise.resolve(result); // eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (err: any) {
		promise.reject(err);
	}
}

function* doUpdateIssueOrInlineCreate({
	payload, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: UpdateIssueOrInlineCreateActionPayload): Generator<Effect, any, any> {
	if (payload.id === INLINE_CREATE_ID) {
		const { id, lexoRank, level, summary, type, ...slimPayload } = payload;
		return yield put(updateInlineCreate({ ...slimPayload }));
	}
	return yield put(updateIssueRaw(payload));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doRefreshSubtask({ payload }: RefreshSubtaskAction): Generator<Effect, any, any> {
	const issueMap = yield select(getIssueMapById);

	const issue = issueMap[payload.issueId];

	if (!issue) {
		return;
	}

	if (issue.level === SUB_TASK_LEVEL) {
		// payload issue is a subtask, which happens when a subtask is moved from one story to another
		// we need to look up the team value from its parent issue
		const parent = issue.parent;
		if (isDefined(parent) && isDefined(issueMap[parent])) {
			const parentIssue = issueMap[parent];
			const updatedValues: SubtaskUpdate = { id: issue.id };
			let changed = false;
			if (issue.team !== parentIssue.team) {
				updatedValues.team = parentIssue.team;
				changed = true;
			}
			if (issue.sprint !== parentIssue.sprint) {
				updatedValues.sprint = parentIssue.sprint;
				changed = true;
			}
			if (changed) {
				yield put(update(updatedValues));
			}
		}
	} else {
		// payload issue is a story, which happens when the team of a story is updated
		// we need to refresh all its subtasks
		const { team, sprint } = issue;
		const { fields } = payload;

		for (const issueId of Object.keys(issueMap)) {
			const curIssue = issueMap[issueId];
			if (curIssue.parent === issue.id && curIssue.level === SUB_TASK_LEVEL) {
				const updatedValues: SubtaskUpdate = { id: curIssue.id };
				if (isDefined(fields) && fields.team && curIssue.team !== team) {
					updatedValues.team = team;
				}
				if (isDefined(fields) && fields.sprint && curIssue.sprint !== sprint) {
					updatedValues.sprint = sprint;
				}
				if (isDefined(updatedValues.team) || isDefined(updatedValues.sprint)) {
					yield put(update(updatedValues));
				}
			}
		}
	}
}

export const mapBaselineDatesToConfiguredDates = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	payload: any,
	dateConfiguration: DateConfiguration,
) =>
	// eslint-disable-next-line @typescript-eslint/no-shadow
	(['baselineStart', 'baselineEnd'] as const).reduce((payload, field) => {
		if (!R.has(field, payload)) return payload;

		const { key, type } = dateConfiguration[`${field}Field`];
		const path = type === DATEFIELD_TYPES.CUSTOM ? ['customFields', key] : [key];
		const value = payload[field];

		// Remove changes of baseline dates and associate them to changes of configured dates
		return R.compose(R.dissoc(field), R.assocPath(path, value))(payload);
	}, payload);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doMapBaselineDatesToConfiguredDates(payload: any): Generator<Effect, any, any> {
	const dateConfiguration: DateConfiguration = yield select(getDateConfiguration);

	return mapBaselineDatesToConfiguredDates(payload, dateConfiguration);
}

export function* doAddIssue({
	payload: { issue: newIssue, sibling, skipRanking = false, batchRanking = false }, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: AddIssueAction): Generator<Effect, any, any> {
	try {
		const issue = yield call(doMapBaselineDatesToConfiguredDates, newIssue);
		const url = urls.add;
		const body = addIssueBody(yield select(getPlan), issue);
		yield put(setIsSaving(true));
		const response = yield call(fetch, url, {
			method: POST,
			body,
		});
		if (!response.ok) {
			if (!fg('improve_redux_saga_error_reporting_plans_batch_2')) {
				yield put(
					deprecatedGenericError({
						...parseError(response, yield call(response.text.bind(response))),
						requestInfo: {
							url,
							type: POST,
							status: response.status,
							body,
						},
					}),
				);
			} else {
				const error = new Error(yield call(response.text.bind(response)));
				fireErrorAnalytics({
					error,
					meta: {
						id: toErrorID(error, 'add-issue-fetch-failed'),
						packageName: PACKAGE_NAME,
						teamName: ERROR_REPORTING_TEAM,
					},
					sendToPrivacyUnsafeSplunk: true,
				});
			}
		} else {
			const result = yield call(response.json.bind(response));
			const { itemKey: id } = result;
			const apiIssue = { ...issue, id };
			yield* batch(function* () {
				yield put(setSiblingId(id));
				yield put(add(apiIssue));
				yield put(updateOriginalIssue({ id }));
				const issues: ReturnType<typeof getSortedIssues> = yield select(getSortedIssues);

				if (!skipRanking) {
					// For the bulk inline create issues, we need to make sure that the ranking of issues finishes before returning.
					// After creating and ranking the previous issue, we can make sure the next created issue would get the correct last sibling by lexoRank
					if (batchRanking) {
						yield* batch(function* () {
							yield put(rankIssue(getAddedIssueRankPayload(apiIssue, issues, sibling)));
						});
					} else {
						yield put(rankIssue(getAddedIssueRankPayload(apiIssue, issues, sibling)));
					}
				}

				yield put(calculateWarnings());
				if (issue.level === SUB_TASK_LEVEL) {
					yield put(refreshSubtask({ issueId: id }));
				}
			});

			if (result) {
				return result;
			}
		}
	} finally {
		yield put(setIsSaving(false));
	}
}

export function* doAddIssueWithExternalPromise(
	action: ActionWithExternalPromise<AddIssueAction>, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	const {
		payload: { promise, ...payload },
		type,
	} = action;
	yield call(
		withExternalPromiseResolve,
		function* () {
			return yield call(doAddIssue, { type, payload });
		},
		promise,
	);
}

export function* doOptimisticIssueUpdate(
	{
		payload,
		issue,
		originalProperties,
	}: {
		payload: UpdateIssuePayload;
		issue: Issue;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		originalProperties: any;
	}, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	const { baselineStartField, baselineEndField }: DateConfiguration =
		yield select(getDateConfiguration);

	const labelCustomFieldIds = yield select(getCustomLabelFieldIds);
	let issueBaselineEnd: Issue['baselineEnd'] = null;

	if (baselineEndField.type === DATEFIELD_TYPES.BUILT_IN) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		issueBaselineEnd = issue[baselineEndField.key as keyof Issue];
	} else if (isDefined(issue.customFields)) {
		issueBaselineEnd = issue.customFields[baselineEndField.key];
	}

	yield* batch(function* () {
		yield put(updateOriginalIssue(issue.issueKey ? originalProperties : { id: issue.id }));
		const updatedProperties: string[] = Object.keys(payload);
		if (isDefined(payload.customFields)) {
			updatedProperties.push(...Object.keys(payload.customFields));
		}

		if (
			(updatedProperties.includes(baselineStartField.key) && !isDefined(issueBaselineEnd)) ||
			updatedProperties.includes(baselineEndField.key) ||
			updatedProperties.includes('fixVersions') ||
			updatedProperties.includes('sprint')
		) {
			const issueVersions = issue.fixVersions || [];
			const payloadVersions = payload.fixVersions || [];

			for (const version of new Set([...issueVersions, ...payloadVersions])) {
				yield put(calculateReleaseStatus(version));
			}
		}

		const customLabelFieldIds = R.intersection(updatedProperties, labelCustomFieldIds);
		if (customLabelFieldIds.length > 0) {
			for (const customLabelFieldId of customLabelFieldIds) {
				const originalCustomLabels =
					(issue.customFields && issue.customFields[customLabelFieldId]) || [];
				const newCustomLabels =
					(payload.customFields && payload.customFields[customLabelFieldId]) || [];
				const newAddedLabels: string[] = R.difference<string>(
					newCustomLabels,
					originalCustomLabels,
				);

				yield put(
					addCustomLabels({
						customFieldId: Number(customLabelFieldId),
						labels: newAddedLabels,
					}),
				);
			}
		}

		if (updatedProperties.includes('labels')) {
			const issueLabelsInStore = new Set(yield select(getIssueLabels));
			const originalIssueLabels = issue.labels || [];
			let newIssueLabels = payload.labels || [];

			// If we are bulk updating labels, the payload is different.
			// This is needed so that ARJ has the new labels that may have been created in bulk edit labels without refreshing
			if (payload.labels?.fieldAction) {
				newIssueLabels = payload.labels?.value || [];
			}

			const addedLabels = R.difference(newIssueLabels, originalIssueLabels);

			for (const addedLabel of addedLabels) {
				if (!issueLabelsInStore.has(addedLabel)) {
					yield put(issueLabelActions.add(addedLabel));
				}
			}
		}
	});
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doUpdateIssueRequest(body: UpdateIssueRequest): Generator<Effect, any, any> {
	const url = urls.update;
	const response = yield call(fetch, url, {
		method: POST,
		body,
	});
	if (!response.ok) {
		if (!fg('improve_redux_saga_error_reporting_plans_batch_2')) {
			yield put(
				deprecatedGenericError({
					...parseError(response, yield call(response.text.bind(response))),
					requestInfo: {
						url,
						type: POST,
						status: response.status,
						body,
					},
				}),
			);
		} else {
			const error = new Error(yield call(response.text.bind(response)));
			fireErrorAnalytics({
				error,
				meta: {
					id: toErrorID(error, 'update-issue-request-failed'),
					packageName: PACKAGE_NAME,
					teamName: ERROR_REPORTING_TEAM,
				},
				sendToPrivacyUnsafeSplunk: true,
			});
		}
	}
	yield put(calculateWarnings());
}

/**
 * Reset scenario changes in the ARJ
 * @param body
 * @param failedCallback
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doResetIssueRequest(body: UpdateIssueRequest): Generator<Effect, any, any> {
	const url = urls.update;
	const resetStatusResponse = yield call(fetch, url, {
		method: POST,
		body,
	});
	if (!resetStatusResponse.ok) {
		if (!fg('improve_redux_saga_error_reporting_plans_batch_2')) {
			yield put(
				deprecatedGenericError({
					...parseError(
						resetStatusResponse,
						yield call(resetStatusResponse.text.bind(resetStatusResponse)),
					),
					requestInfo: {
						url,
						type: POST,
						status: resetStatusResponse.status,
						body,
					},
				}),
			);
		} else {
			const error = new Error(yield call(resetStatusResponse.text.bind(resetStatusResponse)));
			fireErrorAnalytics({
				error,
				meta: {
					id: toErrorID(error, 'reset-issue-request-failed'),
					packageName: PACKAGE_NAME,
					teamName: ERROR_REPORTING_TEAM,
				},
				sendToPrivacyUnsafeSplunk: true,
			});
		}
	} else {
		const data = yield call(resetStatusResponse.json.bind(resetStatusResponse));
		const {
			change: { sequence },
			failed,
		} = data;
		if (failed > 0) {
			if (!fg('improve_redux_saga_error_reporting_plans_batch_2')) {
				yield put(deprecatedGenericError({ message: 'Failed to reset issue scenario changes' }));
			} else {
				const error = new Error('Failed to reset issue scenario changes');
				fireErrorAnalytics({
					error,
					meta: {
						id: toErrorID(error, 'reset-issue-request-failed'),
						packageName: PACKAGE_NAME,
						teamName: ERROR_REPORTING_TEAM,
					},
					sendToPrivacyUnsafeSplunk: true,
				});
			}
		}
		yield put(updateSequence(sequence));
	}
	yield put(calculateWarnings());
	return resetStatusResponse;
}

export function* doUpdateIssue({
	payload: { promise, ...payload }, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: UpdateIssueAction): Generator<Effect, any, any> {
	const issue: Issue = (yield select(getIssueMapById))[payload.id];
	let updatedValues = payload;
	if (R.has('baselineStart', payload) || R.has('baselineEnd', payload)) {
		updatedValues = yield call(doMapBaselineDatesToConfiguredDates, payload);
	}

	const originalProperties = getOriginalProperties(issue, updatedValues);

	if (!originalProperties) {
		return;
	}
	// for updating status, need to pass the statusTransition to the BE, but for the FE, we don't
	// need add the statusTransition field to the issue model which will mess up the changes of the issue, so
	// we need to get rid of the statusTransition in here
	if (R.has('statusTransition', payload)) {
		const updatedValuesWithoutStatusTransition = R.omit(['statusTransition'], updatedValues);
		yield put(update(updatedValuesWithoutStatusTransition));
		yield call(doOptimisticIssueUpdate, {
			payload: updatedValuesWithoutStatusTransition,
			issue,
			originalProperties,
		});
	} else {
		if (updatedValues.goals) {
			const newGoals = updatedValues.goals.map((value: { id: string; key: string }) => value.id);
			const newUpdatedValues = { ...updatedValues };

			newUpdatedValues.goals = newGoals;
			yield put(update(newUpdatedValues));
		} else {
			yield put(update(updatedValues));
		}

		yield call(doOptimisticIssueUpdate, {
			payload: updatedValues,
			issue,
			originalProperties,
		});
	}

	const parentUpdatedForSubtask =
		issue.level === SUB_TASK_LEVEL &&
		(R.has('parent', updatedValues) || R.has('parent', originalProperties));
	const teamUpdatedForStandardIssue =
		issue.level === STORY_LEVEL &&
		(R.has('team', updatedValues) || R.has('team', originalProperties));
	const sprintUpdatedForStandardIssue =
		issue.level === STORY_LEVEL &&
		(R.has('sprint', updatedValues) || R.has('sprint', originalProperties));
	if (teamUpdatedForStandardIssue || sprintUpdatedForStandardIssue || parentUpdatedForSubtask) {
		yield put(
			refreshSubtask({
				issueId: issue.id,
				fields: {
					team: teamUpdatedForStandardIssue || parentUpdatedForSubtask,
					sprint: sprintUpdatedForStandardIssue || parentUpdatedForSubtask,
				},
			}),
		);
	}

	const body = updateIssueBody(yield select(getPlan), updatedValues);
	yield call(doUpdateIssueRequest, body);
}

export function* doUpdateIssueWithExternalPromise(
	action: ActionWithExternalPromise<UpdateIssueAction>, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	const {
		payload: { promise, ...payload },
		type,
	} = action;
	yield call(
		withExternalPromiseResolve,
		function* () {
			yield call(doUpdateIssue, { type, payload });
		},
		promise,
	);
}

export function* doOptimisticIssueDatesUpdate({
	payload: { promise, ...payload },
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
}: ActionWithExternalPromise<UpdateIssueDatesAction>): Generator<Effect, any, any> {
	const issue: Issue = (yield select(getIssueMapById))[payload.id];
	let updatedValues;
	if (R.has('baselineStart', payload) || R.has('baselineEnd', payload)) {
		updatedValues = yield call(doMapBaselineDatesToConfiguredDates, payload);
	}

	const originalProperties = getOriginalProperties(issue, updatedValues);

	if (!originalProperties) {
		return;
	}
	yield put(update(updatedValues));
	yield call(doOptimisticIssueUpdate, {
		payload: updatedValues,
		issue,
		originalProperties,
	});
}

export function* doUpdateMultipleIssues({
	payload, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: UpdateMultipleIssuesAction): Generator<Effect, any, any> {
	for (const issueId of Object.keys(payload)) {
		yield call(doUpdateIssue, {
			type: constants.UPDATE_ISSUE,
			payload: { ...payload[issueId], id: issueId },
		});
	}
}

export function* doBulkIssuesUpdate({
	payload: { issueIds, ...payload }, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: BulkUpdateIssueAction): Generator<Effect, any, any> {
	const itemKeys: string[] = [];
	let updatedValues = payload;

	if (R.has('baselineStart', payload) || R.has('baselineEnd', payload)) {
		updatedValues = yield call(doMapBaselineDatesToConfiguredDates, payload);
	}

	if (R.has('estimate', payload)) {
		const { planningUnit } = yield select(getPlan);
		const workingHours = yield select(getJiraHoursPerDay);
		const propName = planningUnit === PlanningUnits.storyPoints ? 'storyPoints' : 'timeEstimate';
		const estimate = payload.clearEstimates
			? null
			: convertEstimate(planningUnit, payload.estimate, workingHours);
		updatedValues = R.pipe(R.dissoc('estimate'), R.assoc(propName, estimate))(updatedValues);
	}
	yield* batch(function* () {
		const issueMapById: IssueMap = yield select(getIssueMapById);
		const selectedIssues: Issue[] =
			issueIds && issueIds.length
				? issueIds.map((issueId: string) => issueMapById[issueId]).filter(Boolean)
				: yield select(getSelectedIssues);

		for (const issue of selectedIssues) {
			const originalProperties = getOriginalProperties(issue, {
				...updatedValues,
				id: issue.id,
			});
			if (originalProperties) {
				itemKeys.push(issue.id);
				yield call(doOptimisticIssueUpdate, {
					payload: updatedValues,
					issue,
					originalProperties,
				});
			}
		}

		if (itemKeys.length) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const bulkUpdatePayload = selectedIssues.reduce<Record<string, any>>(
				(acc, issue) =>
					Object.assign(acc, {
						[issue.id]: {
							...updatedValues,
						},
					}),
				{},
			);

			if (R.has('labels', updatedValues)) {
				yield put(bulkLabelUpdate(bulkUpdatePayload));
			} else {
				yield put(bulkUpdate(bulkUpdatePayload));
			}

			if (R.has('parent', updatedValues)) {
				yield put(toggleSelectedIssues({ isSelected: false, toggleAll: true, ids: [] }));
			}

			const teamUpdated = R.has('team', updatedValues);
			const sprintUpdated = R.has('sprint', updatedValues);
			if (teamUpdated || sprintUpdated) {
				yield all(
					itemKeys.map((itemKey) =>
						put(
							refreshSubtask({
								issueId: itemKey,
								fields: { team: teamUpdated, sprint: sprintUpdated },
							}),
						),
					),
				);
			}
		}
	});
	if (itemKeys.length) {
		const body = updateIssueBody(yield select(getPlan), updatedValues, itemKeys);
		yield call(doUpdateIssueRequest, body);
	}
}

export function* doBulkIssuesUpdateWithExternalPromise(
	action: ActionWithExternalPromise<BulkUpdateIssueAction>, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	const {
		payload: { promise, ...payload },
		type,
	} = action;
	yield call(
		withExternalPromiseResolve,
		function* () {
			yield call(doBulkIssuesUpdate, { type, payload });
		},
		promise,
	);
}

export function* doBulkIssuesCPRUpdate({
	cprId, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: BulkUpdateIssueCPRAction): Generator<Effect, any, any> {
	const projectsById: Record<string | number, Project> = yield select(getProjectsById);
	const selectedIssuesByProject: Record<string, Issue[]> = yield select(
		getSelectedIssuesByProjectId,
	);
	const crossProjectVersionsById = yield select(getCrossProjectVersionsById);
	const versionsInCPR = crossProjectVersionsById[cprId].versions;
	const releaseToSelectedIssuesMap = Object.keys(selectedIssuesByProject).reduce<
		Map<string | undefined, Issue[]>
	>((data, projectId) => {
		const releaseInCPR = projectsById[projectId].versions.find((projectVersion) =>
			versionsInCPR.includes(projectVersion),
		);
		return data.set(releaseInCPR, selectedIssuesByProject[projectId]);
	}, new Map());

	for (const [versionId, selectedIssues] of releaseToSelectedIssuesMap) {
		const itemKeys: string[] = [];
		const updatedValue = { fixVersions: [versionId] };

		yield* batch(function* () {
			for (const issue of selectedIssues) {
				const originalProperties = getOriginalProperties(issue, {
					...updatedValue,
					id: issue.id,
				});
				if (originalProperties) {
					itemKeys.push(issue.id);
					yield call(doOptimisticIssueUpdate, {
						payload: updatedValue,
						issue,
						originalProperties,
					});
				}
			}

			if (itemKeys.length) {
				const bulkUpdatePayload = selectedIssues.reduce<Record<string, typeof updatedValue>>(
					(acc, issue) =>
						Object.assign(acc, {
							[issue.id]: updatedValue,
						}),
					{},
				);
				yield put(bulkUpdate(bulkUpdatePayload));
			}
		});
		if (itemKeys.length) {
			const body = updateIssueBody(yield select(getPlan), updatedValue, itemKeys);
			yield call(doUpdateIssueRequest, body);
		}
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doRankRequest(body: RankRequest): Generator<Effect, any, any> {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const response: any = yield* http.jsonOrError({
		url: urls.rank,
		body,
		method: 'POST',
	});
	if (!response.ok) return;
	const {
		change: { sequence },
		failed,
		failuresById = {},
	}: RankRequestResponse = response.data;
	if (failed > 0) {
		if (!fg('improve_redux_saga_error_reporting_plans_batch_2')) {
			yield put(deprecatedGenericError({ message: 'Failed to rank some issues' }));
		} else {
			const error = new Error('Failed to rank some issues');
			const isOnlyAnchorNotFound = Object.values(failuresById).every(
				(reason) => reason === 'ANCHOR_NOT_FOUND',
			);
			fireErrorAnalytics({
				error,
				meta: {
					id: toErrorID(error, 'rank-request-failed'),
					packageName: PACKAGE_NAME,
					teamName: ERROR_REPORTING_TEAM,
				},
				sendToPrivacyUnsafeSplunk: true,
				skipSentry: isOnlyAnchorNotFound,
			});
		}
		return;
	}
	yield put(updateSequence(sequence));
}

// TODO revert ranking on error, see JPOS-1354
export function* doRankIssue({
	payload, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: RankIssueAction): Generator<Effect, any, any> {
	yield* batch(function* () {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const { id, lexoRank } = ((yield select(getIssueMapById)) as IssueMap)[payload.itemKeys[0]];
		yield put(updateOriginalIssue({ id, lexoRank }));
		yield put(rank(payload));
		yield put(adjustLexoRank(payload));
	});

	const body = rankIssueBody(yield select(getPlan), payload);
	yield call(doRankRequest, body);
}

export function* doRankIssueWithExternalPromise(
	action: ActionWithExternalPromise<RankIssueAction>, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	const {
		payload: { promise, ...payload },
		type,
	} = action;
	yield call(
		withExternalPromiseResolve,
		function* () {
			yield call(doRankIssue, { type, payload });
		},
		promise,
	);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doBulkIssueRank({ payload }: BulkRankIssueAction): Generator<Effect, any, any> {
	const issues: Issue[] = yield select(getAllSortedIssues);
	const issueIndexById = issues.reduce<Record<string, number>>((acc, { id }, index) => {
		acc[id] = index;
		return acc;
	}, {});
	const selectedIssues: Issue[] = R.sortWith(
		[(a: Issue, b: Issue) => issueIndexById[a.id] - issueIndexById[b.id]],
		yield select(getSelectedIssues),
	);
	const bulkPayload = getBulkRankPayload(
		payload,
		selectedIssues.map(({ id }) => id),
		issues,
	);
	if (!bulkPayload) return;
	yield* batch(function* () {
		for (const { id, lexoRank } of selectedIssues) {
			yield put(updateOriginalIssue({ id, lexoRank }));
		}
		yield put(bulkRank(bulkPayload));
		yield put(bulkAdjustLexoRank(bulkPayload));
	});
	const body = rankIssueBody(yield select(getPlan), bulkPayload);
	yield call(doRankRequest, body);
}

export const moveIssueLogic = (
	issues: Issue[],
	descendantsMap: {
		[key: string]: string[];
	},
	isExpanded: {
		[key: string]: boolean;
	},
	payload: MoveIssuePayload,
	hierarchy: HierarchyRangeFilter,
) => {
	const { anchor, dragAndDrop, group, id, parentId, relation } = payload;

	const movedIssue = issues.find((issue) => issue.id === id);
	if (!movedIssue) {
		throw new Error('Issue is not found. Have you dropped it into the Trash?');
	}

	const actions = [];
	const parentIssue = issues.find((issue) => issue.id === parentId);

	if (parentIssue) {
		const issueMap = createIssueMapByIdPure(issues);

		const parentAncestors = getAncestors(parentIssue, issueMap, Infinity);

		for (const descendantIssueId of descendantsMap[id] || []) {
			// check if descendant issues are in ancestor issues
			if (
				parentAncestors.find((ancestorIssue) => descendantIssueId === ancestorIssue.id) ||
				descendantIssueId === parentId
			) {
				// prevent infinite loop
				actions.push(updateIssueRaw({ id: descendantIssueId, parent: undefined }));
				actions.push(
					updateParent({
						id: descendantIssueId,
						parent: undefined,
						isDragAndDrop: dragAndDrop,
					}),
				);
				break;
			}
		}
	}

	if (!anchor) {
		// issue was moved to the empty group
		if (movedIssue.parent) {
			actions.push(updateIssueRaw({ id, parent: undefined }));
			actions.push(updateParent({ id, parent: undefined, isDragAndDrop: dragAndDrop }));
		}
		return actions;
	}

	if (parentId !== movedIssue.parent && hierarchy.value.start !== movedIssue.level) {
		actions.push(updateIssueRaw({ id, parent: parentId }));
		actions.push(updateParent({ id, parent: parentId, isDragAndDrop: dragAndDrop }));
	}

	if (parentId) {
		const parentIdKey = group ? `${parentId}:${group}` : parentId;
		if (!isExpanded[parentIdKey]) {
			actions.push(
				setIsExpanded({
					[`${parentIdKey}`]: true,
				}),
			);

			// if the new parent is collapsed we want to move issue to the bottom of its children list
			const children = issues.filter(({ parent }) => parent === parentId);
			const lastChild = R.last(children);

			if (lastChild) {
				actions.push(rankIssue({ itemKeys: [id], anchor: lastChild.id, relation: 'AFTER' }));
				return actions;
			}
		}
	}

	if (dragAndDrop) {
		actions.push(rankIssue({ itemKeys: [id], anchor, relation, dragAndDrop }));
	} else {
		actions.push(rankIssue({ itemKeys: [id], anchor, relation }));
	}
	return actions;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doMoveIssue({ payload }: MoveIssueAction): Generator<Effect, any, any> {
	const issues = yield select(getSortedIssues);
	const descendantsMap = yield select(getDescendantIdsByParent);
	const isExpanded = yield select(getIsExpanded);
	const hierarchy = yield select(getHierarchyRangeFilter);

	const actions: ReturnType<typeof moveIssueLogic> = yield call(
		moveIssueLogic,
		issues,
		descendantsMap,
		isExpanded,
		payload,
		hierarchy,
	);

	yield all(actions.map((action) => put(action)));
}

export function* doUpdateOriginalIssue({
	payload, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: UpdateOriginalIssueAction): Generator<Effect, any, any> {
	let updatedValues = payload;

	if (R.has('baselineStart', payload) || R.has('baselineEnd', payload)) {
		updatedValues = yield call(doMapBaselineDatesToConfiguredDates, payload);
	}

	yield put(updateOriginalIssueValues(updatedValues));
	yield put(incrementSequence(['issues']));
}

function* doCommitRequest(
	issueId: string,
	notifyWatchers?: boolean,
	generatedItemId?: string, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, CommitResponseAction | FailCommitRequestAction, any> {
	const {
		domain: {
			plan: { id: planId, currentScenarioId },
			sequence,
		},
	}: State = yield select(R.identity);

	const body = commitBody(
		{ id: planId, currentScenarioId },
		sequence,
		issueId,
		generatedItemId,
		notifyWatchers,
	);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const response: any = yield* http.json({
		url: urls.commitChanges,
		profile: urls.commitChanges,
		method: POST,
		body,
	});
	if (response.ok) {
		const {
			// eslint-disable-next-line @typescript-eslint/no-shadow
			change: { sequence },
			error,
			entity = {},
			id,
		} = response.data;

		yield put(updateSequence(sequence));

		if (isHandleCommitResponseMethod(handleCommitResponse)) {
			const finalAction = handleCommitResponse({
				change: { sequence },
				error,
				entity,
				id,
			});

			yield put(finalAction);

			return finalAction;
		}
	}

	return { type: constants.FAIL_COMMIT_REQUEST, payload: response };
}
// show status change screen
function* doShowChangeStatusScreen(
	issueId: string,
	issueKeyInJira?: string,
	transitionId?: string, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	if (isDefined(issueKeyInJira) && isDefined(transitionId)) {
		yield put(loadChangeStatusScreen({ issueKeyInJira, transitionId, issueId }));
		// listen to the result of the status transition dialog, success/cancel/fail to update status
		const changeStatusResult = yield take([
			SUCCESS_CHANGE_STATUS,
			CANCEL_CHANGE_STATUS,
			FAIL_CHANGE_STATUS,
		]);

		if (changeStatusResult.type === SUCCESS_CHANGE_STATUS) {
			// Transition screen dialog directly communicate with Jira, so when user submit it,
			// the status has changed in Jira, then we should reset the status scenario changed in the ARJ
			const { id: planId, currentScenarioId: scenarioId } = yield select(getPlan);
			const body = {
				itemKeys: [issueId],
				planId,
				scenarioId,
				description: { status: { reset: true }, statusTransition: { reset: true } }, // hardly reset the issue status in the BE
			};
			yield call(doResetIssueRequest, body);
		} else if (changeStatusResult.type === CANCEL_CHANGE_STATUS) {
			yield put(interruptCommit());
		} else if (changeStatusResult.type === FAIL_CHANGE_STATUS) {
			yield put(interruptCommit());
			if (!fg('improve_redux_saga_error_reporting_plans_batch_2')) {
				yield put(deprecatedGenericError({ message: 'Jira failed to transition the status' }));
			} else {
				const error = new Error('Jira failed to transition the status');
				fireErrorAnalytics({
					error,
					meta: {
						id: toErrorID(error, 'show-change-status-screen-failed'),
						packageName: PACKAGE_NAME,
						teamName: ERROR_REPORTING_TEAM,
					},
					sendToPrivacyUnsafeSplunk: true,
				});
			}
		}
		return changeStatusResult;
	}
}

function* doUpdateIssueKey(
	issueId: string,
	generatedItemId?: string, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, CommitResponseAction | FailCommitRequestAction, any> {
	const {
		domain: {
			plan: { id: planId, currentScenarioId },
		},
	}: State = yield select(R.identity);

	const id = generatedItemId ? parseInt(generatedItemId, 10) : null;

	if (id == null || Number.isNaN(id)) {
		// We just return 400 here to save a call
		// The api types allow it but cleary this is invalid and the response will break anyway
		return {
			type: constants.FAIL_COMMIT_REQUEST,
			payload: { ok: false, status: 400, error: 'MANUAL_CREATE_ID_INVALID' },
		};
	}

	const response = yield* http.json<UpdateIssueKeyResponse>({
		url: urls.updateIssueKey,
		method: POST,
		body: {
			planId,
			scenarioId: currentScenarioId,
			itemKey: issueId,
			generatedItemId,
		},
	});

	if (response.ok && response.data.success) {
		const { change } = response.data;

		yield put(updateSequence(change.sequence));

		if (isHandleCommitResponseMethod(handleCommitResponse)) {
			const finalAction = handleCommitResponse({
				change,
				entity: {},
				id,
			});

			yield put(finalAction);

			return finalAction;
		}
	}

	return {
		type: constants.FAIL_COMMIT_REQUEST,
		payload: response,
	};
}

function* doManualCreate(
	issueId: string,
	loadError?: LoadError,
	notifyWatchers?: boolean, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	yield put(loadRequiredFields({ issueId, error: loadError }));

	const manualCreateResult = yield take([
		SUBMIT_REQUIRED_FIELDS,
		CANCEL_REQUIRED_FIELDS,
		FAIL_REQUIRED_FIELDS,
	]);

	const analyticsEvent: UIAnalyticsEvent = yield select(getAnalyticsEvent);

	if (manualCreateResult.type === SUBMIT_REQUIRED_FIELDS) {
		const { generatedItemId } = manualCreateResult.payload;

		// isArjGicPrefilledDataHandlingEnabled clean up , this should become a constant
		let commitRetryResponse;

		if (!!generatedItemId && generatedItemId !== issueId) {
			yield put(domainActions.updateIssueReferences({ oldId: issueId, newId: generatedItemId }));
		}

		if (isArjGicPrefilledDataHandlingEnabled()) {
			commitRetryResponse = yield call(doUpdateIssueKey, issueId, generatedItemId);
		} else {
			commitRetryResponse = yield call(doCommitRequest, issueId, notifyWatchers, generatedItemId);
		}

		if (commitRetryResponse.type === constants.HANDLE_COMMIT_RESPONSE) {
			const { error, entity } = commitRetryResponse.payload;
			const internalEntity = entity && entity.entity;
			const warnings = yield call(inspectForCommitWarnings, internalEntity, error);

			if (warnings.length) {
				fireOperationalAnalytics(
					analyticsEvent,
					'advancedRoadmaps issueCommitManualCreateFailedWithWarnings',
					{ error: loadError?.error },
				);

				yield put(
					addCommitWarning({
						category: ENTITY.ISSUE,
						itemId: issueId,
						warnings,
					}),
				);
			} else {
				fireOperationalAnalytics(
					analyticsEvent,
					'advancedRoadmaps issueCommitManualCreateSuccess',
					{ error: loadError?.error },
				);
			}
		} else if (commitRetryResponse.type === constants.FAIL_COMMIT_REQUEST) {
			yield put(
				addCommitWarning({
					category: ENTITY.ISSUE,
					itemId: issueId,
					warnings: [defaultWarning],
				}),
			);
		}
	} else if (manualCreateResult.type === CANCEL_REQUIRED_FIELDS) {
		fireOperationalAnalytics(analyticsEvent, 'advancedRoadmaps issueCommitManualCreateCancelled', {
			error: loadError?.error,
		});

		yield put(interruptCommit());
	} else if (manualCreateResult.type === FAIL_REQUIRED_FIELDS) {
		fireOperationalAnalytics(analyticsEvent, 'advancedRoadmaps issueCommitManualCreateFailed', {
			error: loadError?.error,
		});

		yield put(interruptCommit());

		const message = yield call(
			!isHandleCommitResponseMethod(handleCommitResponse) &&
				handleCommitResponse.text.bind(handleCommitResponse),
		);
		if (!fg('improve_redux_saga_error_reporting_plans_batch_2')) {
			const finalAction = deprecatedGenericError({ message });
			yield put(finalAction);
			return finalAction;
		}
		const error = new Error(message);
		fireErrorAnalytics({
			error,
			meta: {
				id: toErrorID(error, 'manual-create-issue-failed'),
				packageName: PACKAGE_NAME,
				teamName: ERROR_REPORTING_TEAM,
			},
			sendToPrivacyUnsafeSplunk: true,
		});
		return error;
	}

	return manualCreateResult;
}

// the returned boolean indicates whether a manual input of required fields occurred
export function* handleIssueCommitResponse(
	entityResponse: BulkCommitResponseEntity,
	notifyWatchers?: boolean, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, boolean, any> {
	const issueId = entityResponse.itemKey;

	const internalEntity = entityResponse.entity?.entity;
	const error = entityResponse.error;
	const warnings = yield call(inspectForCommitWarnings, internalEntity, error);

	// when user change the status, we need to check if we need to display the transition screen
	const isTransitionStatusDialog =
		error?.error === RESPONSE_ERROR_CODE.REQUIRE_STATUS_TRANSITION_SCREEN;
	if (isTransitionStatusDialog) {
		const transitionScreenId = error?.errorMessages && error?.errorMessages[0];
		const projectsById = yield select(getProjectsById);
		const issueById = yield select(getIssueMapById);
		const issue = issueById[issueId];

		const projectKeyOfIssue =
			issue.project && projectsById[issue.project] && projectsById[issue.project].key;
		// combine `${projectKey}-${issueKey}` which will be used in commitIssueChange.
		const issueKeyInJira =
			issue.issueKey && projectKeyOfIssue ? `${projectKeyOfIssue}-${issue.issueKey}` : undefined;
		const changeStatusResult = yield call(
			doShowChangeStatusScreen,
			issueId,
			issueKeyInJira,
			transitionScreenId,
		);
		if (changeStatusResult.type === SUCCESS_CHANGE_STATUS) {
			return true;
		}
		if (changeStatusResult.type === CANCEL_CHANGE_STATUS) {
			yield put(
				addCommitWarning({
					category: ENTITY.ISSUE,
					itemId: issueId,
					warnings,
				}),
			);
		}
		return false;
	}

	const analyticsEvent: UIAnalyticsEvent = yield select(getAnalyticsEvent);

	const isManualCreateRequired =
		isScenarioIssue(issueId) &&
		(() => {
			switch (String(error?.error)) {
				case 'REQUIRED_FIELDS_MISSING':
				case 'WORKFLOW_VALIDATION_FAILURE': {
					fireOperationalAnalytics(
						analyticsEvent,
						'advancedRoadmaps issueCommitManualCreateRequired',
						{ error: error?.error },
					);
					return true;
				}
				default:
					return false;
			}
		})();

	// this means the issue could not be created since there are required fields that have not been set,
	// we let the user enter them in a pop up dialogue
	if (isManualCreateRequired) {
		const manualCreateResult = yield call(doManualCreate, issueId, error, notifyWatchers);

		if (manualCreateResult.type === SUBMIT_REQUIRED_FIELDS) {
			return true;
		}
		if (manualCreateResult.type === CANCEL_REQUIRED_FIELDS) {
			yield put(
				addCommitWarning({
					category: ENTITY.ISSUE,
					itemId: issueId,
					warnings,
				}),
			);
		}
	} else if (warnings.length) {
		yield put(
			addCommitWarning({
				category: ENTITY.ISSUE,
				itemId: issueId,
				warnings,
			}),
		);
	}
	return false;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* revertChange(id: string): Generator<Effect, http.JsonResponse<any>, any> {
	const {
		domain: {
			plan: { id: planId, currentScenarioId },
			sequence,
		},
	}: State = yield select(R.identity);
	const body = revertBody({ id: planId, currentScenarioId }, sequence, id);
	const response = yield* http.json({ url: urls.revertIssues, method: POST, body });
	if (response.ok) {
		const {
			// eslint-disable-next-line @typescript-eslint/no-shadow
			change: { sequence },
		} = response.data;
		yield put(updateSequence(sequence));
	}
	return response;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* getHiddenIssues(ids: string[]): Generator<Effect, any, any> {
	const jiraIds = ids
		.map((id: string) => Number.parseInt(id, 10))
		.filter((id: number) => !Number.isNaN(id));
	if (jiraIds.length === 0) {
		return;
	}
	const {
		domain: {
			plan: { id, currentScenarioId },
		},
	}: State = yield select(R.identity);
	const body = hiddenIssuesBody({ id, currentScenarioId }, jiraIds);
	const response = yield call(fetch, urls.getHiddenIssues, {
		method: POST,
		body,
	});
	if (response.ok) {
		const { issues } = yield call(response.json.bind(response));
		if (issues.length > 0) {
			yield put(resetHiddenIssues(issues));
			yield put({ type: OPEN_HIDDEN_ISSUES_DIALOG });
		}
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* getHistoricalDoneIssues(): Generator<Effect, any, any> {
	const {
		domain: {
			plan: { id, currentScenarioId },
		},
	}: State = yield select(R.identity);

	// Cancel if there is no plan Id, or scenario Id, this can happen when the chain of sagas are still running but the app has already navigated away from the page
	if (id == null || !currentScenarioId) return;

	const url = `${urls.getDoneHistory}?planId=${id}&scenarioId=${currentScenarioId}&includeCompletedSince=0`;

	yield put(setIsLoadingHistory(true));
	const response = yield call(fetch, url, {
		method: GET,
		profile: urls.getDoneHistory,
	});
	yield put(setIsLoadingHistory(false));
	if (response.ok) {
		const historyIssues = yield call(response.json.bind(response));
		yield put(resetHistoryIssues(historyIssues.issues));
	}
}

export function* doRemoveSingleIssue({
	payload: { issueId },
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
}: RemoveSingleIssueAction): Generator<Effect, void, any> {
	const { id, currentScenarioId } = yield select(getPlan);

	if (!id) {
		throw new Error('Plan id is not available');
	}

	if (!currentScenarioId) {
		throw new Error('Scenario id is not available');
	}

	const issues: Issue[] = yield select(getIssues);
	// eslint-disable-next-line @typescript-eslint/no-shadow -- PLEASE FIX - ENABLING FLAT LINT CONFIG
	const [selectedIssues, remainingIssues] = partition(issues, ({ id }) => issueId === id);

	yield put(reset(remainingIssues));
	yield put(scenarioRemovedIssuesActions.add({ ...selectedIssues?.[0], excluded: true }));

	const body: RemoveIssuesPayload = {
		itemKeys: [issueId],
		planId: id,
		scenarioId: currentScenarioId,
	};
	const response = yield call(fetch, urls.removeIssues, {
		method: POST,
		body,
	});

	if (response.ok) {
		const {
			change: { sequence },
		} = yield call(response.json.bind(response));
		const scenarioIssues: Array<string> = [];
		if (isScenarioIssue(issueId)) {
			scenarioIssues.push(issueId);
		} else {
			yield put(updateOriginalIssueValues({ id: issueId, excluded: false }));
		}
		if (scenarioIssues.length) {
			yield put(removeOriginalIssues(scenarioIssues));
		}
		yield put(updateSequence(sequence));
		yield put(calculateWarnings());
	} else {
		yield put(reset(issues));
		yield put(scenarioRemovedIssuesActions.remove(issueId));

		const message = yield call(response.text.bind(response));
		if (!fg('improve_redux_saga_error_reporting_plans_batch_2')) {
			yield put(deprecatedGenericError({ message }));
		} else {
			const error = new Error(message);
			fireErrorAnalytics({
				error,
				meta: {
					id: toErrorID(error, 'remove-single-issue-failed'),
					packageName: PACKAGE_NAME,
					teamName: ERROR_REPORTING_TEAM,
				},
				sendToPrivacyUnsafeSplunk: true,
			});
		}
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doRemoveSelectedIssues(): Generator<Effect, any, any> {
	const { id, currentScenarioId } = yield select(getPlan);

	if (!id) {
		throw new Error('Plan id is not available');
	}

	if (!currentScenarioId) {
		throw new Error('Scenario id is not available');
	}

	const selectedIssuesIds = yield select(getSelected);
	const issues: Issue[] = yield select(getIssues);
	const [selectedIssues, remainingIssues] = R.partition(
		// eslint-disable-next-line @typescript-eslint/no-shadow
		({ id }) => selectedIssuesIds.has(id),
		issues,
	);

	yield put(reset(remainingIssues));
	yield put(
		scenarioRemovedIssuesActions.bulkAdd(
			selectedIssues.map((issue) => ({ ...issue, excluded: true })),
		),
	);

	const body: RemoveIssuesPayload = {
		itemKeys: Array.from(selectedIssuesIds),
		planId: id,
		scenarioId: currentScenarioId,
	};
	const response = yield call(fetch, urls.removeIssues, {
		method: POST,
		body,
	});

	if (response.ok) {
		const {
			change: { sequence },
		} = yield call(response.json.bind(response));
		const scenarioIssues: Array<string> = [];
		for (const issueId of selectedIssuesIds) {
			if (isScenarioIssue(issueId)) {
				scenarioIssues.push(issueId);
			} else {
				yield put(updateOriginalIssueValues({ id: issueId, excluded: false }));
			}
		}
		if (scenarioIssues.length) {
			yield put(removeOriginalIssues(scenarioIssues));
		}
		yield put(toggleSelectedIssues({ isSelected: false, toggleAll: true, ids: [] }));
		yield put(updateSequence(sequence));
		yield put(calculateWarnings());
	} else {
		yield put(reset(issues));
		yield put(scenarioRemovedIssuesActions.bulkRemove(selectedIssuesIds));

		const message = yield call(response.text.bind(response));
		if (!fg('improve_redux_saga_error_reporting_plans_batch_2')) {
			yield put(deprecatedGenericError({ message }));
		} else {
			const error = new Error(message);
			fireErrorAnalytics({
				error,
				meta: {
					id: toErrorID(error, 'remove-selected-issues-failed'),
					packageName: PACKAGE_NAME,
					teamName: ERROR_REPORTING_TEAM,
				},
				sendToPrivacyUnsafeSplunk: true,
			});
		}
	}
}

export function* doDeleteSingleIssue({
	payload: { issueId },
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
}: DeleteSingleIssueAction): Generator<Effect, void, any> {
	const { id, currentScenarioId } = yield select(getPlan);

	if (!id) {
		throw new Error('Plan id is not available');
	}

	if (!currentScenarioId) {
		throw new Error('Scenario id is not available');
	}

	const issues: Issue[] = yield select(getIssues);
	// eslint-disable-next-line @typescript-eslint/no-shadow -- PLEASE FIX - ENABLING FLAT LINT CONFIG
	const [selectedIssues, remainingIssues] = partition(issues, ({ id }) => issueId === id);
	yield put(reset(remainingIssues));
	yield put(scenarioRemovedIssuesActions.add(selectedIssues?.[0]));

	const body: DeleteIssuesPayload = {
		itemKeys: [issueId],
		planId: id,
		scenarioId: currentScenarioId,
	};
	const response = yield call(fetch, urls.deleteIssues, {
		method: POST,
		body,
	});

	if (response.ok) {
		const {
			change: { sequence },
		} = yield call(response.json.bind(response));
		const scenarioIssues: Array<string> = [];
		if (isScenarioIssue(issueId)) {
			scenarioIssues.push(issueId);
		} else {
			yield put(updateOriginalIssueValues({ id: issueId }));
		}
		if (scenarioIssues.length) {
			yield put(removeOriginalIssues(scenarioIssues));
		}
		yield put(updateSequence(sequence));
		yield put(calculateWarnings());
	} else {
		yield put(reset(issues));
		yield put(scenarioRemovedIssuesActions.remove(issueId));

		const message = yield call(response.text.bind(response));
		if (!fg('improve_redux_saga_error_reporting_plans_batch_2')) {
			yield put(deprecatedGenericError({ message }));
		} else {
			const error = new Error(message);
			fireErrorAnalytics({
				error,
				meta: {
					id: toErrorID(error, 'delete-single-issue-failed'),
					packageName: PACKAGE_NAME,
					teamName: ERROR_REPORTING_TEAM,
				},
				sendToPrivacyUnsafeSplunk: true,
			});
		}
	}
}

export function* fetchIssueDescription({
	payload, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: SetIssueBeingEditedAction): Generator<Effect, any, any> {
	if (payload === null) {
		return;
	}

	// we show a spinner on the issue description field while we wait for the backend response
	yield put(setLoadingIssueDescription(true));

	const {
		domain: {
			plan: { id, currentScenarioId },
		},
	}: State = yield select(R.identity);

	const body = getDescription({ id, currentScenarioId }, payload);
	const response = yield call(fetch, urls.getDescription, {
		method: POST,
		body,
	});
	if (response.ok) {
		/**
		 * The possible values of issue.description returned by the backend are:
		 * - undefined == if the issue does not have any description
		 * - 'ERROR' == if the request failed
		 * - any other string == the issue has a description which we assume is a valid ADF
		 */
		const description = yield call(response.json.bind(response));

		yield put(
			update({
				id: payload,
				description: description.value,
			}),
		);
	} else {
		if (!fg('improve_redux_saga_error_reporting_plans_batch_2')) {
			yield put(
				deprecatedGenericError({
					...parseError(response, yield call(response.text.bind(response))),
					requestInfo: {
						url: urls.getDescription,
						type: POST,
						status: response.status,
						body,
					},
				}),
			);
		} else {
			const error = new Error(yield call(response.text.bind(response)));
			fireErrorAnalytics({
				error,
				meta: {
					id: toErrorID(error, 'fetch-issue-description-failed'),
					packageName: PACKAGE_NAME,
					teamName: ERROR_REPORTING_TEAM,
				},
				sendToPrivacyUnsafeSplunk: true,
			});
		}
		yield put(
			update({
				id: payload,
				description: DESCRIPTION_FETCH_ERROR,
			}),
		);
	}

	// once the issue description is fetched from the backend, we stop showing the spinner on the description field
	yield put(setLoadingIssueDescription(false));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchAddIssue(): Generator<Effect, any, any> {
	yield takeEvery(constants.ADD_ISSUE, doAddIssueWithExternalPromise);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchUpdateIssueDatesOptimistic(): Generator<Effect, any, any> {
	yield takeEvery(constants.UPDATE_ISSUE_DATES_OPTIMISTIC, doOptimisticIssueDatesUpdate);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchUpdateIssue(): Generator<Effect, any, any> {
	yield takeEvery(constants.UPDATE_ISSUE, doUpdateIssueWithExternalPromise);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchUpdateMultipleIssues(): Generator<Effect, any, any> {
	yield takeEvery(constants.UPDATE_MULTIPLE_ISSUES, doUpdateMultipleIssues);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchBulkIssueUpdate(): Generator<Effect, any, any> {
	yield takeEvery(constants.BULK_ISSUES_UPDATE, doBulkIssuesUpdateWithExternalPromise);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchBulkIssueCPRUpdate(): Generator<Effect, any, any> {
	yield takeEvery(constants.BULK_ISSUES_CPR_UPDATE, doBulkIssuesCPRUpdate);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchBulkIssueRank(): Generator<Effect, any, any> {
	yield takeEvery(constants.BULK_RANK_ISSUES, doBulkIssueRank);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchRankIssue(): Generator<Effect, any, any> {
	yield takeEvery(constants.RANK_ISSUE, doRankIssueWithExternalPromise);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchMoveIssue(): Generator<Effect, any, any> {
	yield takeEvery(constants.MOVE_ISSUE, doMoveIssue);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchUpdateOriginalIssue(): Generator<Effect, any, any> {
	yield takeEvery(constants.UPDATE_ORIGINAL_ISSUE, doUpdateOriginalIssue);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchFetchHistoricalDoneIssues(): Generator<Effect, any, any> {
	yield takeEvery(constants.FETCH_HISTORICAL_DONE_ISSUES, getHistoricalDoneIssues);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchRemoveSingleIssue(): Generator<Effect, any, any> {
	yield takeEvery(constants.REMOVE_SINGLE_ISSUE, doRemoveSingleIssue);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchRemoveSelectedIssues(): Generator<Effect, any, any> {
	yield takeEvery(constants.REMOVE_SELECTED_ISSUES, doRemoveSelectedIssues);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchDeleteSingleIssue(): Generator<Effect, any, any> {
	yield takeEvery(constants.DELETE_SINGLE_ISSUE, doDeleteSingleIssue);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchUpdateOrInlineCreateIssue(): Generator<Effect, any, any> {
	yield takeEvery(UPDATE_INLINE_CREATE_ISSUE, doUpdateIssueOrInlineCreate);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchRefreshSubtask(): Generator<Effect, any, any> {
	yield takeEvery(constants.REFRESH_SUBTASK, doRefreshSubtask);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchFetchIssueDescription(): Generator<Effect, any, any> {
	yield takeEvery(SET_ISSUE_BEING_EDITED, fetchIssueDescription);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default function* (): Generator<Effect, any, any> {
	yield fork(watchAddIssue);
	yield fork(watchUpdateIssue);
	yield fork(watchUpdateIssueDatesOptimistic);
	yield fork(watchUpdateMultipleIssues);
	yield fork(watchBulkIssueUpdate);
	yield fork(watchBulkIssueCPRUpdate);
	yield fork(watchBulkIssueRank);
	yield fork(watchRankIssue);
	yield fork(watchMoveIssue);
	yield fork(watchUpdateOriginalIssue);
	yield fork(watchFetchHistoricalDoneIssues);
	yield fork(watchRemoveSingleIssue);
	yield fork(watchRemoveSelectedIssues);
	yield fork(watchDeleteSingleIssue);
	yield fork(watchUpdateOrInlineCreateIssue);
	yield fork(watchRefreshSubtask);
	yield fork(watchFetchIssueDescription);
}
