import type { Dispatch } from 'redux';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import {
	type AddActionPayload,
	addIssueLink,
	deleteIssueLink,
} from '../../command/issue-links/index.tsx';
import {
	updateIssueOrInlineCreate,
	rankIssue,
	bulkIssuesUpdate,
	addIssue,
	updateIssueDatesOptimistic,
} from '../../command/issue/index.tsx';
import type {
	AddIssuePayload,
	UpdateIssuePayload,
	RankIssuePayload,
	UpdateIssueDatesPayload,
} from '../../command/issue/types.tsx';
import {
	update as updatePlannedCapacity,
	remove as removePlannedCapacity,
} from '../../command/planned-capacities/index.tsx';
import { getIssueTypesById } from '../../query/issue-types/index.tsx';
import {
	resetIssueStatusAction,
	type ResetIssueStatusPayload,
} from '../../command/issue-status/index.tsx';
import type { RemoveActionPayload } from '../../state/domain/issue-links/actions.tsx';
import type { PlannedCapacity } from '../../state/domain/planned-capacities/types.tsx';
import type { State } from '../../state/types.tsx';
import type { StateProps, DispatchProps } from './types.tsx';
import View from './view.tsx';

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	updateIssue: (payload: UpdateIssuePayload) => {
		dispatch(updateIssueOrInlineCreate(payload));
	},
	rankIssue: (payload: RankIssuePayload) => {
		dispatch(rankIssue(payload));
	},
	bulkUpdateIssues: (payload: UpdateIssuePayload) => {
		dispatch(bulkIssuesUpdate(payload));
	},
	addIssue: (payload: AddIssuePayload) => {
		dispatch(addIssue(payload));
	},
	addIssueLink: (payload: AddActionPayload) => {
		dispatch(addIssueLink(payload));
	},
	removeIssueLink: (payload: RemoveActionPayload) => {
		dispatch(deleteIssueLink(payload));
	},
	updatePlannedCapacity: (payload: PlannedCapacity) => {
		dispatch(updatePlannedCapacity(payload));
	},
	removePlannedCapacity: (payload: PlannedCapacity) => {
		dispatch(removePlannedCapacity(payload));
	},
	updateIssueDatesOptimistic: (payload: UpdateIssueDatesPayload) => {
		dispatch(updateIssueDatesOptimistic(payload));
	},
	resetIssueStatus: (payload: ResetIssueStatusPayload) => {
		dispatch(resetIssueStatusAction(payload));
	},
});

const mapStateToProps = (state: State): StateProps => ({
	issueTypesById: getIssueTypesById(state),
});

const BridgeEventSubscriber = connect<StateProps, DispatchProps, {}, State>(
	mapStateToProps,
	mapDispatchToProps,
)(View);

export default BridgeEventSubscriber;
