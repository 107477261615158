import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	header: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-date.header',
		defaultMessage: 'Update dates',
		description: 'Modal dialog header for dates bulk update',
	},
	variousValues: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-date.various-values',
		defaultMessage: 'Various dates',
		description: 'Label for various date values',
	},
	datePlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-date.date-placeholder',
		defaultMessage: 'e.g. {date}',
		description: 'It is placeholder text for a date field.',
	},
	/* eslint-disable jira/i18n/id-named-by-package */
	KEEP_EXISTING: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.set-date-dialog.keep-existing',
		defaultMessage: 'Keep existing',
		description: 'By choosing this option, user wants keep the dates unchanged.',
	},
	CLEAR_DATE: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.set-date-dialog.clear-date',
		defaultMessage: 'Clear date',
		description:
			'By choosing this option, user wants clear the dates from all the selected issues.',
	},
	SET_BY_CHILD_DATES: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.set-date-dialog.set-by-child-dates',
		defaultMessage: 'Set by child issue dates',
		description: 'By choosing this option, user wants set the dates by using child issue dates',
	},
	SET_CUSTOM_DATE: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.set-date-dialog.set-custom-date',
		defaultMessage: 'Set custom date',
		description: 'By choosing this option, user wants set the custom date.',
	},
	SET_BY_CHILD_DATESIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.set-date-dialog.set-by-child-dates-issue-term-refresh',
		defaultMessage: 'Set by child work item dates',
		description: 'By choosing this option, user wants set the dates by using child issue dates',
	},
});
