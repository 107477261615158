import type { Dispatch } from 'redux';
import {
	clearAllComponentColours,
	clearAllIssueTypeColours,
	clearAllLabelColours,
	clearAllPriorityColours,
	clearAllSelectColours,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/colour-by/actions.tsx';
import { clearAll as clearAllComponentGroups } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/component-groups/actions.tsx';
import { clearAll as clearAllCustomFieldValuesGroups } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/custom-field-values-groups/actions.tsx';
import { clearAll as clearAllLabelGroups } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/label-groups/actions.tsx';
import {
	toggleShowSprints,
	toggleShowAggregate,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/visualisations/actions.tsx';
import type { Grouping } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import type { DispatchProps } from './types.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (dispatch: Dispatch<any>): DispatchProps => ({
	clearAllComponentColours: () => {
		dispatch(clearAllComponentColours());
	},
	clearAllComponentGroups: () => {
		dispatch(clearAllComponentGroups());
	},
	clearAllCustomFieldValuesGroups: (grouping: Grouping) => {
		dispatch(clearAllCustomFieldValuesGroups({ grouping }));
	},
	clearAllIssueTypeColours: () => {
		dispatch(clearAllIssueTypeColours());
	},
	clearAllLabelColours: () => {
		dispatch(clearAllLabelColours());
	},
	clearAllLabelGroups: () => {
		dispatch(clearAllLabelGroups());
	},
	clearAllPriorityColours: () => {
		dispatch(clearAllPriorityColours());
	},
	clearAllSelectColours: () => {
		dispatch(clearAllSelectColours());
	},
	toggleShowSprints: (showSprints) => {
		dispatch(toggleShowSprints({ showSprints }));
	},
	toggleShowAggregate: (showAggregate) => {
		dispatch(toggleShowAggregate({ showAggregate }));
	},
});
