import React from 'react';
import noop from 'lodash/noop';
import { Pressable, xcss } from '@atlaskit/primitives';
import { ModalEntryPointPressableTrigger } from '@atlassian/jira-modal-entry-point-pressable-trigger/src/ModalEntryPointPressableTrigger.tsx';

import { planIssueModal } from '@atlassian/jira-plan-issue-modal/entrypoint.tsx';
import {
	getPlanId,
	getScenarioId,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/util/urls.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import type { UpdateIssuePayload } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/issue/types.tsx';
import { issueModalTriggerCommand } from './command.tsx';
import type { DispatchProps, OwnProps, Props } from './types.tsx';

const IssueModalTrigger = ({ children, issueId, updateIssue, defaultSummary }: Props) => {
	return (
		<ModalEntryPointPressableTrigger
			entryPoint={planIssueModal}
			entryPointProps={{
				issueId,
				isReadOnly: false,
				planId: String(getPlanId()) || undefined,
				scenarioId: String(getScenarioId()) || undefined,
				showChangeIndicators: true,
				onClose: () => noop,
				onChange: (payload: UpdateIssuePayload) => {
					if (updateIssue) {
						const transformedPayload = {
							id: payload.id,
							[payload.fieldId]: payload.fieldValue,
						};
						updateIssue(transformedPayload);
					}
				},
				withModalWrapper: false,
				defaultSummary,
			}}
			interactionName="open-plan-issue-modal"
			useInternalModal
			aria-haspopup="dialog"
		>
			{({ ref }) => (
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				<Pressable xcss={triggerButtonStyles} ref={ref as React.Ref<HTMLButtonElement>}>
					{children}
				</Pressable>
			)}
		</ModalEntryPointPressableTrigger>
	);
};

export default connect<null, DispatchProps, OwnProps>(
	null,
	issueModalTriggerCommand,
)(IssueModalTrigger);

const triggerButtonStyles = xcss({
	paddingBlock: 'space.075',
	paddingInline: 'space.050',
	maxWidth: '100%',
	fontWeight: 'font.weight.medium',
	backgroundColor: 'color.background.neutral.subtle',
	color: 'color.text.subtle',
	':hover': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
	},
	':active': {
		backgroundColor: 'color.background.neutral.subtle.pressed',
	},
});
