import type { Effect } from 'redux-saga';
import difference from 'lodash/difference';
import partition from 'lodash/partition';
import groupBy from 'lodash/groupBy';
import { put, call, select, takeEvery, fork } from 'redux-saga/effects';
import { TownsquareGoalAri } from '@atlassian/ari/townsquare';
import { update as updateIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/actions.tsx';
import { revertFields } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/original-issues/actions.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { getLazyGoalsByARI } from '../../query/issue-goals/index.tsx';
import { getPlan } from '../../query/plan/index.tsx';
import { isAtlasConnectInstalled as getIsAtlasConnectInstalled } from '../../query/system/index.tsx';
import { addFlag } from '../../state/domain/flags/actions.tsx';
import { GOALS_ERROR } from '../../state/domain/flags/types.tsx';
import {
	fetchGoalsStart,
	fetchGoalsSuccess,
	fetchGoalsFail,
	searchGoalsStart,
	searchGoalsSuccess,
	searchGoalsFail,
} from '../../state/domain/issue-goals/actions.tsx';
import type {
	LazyGoal,
	LoadedLazyGoal,
	LazyGoalsByARI,
} from '../../state/domain/issue-goals/types.tsx';
import { getCloudId } from '../../util/cloud-id.tsx';
import { doResetIssueRequest } from '../issue/index.tsx';
import { fetchGoalsByARI, searchGoalsByName } from './api/index.tsx';
import type { FetchGoalsByARIResult, SearchGoalsByNameResult } from './api/types.tsx';
import { createLazyGoals } from './utils.tsx';

export const FETCH_GOALS = 'command.issue-goals.FETCH_GOALS' as const;
export const SEARCH_GOALS = 'command.issue-goals.SEARCH_GOALS' as const;
export const RESET_GOALS = 'command.issue-goals.RESET_GOALS' as const;

export type FetchGoalsCommand = {
	type: typeof FETCH_GOALS;
	payload: string[];
};

export type SearchGoalsCommand = {
	type: typeof SEARCH_GOALS;
	payload: string;
};

export type ResetGoalsPayload = {
	issueId: string;
	goals: string[];
};
export type ResetGoalsCommand = {
	type: typeof RESET_GOALS;
	payload: ResetGoalsPayload;
};
export const fetchGoals = (goalARIs: string[]): FetchGoalsCommand => ({
	type: FETCH_GOALS,
	payload: goalARIs,
});

export const searchGoals = (payload: string): SearchGoalsCommand => ({
	type: SEARCH_GOALS,
	payload,
});

export const resetGoals = (payload: ResetGoalsPayload): ResetGoalsCommand => ({
	type: RESET_GOALS,
	payload,
});

export function* doResetGoals({
	payload: { issueId, goals },
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
}: ResetGoalsCommand): Generator<Effect, any, any> {
	const { id: planId, currentScenarioId: scenarioId } = yield select(getPlan);
	const body = {
		itemKeys: [issueId],
		planId,
		scenarioId,
		description: { goals: { reset: true } }, // hardly reset the issue status in the BE
	};
	const resetStatusResponse = yield call(doResetIssueRequest, body);
	if (resetStatusResponse?.ok) {
		yield put(updateIssue({ id: issueId, goals }));
		yield put(
			revertFields({
				id: issueId,
				fields: ['goals'],
			}),
		);
	}
}

const asLoadedGoal = (ari: string, isExternal = false): LoadedLazyGoal => ({
	goal: {
		id: ari,
		key: '',
		name: '',
	},
	isLoading: false,
	isExternal,
});

export function* doFetchGoals({
	payload: goalARIs,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
}: FetchGoalsCommand): Generator<Effect, any, any> {
	if (fg('jpo_plan_timeline_cross_site_goals')) {
		const isAtlasConnectInstalled: boolean = yield select(getIsAtlasConnectInstalled);
		if (isAtlasConnectInstalled) {
			const existingLazyGoals: LazyGoalsByARI = yield select(getLazyGoalsByARI);
			const existingGoalARIs = Object.keys(existingLazyGoals);
			const newGoalARIs = difference(goalARIs, existingGoalARIs);
			const newGoalARIsGroupedByCloudId = groupBy(
				newGoalARIs,
				(ari) => TownsquareGoalAri.parse(ari).cloudId,
			);
			const allLazyGoalsByARI: LazyGoalsByARI = {};
			let isAnyError = false;
			log.safeInfoWithoutCustomerData(
				'jpo.uniqueGoalCloudIds',
				`${Object.keys(newGoalARIsGroupedByCloudId).length}`,
			);
			const currentCloudId = yield call(getCloudId);
			for (const cloudId of Object.keys(newGoalARIsGroupedByCloudId)) {
				const newGoalARIsOfThisCloudId = newGoalARIsGroupedByCloudId[cloudId];
				// For the moment, we can only support goals from the current cloudId.
				// This is because Atlas has not provided necessary APIs to write to multiple cloudIds simultaneously,
				// and there isn't a feasible FE workaround. If we displayed goals from other sites, we risk users
				// removing those goals, and adding new goals which would error without any clear messaging on how to resolve the issue.
				// As such, we have disabled goals from other cloudIds.
				if (cloudId === currentCloudId) {
					yield put(fetchGoalsStart(createLazyGoals(newGoalARIsOfThisCloudId)));
					const { lazyGoalsByARI, isError }: FetchGoalsByARIResult = yield call(
						fetchGoalsByARI,
						newGoalARIsOfThisCloudId,
					);
					// Note: There are no errors when no permission; just empty response
					if (isError || Object.keys(lazyGoalsByARI).length === 0) {
						isAnyError = true;
						for (let i = 0; i < newGoalARIsOfThisCloudId.length; i += 1) {
							const ari = newGoalARIsOfThisCloudId[i];
							allLazyGoalsByARI[ari] = asLoadedGoal(ari);
						}
					}
					// modifying object rather than recreating using spread operator because it is slightly more efficient
					for (const ari of Object.keys(lazyGoalsByARI)) {
						allLazyGoalsByARI[ari] = lazyGoalsByARI[ari];
					}
				} else {
					for (const ari of newGoalARIsOfThisCloudId) {
						allLazyGoalsByARI[ari] = asLoadedGoal(ari, true);
					}
				}
			}

			if (!isAnyError) {
				yield put(fetchGoalsSuccess(allLazyGoalsByARI));
			} else {
				// we can still salvage the lazy goals that were fetched successfully but mark the rest as failed
				yield put(fetchGoalsFail({ ...createLazyGoals(newGoalARIs, false), ...allLazyGoalsByARI }));
				yield put(addFlag({ key: GOALS_ERROR }));
			}
		}
	} else {
		const isAtlasConnectInstalled: boolean = yield select(getIsAtlasConnectInstalled);
		const lazyGoals: LazyGoalsByARI = yield select(getLazyGoalsByARI);
		const existingGoalARIs = Object.keys(lazyGoals);

		const currentCloudId: string = yield call(getCloudId);

		const [internalGoalARIs, externalGoalARIs] = partition(goalARIs, (ari) => {
			if (!currentCloudId) return true;
			const cloudId = TownsquareGoalAri.parse(ari).cloudId;
			return cloudId === currentCloudId;
		});

		const newGoalARIs = difference(internalGoalARIs, existingGoalARIs);

		const generateExternalGoals = (aris: string[]): LazyGoalsByARI =>
			aris
				.map((ari) => asLoadedGoal(ari, true))
				.reduce((acc: Record<string, LazyGoal>, goal: LoadedLazyGoal) => {
					acc[goal.goal.id] = goal;
					return acc;
				}, {});

		if (isAtlasConnectInstalled && newGoalARIs.length > 0) {
			yield put(fetchGoalsStart(createLazyGoals(newGoalARIs)));

			const { lazyGoalsByARI, isError }: FetchGoalsByARIResult = yield call(
				fetchGoalsByARI,
				newGoalARIs,
			);

			const allLazyGoals = {
				...generateExternalGoals(externalGoalARIs),
				...lazyGoalsByARI,
			};

			if (!isError) {
				yield put(fetchGoalsSuccess(allLazyGoals));
			} else {
				yield put(fetchGoalsFail({ ...createLazyGoals(newGoalARIs, false), ...lazyGoalsByARI }));
				yield put(addFlag({ key: GOALS_ERROR }));
			}
		}
	}
}

export function* doSearchGoals({
	payload: query,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
}: SearchGoalsCommand): Generator<Effect, any, any> {
	yield put(searchGoalsStart());

	const cloudId: string = yield call(getCloudId);

	try {
		const { lazyGoalsByARI }: SearchGoalsByNameResult = yield call(
			searchGoalsByName,
			query,
			cloudId,
		);

		yield put(searchGoalsSuccess(lazyGoalsByARI));

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		yield put(searchGoalsFail());
		yield put(addFlag({ key: GOALS_ERROR }));
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchFetchGoals(): Generator<Effect, any, any> {
	yield takeEvery(FETCH_GOALS, doFetchGoals);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchSearchGoals(): Generator<Effect, any, any> {
	yield takeEvery(SEARCH_GOALS, doSearchGoals);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchResetGoals(): Generator<Effect, any, any> {
	yield takeEvery(RESET_GOALS, doResetGoals);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default function* (): Generator<Effect, any, any> {
	yield fork(watchFetchGoals);
	yield fork(watchSearchGoals);
	yield fork(watchResetGoals);
}
