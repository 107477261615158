import {
	createSelector,
	createStructuredSelector,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import { getIssueStatusById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-statuses/index.tsx';
import type { IssueStatusesById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-statuses/types.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { ISSUE_STATUS_CATEGORIES } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import type { MapStateToProps, Status, OwnProps } from './types.tsx';

const sortingArr = [
	ISSUE_STATUS_CATEGORIES.TODO,
	ISSUE_STATUS_CATEGORIES.INPROGRESS,
	ISSUE_STATUS_CATEGORIES.DONE,
];

const getStatuses = createSelector(
	[getIssueStatusById, (_: State, { value }: OwnProps) => value],
	(issueStatuses: IssueStatusesById, value: string[]): Status[] => {
		const counts = value.reduce((acc: Record<string, number>, curr: string) => {
			acc[curr] = (acc[curr] || 0) + 1;
			return acc;
		}, {});

		const statuses: Status[] = Object.keys(counts)
			.map((statusId) => ({
				id: statusId,
				name: issueStatuses[statusId].name,
				categoryId: issueStatuses[statusId].categoryId,
				count: counts[statusId],
			}))
			.sort(
				(a: Status, b: Status) =>
					sortingArr.indexOf(a.categoryId) - sortingArr.indexOf(b.categoryId),
			);

		return statuses;
	},
);

const mapStateToProps: MapStateToProps = createStructuredSelector({
	statuses: getStatuses,
});

export default mapStateToProps;
