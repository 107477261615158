import { getCustomFields } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/custom-fields/index.tsx';
import { getPlan } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/plan/index.tsx';
import { getIsSimplifiedProjectAvailable } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects/index.tsx';
import {
	getDependencySettings,
	getColorByViewSettings,
	getRollupViewSettings,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/view-settings/index.tsx';
import {
	getVisualisationGrouping,
	getVisualisationSorting,
	getShowSprints,
	getShowAggregate,
	isGroupByMultiValueCustomField,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/visualisations/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import {
	createStructuredSelector,
	createSelector,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import { getGroupForAnalyticEvent } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/grouping/index.tsx';
import type { OwnProps, StateProps } from './types.tsx';

const getAnalyticsAttributes = createSelector(
	[
		getPlan,
		getIsSimplifiedProjectAvailable,
		getColorByViewSettings,
		getVisualisationGrouping,
		getVisualisationSorting,
		getShowSprints,
		getShowAggregate,
		getDependencySettings,
		getRollupViewSettings,
		getCustomFields,
	],
	(
		plan,
		planContainsNextGenIssues,
		colorBy,
		grouping,
		sorting,
		showSprints,
		showAggregate,
		dependencySettings,
		rollUpSettings,
		customFields,
	) => ({
		viewSettingsDetails: {
			colorBy: colorBy.colourByValue,
			sortBy: sorting.field,
			groupBy: getGroupForAnalyticEvent(grouping, customFields),
			showCapacityOnTimeline: showSprints,
			showAggregate,
			dependencyStyle: dependencySettings.display,
			rollUpDates: rollUpSettings.showingRolledUpDate,
			rollUpOthers: rollUpSettings.showRolledUpOthers,
		},
		planId: plan.id,
		planContainsNextGenIssues,
	}),
);

export default createStructuredSelector<State, OwnProps, StateProps>({
	analyticsAttributes: getAnalyticsAttributes,
	colourByConfiguration: getColorByViewSettings,
	grouping: getVisualisationGrouping,
	groupingByMultiValueCustomField: isGroupByMultiValueCustomField,
	showSprints: getShowSprints,
	showAggregate: getShowAggregate,
});
