import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	apply: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.apply-optimisation.apply',
		defaultMessage: 'Accept changes',
		description: 'Label for the button used to apply auto-scheduling changes',
	},
	applying: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.apply-optimisation.applying',
		defaultMessage: 'Applying changes ({progress}%)',
		description: 'Label for the button when auto-scheduling changes are being applied',
	},
	previewingAllIssues: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.apply-optimisation.previewing-all-issues',
		defaultMessage: 'Previewing a total of <strong>{issueCount}</strong> auto-scheduled issues',
		description:
			'This is a title of the preview overlay that shows number of all auto-scheduled issues, user is previewing (no filters)',
	},
	previewingFilteredIssues: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.apply-optimisation.previewing-filtered-issues',
		defaultMessage:
			'Previewing <strong>{filteredIssueCount}</strong> of <strong>{allIssueCount}</strong> auto-scheduled issues',
		description:
			'This is a title of the preview overlay that shows number of auto-scheduled issues that are visible and total number of auto-scheduled issues',
	},
	previewingIssuesDescription: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.apply-optimisation.previewing-issues-description',
		defaultMessage:
			"By accepting, your plan will be updated with these changes. You'll still need to review and save all changes in your plan to Jira.",
		description:
			'This is a description of the preview overlay that shows number of auto-scheduled issues, user is previewing',
	},
	previewingAllIssuesIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.apply-optimisation.previewing-all-issues-issue-term-refresh',
		defaultMessage: 'Previewing a total of <strong>{issueCount}</strong> auto-scheduled work items',
		description:
			'This is a title of the preview overlay that shows number of all auto-scheduled issues, user is previewing (no filters)',
	},
	previewingFilteredIssuesIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.apply-optimisation.previewing-filtered-issues-issue-term-refresh',
		defaultMessage:
			'Previewing <strong>{filteredIssueCount}</strong> of <strong>{allIssueCount}</strong> auto-scheduled work items',
		description:
			'This is a title of the preview overlay that shows number of auto-scheduled issues that are visible and total number of auto-scheduled issues',
	},
});
