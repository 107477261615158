import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	teamTooltip: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.add-issue.nested-menu.team-options.team-tooltip',
		defaultMessage: "You can't create issues for external teams.",
		description: 'Tooltip for disabled option for external team',
	},
	teamSearchPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.add-issue.nested-menu.team-options.team-search-placeholder',
		defaultMessage: 'Find teams...',
		description: 'This is a placeholder for search',
	},
	teamTooltipIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.add-issue.nested-menu.team-options.team-tooltip-issue-term-refresh',
		defaultMessage: "You can't create work items for external teams.",
		description: 'Tooltip for disabled option for external team',
	},
});
