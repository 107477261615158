import sortBy from 'lodash/sortBy';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type {
	LazyGoal,
	LazyGoalsByARI,
	GoalsByARI,
	LoadedLazyGoal,
} from '../../state/domain/issue-goals/types.tsx';
import type { Issue } from '../../state/domain/issues/types.tsx';
import type { OriginalIssues } from '../../state/domain/original-issues/types.tsx';
import type { State } from '../../state/types.tsx';
import { getAllIssues, getOriginalIssues } from '../raw-issues/index.tsx';

export const getLazyGoalsByARI = (state: State): LazyGoalsByARI =>
	state.domain.goals.lazyGoalsByARI;

export const getGoalSearchesInProgress = (state: State) => state.domain.goals.searchesInProgress;

export const getIssueGoalARIsPure = (issues: Issue[], originalIssues: OriginalIssues) => {
	const goalARIs = new Set<string>();

	issues.forEach((issue) => {
		if (issue.goals) issue.goals.forEach((goalARI) => goalARIs.add(goalARI));
	});

	Object.values(originalIssues).forEach((issue) => {
		if (issue.goals) issue.goals.forEach((goalARI) => goalARIs.add(goalARI));
	});

	return Array.from(goalARIs);
};

export const getIssueGoalARIs = createSelector(
	[getAllIssues, getOriginalIssues],
	getIssueGoalARIsPure,
);

const sortLazyGoals = (goals: LazyGoal[]) => sortBy(goals, (goal) => goal.goal?.name);

export const getAllLazyGoalsPure = (lazyGoalsByARI: LazyGoalsByARI) =>
	sortLazyGoals(Object.values(lazyGoalsByARI));

export const getAllLazyGoals = createSelector([getLazyGoalsByARI], getAllLazyGoalsPure);

export const getDefinedGoalsPure = (lazyGoalsByARI: LazyGoalsByARI): GoalsByARI => {
	type LoadedLazyGoalEntry = [string, LoadedLazyGoal];

	return Object.fromEntries(
		Object.entries(lazyGoalsByARI)
			.filter((entry): entry is LoadedLazyGoalEntry => {
				const [, lazyGoal] = entry;
				return (
					lazyGoal.goal !== undefined &&
					!lazyGoal.isLoading &&
					(!fg('jpo_plan_timeline_cross_site_goals') ||
						(Boolean(lazyGoal.goal?.name) && lazyGoal.isExternal !== true))
				);
			})
			.map(([goalARI, lazyGoal]) => [goalARI, lazyGoal.goal]),
	);
};

export const getDefinedGoals = createSelector([getLazyGoalsByARI], getDefinedGoalsPure);

export const getIsGoalsLoadingPure = (lazyGoalsByARI: LazyGoalsByARI) =>
	Object.values(lazyGoalsByARI).some((lazyGoal) => lazyGoal.isLoading);

export const getIsGoalsLoading = createSelector([getLazyGoalsByARI], getIsGoalsLoadingPure);
