import React, { type SyntheticEvent } from 'react';
import Popup from '@atlaskit/popup'; // ignore-for-ENGHEALTH-17759
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl, FormattedMessage } from '@atlassian/jira-intl';
import Checkbox from '@atlassian/jira-portfolio-3-common/src/checkbox/index.tsx';
import {
	DialogMenuContainer,
	DialogMenuItem,
} from '@atlassian/jira-portfolio-3-common/src/inline-dialog/dialog-menu/index.tsx';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types.tsx';
import type { IssuePriority } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issue-priorities/types.tsx';
import { ISSUE_PRIORITIES_FILTER_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import SearchField from '@atlassian/jira-portfolio-3-portfolio/src/common/view/search-field/index.tsx';
import {
	fireUIAnalytics,
	type AnalyticsEvent,
	AnalyticsEventToProps,
} from '@atlassian/jira-product-analytics-bridge';
import ClearFilterButton from '../common/clear-filter/index.tsx';
import FilterText from '../common/filter-text/index.tsx';
import NoMatchFound from '../common/no-match-text/index.tsx';
import TitleWithAvatar from '../common/title-with-avatar/index.tsx';
import TriggerButton from '../common/trigger-button/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import '../common/styles.module.css';
import filterMessages from '../messages.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const CheckboxWithAnalytics = AnalyticsEventToProps('checkbox', {
	onChange: 'changed',
})(Checkbox);

function IssuePrioritiesFilter({
	issuePriorities,
	value,
	searchQuery,
	changeIssuePrioritiesFilter,
	isOpen,
	onOpenChange,
	onQueryChange,
	clearIssuePrioritiesFilter,
}: Props) {
	const { formatMessage } = useIntl();
	const constructFilterText = () => {
		return issuePriorities
			.filter(({ id }: { id: string }) => value.includes(id))
			.map(({ name }: { name: string }) => name)
			.join(', ');
	};

	const renderFilterText = () => {
		return value.length > 0 ? (
			<FilterText text={constructFilterText()} />
		) : (
			<FormattedMessage {...messages.emptyPlaceholder} />
		);
	};

	const ariaText = () => {
		const filterText = constructFilterText();
		// Priorities, All
		return `${formatMessage(filterMessages[ISSUE_PRIORITIES_FILTER_ID])}, ${
			value.length > 0 ? filterText : formatMessage(messages.emptyPlaceholder)
		} ${formatMessage(filterMessages.selected)}`;
	};

	const isIssuePrioritySelected = (issuePriorityId: string) => value.includes(issuePriorityId);

	const filterIssueTypesWithSearchQuery = (issuePriority: IssuePriority) =>
		issuePriority.name.toLowerCase().includes(searchQuery.toLowerCase());

	const getIssuePrioritiesOrder = (issuePriorityIds: string[]) => {
		return issuePriorities
			.filter(({ id }) => issuePriorityIds.includes(id)) // eslint-disable-next-line @typescript-eslint/no-explicit-any
			.map((_: any, index: number) => index);
	};

	const onIssuePriorityClick = (issuePriorityId: string, analyticsEvent: AnalyticsEvent): void => {
		let issuePriorityIds: string[];

		if (isIssuePrioritySelected(issuePriorityId)) {
			issuePriorityIds = value.filter((issuePriority) => issuePriority !== issuePriorityId);
			changeIssuePrioritiesFilter(issuePriorityIds);
		} else {
			issuePriorityIds = [...value, issuePriorityId];
			changeIssuePrioritiesFilter(issuePriorityIds);
		}

		// Send an analytics event when the user updates the Issue Priorities filter
		const [actionSubject, action] =
			PRODUCT_ANALYTICS_EVENT_NAMES.UPDATED_ISSUE_PRIORITIES_FILTER.split(' ');
		fireUIAnalytics(analyticsEvent.update({ action, actionSubject }), {
			issuePrioritiesOrder: getIssuePrioritiesOrder(issuePriorityIds),
		});
	};

	const getIssuePrioritiesOptions = (filteredIssuePriorities: IssuePriority[]) =>
		filteredIssuePriorities.map(({ name, id, iconUrl }) => (
			<DialogMenuItem key={id}>
				<CheckboxWithAnalytics
					id={`${id}`}
					key={id}
					isChecked={isIssuePrioritySelected(id)}
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					onChange={(_: SyntheticEvent<any>, analyticsEvent: AnalyticsEvent) =>
						onIssuePriorityClick(id, analyticsEvent)
					}
					label={
						<TitleWithAvatar
							avatarUrl={iconUrl}
							id={id}
							name={name}
							appearance="square"
							size="xsmall"
						/>
					}
				/>
			</DialogMenuItem>
		));

	const getIssuePriorities = () => {
		const filteredIssuePriorities = issuePriorities.filter(filterIssueTypesWithSearchQuery);

		return (
			<>
				{getIssuePrioritiesOptions(filteredIssuePriorities)}
				{filteredIssuePriorities.length === 0 && <NoMatchFound />}
			</>
		);
	};

	return (
		<Popup
			placement="bottom-start"
			isOpen={isOpen}
			shouldUseCaptureOnOutsideClick
			shouldRenderToParent
			autoFocus
			onClose={() => {
				onOpenChange({ isOpen: false });
			}}
			content={({ setInitialFocusRef }) => (
				<DialogMenuContainer>
					<ClearFilterButton isVisible={!!value.length} onClearClick={clearIssuePrioritiesFilter} />
					<SearchField
						placeholder={formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.searchPriorityPlaceholderIssueTermRefresh
								: messages.searchPriorityPlaceholder,
						)}
						searchQuery={searchQuery}
						onQueryChange={onQueryChange}
						ariaLabel={formatMessage(messages.searchPriorityLabel)}
						setInitialFocusRef={setInitialFocusRef}
					/>
					{getIssuePriorities()}
				</DialogMenuContainer>
			)}
			testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.issue-priorities-filter"
			trigger={({ ref, ...triggerProps }) => (
				<TriggerButton
					{...triggerProps}
					ref={ref}
					isOpen={isOpen}
					onOpenChange={onOpenChange}
					triggerButtonText={renderFilterText()}
					ariaLabel={ariaText()}
					testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.issue-priorities-filter.trigger-btn"
				/>
			)}
		/>
	);
}

export default IssuePrioritiesFilter;
