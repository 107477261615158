.wrapper {
	border-radius: 50%;
	background-color: var(--ds-background-neutral, var(--adg3-color-N40));
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}

.initials {
	font-weight: var(--ds-font-weight-semibold, 600);
	color: var(--ds-text, var(--adg3-color-N800));
}
