import React, { Fragment, useCallback, useState, type PropsWithChildren } from 'react';
import * as R from 'ramda';
// eslint-disable-next-line @atlaskit/design-system/no-unsupported-drag-and-drop-libraries
import {
	SortableContainer,
	SortableElement,
	type SortEndHandler,
	type SortMoveHandler,
	type SortStartHandler,
	type SortStart,
} from 'react-sortable-hoc--next';
import { IconButton } from '@atlaskit/button/new';
import StandardButton from '@atlaskit/button/standard-button';
import DragHandlerIcon from '@atlaskit/icon/glyph/drag-handler';
import EditorCloseIcon from '@atlaskit/icon/glyph/editor/close';
import CrossIcon from '@atlaskit/icon/utility/cross';
import DragHandleIcon from '@atlaskit/icon/utility/drag-handle';
import Lozenge from '@atlaskit/lozenge';
import { Flex } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import VisuallyHidden from '@atlaskit/visually-hidden';
import { useIntl } from '@atlassian/jira-intl';
import TitleWithIcon from '@atlassian/jira-portfolio-3-common/src/sections/title-with-icon.tsx';
import { getConfiguredDateTooltip } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/fields/messages.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { LocalizedIssueColumn } from '../types.tsx';
import { getListableColumns } from '../utils.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

// @ts-expect-error - TS2345 - Argument of type '({ children }: Props) => React.ReactNode' is not assignable to parameter of type 'WrappedComponent<Props>'.
const SortableItem = SortableElement(({ children }: Props) => children);
// @ts-expect-error - TS2345 - Argument of type '({ children }: { children: any; }) => any' is not assignable to parameter of type 'WrappedComponent<unknown>'.
const SortableContainerWrapper = SortableContainer(({ children }) => children);

const SelectableFieldSectionContainer = ({
	isVisuallyRefreshed,
	children,
}: PropsWithChildren<{ isVisuallyRefreshed: boolean }>) =>
	isVisuallyRefreshed ? (
		<Flex alignItems="center" columnGap="space.150">
			{children}
		</Flex>
	) : (
		<div
			/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */
			className={styles['afm-selected-field-item-section']}
		>
			{children}
		</div>
	);

const AdvancedFieldsMenu = ({
	selectedColumns,
	getMatchingConfiguredDate,
	hideColumn,
	moveColumn,
}: Props) => {
	const { formatMessage } = useIntl();

	const [sortingAnnouncement, setSortingAnnouncement] = useState('');

	const [sortableInfo, setSortableInfo] = useState<SortStart | null>(null);
	const [initialPosition, setInitialPosition] = useState<number | null>(null);

	const renderCustomFieldLozenge = () => (
		<span
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={styles['afm-custom-field-lozenge']}
			data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.advanced-fields-menu.selected-fields.custom-lozenge"
		>
			<Lozenge>{formatMessage(messages.advancedFieldsMenuCustomFieldLozenge)}</Lozenge>
		</span>
	);

	const renderSelectedFields = (columns: LocalizedIssueColumn[]) =>
		getListableColumns(columns).map((group, index) => {
			const localizedTitle = group[0].localizedTitle;
			// eslint-disable-next-line @typescript-eslint/no-shadow
			const columns = R.sortBy(R.prop('id'), group);
			// eslint-disable-next-line @typescript-eslint/no-shadow
			const isCustomField = columns.every(({ isCustomField }) => isCustomField);
			const compoundId = columns.map(R.prop('id')).join(':');
			const configurableDate = getMatchingConfiguredDate(compoundId);
			const tooltip = getConfiguredDateTooltip(configurableDate);

			return (
				// @ts-expect-error - TS2322 - Type '{ children: Element; id: string; index: number; key: string; }' is not assignable to type 'IntrinsicAttributes & IntrinsicClassAttributes<Component<Props & SortableElementProps, any, any>> & Readonly<...> & Readonly<...>'.
				<SortableItem id={compoundId} index={index} key={compoundId}>
					<div
						tabIndex={0}
						data-title={localizedTitle}
						id={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.advanced-fields-menu.selected-field-${compoundId}`}
						role="button"
						aria-labelledby={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.advanced-fields-menu.selected-field-${compoundId} portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.advanced-fields-menu.selected-field-a11y-text`}
						aria-label={localizedTitle}
						key={`item-${localizedTitle}`}
						data-test-class="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.advanced-fields-menu.selected-field"
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={
							isVisualRefreshEnabled()
								? styles['afm-selected-field-item']
								: styles['afm-selected-field-item-old']
						}
						data-testid={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.advanced-fields-menu.selected-field-${compoundId}`}
					>
						<SelectableFieldSectionContainer isVisuallyRefreshed={isVisualRefreshEnabled()}>
							<Tooltip content={formatMessage(messages.advancedFieldsMenuSelectedFieldDragTooltip)}>
								{(triggerProps) => (
									// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
									<div {...triggerProps} className={styles['afm-selected-field-item-drag-icon']}>
										{isVisualRefreshEnabled() ? (
											<DragHandleIcon
												label={formatMessage(messages.advancedFieldsMenuSelectedFieldDragTooltip)}
												color="currentColor"
											/>
										) : (
											<DragHandlerIcon
												label={formatMessage(messages.advancedFieldsMenuSelectedFieldDragTooltip)}
											/>
										)}
									</div>
								)}
							</Tooltip>

							{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
							<div className={styles['afm-selected-field-item-title']}>
								<TitleWithIcon
									icon={
										configurableDate && (
											<Lozenge
												testId={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.advanced-fields-menu.selected-field-${compoundId}.configurable-date-lozenge`}
											>
												D
											</Lozenge>
										)
									}
									tooltip={tooltip && formatMessage(tooltip, { title: localizedTitle })}
									mode="inline"
								>
									{localizedTitle}
								</TitleWithIcon>
							</div>
						</SelectableFieldSectionContainer>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<div className={styles['afm-selected-field-item-section']}>
							{isCustomField && renderCustomFieldLozenge()}
							<div
								// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
								className={styles['afm-selected-field-item-remove-icon']}
								data-drag="false"
								data-test-class="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.advanced-fields-menu.remove-button"
								data-testid={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.advanced-fields-menu.selected-field-${compoundId}-remove-button`}
							>
								<Tooltip
									content={formatMessage(messages.advancedFieldsMenuSelectedFieldRemoveTooltip)}
								>
									{(triggerProps) =>
										isVisualRefreshEnabled() ? (
											<IconButton
												{...triggerProps}
												appearance="subtle"
												label={formatMessage(
													messages.advancedFieldsMenuSelectedFieldRemoveAriaLabel,
													{
														fieldName: localizedTitle,
													},
												)}
												icon={() => <CrossIcon label="" color="currentColor" spacing="spacious" />}
												onClick={() => {
													columns.forEach(({ id }) => {
														hideColumn(id);
													});
												}}
												testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.advanced-fields-menu.selected-fields.selected-field-remove-button"
											/>
										) : (
											<StandardButton
												{...triggerProps}
												appearance="subtle"
												spacing="none"
												iconBefore={<EditorCloseIcon label="" />}
												onClick={() => {
													columns.forEach(({ id }) => {
														hideColumn(id);
													});
												}}
												// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
												className={styles['afm-selected-field-item-remove-icon-button']}
												aria-label={formatMessage(
													messages.advancedFieldsMenuSelectedFieldRemoveAriaLabel,
													{
														fieldName: localizedTitle,
													},
												)}
												testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.advanced-fields-menu.selected-fields.selected-field-remove-button"
											/>
										)
									}
								</Tooltip>
							</div>
						</div>
					</div>
				</SortableItem>
			);
		});

	const handleSortStart: SortStartHandler = useCallback(
		(sortStart, event) => {
			const { node, isKeySorting, index } = sortStart;
			const fieldName = node.getAttribute('data-title');
			setSortableInfo(sortStart);
			isKeySorting &&
				setSortingAnnouncement(
					formatMessage(messages.advancedFieldMenuGrabbedFieldAnnouncement, {
						fieldName,
					}),
				);

			setInitialPosition(index + 1);

			// this workaround prevents text to be selected in the background when dragging fields - see
			// https://github.com/clauderic/react-sortable-hoc/issues/253#issuecomment-350223723
			event.preventDefault();
		},
		[formatMessage],
	);

	const handleSortEnd: SortEndHandler = (sortEnd) => {
		const { oldIndex, newIndex, isKeySorting, nodes } = sortEnd;
		const columns = getListableColumns(selectedColumns);
		const columnToMove = columns[oldIndex][0].id;
		const moveToTheIndexOf = columns[newIndex][0].id;
		moveColumn(columnToMove, moveToTheIndexOf);
		// @ts-expect-error Property 'node' does not exist on type 'HTMLElement'.ts(2339)
		const fieldName = nodes[oldIndex].node.getAttribute('data-title');
		const position = newIndex + 1;
		if (isKeySorting && oldIndex !== newIndex) {
			setSortingAnnouncement(
				formatMessage(messages.advancedFieldMenuDroppedFieldAnnouncement, {
					fieldName,
					position,
				}),
			);
		}
		setInitialPosition(null);
		setSortableInfo(null);
	};

	const handleSortMove: SortMoveHandler = (e) => {
		// @ts-expect-error Property 'ignoreTransition' does not exist on type 'SortEvent'.
		if (sortableInfo && initialPosition && !e.ignoreTransition) {
			const { node, helper } = sortableInfo;
			const fieldName = node.getAttribute('data-title');
			const { top } = node.getBoundingClientRect();
			const { height } = helper.getBoundingClientRect();
			// @ts-expect-error Property 'pageY' does not exist on type 'SortEvent'.
			const currentPosition = Math.ceil(initialPosition + (e.pageY - top) / height);

			setSortingAnnouncement(
				formatMessage(messages.advancedFieldMenuMovedFieldAnnouncement, {
					fieldName,
					initialPosition,
					currentPosition,
				}),
			);
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const shouldCancelStart = (e: any) =>
		// cancel sorting onClick on remove icon to allow click event propagation and the removal of the field
		Boolean(e.target.closest('div[data-drag="false"]'));

	const renderDialogContent = () => (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles['afm-dropdown-menu-body']} data-role="afm-dropdown-menu-body">
			{selectedColumns.length > 0 ? (
				<>
					<VisuallyHidden id="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.advanced-fields-menu.selected-fields.visually-hidden">
						{}
						<label id="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.advanced-fields-menu.selected-field-a11y-text">
							{formatMessage(messages.advancedFieldMenuFocusedFieldAnnouncement)}
						</label>
						<div role="status" aria-live="polite" aria-atomic>
							<Fragment key={sortingAnnouncement}>{sortingAnnouncement}</Fragment>
						</div>
					</VisuallyHidden>

					<SortableContainerWrapper
						helperClass={styles['afm-sortable-helper']}
						onSortStart={handleSortStart}
						onSortEnd={handleSortEnd}
						shouldCancelStart={shouldCancelStart}
						lockToContainerEdges
						onSortMove={handleSortMove}
						lockAxis="y"
						{...(fg('plans_field_menu_fix_position') && {
							// Looks like it's working fine without this now
							helperContainer:
								// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
								document.querySelector<HTMLElement>(
									"div[data-role='afm-dropdown-menu-drag-handle']",
								) || undefined,
						})}
						// @ts-expect-error - TS2322 - Type '() => Element | null' is not assignable to type 'ContainerGetter'.
						getContainer={() =>
							/**
							 * The container is explicity given so the menu list would auto-scroll when the user
							 * drags an item beyond the current visible items
							 */

							// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
							document.querySelector("div[data-role='afm-dropdown-menu-body']")
						}
					>
						{renderSelectedFields(selectedColumns)}
					</SortableContainerWrapper>
				</>
			) : (
				<div
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={styles['afm-no-selected-fields']}
					data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.advanced-fields-menu.selected-fields.no-selected-field"
				>
					{formatMessage(messages.advancedFieldsMenuNoSelectedFields)}
				</div>
			)}
		</div>
	);

	return renderDialogContent();
};

export default AdvancedFieldsMenu;
