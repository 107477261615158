import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	expandButtonLabel: {
		id: 'aais-timeline-toolbar.container.expand-button-label',
		defaultMessage: 'Expand timeline time period buttons',
		description:
			'button to expand today, weeks, months, quarters and full screen time line toolbar',
	},
	expandLabel: {
		id: 'aais-timeline-toolbar.container.expand-label',
		defaultMessage: 'Timeline time period',
		description:
			'button to expand today, weeks, months, quarters and full screen time line toolbar',
	},
	collapseButtonLabel: {
		id: 'aais-timeline-toolbar.container.collapse-button-label',
		defaultMessage: 'Collapse timeline time period buttons',
		description:
			'button to collapse today, weeks, months, quarters and full screen time line toolbar',
	},
});
