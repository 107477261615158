import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	issueTypeMenuTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.create-issue.options-providers.issue-type.issue-type-menu-title',
		defaultMessage: 'Choose issue type',
		description: 'This is a title for issue type menu',
	},
	issueTypeSearchPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.create-issue.options-providers.issue-type.issue-type-search-placeholder',
		defaultMessage: 'Find issue types...',
		description: 'This is a placeholder for search',
	},
	issueTypeMenuTitleIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.create-issue.options-providers.issue-type.issue-type-menu-title-issue-term-refresh',
		defaultMessage: 'Choose work type',
		description: 'This is a title for issue type menu',
	},
	issueTypeSearchPlaceholderIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.create-issue.options-providers.issue-type.issue-type-search-placeholder-issue-term-refresh',
		defaultMessage: 'Find work types',
		description: 'This is a placeholder for search',
	},
});
