import React, { Component, createRef, type ElementRef } from 'react';
import * as R from 'ramda';
import Lozenge from '@atlaskit/lozenge';
import Popup from '@atlaskit/popup'; // ignore-for-ENGHEALTH-17759
import { fg } from '@atlassian/jira-feature-gating';
import { injectIntl, FormattedMessage } from '@atlassian/jira-intl';
import Checkbox from '@atlassian/jira-portfolio-3-common/src/checkbox/index.tsx';
import {
	DialogMenuContainer,
	DialogMenuItem,
	DialogMenuGroup,
} from '@atlassian/jira-portfolio-3-common/src/inline-dialog/dialog-menu/index.tsx';
// Remove InlineDialog when cleaning up FG 'migrate_plan_filter_to_popup'
import InlineDialog from '@atlassian/jira-portfolio-3-common/src/inline-dialog/index.tsx';
import { NO_RELEASE_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/release-filter/index.tsx';
import type { Project } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/projects/types.tsx';
import type { Version } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/versions/types.tsx';
import {
	CROSS_PROJECT_RELEASE_FILTER_ID,
	RELEASE_FILTER_ID,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import { DelayedAnnouncer } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/common/delayed-announcer/index.tsx';
import { sortAsLowerCaseByNameProp } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/composed.tsx';
import {
	values,
	isDefined,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import EllipsedWithTooltip from '@atlassian/jira-portfolio-3-portfolio/src/common/view/ellipsed-with-tooltip/index.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import SearchField from '@atlassian/jira-portfolio-3-portfolio/src/common/view/search-field/index.tsx';
import ClearFilterButton from '../common/clear-filter/index.tsx';
import FilterText from '../common/filter-text/index.tsx';
import { FILTER_MAX_WIDTH, FILTER_WIDTH } from '../common/index.tsx';
import NoMatchFound from '../common/no-match-text/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as commonStyles from '../common/styles.module.css';
import TitleWithAvatar from '../common/title-with-avatar/index.tsx';
import TriggerButton from '../common/trigger-button/index.tsx';
import filterMessages from '../messages.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

// eslint-disable-next-line jira/react/no-class-components
class ReleaseFilter extends Component<Props> {
	private announcerRef = createRef<ElementRef<typeof DelayedAnnouncer>>();

	onVersionClick = (id: string, isSelected: boolean): void => {
		const { value, changeReleaseFilter } = this.props;
		if (isSelected) {
			changeReleaseFilter(RELEASE_FILTER_ID, [...value, id]);
		} else {
			changeReleaseFilter(
				RELEASE_FILTER_ID,
				R.filter((version) => version !== id, value),
			);
		}
	};

	onCrossProjectVersionClick = (id: string, isSelected: boolean): void => {
		const { crossProjectReleaseFilterValue, changeReleaseFilter } = this.props;
		if (isSelected) {
			changeReleaseFilter(CROSS_PROJECT_RELEASE_FILTER_ID, [...crossProjectReleaseFilterValue, id]);
		} else {
			changeReleaseFilter(
				CROSS_PROJECT_RELEASE_FILTER_ID,
				R.filter((version) => version !== id, crossProjectReleaseFilterValue),
			);
		}
	};

	onClearClick = () => this.props.clearReleaseFilter();

	getVersion = (versionId: string): Version => {
		const { versionsById } = this.props;
		return versionsById[versionId];
	};

	filterProjectsWithSearchQuery = (project: Project): boolean =>
		project.versions.map(this.getVersion).some(this.filterVersionsWithSearchQuery);

	filterVersionsWithSearchQuery = (version: Version) => {
		const { name, crossProjectVersion } = version;
		const { crossProjectVersionsById, searchQuery } = this.props;

		if (
			isDefined(crossProjectVersion) &&
			isDefined(crossProjectVersionsById[crossProjectVersion])
		) {
			return (
				name.toLowerCase().includes(searchQuery.toLowerCase()) ||
				crossProjectVersionsById[crossProjectVersion].name
					.toLowerCase()
					.includes(searchQuery.toLowerCase())
			);
		}
		return name.toLowerCase().includes(searchQuery.toLowerCase());
	};

	constructFilterText = () => {
		const { versionsById, value, crossProjectVersions, crossProjectReleaseFilterValue, intl } =
			this.props;

		const versionNames = sortAsLowerCaseByNameProp(values(versionsById))
			.filter(({ id }) => R.contains(id, value))
			.map(({ name }) => name);

		const crossProjectVersionNames = sortAsLowerCaseByNameProp(crossProjectVersions)
			.filter(({ id }) => R.contains(id, crossProjectReleaseFilterValue))
			.map(({ name }) => name);
		if (R.contains(NO_RELEASE_ID, value)) {
			return [
				intl.formatMessage({ ...messages.noReleasesFilterText }),
				...versionNames,
				...crossProjectVersionNames,
			].join(', ');
		}
		return [...versionNames, ...crossProjectVersionNames].join(', ');
	};

	renderFilterText = () => {
		const { value, crossProjectReleaseFilterValue } = this.props;
		const filterText = this.constructFilterText();
		return value.length > 0 || crossProjectReleaseFilterValue.length > 0 ? (
			<FilterText text={filterText} />
		) : (
			<FormattedMessage {...messages.emptyPlaceholder} />
		);
	};

	ariaText = () => {
		const { value, intl } = this.props;
		const filterText = this.constructFilterText();
		// Releases, All
		return `${intl.formatMessage(filterMessages[RELEASE_FILTER_ID])}, ${
			value.length > 0 ? filterText : intl.formatMessage(messages.emptyPlaceholder)
		} ${intl.formatMessage(filterMessages.selected)}`;
	};

	getFilteredCrossProjectVersions = () => {
		const { crossProjectVersions, searchQuery } = this.props;
		return crossProjectVersions.filter(({ name }) =>
			name.toLowerCase().includes(searchQuery.toLowerCase()),
		);
	};

	renderCrossProjectReleasesGroup = () => {
		const { intl, crossProjectReleaseFilterValue } = this.props;
		const isSelected = (versionId: string) => R.contains(versionId, crossProjectReleaseFilterValue);

		const filteredCrossProjectVersions = this.getFilteredCrossProjectVersions();

		if (filteredCrossProjectVersions.length === 0) {
			return null;
		}

		return (
			<DialogMenuGroup heading={intl.formatMessage(commonMessages.crossProjectReleases)}>
				{filteredCrossProjectVersions.length > 0 &&
					filteredCrossProjectVersions.map((version) => {
						const { id, name } = version;

						return (
							<DialogMenuItem key={id}>
								<Checkbox
									id={id}
									key={id}
									name={name}
									isChecked={isSelected(id)}
									onChange={() => this.onCrossProjectVersionClick(id, !isSelected(id))}
									label={
										<EllipsedWithTooltip id={id} content={name}>
											{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
											<span className={commonStyles.checkboxLabel}>{name}</span>
										</EllipsedWithTooltip>
									}
								/>
							</DialogMenuItem>
						);
					})}
			</DialogMenuGroup>
		);
	};

	getFilteredProjects = () => {
		const { projects } = this.props;
		return projects.filter(
			(project) => project.isReleasesEnabled && this.filterProjectsWithSearchQuery(project),
		);
	};

	renderProjectGroups = () => (
		<>
			{this.getFilteredProjects().map(({ id, name, key, versions, avatarUrl }) => {
				const projectTitle = (
					<TitleWithAvatar avatarUrl={avatarUrl} id={id} projectKey={key} name={name} />
				);
				return (
					<DialogMenuGroup heading={projectTitle} key={id}>
						{this.renderVersionOptions(versions)}
					</DialogMenuGroup>
				);
			})}
		</>
	);

	renderVersionOptions = (versions: string[]) => {
		const { value, crossProjectVersionsById } = this.props;

		const isSelected = (versionId: string) => R.contains(versionId, value);
		const sortedVersionObjects = sortAsLowerCaseByNameProp(versions.map(this.getVersion));
		return sortedVersionObjects.filter(this.filterVersionsWithSearchQuery).map((version) => {
			const { id, name, crossProjectVersion } = version;
			const doesHaveCrossProjectVersionThatIsNotDeleted: boolean =
				isDefined(crossProjectVersion) && isDefined(crossProjectVersionsById[crossProjectVersion]);
			const crossProjectVersionName: string = doesHaveCrossProjectVersionThatIsNotDeleted
				? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					crossProjectVersionsById[crossProjectVersion as string].name
				: '';

			return (
				<DialogMenuItem key={id}>
					<Checkbox
						id={id}
						key={id}
						name={name}
						isChecked={isSelected(id)}
						onChange={() => this.onVersionClick(id, !isSelected(id))}
						label={
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
							<span className={styles.crossProjectReleaseOption}>
								{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
								<span className={styles.crossProjectReleaseName}>
									<EllipsedWithTooltip content={name} id={id}>
										<span>{name}</span>
									</EllipsedWithTooltip>
								</span>

								{isDefined(crossProjectVersion) && (
									// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
									<span className={styles.crossProjectReleaseLozenge}>
										<Lozenge maxWidth={100}>
											<EllipsedWithTooltip content={crossProjectVersionName}>
												<span>{crossProjectVersionName}</span>
											</EllipsedWithTooltip>
										</Lozenge>
									</span>
								)}
							</span>
						}
					/>
				</DialogMenuItem>
			);
		});
	};

	renderNoReleases = () => {
		const { value, intl } = this.props;
		const id = NO_RELEASE_ID;
		const name = intl.formatMessage({ ...messages.noReleasesFilterText });
		const isSelected = (versionId: string) => R.contains(versionId, value);
		const selected = isSelected(id);

		return (
			<DialogMenuItem>
				<Checkbox
					id={id}
					key={id}
					name={name}
					isChecked={selected}
					onChange={() => this.onVersionClick(id, !isSelected(id))}
					label={
						<EllipsedWithTooltip content={name} id={id}>
							{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
							<span className={commonStyles.checkboxLabel}>{name}</span>
						</EllipsedWithTooltip>
					}
				/>
			</DialogMenuItem>
		);
	};

	shouldClearRenderButtonRender = () => {
		const { value, crossProjectReleaseFilterValue } = this.props;
		if (!(value.length || crossProjectReleaseFilterValue.length)) {
			return false;
		}
		return true;
	};

	onSearchQueryChange = (query: string) => {
		this.props.onQueryChange(query);
		this.announcerRef.current?.announce();
	};

	render() {
		const { intl, onOpenChange, isOpen, searchQuery } = this.props;
		const searchResultsLength =
			this.getFilteredProjects().length + this.getFilteredCrossProjectVersions().length;
		const label =
			searchResultsLength > 0
				? intl.formatMessage(messages.searchResultsCount, {
						totalCount: searchResultsLength,
					})
				: intl.formatMessage(commonMessages.noMatchFoundText);

		if (!fg('migrate_plan_filter_to_popup')) {
			return (
				<InlineDialog
					noPaddings
					maxWidth={FILTER_MAX_WIDTH}
					minWidth={FILTER_WIDTH}
					onClose={onOpenChange}
					isOpen={isOpen}
					content={
						<DialogMenuContainer>
							<ClearFilterButton
								isVisible={this.shouldClearRenderButtonRender()}
								onClearClick={this.onClearClick}
							/>
							<SearchField
								placeholder={intl.formatMessage(
									fg('jira-issue-terminology-refresh-m3')
										? messages.searchReleasesPlaceholderIssueTermRefresh
										: messages.searchReleasesPlaceholder,
								)}
								searchQuery={searchQuery}
								ariaLabel={intl.formatMessage(messages.searchReleasesLabel)}
								onQueryChange={this.onSearchQueryChange}
							/>
							<DelayedAnnouncer ref={this.announcerRef} label={label} />
							{!R.isEmpty(this.props.versionsById) && this.renderNoReleases()}
							{this.renderCrossProjectReleasesGroup()}
							{this.renderProjectGroups()}
							{searchResultsLength === 0 && <NoMatchFound />}
						</DialogMenuContainer>
					}
					testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.release-filter"
				>
					<TriggerButton
						isOpen={isOpen}
						onOpenChange={onOpenChange}
						triggerButtonText={this.renderFilterText()}
						testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.release-filter.trigger-btn"
						ariaLabel={this.ariaText()}
					/>
				</InlineDialog>
			);
		}
		return (
			<Popup
				isOpen={isOpen}
				placement="bottom-start"
				shouldUseCaptureOnOutsideClick
				autoFocus
				onClose={() => {
					onOpenChange({ isOpen: false });
				}}
				content={(contentProps) => (
					<DialogMenuContainer>
						<ClearFilterButton
							isVisible={this.shouldClearRenderButtonRender()}
							onClearClick={this.onClearClick}
						/>
						<SearchField
							placeholder={intl.formatMessage(
								fg('jira-issue-terminology-refresh-m3')
									? messages.searchReleasesPlaceholderIssueTermRefresh
									: messages.searchReleasesPlaceholder,
							)}
							searchQuery={searchQuery}
							ariaLabel={intl.formatMessage(messages.searchReleasesLabel)}
							onQueryChange={this.onSearchQueryChange}
							setInitialFocusRef={contentProps.setInitialFocusRef}
						/>
						<DelayedAnnouncer ref={this.announcerRef} label={label} />
						{!R.isEmpty(this.props.versionsById) && this.renderNoReleases()}
						{this.renderCrossProjectReleasesGroup()}
						{this.renderProjectGroups()}
						{searchResultsLength === 0 && <NoMatchFound />}
					</DialogMenuContainer>
				)}
				testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.release-filter"
				trigger={(triggerProps) => (
					<TriggerButton
						{...triggerProps}
						isOpen={isOpen}
						onOpenChange={onOpenChange}
						triggerButtonText={this.renderFilterText()}
						testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.release-filter.trigger-btn"
						ariaLabel={this.ariaText()}
					/>
				)}
			/>
		);
	}
}

export default injectIntl(ReleaseFilter);
