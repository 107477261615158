// eslint-disable-next-line jira/restricted/ramda
import * as R from 'ramda';
import { VIEW_MODES } from '@atlassian/jira-portfolio-3-common/src/common/types/view-mode.tsx';
import { combineReducers } from '@atlassian/jira-portfolio-3-portfolio/src/common/redux/index.tsx';
import { RESET, type ResetAction } from './actions.tsx';
import colourBy from './colour-by/reducer.tsx';
import componentGroups from './component-groups/reducer.tsx';
import customFieldValuesGroups from './custom-field-values-groups/reducer.tsx';
import dateConstraints from './date-constraints-setting/reducer.tsx';
import dependencySettings from './dependency-settings/reducer.tsx';
import fieldColumns, { type Action as FieldColumnsAction } from './field-columns/reducer.tsx';
import filterOptions from './filter-options/reducer.tsx';
import filters from './filters/reducer.tsx';
import highlightedVersions from './highlighted-versions/reducer.tsx';
import issueExpansions from './issue-expansions/reducer.tsx';
import labelGroups from './label-groups/reducer.tsx';
import rollupSettings from './rollup-settings/reducer.tsx';
import timeScale from './time-scale/reducer.tsx';
import type { ViewSettingsState } from './types.tsx';
import viewMode from './view-mode/reducer.tsx';
import visualisations from './visualisations/reducer.tsx';
import warningSettings from './warning-settings/reducer.tsx';

type Action = ResetAction;

const reducersByKey = {
	filtersV1: filters,
	colourByV2: colourBy,
	timeScaleV1: timeScale,
	issueExpansionsV0: issueExpansions,
	fieldColumnsV0: fieldColumns,
	filterOptionsV1: filterOptions,
	rollupSettingsV0: rollupSettings,
	highlightedVersionsV0: highlightedVersions,
	componentGroupsV0: componentGroups,
	labelGroupsV0: labelGroups,
	customFieldValuesGroupsV0: customFieldValuesGroups,
	warningSettingsV1: warningSettings,
	dateConstraintsV0: dateConstraints,
	visualisationsV1: visualisations,
	dependencySettingsV0: dependencySettings,
	listFieldColumnsV0: fieldColumns,
	viewModeV0: viewMode,
} as const;

const FIELD_COLUMNS_KEY = 'fieldColumnsV0' as const;
const LIST_FIELD_COLUMNS_KEY = 'listFieldColumnsV0' as const;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const combined = combineReducers<any, any>(reducersByKey);

const initialState: ViewSettingsState = combined(undefined, { type: '' });

export default function reducer(
	state: ViewSettingsState = initialState,
	action: Action | FieldColumnsAction,
): ViewSettingsState {
	const resetState = initialState;

	switch (action.type) {
		case RESET: {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const pickExact: (arg1: Record<any, any>) => Record<any, any> = R.pick(
				Object.keys(resetState),
			);
			return R.mergeDeepLeft(pickExact(action.payload))(resetState);
		}

		default: {
			const fieldColumnsKey =
				state.viewModeV0?.mode === VIEW_MODES.LIST ? LIST_FIELD_COLUMNS_KEY : FIELD_COLUMNS_KEY;

			return {
				...state,
				...R.omit([FIELD_COLUMNS_KEY, LIST_FIELD_COLUMNS_KEY], combined(state, action)),
				[fieldColumnsKey]: fieldColumns(state[fieldColumnsKey], action),
			};
		}
	}
}
