import { getStartDateCustomField } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/custom-fields/index.tsx';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import { getShowAggregate } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/visualisations/index.tsx';
import type { MapStateToProps } from './types.tsx';

const mapStateToProps: MapStateToProps = createStructuredSelector({
	startDateCustomField: getStartDateCustomField,
	showAggregate: getShowAggregate,
});

export default mapStateToProps;
