import React, { type ComponentType, type SyntheticEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { AkFlag as Flag } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { FormattedMessage } from '@atlassian/jira-intl';
import colors from '@atlassian/jira-portfolio-3-common/src/colors/index.tsx';
import {
	fireUIAnalytics,
	MODAL,
	fireScreenAnalytics,
	ContextualAnalyticsData,
	MountEvent,
} from '@atlassian/jira-product-analytics-bridge';
import DescriptionDI from './description/index.tsx';
import messages from './messages.tsx';

export const getIssueLimitWarningFlag = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Description: ComponentType<Record<any, any>> = DescriptionDI,
	redirectToSpaPlanPage: (page: string) => void,
) => {
	const handleUpdateIssueSourcesButtonClick = (
		_: SyntheticEvent,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		fireUIAnalytics(
			analyticsEvent.update({
				actionSubject: 'issueLimitWarningModal',
				action: 'updateLinkClicked',
			}),
		);
		redirectToSpaPlanPage('/settings/issue-sources');
	};

	const handleSetExclusionRulesButtonClick = (
		_: SyntheticEvent,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		fireUIAnalytics(
			analyticsEvent.update({
				actionSubject: 'issueLimitWarningModal',
				action: 'setExclusionRulesLinkClicked',
			}),
		);
		redirectToSpaPlanPage('/settings/exclusion-rules');
	};

	const actions = [
		{
			content: (
				<FormattedMessage
					{...(fg('jira-issue-terminology-refresh-m3')
						? messages.updateEditIssueSourcesIssueTermRefresh
						: messages.updateEditIssueSources)}
				/>
			),
			onClick: handleUpdateIssueSourcesButtonClick,
		},
		{
			content: <FormattedMessage {...messages.setExclusionRules} />,
			onClick: handleSetExclusionRulesButtonClick,
		},
	];

	return (
		<Flag
			key="issue-limit-warning"
			id="issue-limit-warning"
			title={
				<ContextualAnalyticsData sourceType={MODAL} sourceName="issueLimitWarning">
					<MountEvent onMount={fireScreenAnalytics} />
					<FormattedMessage
						{...(fg('jira-issue-terminology-refresh-m3')
							? messages.titleIssueTermRefresh
							: messages.title)}
					/>{' '}
				</ContextualAnalyticsData>
			}
			description={<Description />}
			icon={<WarningIcon label="" primaryColor={token('color.icon.warning', colors.Y300)} />}
			actions={actions}
		/>
	);
};
