import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { fetchPinnedFieldsByProjectIds } from '../services/index.tsx';
import type { StoreApi } from '../types.tsx';

export const loadPinnedFieldsByProjectId =
	(projectId: ProjectId) =>
	async ({ getState, setState }: StoreApi) => {
		const currentState = getState();

		// don't call once again, when we have data
		if (currentState?.pinnedFields?.[projectId]) {
			return;
		}

		setState({
			...currentState,
			error: undefined,
		});

		try {
			const pinnedFieldsResponse = await fetchPinnedFieldsByProjectIds(projectId);
			setState({
				...currentState,
				pinnedFields: {
					...(currentState.pinnedFields || {}),
					...pinnedFieldsResponse.projectIdsToFieldIds,
				},
				error: undefined,
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			if (!isClientFetchError(error)) {
				log.safeErrorWithoutCustomerData(
					'portfolio-3.portfolio.common.view.ideas-cell.idea-issue.issue-card-pinned-fields.actions',
					'Failed to fetch pinned fields by project id.',
					error,
				);
			}
			setState({
				...getState(),
				error,
			});
		}
	};
