import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';

type PinnedFieldsResponse = {
	projectIdsToFieldIds?: { [key: string]: string[] };
};

const URL = '/gateway/api/polaris/rest/pinned';

export const fetchPinnedFieldsByProjectIds = async (projectId: ProjectId) => {
	const pinnedFieldsResponse = await fetchJson<PinnedFieldsResponse>(URL, {
		method: 'POST',
		body: `{ "projectIds": [${projectId}] }`,
	});

	return pinnedFieldsResponse;
};
