import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createIssue: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.create-issue.create-issue',
		defaultMessage: 'Create issue',
		description: 'Text for the Create Issue button',
	},
	addIssueDisabledTooltip: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.create-issue.add-issue-disabled-tooltip',
		defaultMessage:
			'You can create issues for issue groupings where only single values can be set.',
		description: 'Tooltip is shown when add issue button is disabled',
	},
	createIssueTypeDisabledTooltip: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.create-issue.create-issue-type-disabled-tooltip',
		defaultMessage:
			'This hierarchy level isn’t available for the projects used in this plan. Project admins can add issue types at this hierarchy level in the project settings.',
		description:
			'Shown as a tooltip when a hierarchy level is disabled in the create issue dropdown',
	},
	createIssueTypeDisabledTmpTooltip: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.create-issue.create-issue-type-disabled-tmp-tooltip',
		defaultMessage:
			'Add a company-managed project to your plan to use hierarchy levels above epic.',
		description:
			'Shown as a tooltip when a hierarchy level is disabled in the create issue dropdown as the plan issue sources are TMP only',
	},
	createIssueHiddenEntryTooltip: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.create-issue.create-issue-hidden-entry-tooltip',
		defaultMessage:
			'This level is currently hidden. To create {hierarchy} issues, include this level in the hierarchy range.',
		description: 'Shown as a tooltip when a hierarchy level is hidden in the create issue dropdown',
	},
	createIssueIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.create-issue.create-issue-issue-term-refresh',
		defaultMessage: 'Create',
		description: 'Text for the Create Issue button',
	},
	addIssueDisabledTooltipIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.create-issue.add-issue-disabled-tooltip-issue-term-refresh',
		defaultMessage: 'You can create work items for groupings where only single values can be set.',
		description: 'Tooltip is shown when add issue button is disabled',
	},
	createIssueTypeDisabledTooltipIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.create-issue.create-issue-type-disabled-tooltip-issue-term-refresh',
		defaultMessage:
			'This hierarchy level isn’t available for the projects used in this plan. Project admins can add work types at this hierarchy level in the project settings.',
		description:
			'Shown as a tooltip when a hierarchy level is disabled in the create issue dropdown',
	},
	createIssueHiddenEntryTooltipIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.create-issue.create-issue-hidden-entry-tooltip-issue-term-refresh',
		defaultMessage:
			'This level is currently hidden. To create {hierarchy} work items, include this level in the hierarchy range.',
		description: 'Shown as a tooltip when a hierarchy level is hidden in the create issue dropdown',
	},
});
