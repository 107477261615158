import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	selectHeaderAriaLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.select-change.header.select-header-aria-label',
		defaultMessage: 'Select all issues',
		description: 'Aria label text for changes table select all check box header',
	},
	selectHeaderAriaLabelIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.select-change.header.select-header-aria-label-issue-term-refresh',
		defaultMessage: 'Select all work items',
		description: 'Aria label text for changes table select all check box header',
	},
});
export default messages;
