/** @jsx jsx */
import React, { type PropsWithChildren } from 'react';
import { css, styled, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { Flex, xcss } from '@atlaskit/primitives';
import Heading from '@atlaskit/heading';
import { fg } from '@atlassian/jira-feature-gating';

interface Props {
	title: string;
}

const LegendSection = ({ title, children }: PropsWithChildren<Props>) =>
	fg('jsw_roadmaps_timeline-fix-a11y-rain') ? (
		<Flex direction="column" gap="space.100">
			<Heading as="h2" size="xxsmall">
				{title}
			</Heading>
			<Flex direction="column" gap="space.100" as="ul" xcss={listStyles}>
				{children}
			</Flex>
		</Flex>
	) : (
		<Container>
			<span css={TitleStyles}>{title}</span>
			{children}
		</Container>
	);

export default LegendSection;

const listStyles = xcss({
	padding: '0',
	margin: '0',
	listStyleType: 'none',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	flexDirection: 'column',
	gap: token('space.100', '8px'),
});

const TitleStyles = css({
	color: token('color.text.subtle'),
	font: token('font.heading.xxsmall'),
	lineHeight: token('space.250'),
});
