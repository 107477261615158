import React, { Component } from 'react';
import StandardButton from '@atlaskit/button/standard-button';
import ErrorIcon from '@atlaskit/icon/glyph/error';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, {
	ModalHeader,
	ModalTitle,
	ModalBody,
	ModalFooter,
} from '@atlaskit/modal-dialog'; // ignore-for-ENGHEALTH-17759
import { token } from '@atlaskit/tokens';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { AkFlag as Flag, FlagGroup, type FlagProps as FlagType } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { type IntlShape, FormattedMessage, type MessageDescriptor } from '@atlassian/jira-intl';
import { injectIntlWithDefaultProps } from '@atlassian/jira-portfolio-3-portfolio/src/common/inject-intl-with-default-props/index.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import type { ErrorType } from '../../state/ui/errors/types.tsx';
import ViewExperienceFailureTracker from '../failure-tracker/index.tsx';
import ErrorDetail from './error-detail/index.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props, State } from './types.tsx';

const Description = ({ description }: { description?: MessageDescriptor }) => {
	const emailLink = (
		<a href="https://support.atlassian.com/contact" target="_blank" rel="noopener noreferrer">
			https://support.atlassian.com/contact
		</a>
	);

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles['email-link']}>
			<FormattedMessage {...(description || messages.errorMessage)} values={{ emailLink }} />
		</div>
	);
};

const getFlag = ({ id, title, description }: ErrorType, intl: IntlShape): FlagType => ({
	id,
	title: title || intl.formatMessage(messages.errorTitle),
	appearance: 'error',
	description: <Description description={description} />,
	icon: (
		<ErrorIcon
			label={intl.formatMessage(messages.errorIconLabel)}
			primaryColor={token('color.icon.danger', '#FF5630')}
		/>
	),
});

// eslint-disable-next-line jira/react/no-class-components
class ErrorNotification extends Component<Props, State> {
	static defaultProps = {
		ViewExperienceFailureTracker,
	};

	dismissFlag = () => {
		const { hideError, hideErrorLogDialog } = this.props;
		hideErrorLogDialog();
		hideError();
	};

	dismissAllFlag = () => {
		const { hideAllErrors, hideErrorLogDialog } = this.props;
		hideErrorLogDialog();
		hideAllErrors();
	};

	render() {
		// hiding error notifications flags on StoryBook to prevent flakey Cypress tests

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		if (window?.STORYBOOK_ENV) {
			return null;
		}
		const {
			intl,
			errors,
			isErrorLogDialogOpen,
			showErrorLogDialog,
			hideErrorLogDialog,
			// eslint-disable-next-line @typescript-eslint/no-shadow
			ViewExperienceFailureTracker,
		} = this.props;
		const actionsSingleError = [
			{
				content: intl.formatMessage(messages.viewLog),
				onClick: showErrorLogDialog,
			},
			{
				content: intl.formatMessage(messages.dismiss),
				onClick: this.dismissFlag,
				testId:
					'portfolio-3-portfolio.app-simple-plans.error-notification.error-notification-flag.dismiss-btn',
			},
		];
		const actions = [
			...actionsSingleError,
			{
				content: intl.formatMessage(messages.dismissAll),
				onClick: this.dismissAllFlag,
				testId:
					'portfolio-3-portfolio.app-simple-plans.error-notification.error-notification-flag.dismiss-all-btn',
			},
		];

		const errorOnTop = errors[errors.length - 1];
		let errorDetails;
		let ErrorInfoComponent;
		let ErrorDetailsComponent;

		if (errorOnTop) {
			const errorInfo = {
				title: 'Stack Trace',
				sections: [
					{
						title: `${errorOnTop.message}\n\n`,
						info: errorOnTop.stackTrace || intl.formatMessage(messages.stackTraceNotFoundMessage),
					},
				],
			};

			ErrorInfoComponent = <ErrorDetail {...errorInfo} />;

			if (errorOnTop.details) {
				errorDetails = errorOnTop.details;
				let requestInfoComponent;

				if (errorDetails.requestInfo) {
					const requestInfo = {
						title: 'Request Information',
						sections: [
							{
								title: 'Url: ',
								info: errorDetails.requestInfo.url,
							},
							{
								title: 'Type: ',
								info: errorDetails.requestInfo.type,
							},
							{
								title: 'Status: ',
								info: errorDetails.requestInfo.status,
							},
							{
								title: 'Data: ',
								info: JSON.stringify(errorDetails.requestInfo.body, undefined, 2) || '',
							},
						],
					};

					requestInfoComponent = <ErrorDetail {...requestInfo} />;
				}
				const clientInfo = {
					title: 'Client Information',
					sections: [
						{
							title: 'User Agent: ',
							info: errorDetails.clientInfo.userAgent,
						},
						{
							title: 'Local Time: ',
							info: errorDetails.clientInfo.localTime,
						},
					],
				};
				const systemInfo = {
					title: 'System Information',
					sections: [
						{
							title: 'Jira Title: ',
							info: errorDetails.systemInfo.jiraTitle,
						},
					],
				};

				ErrorDetailsComponent = (
					<>
						{requestInfoComponent}
						<ErrorDetail {...clientInfo} />
						<ErrorDetail {...systemInfo} />
					</>
				);
			}
		}
		return (
			<>
				<ViewExperienceFailureTracker location="portfolio-3.simple-plans.state-or-global-error" />
				<FlagGroup onDismissed={this.dismissFlag}>
					{errors
						.map((error) => getFlag(error, intl))
						.map((flag) => (
							<Flag
								actions={errors.length === 1 ? actionsSingleError : actions}
								{...flag}
								key={flag.id}
								testId="portfolio-3-portfolio.app-simple-plans.error-notification.error-notification-flag"
							/>
						))}
				</FlagGroup>
				{isErrorLogDialogOpen && (
					<ShortcutScope>
						<ModalDialog onClose={() => hideErrorLogDialog()} width="x-large" autoFocus>
							<ModalHeader>
								<ModalTitle appearance="danger">
									{errorOnTop.title || intl.formatMessage(messages.errorTitle)}
								</ModalTitle>
							</ModalHeader>
							<ModalBody>
								{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
								<div className={styles['error-log']}>
									{ErrorDetailsComponent}
									{ErrorInfoComponent}
								</div>
							</ModalBody>
							<ModalFooter>
								<StandardButton appearance="danger" onClick={() => hideErrorLogDialog()}>
									{intl.formatMessage(commonMessages.cancel)}
								</StandardButton>
							</ModalFooter>
						</ModalDialog>
					</ShortcutScope>
				)}
			</>
		);
	}
}

export default injectIntlWithDefaultProps(ErrorNotification);
