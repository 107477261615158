import React, { useCallback } from 'react';
import Button from '@atlaskit/button/standard-button';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const TodayButton = ({ onClick, testId, isHidden }: Props) => {
	const { formatMessage } = useIntl();

	const onClickHandler = useCallback(
		(e: React.MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			if (fg('plan_timeline_core_action_analytics')) {
				fireUIAnalytics(analyticsEvent, 'today');
			}
			onClick(e, analyticsEvent);
		},
		[onClick],
	);

	return (
		<Button
			testId={testId}
			appearance="subtle"
			onClick={onClickHandler}
			{...(fg('platform_button_item-add-ufo-metrics')
				? { interactionName: 'timeline-today-clicked' }
				: {})}
			{...(fg('jsw_roadmaps_timeline-fix-a11y-rain')
				? { 'aria-hidden': isHidden, tabIndex: isHidden ? -1 : 0 }
				: {})}
		>
			{formatMessage(messages.today)}
		</Button>
	);
};

export default TodayButton;
