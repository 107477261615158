import type { Dispatch } from 'redux';
import { updateIssueRaw } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/issue/index.tsx';
import type { UpdateIssuePayload } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/issue/types.tsx';

import type { DispatchProps } from './types.tsx';

export const issueModalTriggerCommand = (dispatch: Dispatch): DispatchProps => ({
	updateIssue: (payload: UpdateIssuePayload) => {
		dispatch(updateIssueRaw(payload));
	},
});
