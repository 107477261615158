:root {
	--ranked-gutter-size: 5px;
}

.container {
	height: 40px;
	padding: 12px 0 12px 0;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	cursor: pointer;
}

.rank {
	color: var(--ds-text-subtlest, var(--adg3-color-subtleText-light));
	padding: 0;
}

.ranked-gutter {
	min-width: var(--ranked-gutter-size);
	max-width: var(--ranked-gutter-size);
	width: var(--ranked-gutter-size);
	height: 40px;
	margin-right: calc(var(--jpo-common-base-spacing) - var(--ranked-gutter-size));
}

.icon {
	width: var(--jpo-icon-size-medium);
	min-width: var(--jpo-icon-size-medium);
	height: var(--jpo-icon-size-medium);
	padding: 0;
	cursor: pointer;
}

.spacer {
	height: var(--jpo-icon-size-medium);
	padding: 0;
	cursor: pointer;
}

.select-checkbox {
	min-width: var(--jpo-icon-size-large);
	user-select: none;
}

.issue-icon-old {
	width: var(--jpo-icon-size-small);
	min-width: var(--jpo-icon-size-small);
	height: var(--jpo-icon-size-small);
	margin-right: 5px;
}

.issue-icon {
	width: var(--jpo-icon-size-small);
	min-width: var(--jpo-icon-size-small);
	height: var(--jpo-icon-size-small);
	margin-right: 5px;
	pointer-events: none;
}

.issue-icon-new {
	width: var(--jpo-icon-size-small);
	min-width: var(--jpo-icon-size-small);
	height: var(--jpo-icon-size-small);
	margin-right: 5px;
	display: block;
}

.issueIconTooltipWrapper {
	align-self: baseline;
	display: flex;
}

/* Keep the + icon on an issue if we're hovering on the line OR we've already expanded the dropdown */
.container:hover .addIcon,
.container [aria-expanded='true'] .addIcon {
	display: flex;
}

/* NOTE Do not remove, it's used to pass properly munged class name used in selector above down to CreateChild */
.addIcon {
}

.toggle {
	outline: none;
	border: none;
	background-color: transparent;
}

.link {
	display: flex;
	white-space: nowrap;
	margin-right: 5px;
}

.parent-match {
	background-color: var(--ds-background-accent-purple-subtle, rgba(135, 119, 217, 0.5));
	border-radius: 2px;
	width: 14px;
	min-width: 14px;
	margin: 0 5px;
	& > span {
		margin-left: -5px;
	}
}

.summary {
	/* flex items will refuse to shrink below their minimum intrinsic width, unless you explicitly specify "min-width" or "width" or "max-width" on them */
	/* we need to set both min-width and width to enable shrinking and to still have growth */
	min-width: 0;
	flex: 1 1 0;

	& > form > div {
		margin-top: 0px;
		margin-left: 0px;
	}

	& input {
		cursor: initial;
	}
}

.summaryReadView {
	display: flex;
	line-height: 1.42857;
	min-height: 1.42857em;
	padding: var(--jpo-common-base-spacing) 6px;
}

.ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.light-summary {
	pointer-events: none;
	/* to compensate weird InlineEdit margin-left: -8px; */
	padding-left: var(--jpo-common-base-spacing);
	/* flex items will refuse to shrink below their minimum intrinsic width, unless you explicitly specify "min-width" or "width" or "max-width" on them */
	/* we need to set both min-width and width to enable shrinking and to still have growth */
	min-width: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: flex;
	align-items: center;
	flex: 1 1 0;
	padding-right: 21px;
}

.summaryBtn {
	padding-left: 4px !important;
	padding-right: 4px !important;
}

.odd {
	background-color: var(--ds-background-accent-gray-subtlest, var(--adg3-color-N20));
}

.oddOptimized {
	background-color: var(--ds-background-accent-gray-subtlest, var(--adg3-color-N20-transparent));
}

.even {
	background-color: var(--ds-surface, var(--adg3-color-N0));
}

.evenOptimized {
	background-color: transparent;
}

.warnings {
	background-color: var(--ds-background-warning, var(--adg3-color-Y50));
}

.warningsOptimized {
	background-color: var(--ds-background-warning-hovered, color(var(--adg3-color-Y75) a(33%)));
}

.isDragging {
	background-color: var(--ds-background-selected, var(--adg3-color-B50));
}

.isSelected {
	background-color: var(--ds-background-selected, var(--adg3-color-B50));
}

.drop-parent {
	background-color: var(--ds-background-selected, var(--adg3-color-B50));
	border-color: var(--ds-border-focused, var(--adg3-color-B200));
	border-width: 2px 3px 2px 2px;
	border-style: solid;
}

.drop-parent .ranked-gutter {
	height: 36px;
	margin-top: 2px;
	margin-bottom: 2px;
}

.flyoutPositioner {
	position: absolute;
}

.group-indentation {
	min-width: calc(var(--jpo-common-base-spacing) * 5);
}
