import React, {
	Component,
	type KeyboardEvent,
	type MouseEvent,
	createRef,
	type KeyboardEventHandler,
} from 'react';
import ReactFocusLock from 'react-focus-lock';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { IconButton } from '@atlaskit/button/new';
import PreferencesIcon from '@atlaskit/icon/glyph/preferences';
import Popup, { type TriggerProps } from '@atlaskit/popup'; // ignore-for-ENGHEALTH-17759
import { Box, xcss } from '@atlaskit/primitives';
import { FormattedMessage, injectIntl, type IntlShape } from '@atlassian/jira-intl';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import Checkbox from '@atlassian/jira-portfolio-3-common/src/checkbox/index.tsx';
import InlineDialog from '@atlassian/jira-portfolio-3-common/src/inline-dialog/index.tsx';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types.tsx';
import { isColorByCustomFieldOption } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/visualisations/index.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { colourByOptions } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/colours/index.tsx';
import {
	type Grouping,
	GROUPING,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import ColourByComponent from './colour-by-component/index.tsx';
import ColourByIssueType from './colour-by-issue-type/index.tsx';
import ColourByLabel from './colour-by-label/index.tsx';
import ColourByPriority from './colour-by-priority/index.tsx';
import ColourBySelect from './colour-by-select/index.tsx';
import ColourBy from './colour-by/index.tsx';
import colourByMessages from './colour-by/messages.tsx';
import DependencySettings from './dependency-settings/index.tsx';
import GroupByComponents from './group-by/components/index.tsx';
import GroupByCustomFieldValues from './group-by/custom-fields/index.tsx';
import GroupBy from './group-by/index.tsx';
import GroupByLabels from './group-by/labels/index.tsx';
import messages from './messages.tsx';
import EstimateRollup from './rollup-settings/index.tsx';
import SortBy from './sort-by/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props, State } from './types.tsx';

type HocProps = {
	intl: IntlShape;
};

export type ViewSettingsProps = Props & HocProps;

// eslint-disable-next-line jira/react/no-class-components
export class ViewSettings extends Component<ViewSettingsProps, State> {
	static defaultProps = {
		ColourBy,
		ColourByIssueType,
		ColourByLabel,
		ColourByComponent,
		ColourByPriority,
		ColourBySelect,
		DependencySettings,
		GroupBy,
		GroupByComponents,
		SortBy,
		EstimateRollup,
		groupingByMultiValueCustomField: false,
	};

	state = {
		isOpen: !!this.props.isDefaultOpen,
		isDialogLocked: false,
	};

	buttonRef = createRef<HTMLElement>();

	toggleDialogLocked = (isDialogLocked: boolean) => {
		this.setState({ isDialogLocked });
	};

	toggleMenu = (_: KeyboardEvent | MouseEvent, analyticsEvent: UIAnalyticsEvent) => {
		this.setState(({ isOpen }) => {
			if (!isOpen) {
				const [actionSubject, action] =
					PRODUCT_ANALYTICS_EVENT_NAMES.INLINE_DIALOG_VIEWED.split(' ');
				fireUIAnalytics(analyticsEvent.update({ actionSubject, action }));
			}
			return { isOpen: !isOpen };
		});
	};

	renderTriggerButton = (props?: TriggerProps) => {
		const {
			intl: { formatMessage },
		} = this.props;
		const { isOpen } = this.state;

		return isVisualRefreshEnabled() ? (
			<IconButton
				icon={() => <PreferencesIcon label="" />}
				label={formatMessage(messages.triggerButtonLabel)}
				isSelected={isOpen}
				onClick={this.toggleMenu}
				ref={this.buttonRef}
				aria-expanded={this.state.isOpen}
				testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.button"
				isTooltipDisabled={false}
				{...props}
			/>
		) : (
			<Button
				iconBefore={<PreferencesIcon label="" />}
				isSelected={isOpen}
				onClick={this.toggleMenu}
				ref={this.buttonRef}
				aria-expanded={this.state.isOpen}
				testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.button"
				{...props}
			>
				{formatMessage(messages.triggerButtonLabel)}
			</Button>
		);
	};

	renderColourByIssueTypeLegend = () => {
		// eslint-disable-next-line @typescript-eslint/no-shadow
		const { ColourByIssueType } = this.props;
		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<tr className={styles.row}>
				<td />
				<td colSpan={2}>
					{ColourByIssueType && <ColourByIssueType onMenuToggle={this.toggleDialogLocked} />}
				</td>
			</tr>
		);
	};

	renderColourByLabelLegend = () => {
		// eslint-disable-next-line @typescript-eslint/no-shadow
		const { ColourByLabel } = this.props;
		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<tr className={styles.row}>
				<td />
				<td colSpan={2}>
					{ColourByLabel && <ColourByLabel onMenuToggle={this.toggleDialogLocked} />}
				</td>
			</tr>
		);
	};

	renderColourByComponentLegend = () => {
		// eslint-disable-next-line @typescript-eslint/no-shadow
		const { ColourByComponent } = this.props;
		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<tr className={styles.row}>
				<td />
				<td colSpan={2}>
					{ColourByComponent && <ColourByComponent onMenuToggle={this.toggleDialogLocked} />}
				</td>
			</tr>
		);
	};

	renderColourByPriorityLegend = () => {
		// eslint-disable-next-line @typescript-eslint/no-shadow
		const { ColourByPriority } = this.props;

		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<tr className={styles.row}>
				<td />
				<td colSpan={2}>
					{ColourByPriority && <ColourByPriority onMenuToggle={this.toggleDialogLocked} />}
				</td>
			</tr>
		);
	};

	renderColourBySelectLegend = () => {
		// eslint-disable-next-line @typescript-eslint/no-shadow
		const { ColourBySelect } = this.props;
		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<tr className={styles.row}>
				<td />
				<td colSpan={2}>
					{ColourBySelect && <ColourBySelect onMenuToggle={this.toggleDialogLocked} />}
				</td>
			</tr>
		);
	};

	renderColourByClearButton = () => {
		const {
			clearAllComponentColours,
			clearAllIssueTypeColours,
			clearAllLabelColours,
			clearAllPriorityColours,
			clearAllSelectColours,
			colourByConfiguration: { colourByValue },
		} = this.props;

		return (
			<Button
				appearance="link"
				onClick={(e) => {
					// This is required to keep ViewSettings InlineDialog open.
					e.preventDefault();

					if (colourByValue === colourByOptions.COMPONENT) {
						clearAllComponentColours();
					} else if (colourByValue === colourByOptions.ISSUE_TYPE) {
						clearAllIssueTypeColours();
					} else if (colourByValue === colourByOptions.LABEL) {
						clearAllLabelColours();
					} else if (colourByValue === colourByOptions.PRIORITY) {
						clearAllPriorityColours();
					} else if (isColorByCustomFieldOption(colourByValue)) {
						clearAllSelectColours();
					}
				}}
				spacing="compact"
				testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.clear-all-colors"
			>
				<FormattedMessage {...messages.clearAllColours} />
			</Button>
		);
	};

	shouldShowClearButton = () => {
		const {
			colourByConfiguration: {
				colourByValue,
				colourIssueTypes,
				colourLabels,
				colourPriorities,
				colourSelects,
				colourComponents,
			},
		} = this.props;

		if (colourByValue === colourByOptions.COMPONENT) {
			const isAddingNewComponentColour = colourComponents.some(({ colour }) => !colour);
			return colourComponents.length > (isAddingNewComponentColour ? 1 : 0);
		}

		if (colourByValue === colourByOptions.ISSUE_TYPE) {
			if (isDefined(colourIssueTypes)) {
				const isAddingNewIssueTypeColour = colourIssueTypes.some(({ colour }) => !colour);
				return colourIssueTypes.length > (isAddingNewIssueTypeColour ? 1 : 0);
			}
			return false;
		}

		if (colourByValue === colourByOptions.LABEL) {
			const isAddingNewLabelColour = colourLabels.some(({ colour }) => !colour);
			return colourLabels.length > (isAddingNewLabelColour ? 1 : 0);
		}

		if (colourByValue === colourByOptions.PRIORITY) {
			if (isDefined(colourPriorities)) {
				const isAddingNewPriorityColour = colourPriorities.some(({ colour }) => !colour);
				return colourPriorities.length > (isAddingNewPriorityColour ? 1 : 0);
			}
			return false;
		}

		if (isColorByCustomFieldOption(colourByValue)) {
			const isAddingNewSelectColour = (colourSelects[colourByValue] || []).some(
				({ colour }) => !colour,
			);
			return (colourSelects[colourByValue] || []).length > (isAddingNewSelectColour ? 1 : 0);
		}
		return false;
	};

	shouldShowClearAllGroupsButton = () => {
		const { grouping, groupingByMultiValueCustomField } = this.props;

		return (
			grouping === GROUPING.COMPONENT ||
			grouping === GROUPING.LABEL ||
			groupingByMultiValueCustomField
		);
	};

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onClearAllGroups = (e: Partial<KeyboardEvent | MouseEvent> = { preventDefault: () => {} }) => {
		// This is required to keep ViewSettings InlineDialog open.
		e.preventDefault && e.preventDefault();

		const {
			clearAllCustomFieldValuesGroups,
			clearAllComponentGroups,
			clearAllLabelGroups,
			grouping,
		} = this.props;

		if (grouping === GROUPING.COMPONENT) {
			return clearAllComponentGroups();
		}
		if (grouping === GROUPING.LABEL) {
			return clearAllLabelGroups();
		}

		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return clearAllCustomFieldValuesGroups(grouping as Grouping);
	};

	renderClearAllGroupsButton = () => (
		<Button
			appearance="link"
			spacing="compact"
			onClick={this.onClearAllGroups}
			testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.clear-all-groups"
		>
			<FormattedMessage {...messages.clearAllGroups} />
		</Button>
	);

	handleKeyDownCapture: KeyboardEventHandler = (e) => {
		if (e.key === 'Escape' && !this.state.isDialogLocked) {
			this.setState({ isOpen: false }, () => {
				this.buttonRef.current?.focus();
			});
		}
	};

	renderContent = () => {
		const {
			// eslint-disable-next-line @typescript-eslint/no-shadow
			ColourBy,
			// eslint-disable-next-line @typescript-eslint/no-shadow
			DependencySettings,
			// eslint-disable-next-line @typescript-eslint/no-shadow
			GroupBy,
			// eslint-disable-next-line @typescript-eslint/no-shadow
			GroupByComponents,
			// eslint-disable-next-line @typescript-eslint/no-shadow
			SortBy,
			// eslint-disable-next-line @typescript-eslint/no-shadow
			EstimateRollup,
			colourByConfiguration: { colourByValue },
			grouping,
			showSprints,
			toggleShowSprints,
			showAggregate,
			toggleShowAggregate,
			intl,
			groupingByMultiValueCustomField,
		} = this.props;

		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={styles.settingsList} onKeyDownCapture={this.handleKeyDownCapture}>
				<table>
					<tbody>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<tr className={styles.row}>
							<td>
								<FormattedMessage {...colourByMessages.colourByLabel} />
							</td>
							<td
								// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
								className={styles.viewSettingSelect}
								{...(!this.shouldShowClearButton() && { colSpan: 2 })}
							>
								{ColourBy && <ColourBy onMenuToggle={this.toggleDialogLocked} />}
							</td>
							{this.shouldShowClearButton() && (
								// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
								<td className={styles.clearAllButton}>{this.renderColourByClearButton()}</td>
							)}
						</tr>
						{colourByValue === colourByOptions.COMPONENT && this.renderColourByComponentLegend()}
						{colourByValue === colourByOptions.ISSUE_TYPE
							? this.renderColourByIssueTypeLegend()
							: null}
						{colourByValue === colourByOptions.LABEL && this.renderColourByLabelLegend()}
						{colourByValue === colourByOptions.PRIORITY
							? this.renderColourByPriorityLegend()
							: null}
						{isColorByCustomFieldOption(colourByValue) && this.renderColourBySelectLegend()}
						{SortBy && <SortBy onMenuToggle={this.toggleDialogLocked} />}
						<tr
							/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */
							className={isVisualRefreshEnabled() ? styles.groupByRow : styles.row}
						>
							<td>
								<FormattedMessage {...commonMessages.groupBy} />
							</td>
							<td
								// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
								className={styles.viewSettingSelect}
								{...(!this.shouldShowClearAllGroupsButton() && {
									colSpan: 2,
								})}
							>
								{GroupBy && <GroupBy onMenuToggle={this.toggleDialogLocked} />}
							</td>
							{this.shouldShowClearAllGroupsButton() && (
								// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
								<td className={styles.clearAllButton}>{this.renderClearAllGroupsButton()}</td>
							)}
						</tr>
						{grouping === GROUPING.COMPONENT && GroupByComponents && (
							<GroupByComponents onMenuToggle={this.toggleDialogLocked} />
						)}
						{grouping === GROUPING.LABEL && GroupByLabels && (
							<GroupByLabels onMenuToggle={this.toggleDialogLocked} />
						)}
						{groupingByMultiValueCustomField && GroupByCustomFieldValues && (
							<GroupByCustomFieldValues onMenuToggle={this.toggleDialogLocked} />
						)}
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<tr className={styles.row}>
							<td />
							<td colSpan={2}>
								{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
								<div className={styles.showCapacityCheckbox}>
									<Checkbox
										defaultChecked={showSprints}
										isDisabled={grouping !== GROUPING.SPRINT && grouping !== GROUPING.TEAM}
										label={intl.formatMessage({
											...messages.showSprintsOnTimeline,
										})}
										onChange={(_, analyticsEvent) => {
											const nextValue = !showSprints;
											const [actionSubject, action, actionSubjectId] =
												PRODUCT_ANALYTICS_EVENT_NAMES.SHOW_CAPACITY_ON_TIMELINE_CHANGED.split(' ');
											fireUIAnalytics(
												analyticsEvent.update({
													actionSubject,
													action,
												}),
												actionSubjectId,
												{ newPlanSettingValue: nextValue },
											);

											toggleShowSprints(nextValue);
										}}
										testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.show-capacity-checkbox"
										value="show-sprints"
									/>
								</div>

								{grouping !== GROUPING.SPRINT && grouping !== GROUPING.TEAM && (
									<div
										// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
										className={styles.checkboxInfo}
										data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.show-capacity-checkbox-info"
									>
										<FormattedMessage {...messages.onlyAvailableForTeamOrSprint} />
									</div>
								)}

								{fg('aggregate_fields_for_plan_m2') && (
									// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop
									<div className={styles.showAggregateToggle}>
										<Checkbox
											defaultChecked={showAggregate}
											isDisabled={grouping === GROUPING.NONE}
											label={intl.formatMessage({
												...messages.showAggregateValue,
											})}
											onChange={(_, analyticsEvent) => {
												const nextValue = !showAggregate;
												const [actionSubject, action, actionSubjectId] =
													PRODUCT_ANALYTICS_EVENT_NAMES.SHOW_AGGREGATE_VALUE.split(' ');
												fireUIAnalytics(
													analyticsEvent.update({
														actionSubject,
														action,
													}),
													actionSubjectId,
													{ newPlanSettingValue: nextValue },
												);

												toggleShowAggregate(nextValue);
											}}
											testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.show-aggregate"
											value="show-aggregate"
										/>
									</div>
								)}
							</td>
						</tr>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<tr className={styles.row}>
							{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
							<td className={styles.topAlignedTd}>
								<FormattedMessage {...messages.dependencySettingsLabel} />
							</td>
							{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
							<td colSpan={2} className={styles.topAlignedTd}>
								{DependencySettings && (
									<DependencySettings onMenuToggle={this.toggleDialogLocked} />
								)}
							</td>
						</tr>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<tr className={styles.row}>
							{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
							<td className={styles.topAlignedTd}>
								{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
								<div className={styles.rollupSetting}>
									<FormattedMessage {...messages.rollupLabel} />
								</div>
							</td>
							{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
							<td colSpan={2} className={styles.topAlignedTd}>
								{EstimateRollup && <EstimateRollup />}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		);
	};

	render() {
		const { intl } = this.props;

		return getWillShowNav4() ? (
			<Popup
				content={() => (
					<Box xcss={contentContainerStyles} paddingInline="space.300" paddingBlock="space.200">
						{this.renderContent()}
					</Box>
				)}
				isOpen={this.state.isOpen}
				trigger={this.renderTriggerButton}
				onClose={() => {
					if (this.state.isDialogLocked) {
						return;
					}
					this.setState({ isOpen: false }, () => {
						this.buttonRef.current?.focus();
					});
				}}
				placement="bottom-end"
				shouldRenderToParent
				role="dialog"
				label={intl.formatMessage(messages.triggerButtonLabel)}
			/>
		) : (
			<InlineDialog
				content={<ReactFocusLock>{this.renderContent()}</ReactFocusLock>}
				isOpen={this.state.isOpen}
				onClose={() => {
					if (this.state.isDialogLocked) {
						return;
					}
					this.setState({ isOpen: false }, () => {
						this.buttonRef.current?.focus();
					});
				}}
				placement="bottom-end"
			>
				{this.renderTriggerButton()}
			</InlineDialog>
		);
	}
}

export default injectIntl(ViewSettings);

const contentContainerStyles = xcss({
	maxWidth: '496px',
});
