import React from 'react';
import type { EmojiDescription } from '@atlaskit/emoji';
import { xcss, Box } from '@atlaskit/primitives';
import { getAppliedNumberDecoration } from '@atlassian/jira-polaris-component-decorations/src/utils/index.tsx';
import type { ValueDecoration } from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import { CommonDecoratedTag } from '@atlassian/jira-polaris-lib-decoration/src/ui/decoration/common/index.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import type { CommonFieldComponentProps } from '../../common/types.tsx';
import { useFieldValueDecorations } from '../../controllers/fields/index.tsx';

export const isNumberFieldComponentProps = (
	props: CommonFieldComponentProps,
): props is NumberFieldComponentProps => 'value' in props && typeof props.value === 'number';

export type NumberFieldComponentProps = CommonFieldComponentProps & {
	value: number;
};

export const NumberField = ({ fieldKey, value }: NumberFieldComponentProps) => {
	const [fieldValueDecorations] = useFieldValueDecorations(fieldKey);

	const decoration = getAppliedNumberDecoration(fieldValueDecorations, value);
	const emojiDescription = useEmoji(decoration?.emoji);

	return (
		<NumberFieldDisplay value={value} decoration={decoration} emojiDescription={emojiDescription} />
	);
};

export const NumberFieldDisplay = ({
	decoration,
	emojiDescription,
	value,
}: {
	value: number;
	decoration: ValueDecoration | undefined;
	emojiDescription: EmojiDescription | undefined;
}) => (
	<Box xcss={maxContentStyles}>
		<CommonDecoratedTag
			value={`${value}`}
			mainColor={decoration?.backgroundColor}
			emoji={emojiDescription}
		/>
	</Box>
);

const maxContentStyles = xcss({
	width: 'max-content',
});
