import React from 'react';
import { useFlagsService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { AssociatedIssuesContainer } from '@atlassian/jira-portfolio-3-associated-issues/src/controllers/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { AssociatedIssuesChangeboardingFlag } from './associated-issues-changeboarding-flag/index.tsx';
import AssociatedIssuesSyncWrapper from './associated-issues-sync-wrapper/index.tsx';
import type { WrapperProps } from './types.tsx';

// Use the same pattern as for Goals for consistency (use AssociatedIssuesSyncWrapper for sync state)
// isConfluenceMacroProxyMode pattern is a workaround for POL-12202
const Wrapper = ({ associatedIssueIds, children, isConfluenceMacroProxyMode }: WrapperProps) => {
	const cloudId = useCloudId();
	const { showFlag } = useFlagsService();
	const intlApi = useIntl();
	const isFlagAbleToShow = !isConfluenceMacroProxyMode;

	return (
		<AssociatedIssuesContainer
			associatedIssueIds={associatedIssueIds}
			cloudId={cloudId}
			showFlag={showFlag}
			intlApi={intlApi}
			isConfluenceMacroProxyMode={isConfluenceMacroProxyMode}
		>
			<AssociatedIssuesSyncWrapper />
			{children}
			{isFlagAbleToShow ? <AssociatedIssuesChangeboardingFlag /> : null}
		</AssociatedIssuesContainer>
	);
};

export default Wrapper;
