/** @jsx jsx */
/* eslint-disable  @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */
import React, {
	type CSSProperties,
	type PropsWithChildren,
	memo,
	type ReactNode,
	useCallback,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { css, jsx } from '@compiled/react';
import omit from 'lodash/fp/omit';
import isEqual from 'lodash/fp/isEqual';
import { CardClient as Client, SmartCardProvider } from '@atlaskit/link-provider';
import { Box, xcss } from '@atlaskit/primitives';
import UFOCustomData from '@atlaskit/react-ufo/custom-data';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { ViewExperienceTrackingProvider } from '@atlassian/jira-common-experience-tracking-viewing/src/view/experience-tracking-provider/index.tsx';
import ReportErrors from '@atlassian/jira-errors-handling/src/utils/reporting-error-boundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedMessage } from '@atlassian/jira-intl';
import { useIsFullscreen } from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/fullscreen/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import {
	VIEW_MODES,
	type ViewMode,
} from '@atlassian/jira-portfolio-3-common/src/common/types/view-mode.tsx';
import { useNativeScrollbarHeight } from '@atlassian/jira-portfolio-3-common/src/custom-scrollbar/utils.tsx';
import { isPollinatorTenant } from '@atlassian/jira-portfolio-3-common/src/feature-flags/index.tsx';
import HorizontalScrollBarOverlay from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/ui/scroll-bar-overlay/index.tsx';
import { useCttSpotlight } from '@atlassian/jira-portfolio-3-onboarding/src/controllers/ctt-onboarding/index.tsx';
import { CttSpotlights } from '@atlassian/jira-portfolio-3-onboarding/src/controllers/ctt-spotlights/index.tsx';
import {
	Spotlights,
	useCurrentSpotlight as useCurrentSpotlightNew,
	useCurrentSpotlightOld,
} from '@atlassian/jira-portfolio-3-onboarding/src/controllers/spotlights/index.tsx';
import { SpotlightTarget } from '@atlassian/jira-portfolio-3-onboarding/src/ui/spotlight-target/index.tsx';
import {
	ADD_FIELDS,
	FIELD,
	HEADER,
	SCOPE,
	SUBHEADER,
	TABLE_ITEM,
	TIMELINE,
	EMPTY,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/grid/constants.tsx';
import {
	TABLE_BLANK_ROW,
	TABLE_GROUP,
	TABLE_GROUP_HEADER,
	TABLE_INLINE_CREATE,
	TABLE_ISSUE,
	type TableItem,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/table/index.tsx';
import {
	EDIT,
	EXPORT,
	OPTIMIZED,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/app/types.tsx';
import { INLINE_CREATE_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/main/tabs/roadmap/scope/inline-create/reducer.tsx';
import ViewExperienceFailureTracker from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/failure-tracker/index.tsx';
import { getBody } from '@atlassian/jira-portfolio-3-portfolio/src/common/dom/index.tsx';
import {
	ERROR_REPORTING_PACKAGE,
	ERROR_REPORTING_TEAM,
	PACKAGE_NAME,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { ZIndexProvider } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/z-index/index.tsx';
import type {
	RowConfig,
	ColumnConfig,
	LiteRowConfig,
} from '@atlassian/jira-portfolio-3-treegrid/src/common/types.tsx';
import { Grid } from '@atlassian/jira-portfolio-3-treegrid/src/ui/grid/index.tsx';
import { Row as RowPrimitive } from '@atlassian/jira-portfolio-3-treegrid/src/ui/row/index.tsx';
import { Row as RowCompiled } from '@atlassian/jira-portfolio-3-treegrid/src/ui/row-compiled/index.tsx';
import {
	ContextualAnalyticsData,
	MountEvent,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import { JIRA_PORTFOLIO } from '@atlassian/jira-shared-types/src/application.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { useParentIndicator } from '@atlassian/jira-portfolio-3-issue-drag-and-drop/src/controller/hooks.tsx';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import {
	DependenciesFlyoutOverlay,
	DependenciesFlyoutProvider,
} from '../dependencies-flyout/index.tsx';
import ZoomLevelSync from '../horizontal-scrolling/zoom-level-sync/index.tsx';
import AllIssueSelect from '../scope/all-issues-select/index.tsx';
import InlineCreate from '../scope/issues/inline-create/index.tsx';
import IssueModal from '../scope/issues/issue-modal/index.tsx';
import NowLine from '../timeline/now/index.tsx';
import { getExperienceName } from '../util.tsx';
import { Field as FieldComponent } from './body/field-new/index.tsx';
import { FieldNew, FieldOld } from './body/field/index.tsx';
import { GroupHeader } from './body/group-header/index.tsx';
import { GroupHeaderField } from './body/group-header-field/index.tsx';
import { Group } from './body/group/index.tsx';
import { OrphanDropTarget } from './body/orphan-drop-target/index.tsx';
import { Scope } from './body/scope/index.tsx';
import { TimelineAligned } from './body/timeline/aligned/index.tsx';
import { TimelineAxis } from './body/timeline/axis/index.tsx';
import { TimelineIssueRow } from './body/timeline/issue-row/index.tsx';
import { TimelineReleaseBar } from './body/timeline/release-bar/index.tsx';
import { TimelineTableGroup } from './body/timeline/table-group/index.tsx';
import { SCROLL_PADDING, SPOTLIGHT_TARGET_Z_INDEX } from './constants.tsx';
import { EmptyView } from './empty/index.tsx';
import { FieldsHeader } from './header/fields/index.tsx';
import { ScopeHeader } from './header/scope/index.tsx';
import { TimelineHeader } from './header/timeline/index.tsx';
import messages from './messages.tsx';
import { Arrows } from './overlay/arrows/index.tsx';
import { AxisFirstTimeUnit } from './overlay/axis-first-time-unit/index.tsx';
import { Blanket } from './overlay/blanket/index.tsx';
import { ColumnText, TimelineColumnText } from './overlay/column-text/index.tsx';
import { DependencyDragPreview } from './overlay/dependency-drag-preview/view.tsx';
import { DependencyLineDetail } from './overlay/dependency-line-detail/index.tsx';
import {
	DependencyLinesContainerOld,
	DependencyLinesWrapper,
} from './overlay/dependency-lines/index.tsx';
import DependencyLinesProvider from './overlay/dependency-lines/provider/index.tsx';
import { EmptyList } from './overlay/empty-list/index.tsx';
import { ScrollShadowOverlay } from './overlay/scroll-shadow/index.tsx';
import { ScrollBar } from './scroll-bar/index.tsx';
import { AddFields } from './subheader/add-fields/index.tsx';
import { FieldSubheader } from './subheader/field/index.tsx';
import { ScopeSubheader } from './subheader/scope/index.tsx';
import { TimelineSubheader } from './subheader/timeline/index.tsx';
import { TableToolbar } from './toolbar/index.tsx';
import type { Props } from './types.tsx';
import {
	getDefaultStickyOffset,
	makeColspan,
	makeRangeExtractor,
	getColumnIds,
	getViewportInset,
	getAdditionalHeightToHideNativeScrollbar,
	getBackgroundColor,
} from './utils.tsx';
import { BackgroundProvider } from './utils/background-provider/index.tsx';
import { Colspan as ColspanProvider } from './utils/colspan/index.tsx';
import { Collapsible } from './utils/column-collapse/index.tsx';
import { useColumnToggle } from './utils/column-collapse/utils/hooks.tsx';
import { ColumnWidthsProvider } from './utils/column-widths-provider/index.tsx';
import { DependencyDragCreate } from './utils/dependency-drag-create/index.tsx';
import { AutoScrollObserver } from './utils/drag-and-drop/auto-scroll-observer/index.tsx';
import { DragScrollLock } from './utils/drag-and-drop/drag-scroll-lock/index.tsx';
import { DragAndDropProvider } from './utils/drag-and-drop/index.tsx';
import { ColumnDragAutoScroll } from './utils/draggable-column/drag-auto-scroll/index.tsx';
import { ColumnDragManager } from './utils/draggable-column/drag-manager/index.tsx';
import { ColumnDragScrollLock } from './utils/draggable-column/drag-scroll-lock/index.tsx';
import { EnhancedCell as EnhancedCellCompiled } from './utils/enhanced-cell-compiled/index.tsx';
import { EnhancedCell as EnhancedCellPrimitive } from './utils/enhanced-cell/index.tsx';
import { EnhancedContainer } from './utils/enhanced-container/index.tsx';
import { FieldAccessories } from './utils/fields-accessories/index.tsx';
import { HorizontalScrollingSync } from './utils/horizontal-scrolling-sync/index.tsx';
import { ItemProvider } from './utils/item-provider/index.tsx';
import { ResizeHandle } from './utils/resize-handle/index.tsx';
import { ScrollIntoView } from './utils/scroll-into-view/index.tsx';
import { ScrollReset } from './utils/scroll-reset/index.tsx';
import { TimelineDragAutoScroll } from './utils/timeline-auto-scroll/index.tsx';
import { TimelineDragAutoScroll as TimelineDragAutoScrollOld } from './utils/timeline-drag-auto-scroll/view.tsx';
import { ViewExperienceSuccessTracker } from './utils/view-experience-success-tracker/index.tsx';

export const PlanTable: React.FC<Props> = ({
	rows,
	rowIds,
	rowConfigs,
	columnConfigs,
	stickyRows,
	columns,
	mode,
	viewMode,
	viewId,
	isReportMode,
	isConfluenceMacro,
	isEmbed,
	reportTimeToInteractive,
	onRoadmapView,
	exportAppWidth,
	fieldColumns,
	showOptimizations,
	items,
}) => {
	const shouldShowNav4 = getWillShowNav4();
	const useCurrentSpotlight = shouldShowNav4 ? useCurrentSpotlightNew : useCurrentSpotlightOld;
	const { software: edition } = useAppEditions();
	const columnIds = useMemo(() => getColumnIds(columns), [columns]);
	let isFullscreen;
	let setIsFullscreen;
	if (shouldShowNav4) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		[isFullscreen, { setIsFullscreen }] = useIsFullscreen();
	} else {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		[isFullscreen, setIsFullscreen] = useState<boolean>(false);
	}
	const numOfRows = rowConfigs.length;
	const hasGroups = rows.some(
		(row) => row.tag === TABLE_ITEM && row.payload.itemTag === TABLE_GROUP,
	);
	const [{ spotlight: onboardingSpotlight }] = useCurrentSpotlight();
	const [cttOnboardingSpotlight] = useCttSpotlight();

	const spotlightSelection =
		onboardingSpotlight === Spotlights.Inactive ? cttOnboardingSpotlight : onboardingSpotlight;

	const rangeExtractor = useMemo(() => makeRangeExtractor(stickyRows), [stickyRows]);
	const isExportMode = mode === EXPORT;
	const isReadOnly = mode !== EDIT;
	const nativeScrollbarHeight = useNativeScrollbarHeight() ?? 0;
	const hasFields = columns.length > 2;

	const shouldHideScrollbar = !isExportMode && viewMode === VIEW_MODES.TIMELINE;

	const viewportInset = useMemo(
		() => (shouldHideScrollbar ? getViewportInset(nativeScrollbarHeight) : undefined),
		[shouldHideScrollbar, nativeScrollbarHeight],
	);

	const additionalHeightToHideNativeScrollbar = shouldHideScrollbar
		? getAdditionalHeightToHideNativeScrollbar(nativeScrollbarHeight)
		: 0;

	const renderRow = useCallback(
		(
			_row: RowConfig,
			_columns: ColumnConfig[],
			index: number,
			liteRow: LiteRowConfig | undefined,
		): ReactNode => {
			const row = rows[index];
			const key = rowIds[index] ?? index;

			if (row.tag === HEADER) {
				return fg('plans_performance_improvements_2') ? (
					<MemoedHeaderRow
						key={key}
						index={index}
						columns={columns}
						mode={mode}
						viewMode={viewMode}
					/>
				) : (
					<HeaderRow key={key} index={index} columns={columns} mode={mode} viewMode={viewMode} />
				);
			}

			if (row.tag === SUBHEADER) {
				return fg('plans_performance_improvements_2') ? (
					<MemoedSubheaderRow
						key={key}
						index={index}
						columns={columns}
						mode={mode}
						viewMode={viewMode}
					/>
				) : (
					<SubheaderRow key={key} index={index} columns={columns} mode={mode} viewMode={viewMode} />
				);
			}

			if (items && fg('plans_performance_improvements_3')) {
				return (
					<ItemRow
						key={key}
						row={index}
						columns={columns}
						mode={mode}
						viewMode={viewMode}
						hasFields={hasFields}
						liteRow={liteRow}
						item={items[row.payload.itemIndex]}
						fieldColumns={fieldColumns}
						showOptimizations={showOptimizations}
						isReadOnly={isReadOnly}
					/>
				);
			}

			return fg('plans_performance_improvements_2') ? (
				<WrappedItemProviderOld
					key={key}
					row={index}
					itemIndex={row.payload.itemIndex}
					columns={columns}
					mode={mode}
					viewMode={viewMode}
					hasFields={hasFields}
					liteRow={liteRow}
					fieldColumns={fieldColumns}
					showOptimizations={showOptimizations}
					isReadOnly={isReadOnly}
				/>
			) : (
				<ItemProvider key={key} itemIndex={row.payload.itemIndex}>
					{(item) => (
						<BodyRowWithBackgroundProvider index={index} item={item}>
							<BodyRowCells
								isFirst={index === 2}
								item={item}
								columns={columns}
								mode={mode}
								viewMode={viewMode}
								hasFields={hasFields}
								liteRow={liteRow}
								fieldColumns={fieldColumns}
								showOptimizations={showOptimizations}
								isReadOnly={isReadOnly}
							/>
						</BodyRowWithBackgroundProvider>
					)}
				</ItemProvider>
			);
		},
		[
			rows,
			rowIds,
			columns,
			mode,
			viewMode,
			hasFields,
			fieldColumns,
			showOptimizations,
			items,
			isReadOnly,
		],
	);

	const EnhancedCell = fg('plans_performance_improvements_3')
		? EnhancedCellCompiled
		: EnhancedCellPrimitive;

	const Row = fg('plans_performance_improvements_3') ? RowCompiled : RowPrimitive;

	const renderEmpty = useCallback(
		(height?: number, isFirstRow?: boolean): ReactNode => (
			<Row index={-1} height={height} role="presentation">
				{columns.map((column, index) => (
					<EnhancedCell
						key={index}
						column={index}
						{...(fg('plans_performance_improvements_2') ? { hasRowSpan: isFirstRow } : {})}
					>
						{/* If we only have 2 rows (header and subheader), it means there are no issues in the plan  */}
						{column.tag === SCOPE && numOfRows === 2 && <EmptyView />}

						{column.tag === TIMELINE && (
							<TimelineAligned offset={SCROLL_PADDING}>
								<TimelineAxis />
								<NowLine noMarker />
								<TimelineReleaseBar noMarker />
							</TimelineAligned>
						)}
					</EnhancedCell>
				))}
			</Row>
		),
		[Row, columns, EnhancedCell, numOfRows],
	);

	const colspanOld = useMemo(() => makeColspan(viewMode, columns), [viewMode, columns]);

	const toggleFullscreen = useCallback(() => {
		setIsFullscreen(!isFullscreen);
	}, [isFullscreen, setIsFullscreen]);

	const ref = useRef<HTMLDivElement>(null);

	const withSpotlight =
		spotlightSelection === Spotlights.HeartOfPlan || spotlightSelection === CttSpotlights.Scope;

	useLayoutEffect(() => {
		reportTimeToInteractive({
			isNewSidebarEnabled: true,
			isTransposed: true,
		});
	}, [reportTimeToInteractive]);

	// NOTE: Firefox does not reset the scroll when switching view modes (timeline / list)
	useLayoutEffect(() => {
		if (ref?.current && viewMode === VIEW_MODES.LIST) {
			ref.current.scrollLeft = 0;
		}
	}, [viewMode]);

	return fg('plans_performance_improvements_2') ? (
		<>
			<ScrollShadowOverlay hasGroups={hasGroups} />
			<ColumnWidthsProvider
				columnIds={columnIds}
				columns={columns}
				viewMode={viewMode}
				viewId={viewId}
			>
				{({
					value: columnWidths,
					defaultValue: defaultColumnWidths,
					onChange: handleColumnWidthsChange,
					resizer,
				}) => (
					<Grid
						layout="fixed"
						columnWidths={columnWidths}
						columnConfigs={columnConfigs}
						rowConfigs={rowConfigs}
						resizer={resizer}
						row={renderRow}
						empty={renderEmpty}
						overscan={isExportMode ? Infinity : 20}
						stickyOffset={getDefaultStickyOffset()}
						rangeExtractor={rangeExtractor}
						virtualizationScrollThreshold={400 /** approx. 10 rows */}
						onColumnWidthsChange={handleColumnWidthsChange}
					>
						{(table) => {
							if (viewMode === VIEW_MODES.LIST) {
								return (
									<>
										{!hasFields && <EmptyList />}
										<ColspanProvider viewMode={viewMode} columns={columns}>
											{table}
										</ColspanProvider>
										<ScrollReset viewId={viewId} viewMode={viewMode} />
										{fg('plan_timeline_drag_and_drop_field_columns') && (
											<ColumnDragAutoScroll containerRef={ref} />
										)}
									</>
								);
							}

							return (
								<>
									<AxisFirstTimeUnit />
									<Arrows />
									<DependencyDragPreview />
									<Blanket />
									<Collapsible
										columnIds={columnIds}
										onColumnWidthsChange={handleColumnWidthsChange}
										defaultColumnWidths={defaultColumnWidths}
										resizer={resizer}
									>
										<ColspanProvider viewMode={viewMode} columns={columns}>
											{table}
										</ColspanProvider>
									</Collapsible>
									{fg('convert-column-provider-to-context') ? (
										<DependencyLinesWrapper />
									) : (
										<DependencyLinesContainerOld />
									)}
									<DependencyLineDetail />
									<HorizontalScrollingSync columnWidths={columnWidths} offset={SCROLL_PADDING} />
									<ZoomLevelSync />
									<ScrollReset viewId={viewId} viewMode={viewMode} />
									<ScrollBar bottom={viewportInset?.bottom ?? 0} />
									{fg('plan_timeline_drag_and_drop_field_columns') ? (
										<TimelineDragAutoScroll containerRef={ref} />
									) : (
										<TimelineDragAutoScrollOld containerRef={ref} />
									)}
								</>
							);
						}}
					</Grid>
				)}
			</ColumnWidthsProvider>
			<DependencyDragCreate rows={rows} />
		</>
	) : (
		<UFOSegment name="view-advanced-roadmaps-timeline">
			<ViewExperienceTrackingProvider
				experience={getExperienceName(isReportMode, isConfluenceMacro, isEmbed)}
				analyticsSource="portfolio3"
				application={JIRA_PORTFOLIO}
				edition={edition}
				additionalAttributes={{
					synthetic: isPollinatorTenant(),
				}}
			>
				<ViewExperienceFailureTracker
					location="portfolio-3.simple-plans.roadmap.state-or-global-error"
					locationType="path"
					locationPrefix="roadmap"
				/>
				<SmartCardProvider client={new Client()}>
					<ContextualAnalyticsData
						sourceType={SCREEN}
						sourceName={ERROR_REPORTING_PACKAGE.ROADMAP}
						attributes={{
							isTransposed: true,
						}}
					>
						<ReportErrors
							id={ERROR_REPORTING_PACKAGE.ROADMAP}
							packageName={PACKAGE_NAME}
							teamName={ERROR_REPORTING_TEAM}
							sendToPrivacyUnsafeSplunk
							attributes={{
								isTransposed: 'true',
							}}
						>
							{!isExportMode && (
								<UFOSegment name="view-advanced-roadmaps-timeline-overlay">
									<TableToolbar
										viewMode={viewMode}
										isCollapsible={viewMode === VIEW_MODES.TIMELINE}
										onToggleFullscreen={toggleFullscreen}
										isFullscreen={isFullscreen}
									/>
								</UFOSegment>
							)}
							<ZIndexProvider>
								<DragAndDropProvider>
									{fg('plan_timeline_drag_and_drop_field_columns') && <ColumnDragManager />}
									{fg('plan_timeline_drag_and_drop_field_columns') && <ColumnDragScrollLock />}
									<AutoScrollObserver scrollRef={ref} />
									<DragScrollLock />
									<DependenciesFlyoutProvider>
										<DependencyLinesProvider>
											{withSpotlight && !shouldShowNav4 && (
												<SpotlightTarget name={Spotlights.HeartOfPlan}>
													<SpotlightTarget name={CttSpotlights.Scope}>
														<Box xcss={spotlightPlaceholderStyles} />
													</SpotlightTarget>
												</SpotlightTarget>
											)}
											<Box xcss={hiddenOverflowStyles}>
												<EnhancedContainer
													xcss={[
														withSpotlight && !shouldShowNav4 && spotlightContainerStyles,
														isFullscreen && fullscreenContainerStyles,
														isExportMode && exportContainerStyles,
													]}
													width={exportAppWidth}
													style={{ bottom: -additionalHeightToHideNativeScrollbar }}
													viewportInset={viewportInset}
													outerRef={ref}
													data-scroll="lockable"
													testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table"
												>
													<ScrollShadowOverlay hasGroups={hasGroups} />
													<ColumnWidthsProvider
														columnIds={columnIds}
														columns={columns}
														viewMode={viewMode}
														viewId={viewId}
													>
														{({
															value: columnWidths,
															defaultValue: defaultColumnWidths,
															onChange: handleColumnWidthsChange,
															resizer,
														}) => (
															<Grid
																layout="fixed"
																columnWidths={columnWidths}
																columnConfigs={columnConfigs}
																colspan={colspanOld}
																rowConfigs={rowConfigs}
																resizer={resizer}
																row={renderRow}
																empty={renderEmpty}
																overscan={isExportMode ? Infinity : 20}
																stickyOffset={getDefaultStickyOffset()}
																rangeExtractor={rangeExtractor}
																virtualizationScrollThreshold={400 /** approx. 10 rows */}
																onColumnWidthsChange={handleColumnWidthsChange}
															>
																{(table) => {
																	if (viewMode === VIEW_MODES.LIST) {
																		return (
																			<>
																				{!hasFields && <EmptyList />}
																				{table}
																				<ScrollReset viewId={viewId} viewMode={viewMode} />
																				{fg('plan_timeline_drag_and_drop_field_columns') && (
																					<ColumnDragAutoScroll containerRef={ref} />
																				)}
																			</>
																		);
																	}

																	return (
																		<>
																			<AxisFirstTimeUnit />
																			<Arrows />
																			<DependencyDragPreview />
																			<Blanket />
																			<Collapsible
																				columnIds={columnIds}
																				onColumnWidthsChange={handleColumnWidthsChange}
																				defaultColumnWidths={defaultColumnWidths}
																				resizer={resizer}
																			>
																				{table}
																			</Collapsible>
																			{fg('convert-column-provider-to-context') ? (
																				<DependencyLinesWrapper />
																			) : (
																				<DependencyLinesContainerOld />
																			)}
																			<DependencyLineDetail />
																			<HorizontalScrollingSync
																				columnWidths={columnWidths}
																				offset={SCROLL_PADDING}
																			/>
																			<ZoomLevelSync />
																			<ScrollReset viewId={viewId} viewMode={viewMode} />
																			<ScrollBar bottom={viewportInset?.bottom ?? 0} />
																			{fg('plan_timeline_drag_and_drop_field_columns') ? (
																				<TimelineDragAutoScroll containerRef={ref} />
																			) : (
																				<TimelineDragAutoScrollOld containerRef={ref} />
																			)}
																		</>
																	);
																}}
															</Grid>
														)}
													</ColumnWidthsProvider>
													<DependencyDragCreate rows={rows} />
													{fg('entrypoint_migration_arj-description-editor') ? null : (
														<IssueModal />
													)}
													<ScrollIntoView />
													<DependenciesFlyoutOverlay />
													<HorizontalScrollBarOverlay />
												</EnhancedContainer>
											</Box>
										</DependencyLinesProvider>
									</DependenciesFlyoutProvider>
								</DragAndDropProvider>
							</ZIndexProvider>
							<MountEvent onMount={onRoadmapView} />
							<ViewExperienceSuccessTracker />
						</ReportErrors>
					</ContextualAnalyticsData>
				</SmartCardProvider>
				<UFOCustomData data={{ viewMode, isTransposed: true }} />
			</ViewExperienceTrackingProvider>
		</UFOSegment>
	);
};

const HeaderRow: React.FC<{ index: number } & Pick<Props, 'columns' | 'mode' | 'viewMode'>> = ({
	index,
	columns,
	mode,
	viewMode,
}) => {
	const EnhancedCell = fg('plans_performance_improvements_3')
		? EnhancedCellCompiled
		: EnhancedCellPrimitive;

	const Row = fg('plans_performance_improvements_3') ? RowCompiled : RowPrimitive;

	return (
		<Row
			index={index}
			style={
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				{
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
					'--plan-grid-row-background': token('color.background.neutral', colors.N20A),
				} as CSSProperties
			}
		>
			{
				// eslint-disable-next-line @typescript-eslint/no-shadow
				columns.map((column, index) => {
					if (column.tag === SCOPE) {
						return (
							<EnhancedCell
								key={index}
								column={index}
								hasBorderBottom
								resizeHandle={<ResizeHandle />}
								top={0}
								rowType="header"
							>
								<AllIssueSelect>
									{({ isSelected }) => <ScopeHeader isSelected={isSelected} />}
								</AllIssueSelect>
							</EnhancedCell>
						);
					}

					if (column.tag === TIMELINE) {
						return (
							<EnhancedCell
								key={index}
								column={index}
								hasBorderBottom
								top={0}
								columnText={
									<TimelineColumnText
										column={index}
										hasStripe={mode === OPTIMIZED}
										title={<FormattedMessage {...messages.timeline} />}
										icon={null}
										appearance="section"
										useColumnToggle={useColumnToggle}
									/>
								}
								rowType="header"
							>
								<TimelineAligned offset={SCROLL_PADDING}>
									<TimelineHeader />
								</TimelineAligned>
							</EnhancedCell>
						);
					}

					if (index === 1) {
						// FIELDS
						return (
							<EnhancedCell
								key={index}
								column={index}
								hasBorderBottom
								columnText={
									<ColumnText
										column={index}
										title={<FormattedMessage {...messages.fields} />}
										icon={null}
										appearance="section"
										hasStripe={mode === OPTIMIZED}
										useColumnToggle={useColumnToggle}
									/>
								}
								resizeHandle={viewMode === VIEW_MODES.TIMELINE ? <ResizeHandle /> : undefined}
								clipped={false}
								top={0}
								rowType="header"
							>
								<FieldsHeader
									useColumnToggle={useColumnToggle}
									collapsible={viewMode === VIEW_MODES.TIMELINE}
								/>
							</EnhancedCell>
						);
					}

					return null;
				})
			}
		</Row>
	);
};

const MemoedHeaderRow = memo(HeaderRow);

const SubheaderRow: React.FC<{ index: number } & Pick<Props, 'columns' | 'mode' | 'viewMode'>> = ({
	index,
	columns,
	mode,
	viewMode,
}) => {
	const EnhancedCell = fg('plans_performance_improvements_3')
		? EnhancedCellCompiled
		: EnhancedCellPrimitive;

	const Row = fg('plans_performance_improvements_3') ? RowCompiled : RowPrimitive;

	return (
		<Row index={index}>
			{
				// eslint-disable-next-line @typescript-eslint/no-shadow
				columns.map((column, index) => {
					if (column.tag === SCOPE) {
						return (
							<EnhancedCell
								key="scope"
								column={index}
								top={50}
								hasBorderBottom
								hasColumnDropIndicator
								rowType="subheader"
							>
								<AllIssueSelect>
									{({ totalSelected, isSelected, isIndeterminate, toggleAllSelected }) => (
										<ScopeSubheader
											totalSelected={totalSelected}
											isSelected={isSelected}
											isIndeterminate={isIndeterminate}
											toggleAllSelected={toggleAllSelected}
										/>
									)}
								</AllIssueSelect>
							</EnhancedCell>
						);
					}

					if (column.tag === ADD_FIELDS || column.tag === EMPTY) {
						return (
							<EnhancedCell
								key="add-fields"
								column={index}
								top={50}
								hasBorderBottom
								rowType="subheader"
							>
								<AddFields />
							</EnhancedCell>
						);
					}

					if (column.tag === TIMELINE) {
						return (
							<EnhancedCell key={index} column={index} top={50} hasBorderBottom rowType="subheader">
								<TimelineAligned offset={SCROLL_PADDING}>
									<TimelineSubheader />
									<TimelineReleaseBar />
								</TimelineAligned>
							</EnhancedCell>
						);
					}

					return (
						<FieldAccessories key={column.payload.id} columnId={column.payload.id}>
							{({ title, icon, column: issueColumn }) => (
								<EnhancedCell
									column={index}
									columnId={column.payload.id}
									isDraggableColumn
									hasColumnDropIndicator
									top={50}
									hasBorderBottom
									resizeHandle={<ResizeHandle />}
									columnText={
										<ColumnText
											column={index}
											title={title}
											icon={icon}
											appearance="field"
											hasStripe={mode === OPTIMIZED}
											useColumnToggle={useColumnToggle}
										/>
									}
									rowType="subheader"
								>
									<FieldSubheader
										title={title}
										icon={icon}
										column={issueColumn}
										useColumnToggle={useColumnToggle}
										collapsible={viewMode === VIEW_MODES.TIMELINE}
									/>
								</EnhancedCell>
							)}
						</FieldAccessories>
					);
				})
			}
		</Row>
	);
};

const MemoedSubheaderRow = memo(SubheaderRow);

const withoutTopOffset = omit('topOffset');

const ItemRow: React.FC<
	{
		row: number;
		liteRow: LiteRowConfig | undefined;
		hasFields: boolean;
		viewMode: ViewMode;
		item: TableItem;
		isReadOnly: boolean;
	} & Pick<Props, 'columns' | 'mode' | 'fieldColumns' | 'showOptimizations'>
> = memo(
	({
		row,
		columns,
		viewMode,
		mode,
		hasFields,
		liteRow,
		fieldColumns,
		showOptimizations,
		item,
		isReadOnly,
	}) => {
		const memoedRef = useRef(item);
		// Performance note:
		// The table item, or even the issue object (if any) in the item, is changed every time it's calculated at getTableItems()
		// This method is trying not the change the ref of the item when it's not necessary to avoid re-render for inner components
		memoedRef.current = isEqual(withoutTopOffset(item), withoutTopOffset(memoedRef.current))
			? memoedRef.current
			: item;

		return (
			<MemoedBodyRow
				{...{
					row,
					columns,
					viewMode,
					mode,
					hasFields,
					liteRow,
					fieldColumns,
					showOptimizations,
					isReadOnly,
				}}
				item={memoedRef.current}
			/>
		);
	},
);

const MemoedBodyRow: React.FC<
	{
		row: number;
		liteRow: LiteRowConfig | undefined;
		hasFields: boolean;
		viewMode: ViewMode;
		item: TableItem;
		isReadOnly: boolean;
	} & Pick<Props, 'columns' | 'mode' | 'fieldColumns' | 'showOptimizations'>
> = memo(
	({
		row,
		columns,
		viewMode,
		mode,
		hasFields,
		liteRow,
		item,
		isReadOnly,
		fieldColumns,
		showOptimizations,
	}) =>
		fg('plans_performance_improvements_3') ? (
			<BodyRowWithoutBackgroundProvider
				index={row}
				item={item}
				showOptimizations={showOptimizations}
			>
				<BodyRowCells
					isFirst={row === 2}
					item={item}
					columns={columns}
					mode={mode}
					viewMode={viewMode}
					hasFields={hasFields}
					liteRow={liteRow}
					fieldColumns={fieldColumns}
					showOptimizations={showOptimizations}
					isReadOnly={isReadOnly}
				/>
			</BodyRowWithoutBackgroundProvider>
		) : (
			<BodyRowWithBackgroundProvider index={row} item={item}>
				<BodyRowCells
					isFirst={row === 2}
					item={item}
					columns={columns}
					mode={mode}
					viewMode={viewMode}
					hasFields={hasFields}
					liteRow={liteRow}
					fieldColumns={fieldColumns}
					showOptimizations={showOptimizations}
					isReadOnly={isReadOnly}
				/>
			</BodyRowWithBackgroundProvider>
		),
);

const WrappedItemProviderOld: React.FC<
	{
		row: number;
		itemIndex: number;
		liteRow: LiteRowConfig | undefined;
		hasFields: boolean;
		viewMode: ViewMode;
		isReadOnly: boolean;
	} & Pick<Props, 'columns' | 'mode' | 'fieldColumns' | 'showOptimizations'>
> = memo(
	({
		row,
		columns,
		viewMode,
		mode,
		hasFields,
		liteRow,
		itemIndex,
		fieldColumns,
		showOptimizations,
		isReadOnly,
	}) => (
		<ItemProvider itemIndex={itemIndex}>
			{(item) => (
				<BodyRowWithBackgroundProvider index={row} item={item}>
					<BodyRowCells
						isFirst={row === 2}
						item={item}
						columns={columns}
						mode={mode}
						viewMode={viewMode}
						hasFields={hasFields}
						liteRow={liteRow}
						fieldColumns={fieldColumns}
						showOptimizations={showOptimizations}
						isReadOnly={isReadOnly}
					/>
				</BodyRowWithBackgroundProvider>
			)}
		</ItemProvider>
	),
);

const BodyRowWithBackgroundProvider: React.FC<
	PropsWithChildren<{
		index: number;
		item: TableItem;
	}>
> = memo(({ index: rowIndex, children, item }) => {
	const Row = fg('plans_performance_improvements_3') ? RowCompiled : RowPrimitive;

	switch (item?.tag) {
		case TABLE_GROUP:
			return <Row index={rowIndex}>{children}</Row>;

		case TABLE_INLINE_CREATE:
			return (
				<Row
					{...(fg('plans_performance_improvements_2') ? { 'data-issue': INLINE_CREATE_ID } : {})}
					index={rowIndex}
				>
					{children}
				</Row>
			);

		case TABLE_GROUP_HEADER:
			return (
				<BackgroundProvider index={rowIndex}>
					{({ base }) => (
						<Row
							index={rowIndex}
							style={
								// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
								{
									'--plan-grid-row-background': base,
								} as CSSProperties
							}
						>
							{children}
						</Row>
					)}
				</BackgroundProvider>
			);

		case TABLE_ISSUE:
			return (
				<BackgroundProvider issue={item.value}>
					{({ base, hover }) =>
						fg('plans_performance_improvements_3') ? (
							<RowCompiled
								index={rowIndex}
								style={
									// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
									{
										'--plan-grid-row-background-base': base,
										'--plan-grid-row-background-hover': hover,
									} as CSSProperties
								}
								css={rowHoverCompiledStyles}
							>
								{children}
							</RowCompiled>
						) : (
							<RowPrimitive
								index={rowIndex}
								style={
									// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
									{
										'--plan-grid-row-background-base': base,
										'--plan-grid-row-background-hover': hover,
									} as CSSProperties
								}
								xcss={rowHoverStyles}
							>
								{children}
							</RowPrimitive>
						)
					}
				</BackgroundProvider>
			);

		case TABLE_BLANK_ROW:
		default:
			return (
				<BackgroundProvider index={rowIndex}>
					{({ base }) => (
						<Row
							index={rowIndex}
							style={
								// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
								{
									'--plan-grid-row-background': base,
								} as CSSProperties
							}
						>
							{children}
						</Row>
					)}
				</BackgroundProvider>
			);
	}
});

// Rename to BodyRow when cleaning up plans_performance_improvements_3
const BodyRowWithoutBackgroundProvider: React.FC<
	PropsWithChildren<{
		index: number;
		item: TableItem;
		showOptimizations?: boolean;
	}>
> = memo(({ index: rowIndex, children, item, showOptimizations }) => {
	const parentIndicator = useParentIndicator({
		id: item?.tag === TABLE_ISSUE ? item.value.id : undefined,
		index: rowIndex,
	});

	switch (item?.tag) {
		case TABLE_GROUP:
			return <RowCompiled index={rowIndex}>{children}</RowCompiled>;

		case TABLE_INLINE_CREATE:
			return (
				<RowCompiled
					{...(fg('plans_performance_improvements_2') ? { 'data-issue': INLINE_CREATE_ID } : {})}
					index={rowIndex}
				>
					{children}
				</RowCompiled>
			);

		case TABLE_GROUP_HEADER: {
			const { base } = getBackgroundColor({
				isOptimized: showOptimizations,
			});
			return (
				<RowCompiled
					index={rowIndex}
					style={
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						{
							'--plan-grid-row-background': base,
						} as CSSProperties
					}
				>
					{children}
				</RowCompiled>
			);
		}

		case TABLE_ISSUE: {
			const { base, hover } = getBackgroundColor({
				issue: item.value,
				isOptimized: showOptimizations,
				parentIndicator,
			});
			return (
				<RowCompiled
					index={rowIndex}
					style={
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						{
							'--plan-grid-row-background-base': base,
							'--plan-grid-row-background-hover': hover,
						} as CSSProperties
					}
					css={rowHoverCompiledStyles}
				>
					{children}
				</RowCompiled>
			);
		}

		case TABLE_BLANK_ROW:
		default: {
			const { base } = getBackgroundColor({
				isOptimized: showOptimizations,
			});
			return (
				<RowCompiled
					index={rowIndex}
					style={
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						{
							'--plan-grid-row-background': base,
						} as CSSProperties
					}
				>
					{children}
				</RowCompiled>
			);
		}
	}
});

const BodyRowCells: React.FC<
	{
		item: TableItem;
		liteRow: LiteRowConfig | undefined;
		viewMode: ViewMode;
		hasFields: boolean;
		/** Determines whether the row is the first one in the table body. */
		isFirst: boolean;
		isReadOnly: boolean;
	} & Pick<Props, 'columns' | 'mode' | 'fieldColumns' | 'showOptimizations'>
> = memo(
	({
		item,
		viewMode,
		hasFields,
		isFirst,
		columns,
		mode,
		liteRow,
		isReadOnly,
		fieldColumns,
		showOptimizations,
	}) => {
		/**
		 * Optimisation for lite row:
		 * Remove indicators and only render the visible columns in lite row.
		 */
		const isLite = Boolean(liteRow);
		const isVisibleInLite = (column: number): boolean =>
			Boolean(liteRow?.visibleCols && liteRow.visibleCols[column.toString()]);

		const Field = fg('plans_performance_improvements_2') ? FieldNew : FieldOld;

		const EnhancedCell = fg('plans_performance_improvements_3')
			? EnhancedCellCompiled
			: EnhancedCellPrimitive;

		switch (item?.tag) {
			case TABLE_GROUP:
				return (
					<>
						{columns.map((column, index) => (
							<EnhancedCell
								column={index}
								key={index}
								hasDropIndicator={!isLite}
								top={90}
								hasBorderTop={!isFirst /* Sub-header already has a bottom border. */}
								rowType="group"
							>
								{column.tag === SCOPE && (
									<div data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.scope">
										<Group item={item} />
									</div>
								)}
								{column.tag === TIMELINE && (
									<TimelineAligned offset={SCROLL_PADDING}>
										<TimelineTableGroup item={item} />
										<NowLine noMarker />
										<TimelineReleaseBar noMarker />
									</TimelineAligned>
								)}
								{!fg('plans_performance_improvements_3') &&
									column.tag !== SCOPE &&
									column.tag !== TIMELINE && <Group />}

								{column.tag !== SCOPE &&
									column.tag !== TIMELINE &&
									column.tag !== ADD_FIELDS &&
									column.tag !== EMPTY &&
									expValEquals('plan_timeline_aggregate_field_values', 'cohort', 'variation') && (
										<GroupHeaderField group={item.group} columnId={column.payload.id} />
									)}
							</EnhancedCell>
						))}
					</>
				);

			case TABLE_INLINE_CREATE:
				return (
					<>
						{columns.map((column, index) => (
							<EnhancedCell column={index} key={index} hasDropIndicator={!isLite}>
								{column.tag === SCOPE && (
									<InlineCreate
										rootIndex={item.value.rootIndex}
										isReadOnly={false}
										fixVersions={item.value.fixVersions?.map((x) => Number(x))}
										components={item.value.components?.map((x) => Number(x))}
										getContainer={getBody}
									/>
								)}
								{column.tag === FIELD &&
									(showOptimizations !== undefined &&
									fieldColumns &&
									fg('plans_performance_improvements_3') ? (
										<FieldComponent
											issue={item.value}
											column={fieldColumns[column.payload.id]}
											isScrolling={isLite}
											isReadOnly={isReadOnly}
											showOptimizations={showOptimizations}
										/>
									) : (
										<Field issue={item.value} columnId={column.payload.id} isScrolling={isLite} />
									))}
								{column.tag === TIMELINE && (
									<TimelineAligned offset={SCROLL_PADDING}>
										<TimelineAxis />
										<NowLine noMarker />
										<TimelineReleaseBar noMarker />
									</TimelineAligned>
								)}
							</EnhancedCell>
						))}
					</>
				);

			case TABLE_GROUP_HEADER:
				return (
					<>
						{columns.map((column, index) => (
							<EnhancedCell
								column={index}
								key={index}
								hasDropIndicator={!isLite}
								testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.group-header"
							>
								{column.tag === SCOPE && <GroupHeader item={item} readonly={mode !== EDIT} />}
								{column.tag === TIMELINE && (
									<TimelineAligned offset={SCROLL_PADDING}>
										<TimelineAxis />
										<NowLine noMarker />
										<TimelineReleaseBar noMarker />
									</TimelineAligned>
								)}
							</EnhancedCell>
						))}
					</>
				);

			case TABLE_ISSUE:
				return (
					<>
						{columns.map((column, index) => {
							if (column.tag === SCOPE) {
								return (
									<EnhancedCell
										column={index}
										key={index}
										hasDragIndicator={!isLite}
										hasDropIndicator={!isLite}
										testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.scope"
									>
										<Scope item={item} readonly={mode !== EDIT} isScrolling={isLite} />
									</EnhancedCell>
								);
							}

							if (column.tag === FIELD)
								return (
									<EnhancedCell
										column={index}
										key={index}
										hasDragIndicator={!isLite}
										hasDropIndicator={!isLite}
										data-name={`cell-${item.value.id}`}
										data-attribute={column.payload.id}
									>
										{(!isLite || isVisibleInLite(index)) &&
											(showOptimizations !== undefined &&
											fieldColumns &&
											fg('plans_performance_improvements_3') ? (
												<FieldComponent
													issue={item.value}
													column={fieldColumns[column.payload.id]}
													isScrolling={isLite}
													isReadOnly={isReadOnly}
													showOptimizations={showOptimizations}
												/>
											) : (
												<Field
													issue={item.value}
													columnId={column.payload.id}
													isScrolling={isLite}
												/>
											))}
									</EnhancedCell>
								);

							if (column.tag === ADD_FIELDS || column.tag === EMPTY) {
								return (
									<EnhancedCell
										column={index}
										key={index}
										hasDragIndicator={!isLite}
										hasDropIndicator={!isLite}
										hasBackground={hasFields || viewMode === VIEW_MODES.TIMELINE}
									/>
								);
							}

							if (column.tag === TIMELINE)
								return (
									<EnhancedCell
										column={index}
										key={index}
										hasDragIndicator={!isLite}
										hasDropIndicator={!isLite}
									>
										<TimelineAligned offset={SCROLL_PADDING}>
											<TimelineAxis />
											<TimelineIssueRow issue={item.value} />
											<NowLine noMarker />
											<TimelineReleaseBar noMarker />
										</TimelineAligned>
									</EnhancedCell>
								);

							return null;
						})}
					</>
				);

			case TABLE_BLANK_ROW:
			default:
				return (
					<>
						{columns.map((column, index) => (
							<EnhancedCell
								column={index}
								key={index}
								hasDropIndicator={!isLite}
								testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.empty"
							>
								{column.tag === SCOPE && <OrphanDropTarget />}
								{column.tag === TIMELINE && (
									<TimelineAligned offset={SCROLL_PADDING}>
										<TimelineAxis />
										<NowLine noMarker />
										<TimelineReleaseBar noMarker />
									</TimelineAligned>
								)}
							</EnhancedCell>
						))}
					</>
				);
		}
	},
);

const spotlightPlaceholderStyles = xcss({
	position: 'absolute',
	inset: 'space.0',
	pointerEvents: 'none',
});

/* Used to hide the native scrollbars that we push beyond the viewport via negative offsets */
const hiddenOverflowStyles = xcss({
	overflow: 'hidden',
	position: 'relative',
	width: '100%',
});

const fullscreenContainerStyles = xcss({
	position: 'fixed',
	background: 'elevation.surface',
	// @ts-expect-error - TS2322: Type number is not assignable to type
	zIndex: 1,

	'::before': {
		content: '""',
		background: 'elevation.surface',
		position: 'absolute',
		// @ts-expect-error - TS2322: Type number is not assignable to type
		zIndex: -1,
	},
});

const spotlightContainerStyles = xcss({
	pointerEvents: 'none',
	// @ts-expect-error - TS2322: Type number is not assignable to type
	zIndex: SPOTLIGHT_TARGET_Z_INDEX + 1, // eslint-disable-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
});

const exportContainerStyles = xcss({
	position: 'relative',
	// @ts-expect-error - TS2322: Type number is not assignable to type
	zIndex: 1,
});

const rowHoverStyles = xcss({
	'--plan-grid-row-background': 'var(--plan-grid-row-background-base)',
	':hover': {
		// @ts-expect-error - TS2353: Object literal may only specify known properties
		'--plan-grid-row-background':
			'var(--plan-grid-row-background-hover, var(--plan-grid-row-background-base))',
	},
});

const rowHoverCompiledStyles = css({
	'--plan-grid-row-background': 'var(--plan-grid-row-background-base)',
	'&:hover': {
		'--plan-grid-row-background':
			'var(--plan-grid-row-background-hover, var(--plan-grid-row-background-base))',
	},
});
