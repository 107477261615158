.timeUnitsBar {
	height: 100%;
	position: absolute;
	width: 100%;
}

.timeUnitsBarTop {
	display: flex;
	height: 25px;
}

.timeUnitsBarTopUnit {
	color: var(--ds-text-subtlest, var(--adg3-color-N200));
	font-size: 12px;
	font-weight: var(--ds-font-weight-semibold, 600);
	height: 25px;
	line-height: 25px;
	text-align: left;
	text-transform: uppercase;
	white-space: nowrap;

	&.newOverride {
		color: var(--ds-text-subtle, var(--adg3-color-N400));
		text-transform: none;
	}

	&.infiniteAxis {
		position: absolute;
	}
}

.timeUnitsBarTopUnitContent {
	padding: 0px var(--jpo-common-base-spacing);
	position: absolute;

	&.withLeftBorder {
		border-left: 1px solid var(--ds-border, var(--adg3-color-N50));
		box-sizing: border-box;
	}

	&.staticAxis {
		box-sizing: border-box;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: inherit;
	}
}

.timeUnitsBarBottom {
	display: flex;
	height: 25px;
}

.timeUnitsBarBottomUnit {
	position: absolute;
}

.timeUnitsBarBottomUnitContent {
	border-bottom: 1px solid var(--ds-border, var(--adg3-color-N50));
	box-sizing: border-box;
	color: var(--ds-text-subtlest, var(--adg3-color-N200));
	font-size: 12px;
	height: 25px;
	line-height: 25px;
	text-align: center;
	text-transform: uppercase;
	white-space: nowrap;

	&.newOverride {
		color: var(--ds-text-subtle, var(--adg3-color-N400));
		text-transform: none;
	}

	&.staticAxis {
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&.withLeftBorder {
		border-left: 1px solid var(--ds-border, var(--adg3-color-N50));
		box-sizing: border-box;
	}
}

.dateLozenge {
	font-weight: var(--ds-font-weight-regular, 400);
}

/* SPA Experience Styles */

.timeUnitsBarContent {
	padding: 0px var(--jpo-common-base-spacing);
	display: flex;
	justify-content: center;
	text-transform: none;
	align-items: center;
	font-weight: var(--ds-font-weight-semibold, 600);

	&.withLeftBorder {
		border-left: 1px solid var(--ds-border, var(--adg3-color-N50));
		box-sizing: border-box;
	}

	&.staticAxis {
		box-sizing: border-box;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: inherit;
	}
}

.yearWrapper {
	text-transform: none;
	display: flex;
	justify-content: space-evenly;
	overflow: clip;
}
