import React, { type ReactNode } from 'react';
import { Box, xcss, Inline } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

type Props = {
	count: number;
	total: number;
	label: ReactNode;
};

export default function Percentage({ count, total, label }: Props) {
	const { formatMessage } = useIntl();
	const percentage = total && Math.round((count / total) * 100);

	return (
		<Inline>
			<Box xcss={countLabel} as="span" paddingInlineEnd="space.050">
				{label}
			</Box>
			<Box xcss={percentageLabel} as="span">
				{formatMessage(messages.percentage, { percentage })}
			</Box>
		</Inline>
	);
}

const countLabel = xcss({
	fontWeight: token('font.weight.semibold'),
	color: 'color.text',
});

const percentageLabel = xcss({
	color: 'color.text.subtlest',
});
