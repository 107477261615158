.container {
	flex: 1 1 auto;
	margin: calc(2 * var(--jpo-common-base-spacing)) 0;
}

.table > div:first-child {
	border-bottom: 2px solid var(--ds-border, var(--adg3-color-N40));
}

.grid:focus {
	outline: none;
}

.tableRow {
	display: flex;
	align-items: center;
	box-sizing: border-box;
}

.ReactVirtualized__Table__headerRow.tableRow {
	padding-bottom: 2px;
}

.columnHeader {
	box-sizing: border-box;
	color: var(--ds-text-subtlest, var(--adg3-color-N200));
	font-size: 12px;
	font-weight: var(--ds-font-weight-semibold, 600);
	text-transform: none;
}

.tableRow > div:first-child {
	margin-left: 0px;
	margin-right: 3px;
}

.modifiedColumnHeader {
	display: flex;
	justify-content: flex-end;
	outline: none;

	& > span {
		position: relative;
		overflow: visible;
		margin-right: 10px;
	}

	& > span::before {
		display: block;
		height: 0px;
		position: absolute;
		right: -8px;
		width: 0px;
		bottom: 10px;
		content: ' ';
		border-width: 3px;
		border-style: solid;
		border-image: initial;
	}

	& > span::after {
		display: block;
		height: 0px;
		position: absolute;
		right: -8px;
		width: 0px;
		bottom: 2px;
		content: ' ';
		border-width: 3px;
		border-style: solid;
		border-image: initial;
	}

	& > span[data-sortdir='ASC']::before {
		border-color: transparent transparent var(--ds-border, var(--adg3-color-N40));
		border-bottom: 3px solid var(--ds-border, var(--adg3-color-N40));
	}

	& > span[data-sortdir='ASC']::after {
		border-color: var(--ds-border, var(--adg3-color-N300)) transparent transparent;
		border-top: 3px solid var(--ds-border, var(--adg3-color-N300));
	}
	& > span[data-sortdir='DESC']::before {
		border-color: transparent transparent var(--ds-border, var(--adg3-color-N300));
		border-bottom: 3px solid var(--ds-border, var(--adg3-color-N300));
	}
	& > span[data-sortdir='DESC']::after {
		border-color: var(--ds-border, var(--adg3-color-N40)) transparent transparent;
		border-top: 3px solid var(--ds-border, var(--adg3-color-N40));
	}
}

.gridContainer {
	flex: 1 1 auto;
	margin: calc(2 * var(--jpo-common-base-spacing)) 0;
	overflow-y: auto;
}

.modifiedGridColumnHeader {
	background: none;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
	float: right;
	display: flex;

	margin-top: 1px;
	margin-right: 2px;
	outline-offset: 0px;

	box-sizing: border-box;
	color: var(--ds-text-subtlest, var(--adg3-color-N200));
	font-size: 12px;
	font-weight: var(--ds-font-weight-semibold, 600);
	text-transform: none;

	& > span {
		position: relative;
		overflow: visible;
		margin-right: 10px;
	}

	& > span::before {
		display: block;
		height: 0px;
		position: absolute;
		right: -8px;
		width: 0px;
		bottom: 10px;
		content: ' ';
		border-width: 3px;
		border-style: solid;
		border-image: initial;
	}

	& > span::after {
		display: block;
		height: 0px;
		position: absolute;
		right: -8px;
		width: 0px;
		bottom: 2px;
		content: ' ';
		border-width: 3px;
		border-style: solid;
		border-image: initial;
	}

	& > span[data-sortdir='ASC']::before {
		border-color: transparent transparent var(--ds-border, var(--adg3-color-N40));
		border-bottom: 3px solid var(--ds-background-accent-gray-bolder, var(--adg3-color-N40));
	}

	& > span[data-sortdir='ASC']::after {
		border-color: var(--ds-border, var(--adg3-color-N300)) transparent transparent;
		border-top: 3px solid var(--ds-border, var(--adg3-color-N300));
	}
	& > span[data-sortdir='DESC']::before {
		border-color: transparent transparent var(--ds-border, var(--adg3-color-N300));
		border-bottom: 3px solid var(--ds-border, var(--adg3-color-N300));
	}
	& > span[data-sortdir='DESC']::after {
		border-color: var(--ds-border, var(--adg3-color-N40)) transparent transparent;
		border-top: 3px solid var(--ds-background-accent-gray-bolder, var(--adg3-color-N40));
	}
}

.modifiedGridColumnHeader:focus-visible {
	outline: 2px solid var(--ds-border-focused, #4c9aff);
	outline-offset: 0px;
}

.commonColumnHeader {
	display: flex;
	justify-content: flex-start;
	outline: none;

	& > span {
		position: relative;
		overflow: visible;
		margin-right: 10px;
	}
}

.oldColumnHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.oldColumnHeaderArrow {
	margin-right: 5px;
	margin-bottom: -2px;
}

.hideScrollBar {
	overflow: 'hidden';
	position: 'relative';
	width: '100%';
}
