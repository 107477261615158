import type { Effect } from 'redux-saga';
import * as R from 'ramda';
import { put, call, select, fork, takeEvery } from 'redux-saga/effects';
import { fireErrorAnalytics } from '@atlassian/jira-portfolio-3-portfolio/src/common/error/index.tsx';
import fetch from '@atlassian/jira-portfolio-3-portfolio/src/common/fetch/index.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import {
	ERROR_REPORTING_TEAM,
	PACKAGE_NAME,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { redirectToNewScenario } from '@atlassian/jira-portfolio-3-portfolio/src/common/window/index.tsx';
import { getPlanScenarios } from '../../query/plan/index.tsx';
import {
	enablePlanScenarios,
	addPlanScenario,
	removePlanScenario,
	disablePlanScenarios,
	updatePlanScenario,
	type UpdatePlanScenarioPayload,
} from '../../state/domain/plan/actions.tsx';
import type { State } from '../../state/types.tsx';
import {
	toggleDialogVisibility,
	initiateRequest,
	completeRequest,
} from '../../state/ui/settings/scenarios/actions.tsx';
import { POST } from '../api.tsx';
import { toErrorID } from '../util.tsx';
import { urls } from './api.tsx';

export const ENABLE_SCENARIOS = 'command.scenarios.ENABLE_SCENARIOS' as const;

export const CREATE_SCENARIO = 'command.scenarios.CREATE_SCENARIO' as const;

export const DELETE_SCENARIO = 'command.scenarios.DELETE_SCENARIO' as const;

export const DISABLE_SCENARIOS = 'command.scenarios.DISABLE_SCENARIOS' as const;

export const UPDATE_PLAN_SCENARIO = 'command.scenarios.UPDATE_PLAN_SCENARIO' as const;

export type ScenarioPayload = {
	title: string;
	color: string;
	scenarioId?: number;
	redirect?: boolean;
};

export type DeleteScenarioPayload = {
	title: string;
	scenarioId: number;
};

type EnableScenariosAction = {
	type: typeof ENABLE_SCENARIOS;
	payload: ScenarioPayload;
};

type CreateScenarioAction = {
	type: typeof CREATE_SCENARIO;
	payload: ScenarioPayload;
};

type DeleteScenarioAction = {
	type: typeof DELETE_SCENARIO;
	payload: DeleteScenarioPayload;
};

type DisableScenariosAction = {
	type: typeof DISABLE_SCENARIOS;
	scenarioToKeepId: number;
};

export type UpdateScenarioAction = {
	type: typeof UPDATE_PLAN_SCENARIO;
	payload: UpdatePlanScenarioPayload;
};
export const enableScenarios = (payload: ScenarioPayload) => ({
	type: ENABLE_SCENARIOS,
	payload,
});

export const createScenario = (payload: ScenarioPayload) => ({
	type: CREATE_SCENARIO,
	payload,
});

export const deleteScenario = (payload: DeleteScenarioPayload) => ({
	type: DELETE_SCENARIO,
	payload,
});

export const disableScenarios = (scenarioToKeepId: number) => ({
	type: DISABLE_SCENARIOS,
	scenarioToKeepId,
});

export const updateScenario = (payload: UpdatePlanScenarioPayload) => ({
	type: UPDATE_PLAN_SCENARIO,
	payload,
});

export function* doEnableScenarios({
	payload, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: EnableScenariosAction): Generator<Effect, any, any> {
	try {
		const {
			domain: {
				plan: { id: planId, currentScenarioId },
			},
		}: State = yield select(R.identity);

		if (!planId) {
			throw new Error('Plan id is not available');
		}

		if (!currentScenarioId) {
			throw new Error('Scenario id is not available');
		}

		yield put(initiateRequest());
		const body = { planId, ...payload };
		const response = yield call(fetch, urls.enable, {
			method: POST,
			body,
		});
		yield put(toggleDialogVisibility(false));
		yield put(completeRequest());

		if (!response.ok) {
			const error = new Error(yield call(response.text.bind(response)));
			fireErrorAnalytics({
				error,
				meta: {
					id: toErrorID(error, 'do-enable-scenarios-fetch-failed'),
					packageName: PACKAGE_NAME,
					teamName: ERROR_REPORTING_TEAM,
				},
				sendToPrivacyUnsafeSplunk: true,
			});
			return;
		}

		yield put(
			enablePlanScenarios([
				{
					id: currentScenarioId,
					planId,
					title: payload.title,
					color: payload.color,
				},
			]),
		);
	} finally {
		yield put(completeRequest());
	}
}

export function* doCreateScenario({
	payload: { redirect, ...rest }, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: CreateScenarioAction): Generator<Effect, any, any> {
	try {
		const {
			domain: {
				app: { browserHistory },
				plan: { id: planId },
			},
		}: State = yield select(R.identity);
		if (!planId) {
			throw new Error('Plan ID is missing');
		}
		yield put(initiateRequest());
		const body = { planId, ...rest };
		const response = yield call(fetch, urls.scenarios, {
			method: POST,
			body,
		});
		yield put(toggleDialogVisibility(false));

		if (!response.ok) {
			throw new Error(yield call(response.text.bind(response)));
		}

		const { id } = yield call(response.json.bind(response));
		yield put(
			addPlanScenario({
				id,
				planId,
				title: rest.title,
				color: rest.color,
			}),
		);
		if (redirect && isDefined(browserHistory)) {
			yield call(redirectToNewScenario, browserHistory, id);
		}
	} catch (error: unknown) {
		if (!(error instanceof Error)) throw error;
		fireErrorAnalytics({
			error,
			meta: {
				id: toErrorID(error, 'do-create-scenarios-fetch-failed'),
				packageName: PACKAGE_NAME,
				teamName: ERROR_REPORTING_TEAM,
			},
			sendToPrivacyUnsafeSplunk: true,
		});
	} finally {
		yield put(completeRequest());
	}
}

export function* doDeleteScenario({
	payload: { scenarioId, title }, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: DeleteScenarioAction): Generator<Effect, any, any> {
	let deletedScenario;

	try {
		const {
			domain: {
				app: { browserHistory },
				plan: { id: planId, currentScenarioId },
			},
		}: State = yield select(R.identity);
		if (!planId) {
			throw new Error('Plan ID is missing');
		}
		deletedScenario = {
			id: scenarioId,
			title,
			planId,
		};
		yield put(removePlanScenario(deletedScenario));
		const body = { planId, scenarioId };
		const response = yield call(fetch, urls.delete, {
			method: POST,
			body,
		});
		if (!response.ok) {
			throw new Error(yield call(response.text.bind(response)));
		}
		if (currentScenarioId === scenarioId && isDefined(browserHistory)) {
			const scenarios = yield select(getPlanScenarios);
			yield call(redirectToNewScenario, browserHistory, scenarios[0].id);
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		if (deletedScenario) {
			yield put(addPlanScenario(deletedScenario));
		}

		fireErrorAnalytics({
			error: e,
			meta: {
				id: toErrorID(e, 'do-delete-scenarios-fetch-failed'),
				packageName: PACKAGE_NAME,
				teamName: ERROR_REPORTING_TEAM,
			},
			sendToPrivacyUnsafeSplunk: true,
		});
	}
}

export function* doDisableScenarios({
	scenarioToKeepId, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: DisableScenariosAction): Generator<Effect, any, any> {
	const {
		domain: {
			app: { browserHistory },
			plan: { id: planId, currentScenarioId, scenarios },
		},
	}: State = yield select(R.identity);
	try {
		if (!planId) {
			throw new Error('Plan ID is missing');
		}
		yield put(disablePlanScenarios(scenarioToKeepId));
		const body = { planId, scenarioToKeepId };
		const response = yield call(fetch, urls.disable, {
			method: POST,
			body,
		});
		if (!response.ok) {
			throw new Error(yield call(response.text.bind(response)));
		}
		if (currentScenarioId !== scenarioToKeepId && isDefined(browserHistory)) {
			// eslint-disable-next-line @typescript-eslint/no-shadow
			const scenarios = yield select(getPlanScenarios);
			yield call(redirectToNewScenario, browserHistory, scenarios[0].id);
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		yield put(enablePlanScenarios(scenarios || []));
		fireErrorAnalytics({
			error: e,
			meta: {
				id: toErrorID(e, 'do-disable-plan-scenarios-fetch-failed'),
				packageName: PACKAGE_NAME,
				teamName: ERROR_REPORTING_TEAM,
			},
			sendToPrivacyUnsafeSplunk: true,
		});
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doUpdateScenario({ payload }: UpdateScenarioAction): Generator<Effect, any, any> {
	const {
		domain: {
			plan: { id: planId },
		},
	}: State = yield select(R.identity);

	if (!planId) {
		throw new Error('Plan ID is missing');
	}
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const patch = (Object.keys(payload) as Array<keyof UpdatePlanScenarioPayload>).reduce<
		Record<
			string,
			{ scenarioId: UpdatePlanScenarioPayload } & {
				[K in Exclude<'id', keyof UpdatePlanScenarioPayload>]: {
					value: UpdatePlanScenarioPayload[K];
				};
			}
		>
	>((acc, el) => {
		if (el === 'id') {
			return Object.assign(acc, {
				scenarioId: payload[el],
			});
		}
		return Object.assign(acc, {
			[el]: {
				value: payload[el],
			},
		});
	}, {});

	const body = { planId, ...patch };
	const response = yield call(fetch, urls.update, {
		method: POST,
		body,
	});

	if (!response.ok) {
		const error = new Error(yield call(response.text.bind(response)));
		fireErrorAnalytics({
			error,
			meta: {
				id: toErrorID(error, 'do-update-scenarios-fetch-failed'),
				packageName: PACKAGE_NAME,
				teamName: ERROR_REPORTING_TEAM,
			},
			sendToPrivacyUnsafeSplunk: true,
		});
		return;
	}
	yield put(updatePlanScenario(payload));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchEnableScenarios(): Generator<Effect, any, any> {
	yield takeEvery(ENABLE_SCENARIOS, doEnableScenarios);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchCreateScenario(): Generator<Effect, any, any> {
	yield takeEvery(CREATE_SCENARIO, doCreateScenario);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchDeleteScenario(): Generator<Effect, any, any> {
	yield takeEvery(DELETE_SCENARIO, doDeleteScenario);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchDisableScenarios(): Generator<Effect, any, any> {
	yield takeEvery(DISABLE_SCENARIOS, doDisableScenarios);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchUpdateScenario(): Generator<Effect, any, any> {
	yield takeEvery(UPDATE_PLAN_SCENARIO, doUpdateScenario);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default function* (): Generator<Effect, any, any> {
	yield fork(watchEnableScenarios);
	yield fork(watchCreateScenario);
	yield fork(watchDeleteScenario);
	yield fork(watchDisableScenarios);
	yield fork(watchUpdateScenario);
}
