import * as R from 'ramda';
import { reset } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/actions.tsx';
import migrateColourBy from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/colour-by/migrate.tsx';
import migrateComponentGroups from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/component-groups/migrate.tsx';
import migrateDateConstraintsSetting from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/date-constraints-setting/migrate.tsx';
import migrateFieldColumns, {
	migrateListFieldColumns,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/field-columns/migrate.tsx';
import migrateFilterOptions from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filter-options/migrate.tsx';
import migrateFilters from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/migrate.tsx';
import migrateHighlightedVersions from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/highlighted-versions/migrate.tsx';
import migrateIssueExpansions from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/issue-expansions/migrate.tsx';
import migrateLabelGroups from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/label-groups/migrate.tsx';
import reducer from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/reducer.tsx';
import migrateRollupSettings from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/rollup-settings/migrate.tsx';
import migrateTimeScale from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/time-scale/migrate.tsx';
import migrateViewMode from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/view-mode/migrate.tsx';
import migrateVisualisations from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/visualisations/migrate.tsx';
import migrateWarningSettings from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/warning-settings/migrate.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const normalize = (blob: any) => reducer(undefined, reset(blob));

const migrate = [
	migrateFilters,
	migrateColourBy,
	migrateTimeScale,
	migrateIssueExpansions,
	migrateFieldColumns,
	migrateListFieldColumns,
	migrateViewMode,
	migrateFilterOptions,
	migrateRollupSettings,
	migrateHighlightedVersions,
	migrateComponentGroups,
	migrateLabelGroups,
	migrateWarningSettings,
	migrateDateConstraintsSetting,
	migrateVisualisations,
] // eslint-disable-next-line @typescript-eslint/no-explicit-any
	.reduce((acc, fn) => R.pipe<any, any, any>(acc, fn));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fromV0 = R.pipe<any, any, any>(migrate, normalize);

export default fromV0;
