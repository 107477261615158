import React, { useState, useEffect, useCallback, useRef, type PropsWithChildren } from 'react';
import * as R from 'ramda';
import StandardButton from '@atlaskit/button/standard-button';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import Lozenge from '@atlaskit/lozenge';
import Popup, { type TriggerProps, type ContentProps } from '@atlaskit/popup'; // ignore-for-ENGHEALTH-17759
import { Box } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { useIntl, type IntlShape } from '@atlassian/jira-intl';
import { DropMenuItem } from '@atlassian/jira-portfolio-3-common/src/drop-menu/index.tsx';
import TitleWithIcon from '@atlassian/jira-portfolio-3-common/src/sections/title-with-icon.tsx';
import type {
	ActionMeta,
	OptionType,
	ValueType,
} from '@atlassian/jira-portfolio-3-common/src/select/types.tsx';
import { UseShortcuts } from '@atlassian/jira-portfolio-3-keyboard-shortcuts/src/controllers/index.tsx';
import KeyboardShortcutTooltip from '@atlassian/jira-portfolio-3-keyboard-shortcuts/src/ui/keyboard-shortcut-tooltip/index.tsx';
import { ADD_FIELDS_COLUMN_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/field-columns/types.tsx';
import {
	getPlanId,
	getScenarioId,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/util/urls.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import Select from '@atlassian/jira-portfolio-3-portfolio/src/common/view/select/view.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';
import { fg } from '@atlassian/jira-feature-gating';
import { getConfiguredDateTooltip } from '../../messages.tsx';
import type { IssueColumn } from '../../types.tsx';
import CustomPopupContainer from './custom-popup-container/index.tsx';
import messages from './messages.tsx';
import SelectedFields from './selected-fields/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { LocalizedIssueColumn, Props, MenuTriggerProps, SearchFieldOption } from './types.tsx';
import { getListableColumns } from './utils.tsx';

const getColumnTitle = (column: IssueColumn, intl: IntlShape) => {
	/**
	 * this type check is performed because:
	 * - the title of a standard field is of type Message; whereas
	 * - the title of a custom field is of type string
	 *
	 * If the column title is passed as a string in the formatMessage below, the plan would crash
	 * because an `id` must be provided to format a message.
	 */
	if (typeof column.title === 'string') {
		/**
         * There are 3 scenarios to take care of with the title of custom fields:
         * 1. the user could have entered a whitespace in front of the title e.g. " My custom label"
         * 2. the user could have forgotten to type the first letter as capital e.g. "my custom label"
         * 3. the user did both 1. and 2. e.g. " my custom label"

         * These scenarios impact the sorting of fields in the "Choose fields" picklist:
         * - fields with a whitespace in front of the title are displayed first
         * - fields with a non-capital letter are displayed last
         *
         * The solution is to trim the title, then capitalize the first letter
         */
		return R.compose<string, string, string[], string>(
			R.join(''),
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			R.over(R.lensIndex(0), R.toUpper) as (value: string) => string[],
			R.trim,
		)(column.title);
	}

	return intl.formatMessage(column.groupTitle || column.title);
};

const SelectMenuContainer = ({
	isVisuallyRefreshed,
	children,
}: PropsWithChildren<{ isVisuallyRefreshed: boolean }>) => {
	return isVisuallyRefreshed ? (
		<Box
			paddingInline="space.100"
			testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.advanced-fields-menu.select"
		>
			{children}
		</Box>
	) : (
		<div
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={styles['afm-dropdown-menu-header']}
			data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.advanced-fields-menu.select"
		>
			{children}
		</div>
	);
};

const localizeColumns = (columns: IssueColumn[], intl: IntlShape): LocalizedIssueColumn[] =>
	columns.map((column: IssueColumn) => {
		const { id, isVisible, isCustomField, projectId } = column;
		const localizedTitle = getColumnTitle(column, intl);
		const localizedColumn: LocalizedIssueColumn = {
			id,
			isVisible,
			localizedTitle,
			isCustomField,
			projectId,
		};
		return localizedColumn;
	});

const AdvancedFieldsMenu = ({
	columns,
	isDropMenuOpenByDefault,
	getMatchingConfiguredDate,
	hideColumn,
	moveColumn,
	showColumn,
	isDropMenuSelectOpenByDefault = false,
	isReadOnly,
	viewMode,
	id,
	isShortcutEnabled = true,
	renderMenuTrigger,
	placement = 'bottom-start',
}: Props) => {
	const intl = useIntl();
	const { push } = useRouterActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = intl;

	const [localizedColumns, setLocalizedColumns] = useState<LocalizedIssueColumn[]>(
		localizeColumns(columns, intl),
	);
	const [isAdvancedFieldsMenuOpen, setIsAdvancedFieldsMenuOpen] = useState<boolean>(
		isDropMenuOpenByDefault || false,
	);

	useEffect(() => {
		setLocalizedColumns(localizeColumns(columns, intl));
	}, [columns, intl]);

	const toggleAdvancedFieldsMenu = useCallback(() => {
		const analyticsEvent = createAnalyticsEvent({ action: 'clicked', actionSubject: 'button' });
		fireUIAnalytics(analyticsEvent, 'fieldsMenu', { viewMode, trigger: id });

		setIsAdvancedFieldsMenuOpen(!isAdvancedFieldsMenuOpen);
	}, [createAnalyticsEvent, id, viewMode, isAdvancedFieldsMenuOpen]);

	const openDropMenu = useCallback(() => {
		const analyticsEvent = createAnalyticsEvent({ action: 'clicked', actionSubject: 'button' });
		fireUIAnalytics(analyticsEvent, 'fieldsMenu', { viewMode, trigger: id });

		setIsAdvancedFieldsMenuOpen(true);
	}, [createAnalyticsEvent, id, viewMode]);

	const updateRef = useRef<Function>();

	useEffect(() => {
		updateRef.current?.();
	}, [columns, updateRef]);

	const planId = getPlanId();
	const scenarioId = getScenarioId();

	const getSearchFieldOptions = (options: LocalizedIssueColumn[]): SearchFieldOption[] => {
		const listableColumns = getListableColumns(options);
		return R.sortBy((option) => isDefined(option) && option[0].localizedTitle, listableColumns).map(
			(group) => {
				const localizedTitle = group && group[0] ? group[0].localizedTitle : '';
				// eslint-disable-next-line @typescript-eslint/no-shadow
				const columns = R.sortBy(R.prop('id'), group);
				const compoundId = columns.map(R.prop('id')).join(':');
				const configurableDate = getMatchingConfiguredDate(compoundId);

				return {
					value: group,
					label: localizedTitle,
					isCustomField: group.find((e) => e.isCustomField),
					configurableDate,
				};
			},
		);
	};

	const renderCustomFieldLozenge = () => (
		<span
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={styles['afm-custom-field-lozenge']}
			data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.advanced-fields-menu.custom-lozenge"
		>
			<Lozenge>{intl.formatMessage(messages.advancedFieldsMenuCustomFieldLozenge)}</Lozenge>
		</span>
	);

	const renderOptionLabel = (option: OptionType) => {
		const { configurableDate, label, isCustomField, value = [] } = option;
		const tooltip = getConfiguredDateTooltip(configurableDate);

		return (
			<div
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={styles['afm-field-option']}
				data-testid={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.advanced-fields-menu.option-${
					value[0] && value[0].id
				}`}
			>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles['afm-field-option-title']}>
					<TitleWithIcon
						icon={
							configurableDate && (
								<Lozenge
									testId={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.advanced-fields-menu.option-${
										value[0] && value[0].id
									}.configurable-date-lozenge`}
								>
									{formatMessage(messages.dateLozenge)}
								</Lozenge>
							)
						}
						tooltip={tooltip && intl.formatMessage(tooltip, { title: label })}
						mode="inline"
					>
						{label}
					</TitleWithIcon>
				</div>
				{isCustomField && (
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					<span className={styles['afm-field-option-custom-lozenge']}>
						{renderCustomFieldLozenge()}
					</span>
				)}
			</div>
		);
	};

	const formatOptionLabel = (option: OptionType) => (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles['afm-select-option-label']}>{renderOptionLabel(option)}</div>
	);

	const safeLocalizedColumns = localizedColumns.filter(
		// eslint-disable-next-line @typescript-eslint/no-shadow
		({ id }: LocalizedIssueColumn) => id !== ADD_FIELDS_COLUMN_ID,
	);

	const [selected, unselected] = R.partition(R.prop('isVisible'), safeLocalizedColumns);

	/**
	 * A different message is displayed in the drop menu of the "Choose fields..." drop list when
	 * - there are no fields which match the search criteria, or
	 * - there are no fields because all of them have been selected
	 */
	const noFieldsMessage = intl.formatMessage(
		unselected && unselected.length
			? messages.advancedFieldsMenuNoFieldsMatchCriteria
			: messages.advancedFieldsMenuAllFieldsSelected,
	);

	const onSelectChange = (value: ValueType, _: ActionMeta) => {
		if (value != null) {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-shadow
			(value as SearchFieldOption).value.forEach(({ id, isCustomField, projectId }) => {
				showColumn(id);
				if (isCustomField && projectId) {
					fireUIAnalytics(
						createAnalyticsEvent({
							action: 'changed',
							actionSubject: 'select',
						}),
						'addTmpCustomFieldToTimeline',
					);
				}
			});
		}
	};

	const renderDialogContent = () => (
		<div
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={styles['afm-dropdown-container']}
			data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.advanced-fields-menu.menu"
		>
			<SelectMenuContainer isVisuallyRefreshed={isVisualRefreshEnabled()}>
				<Select
					autoFocus
					isTransparentBackground={false}
					options={getSearchFieldOptions(unselected)}
					menuPlacement="auto"
					value={null}
					onChange={onSelectChange}
					formatOptionLabel={formatOptionLabel}
					placeholder={intl.formatMessage(messages.advancedFieldsMenuSelectPlaceholder)}
					noOptionsMessage={() => noFieldsMessage}
					menuIsOpen={isDropMenuSelectOpenByDefault}
					aria-label={intl.formatMessage(messages.advancedFieldsMenuSelectLabel)}
					{...(fg('plans_field_menu_fix_position') && {
						menuPortalTarget: globalThis.document?.body,
						styles: {
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							menuPortal: (base: any) => ({
								...base,
								zIndex: 9999,
							}),
						},
					})}
				/>
			</SelectMenuContainer>
			<SelectedFields
				selectedColumns={selected}
				moveColumn={moveColumn}
				hideColumn={hideColumn}
				getMatchingConfiguredDate={getMatchingConfiguredDate}
			/>
			{!isReadOnly && planId && scenarioId && (
				<>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<div className={styles['afm-dropdown-menu-divider']} />
					<DropMenuItem
						onClick={() =>
							push(`/jira/plans/${planId}/scenarios/${scenarioId}/settings/custom-fields`)
						}
					>
						{intl.formatMessage(messages.advancedFieldsMenuManageCustomFieldsLink)}
					</DropMenuItem>
				</>
			)}
		</div>
	);

	const renderTriggerButton = (triggerProps?: TriggerProps) => {
		if (renderMenuTrigger !== undefined) {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			return renderMenuTrigger({
				...triggerProps,
				onClick: toggleAdvancedFieldsMenu,
				isSelected: isAdvancedFieldsMenuOpen,
			} as MenuTriggerProps);
		}
		return (
			<StandardButton
				{...triggerProps}
				onClick={toggleAdvancedFieldsMenu}
				appearance="subtle"
				isSelected={isAdvancedFieldsMenuOpen}
				testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.advanced-fields-menu.trigger-menu-button"
				iconAfter={
					<ChevronDownIcon
						testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.advanced-fields-menu.trigger-menu-icon"
						label=""
					/>
				}
			>
				{intl.formatMessage(commonMessages.fields)}
			</StandardButton>
		);
	};

	const renderTrigger = (triggerProps?: TriggerProps) => (
		<Tooltip
			content={
				isShortcutEnabled ? (
					<KeyboardShortcutTooltip
						letter="A"
						message={messages.advancedFieldsMenuAddFieldTooltip}
					/>
				) : (
					intl.formatMessage(messages.advancedFieldsMenuAddFieldTooltip)
				)
			}
			position="bottom"
		>
			{renderTriggerButton(triggerProps)}
		</Tooltip>
	);

	const renderContent = ({ update }: ContentProps) => {
		updateRef.current = update;
		return renderDialogContent();
	};

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles['afm-header-left']}>
			{isShortcutEnabled && <UseShortcuts shortcutKey="KeyA" onKeyDown={openDropMenu} />}
			<Popup
				placement={placement}
				fallbackPlacements={['bottom', 'bottom-end']}
				autoFocus={false}
				isOpen={isAdvancedFieldsMenuOpen}
				onClose={() => setIsAdvancedFieldsMenuOpen(false)}
				popupComponent={fg('plans_field_menu_fix_position') ? undefined : CustomPopupContainer}
				trigger={renderTrigger}
				content={renderContent}
				shouldUseCaptureOnOutsideClick={!fg('plans_field_menu_fix_position')}
			/>
		</div>
	);
};

export default AdvancedFieldsMenu;
