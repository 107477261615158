import React, { Component } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, { ModalHeader, ModalTitle, ModalBody } from '@atlaskit/modal-dialog'; // ignore-for-ENGHEALTH-17759
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { injectIntl } from '@atlassian/jira-intl';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import { MODAL_DIALOG_WIDTH, SET_CP_RELEASE } from '../../types.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

// eslint-disable-next-line jira/react/no-class-components
export class SetCPVersionsDialog extends Component<Props> {
	getCurrentValue = () => {
		const {
			selectedIssues,
			intl: { formatMessage },
		} = this.props;
		const isNoneValue = selectedIssues.every(
			(issue) => !issue.fixVersions || !issue.fixVersions.length,
		);

		if (isNoneValue) {
			return formatMessage(commonMessages.none);
		}

		return formatMessage(messages.variousValues);
	};

	getOptions = () => {
		const { cpVersionsForSelectedIssues, crossProjectVersionsById } = this.props;
		return cpVersionsForSelectedIssues.map((cprId) => {
			const { id, name } = crossProjectVersionsById[cprId];
			return {
				label: name,
				value: id,
			};
		});
	};

	render() {
		const {
			intl: { formatMessage },
			toggleModalDialog,
			bulkIssuesCRPUpdate,
			bulkUnsetVersion,
			UpdateAttributeForm,
			bulkActionSuccess,
		} = this.props;
		return (
			<ShortcutScope>
				<ModalDialog
					autoFocus
					shouldScrollInViewport
					onClose={toggleModalDialog}
					width={MODAL_DIALOG_WIDTH}
				>
					<ModalHeader>
						<ModalTitle>{formatMessage(messages.setReleaseHeader)}</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<UpdateAttributeForm
							updatedAttributeType={SET_CP_RELEASE}
							options={this.getOptions()}
							currentValue={this.getCurrentValue()}
							onCancel={toggleModalDialog}
							onSubmit={(
								{ crossProjectVersion }: { [key: string]: string },
								analyticsEvent: UIAnalyticsEvent,
							) => {
								if (crossProjectVersion) {
									bulkIssuesCRPUpdate(crossProjectVersion);
								} else {
									bulkUnsetVersion();
								}
								toggleModalDialog();
								bulkActionSuccess(analyticsEvent);
							}}
						/>
					</ModalBody>
				</ModalDialog>
			</ShortcutScope>
		);
	}
}

export default injectIntl(SetCPVersionsDialog);
