import React from 'react';
import Avatar from '@atlaskit/avatar';
import Heading from '@atlaskit/heading';
import { Anchor, Box, xcss } from '@atlaskit/primitives';
import type { AssociatedIssue } from '@atlassian/jira-portfolio-3-associated-issues/src/common/types.tsx';
import { IssueCardPinnedFields } from '../issue-card-pinned-fields/src/ui/index.tsx';

type Props = {
	data: AssociatedIssue;
	projectId: string;
	issueKey: string;
};

export const IssueCardContent = ({ data, projectId, issueKey }: Props) => (
	<Box>
		<Box xcss={ideaContentCardTitle}>
			<Box xcss={ideaContentCardAvatar}>
				<Avatar src={data.iconUrl} size="xsmall" />
			</Box>
			<Anchor href={`/browse/${data.key}`} target="_blank" xcss={anchorStyles}>
				{data.key}
			</Anchor>
		</Box>
		<Heading as="div" size="xsmall">
			{data.summary}
		</Heading>

		<IssueCardPinnedFields projectId={projectId} issueKey={issueKey} />
	</Box>
);

const ideaContentCardTitle = xcss({
	display: 'flex',
	marginBottom: 'space.150',
});

const ideaContentCardAvatar = xcss({
	marginRight: 'space.100',
});

const anchorStyles = xcss({
	textDecoration: 'none',

	':hover': {
		textDecoration: 'underline',
	},
});
