import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	externalSource: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.add-issue.nested-menu.sprint-options.sprint-dialog-content.external-source',
		defaultMessage: 'External',
		description: 'Title for an external issue source',
	},
	sprintSearchPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.add-issue.nested-menu.sprint-options.sprint-dialog-content.sprint-search-placeholder',
		defaultMessage: 'Find sprints...',
		description: 'This is a placeholder for search',
	},
	sprintTooltip: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.add-issue.nested-menu.sprint-options.sprint-dialog-content.sprint-tooltip',
		defaultMessage: "You can't create issues for external and completed sprints.",
		description:
			'Tooltip for sprint option being disabled because it is either already completed, or comes from an external source',
	},
	sprintTooltipIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.add-issue.nested-menu.sprint-options.sprint-dialog-content.sprint-tooltip-issue-term-refresh',
		defaultMessage: "You can't create work items for external and completed sprints.",
		description:
			'Tooltip for sprint option being disabled because it is either already completed, or comes from an external source',
	},
});
