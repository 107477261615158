import React, { useMemo } from 'react';
import { Inline, xcss } from '@atlaskit/primitives';
import { getAppliedDecoration } from '@atlassian/jira-polaris-component-decorations/src/utils/index.tsx';
import type { ValueDecoration } from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import { CommonDecoratedTag } from '@atlassian/jira-polaris-lib-decoration/src/ui/decoration/common/index.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import type { CommonFieldComponentProps } from '../../common/types.tsx';
import { useFieldValueDecorations } from '../../controllers/fields/index.tsx';

export const isLabelsFieldComponentProps = (
	props: CommonFieldComponentProps,
): props is LabelsFieldComponentProps =>
	'value' in props && Array.isArray(props.value) && props.value.every((v) => typeof v === 'string');

export type LabelsFieldComponentProps = CommonFieldComponentProps & {
	value: string[];
};

export const LabelsField = ({ fieldKey, value, isMultiline }: LabelsFieldComponentProps) => {
	const [fieldValueDecorations] = useFieldValueDecorations(fieldKey);

	const tags = useMemo(
		() =>
			value
				.map((v) => {
					const decoration = getAppliedDecoration(fieldValueDecorations, `${v}`);
					return { decoration, value: v };
				})
				.filter(Boolean),
		[fieldValueDecorations, value],
	);

	return <LabelsFieldDisplay tags={tags} isMultiline={isMultiline ?? false} />;
};

type Tag = {
	decoration: ValueDecoration | undefined;
	value: string;
};

export const LabelsFieldDisplay = ({
	tags,
	isMultiline,
}: {
	tags: Tag[];
	isMultiline: boolean;
}) => {
	return (
		<Inline xcss={isMultiline ? multilineStyles : maxContentStyles}>
			{tags.map((tag) => (
				<LabelsFieldTagDisplay key={tag.value} value={tag.value} decoration={tag.decoration} />
			))}
		</Inline>
	);
};

const LabelsFieldTagDisplay = ({
	value,
	decoration,
}: {
	value: string;
	decoration: ValueDecoration | undefined;
}) => {
	const emojiDescription = useEmoji(decoration?.emoji);
	return (
		<CommonDecoratedTag
			value={value}
			mainColor={decoration?.backgroundColor}
			emoji={emojiDescription}
		/>
	);
};

const maxContentStyles = xcss({
	width: 'max-content',
});

const multilineStyles = xcss({
	flexWrap: 'wrap',
});
