import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	removedFromActiveSprintHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.update-jira.removed-from-active-sprint-header',
		defaultMessage: 'This issue has been removed from an active sprint',
		description: 'Message to show in the header when issue was removed from the active sprint',
	},
	removedFromActiveSprintMessage: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.update-jira.removed-from-active-sprint-message',
		defaultMessage:
			"Saving this change will remove the issue from the scope of a sprint that's in progress.",
		description: 'Message to show in the content when issue was removed from the active sprint',
	},
	addedToActiveSprintHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.update-jira.added-to-active-sprint-header',
		defaultMessage: 'This issue will be added to an active sprint',
		description: 'Message to show in the header when issue was added to the active sprint',
	},
	addedToActiveSprintMessage: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.update-jira.added-to-active-sprint-message',
		defaultMessage: "Saving this change will add the issue to a sprint that's already in progress.",
		description: 'Message to show in the content when issue was added to the active sprint',
	},
	removedFromActiveSprintHeaderIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.update-jira.removed-from-active-sprint-header-issue-term-refresh',
		defaultMessage: 'This work item has been removed from an active sprint',
		description: 'Message to show in the header when issue was removed from the active sprint',
	},
	removedFromActiveSprintMessageIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.update-jira.removed-from-active-sprint-message-issue-term-refresh',
		defaultMessage:
			"Saving this change will remove the work item from the scope of a sprint that's in progress.",
		description: 'Message to show in the content when issue was removed from the active sprint',
	},
	addedToActiveSprintHeaderIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.update-jira.added-to-active-sprint-header-issue-term-refresh',
		defaultMessage: 'This work item will be added to an active sprint',
		description: 'Message to show in the header when issue was added to the active sprint',
	},
	addedToActiveSprintMessageIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.update-jira.added-to-active-sprint-message-issue-term-refresh',
		defaultMessage:
			"Saving this change will add the work item to a sprint that's already in progress.",
		description: 'Message to show in the content when issue was added to the active sprint',
	},
});
