import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	sumStoryPointsTooltip: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.estimate-cell.sum-story-points-tooltip',
		defaultMessage: 'Sum of all set story points: {value}',
		description:
			'Label shown in the tooltip when hover the Story Points field in the group header row',
	},
	sumTimeEstimateTooltip: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.estimate-cell.sum-time-estimate-tooltip',
		defaultMessage: 'Sum of all set time estimates: {value}',
		description:
			'Label shown in the tooltip when hover the Time Estimate field in the group header row',
	},
});
