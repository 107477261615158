/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';

export type EmojiWrapperProps = {
	children: React.ReactNode;
	standalone?: boolean;
	color?: string;
	className?: String;
};

export const EmojiWrapper = ({ standalone, color, children, className }: EmojiWrapperProps) => {
	return (
		<div
			css={[
				wrapperStyles,
				standalone && borderRadiusStandalone,
				!standalone && borderRadiusNonStandalone,
			]}
			// eslint-disable-next-line jira/react/no-style-attribute
			style={{
				backgroundColor: color,
			}}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop
			className={className}
		>
			{children}
		</div>
	);
};

const wrapperStyles = css({
	overflow: 'hidden',
	gridArea: 'icon',
	width: '24px',
	height: '22px',
	boxSizing: 'border-box',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});

const borderRadiusStandalone = css({
	borderRadius: token('border.radius.100', '3px'),
});

const borderRadiusNonStandalone = css({
	borderRadius: `${token('border.radius.100', '3px')} 0 0 ${token('border.radius.100', '3px')}`,
});
