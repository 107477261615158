import * as R from 'ramda';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import { GROUPING } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { isScenarioIssue } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/utils/issue.tsx';
import type { Issue } from '../../state/domain/issues/types.tsx';
import type { Sprint } from '../../state/domain/sprints/types.tsx';
import type { Team } from '../../state/domain/teams/types.tsx';
import { ALL_OTHER_ISSUES } from '../../state/domain/view-settings/component-groups/types.tsx';
import type { GroupingExpandedItem } from '../../state/domain/view-settings/visualisations/types.tsx';
import type { View } from '../../state/domain/views/types.tsx';
import type { State } from '../../state/types.tsx';
import type { Error } from '../../state/ui/top/view-bar/types.tsx';
import type { IssueMap } from '../issues/types.tsx';
import { getProjectsById } from '../projects/index.tsx';
import type { ProjectsById } from '../projects/types.tsx';
import { getIssueMapById, getAllIssues } from '../raw-issues/index.tsx';
import { getDescendantsByParent } from '../raw-issues/issues-tree.tsx';
import { getSprintByIdMap } from '../sprints/index.tsx';
import { getTeamsById } from '../teams/index.tsx';
import { getVersionsById } from '../versions/index.tsx';
import type { VersionsById } from '../versions/types.tsx';
import {
	getLabelGroupsById,
	getComponentGroupsById,
	type LabelGroupsById,
	type ComponentGroupsById,
} from '../view-settings/index.tsx';

export const getViews = (state: State): View[] => state.domain.views || [];
export const getErrors = (state: State): Error[] => state.ui.Top.ViewBar.errors;
export const getSaveViewAsDialogStatus = (state: State) =>
	state.ui.Top.ViewBar.saveViewAsDialogStatus;

export const getActiveView = createSelector(
	[getViews],
	(views) => views.find(R.prop('active')) || views[0],
);

export const getActiveViewId = createSelector([getActiveView], (view) => view?.id || 0);

export const getNamespaceForView = (id: number) => `view-${id}`;

export const getPrunedActiveViewPure = (
	activeView: View,
	issuesById: IssueMap,
	issues: Issue[],
	projectsById: ProjectsById,
	versionsById: VersionsById,
	teamsById: {
		[key: string]: Team;
	},
	sprintsById: {
		[key: string]: Sprint;
	},
	labelGroupsById: LabelGroupsById,
	componentGroupsById: ComponentGroupsById,
	descendantsByParent: {
		[key: string]: Issue[];
	},
) => {
	const { preferences } = activeView;
	const expandedState = preferences.issueExpansionsV0.isExpanded;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	let prunedExpandedState = Object.entries(expandedState).reduce<Record<string, any>>(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(acc, [key, value]: [any, any]) => {
			const issueId = key.split(':')[0];
			if (Number(issueId) || isScenarioIssue(issueId)) {
				// if expanded state is stored for issues which are no longer in the plan OR for issues without children, then remove them
				if (!issuesById[issueId] || (descendantsByParent[issueId] || []).length === 0) {
					return acc;
				}
			}
			acc[key] = value;
			return acc;
		},
		{},
	);

	// remove expansion state for group by components stored in issue expansion slice
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	prunedExpandedState = Object.entries(prunedExpandedState).reduce<Record<string, any>>(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(acc, [key, value]: [any, any]) => {
			if (key.includes(':components-')) {
				const componentGroupName = key.split('-')[1];
				if (!componentGroupsById[componentGroupName]) {
					return acc;
				}
			}

			acc[key] = value;
			return acc;
		},
		{},
	);

	preferences.issueExpansionsV0.isExpanded = prunedExpandedState;

	const groupingExpandedItems = preferences.visualisationsV1.expandedItems;

	if (!groupingExpandedItems) {
		return activeView;
	}

	if (groupingExpandedItems[GROUPING.ASSIGNEE]) {
		const uniqueAssignees = new Set();
		issues.forEach((issue) => {
			uniqueAssignees.add(issue.assignee);
		});

		const prunedAssigneeGrouping = Object.entries(
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			groupingExpandedItems[GROUPING.ASSIGNEE] as GroupingExpandedItem,
		) // eslint-disable-next-line @typescript-eslint/no-explicit-any
			.reduce<Record<string, any>>((acc, [key, value]: [any, any]) => {
				if (key === 'assignee-unassigned' || uniqueAssignees.has(key.split('-')[1])) {
					acc[key] = value;
				}

				return acc;
			}, {});

		groupingExpandedItems[GROUPING.ASSIGNEE] = prunedAssigneeGrouping;
	}

	if (groupingExpandedItems[GROUPING.PROJECT]) {
		const prunedProjectGrouping = Object.entries(
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			groupingExpandedItems[GROUPING.PROJECT] as GroupingExpandedItem,
		) // eslint-disable-next-line @typescript-eslint/no-explicit-any
			.reduce<Record<string, any>>((acc, [key, value]: [any, any]) => {
				if (projectsById[key.split('-')[1]]) {
					acc[key] = value;
				}
				return acc;
			}, {});
		groupingExpandedItems[GROUPING.PROJECT] = prunedProjectGrouping;
	}

	if (groupingExpandedItems[GROUPING.RELEASE]) {
		const prunedReleaseGrouping = Object.entries(
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			groupingExpandedItems[GROUPING.RELEASE] as GroupingExpandedItem,
		) // eslint-disable-next-line @typescript-eslint/no-explicit-any
			.reduce<Record<string, any>>((acc, [key, value]: [any, any]) => {
				if (key === 'UNDEFINED' || versionsById[key.split('-')[1]]) {
					acc[key] = value;
				}
				return acc;
			}, {});
		groupingExpandedItems[GROUPING.RELEASE] = prunedReleaseGrouping;
	}

	if (groupingExpandedItems[GROUPING.TEAM]) {
		const prunedTeamGrouping = Object.entries(
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			groupingExpandedItems[GROUPING.TEAM] as GroupingExpandedItem,
		) // eslint-disable-next-line @typescript-eslint/no-explicit-any
			.reduce<Record<string, any>>((acc, [key, value]: [any, any]) => {
				if (key === 'UNDEFINED' || teamsById[key.split('-')[1]]) {
					acc[key] = value;
				}
				return acc;
			}, {});
		groupingExpandedItems[GROUPING.TEAM] = prunedTeamGrouping;
	}

	if (groupingExpandedItems[GROUPING.SPRINT]) {
		const prunedSprintGrouping = Object.entries(
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			groupingExpandedItems[GROUPING.SPRINT] as GroupingExpandedItem,
		) // eslint-disable-next-line @typescript-eslint/no-explicit-any
			.reduce<Record<string, any>>((acc, [key, value]: [any, any]) => {
				if (key === 'UNDEFINED' || sprintsById[key.split('-')[1]]) {
					acc[key] = value;
				}
				return acc;
			}, {});
		groupingExpandedItems[GROUPING.SPRINT] = prunedSprintGrouping;
	}

	if (groupingExpandedItems[GROUPING.LABEL]) {
		const prunedLabelGrouping = Object.entries(
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			groupingExpandedItems[GROUPING.LABEL] as GroupingExpandedItem,
		) // eslint-disable-next-line @typescript-eslint/no-explicit-any
			.reduce<Record<string, any>>((acc, [key, value]: [any, any]) => {
				if (key === `labels-${ALL_OTHER_ISSUES}` || labelGroupsById[key.split('-')[1]]) {
					acc[key] = value;
				}
				return acc;
			}, {});
		groupingExpandedItems[GROUPING.LABEL] = prunedLabelGrouping;
	}

	if (groupingExpandedItems[GROUPING.COMPONENT]) {
		const prunedComponentGrouping = Object.entries(
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			groupingExpandedItems[GROUPING.COMPONENT] as GroupingExpandedItem,
		) // eslint-disable-next-line @typescript-eslint/no-explicit-any
			.reduce<Record<string, any>>((acc, [key, value]: [any, any]) => {
				if (key === `components-${ALL_OTHER_ISSUES}` || componentGroupsById[key.split('-')[1]]) {
					acc[key] = value;
				}
				return acc;
			}, {});
		groupingExpandedItems[GROUPING.COMPONENT] = prunedComponentGrouping;
	}

	return activeView;
};

export const getPrunedActiveView = createSelector(
	[
		getActiveView,
		getIssueMapById,
		getAllIssues,
		getProjectsById,
		getVersionsById,
		getTeamsById,
		getSprintByIdMap,
		getLabelGroupsById,
		getComponentGroupsById,
		getDescendantsByParent,
	],
	getPrunedActiveViewPure,
);

// The sections component uses namespace to differentiate it's state for different views. This selector provides the
// namespace based on the active view. Defaults to a default namespace when there is no active view.
export const getNamespaceFromActiveView = createSelector([getViews], (views) => {
	const activeView = views.find(R.prop('active'));
	return (activeView && getNamespaceForView(activeView.id)) || 'default-view';
});

export const getDefaultView = createSelector([getViews], (views) =>
	views.find(R.prop('isDefault')),
);

export const getViewById = (state: State, viewId: number) => {
	const views = getViews(state);
	return views.find(R.propEq('id', viewId));
};

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default as getPresetPreferences } from './presets';
