import React from 'react';
import Avatar, { AvatarItem } from '@atlaskit/avatar';
import { xcss, Box } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import type { User } from '@atlassian/jira-shared-types/src/rest/jira/user.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { CommonFieldComponentProps } from '../../common/types.tsx';
import { useFieldName } from '../../controllers/fields/index.tsx';

export const isUserFieldComponentProps = (
	props: CommonFieldComponentProps,
): props is UserFieldComponentProps =>
	'users' in props && 'value' in props && typeof props.value === 'string';

export type UserFieldComponentProps = CommonFieldComponentProps & {
	value: string;
	users: { [key: string]: User };
	isInsideTooltip?: boolean;
};

export const UserField = ({ fieldKey, value, users, isInsideTooltip }: UserFieldComponentProps) => {
	const [fieldName] = useFieldName(fieldKey);
	const user = users[value];

	return <UserFieldDisplay fieldName={fieldName} user={user} isInsideTooltip={isInsideTooltip} />;
};

const UserFieldDisplayAvatar = ({ user }: { user: User }) => (
	<AvatarItem
		avatar={
			<Avatar
				appearance="circle"
				src={user.avatarUrls['48x48']}
				size="small"
				name={user.displayName}
			/>
		}
		backgroundColor="transparent"
		primaryText={user.displayName}
	/>
);

type UserFieldDisplayProps = {
	fieldName: string;
	user: User;
	isInsideTooltip?: boolean;
};

export const UserFieldDisplay = ({ fieldName, user, isInsideTooltip }: UserFieldDisplayProps) => (
	<Box xcss={maxContentStyles}>
		{isInsideTooltip && fg('polaris-pinned-fields-for-ideas-in-plans') ? (
			<UserFieldDisplayAvatar user={user} />
		) : (
			<Tooltip content={`${fieldName}: ${user.displayName}`}>
				<UserFieldDisplayAvatar user={user} />
			</Tooltip>
		)}
	</Box>
);

const maxContentStyles = xcss({
	width: 'max-content',
});
