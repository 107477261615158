import React, { Component } from 'react';
import * as R from 'ramda';
import { injectIntl, FormattedMessage } from '@atlassian/jira-intl';
import Checkbox from '@atlassian/jira-portfolio-3-common/src/checkbox/index.tsx';
import {
	DialogMenuContainer,
	DialogMenuItem,
} from '@atlassian/jira-portfolio-3-common/src/inline-dialog/dialog-menu/index.tsx';
import InlineDialog from '@atlassian/jira-portfolio-3-common/src/inline-dialog/index.tsx';
import { NO_CUSTOM_FIELD_VALUE_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/custom-field-filter/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import EllipsedWithTooltip from '@atlassian/jira-portfolio-3-portfolio/src/common/view/ellipsed-with-tooltip/index.tsx';
import SearchField from '@atlassian/jira-portfolio-3-portfolio/src/common/view/search-field/index.tsx';
import ClearFilterButton from '../../common/clear-filter/index.tsx';
import FilterText from '../../common/filter-text/index.tsx';
import { FILTER_MAX_WIDTH, FILTER_WIDTH } from '../../common/index.tsx';
import TriggerButton from '../../common/trigger-button/index.tsx';
import filterMessages from '../../messages.tsx';
import messages from '../messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from '../styles.module.css';
import type { PropsOld } from './types.tsx';

// eslint-disable-next-line jira/react/no-class-components
class MultiSelect extends Component<PropsOld, State> {
	constructFilterText = () => {
		const { intl, options, value } = this.props;
		const noValueOption = {
			id: NO_CUSTOM_FIELD_VALUE_ID,
			value: intl.formatMessage(messages.noValue),
		};

		const uniqueLabels = [noValueOption].concat(options).reduce((acc, option) => {
			if (value.includes(`${option.id}`)) {
				return acc.add(option.value);
			}
			return acc;
		}, new Set<string>());

		return [...uniqueLabels].join(', ');
	};

	renderFilterText = () => {
		const { value } = this.props;

		return value.length ? (
			<FilterText text={this.constructFilterText()} />
		) : (
			<FormattedMessage {...messages.emptyPlaceholder} />
		);
	};

	ariaText = () => {
		const { value, intl, customField } = this.props;
		const filterText = this.constructFilterText();

		return `${customField.title}, ${
			value.length > 0 ? filterText : intl.formatMessage(messages.emptyPlaceholder)
		} ${intl.formatMessage(filterMessages.selected)}`;
	};

	isOptionSelectedNew = (optionValue: string) => {
		const { value, options } = this.props;

		if (optionValue === NO_CUSTOM_FIELD_VALUE_ID) {
			return R.includes(optionValue, value);
		}
		const matchingOptionIds = options
			.filter((option) => option.value === optionValue)
			.map((option) => option.id.toString());
		// eg if id=1, value='red', id=2, value='red', id=3, value='blue' and value=[2,3] is saved in the filter
		return R.intersection(matchingOptionIds, value).length > 0;
	};

	isOptionSelected = (optionId: string) => R.contains(optionId, this.props.value);

	handleOptionClickNew = (optionValue: string): void => {
		const { onChange, value, options } = this.props;

		const matchingOptionIds =
			optionValue === NO_CUSTOM_FIELD_VALUE_ID
				? [NO_CUSTOM_FIELD_VALUE_ID]
				: options
						.filter((option) => option.value === optionValue)
						.map((option) => option.id.toString());

		// if option is already selected, remove it
		const isSelected = value.some((opt) => matchingOptionIds.includes(opt));
		if (isSelected) {
			// by sending the set without these options
			onChange(R.difference(value, matchingOptionIds));
		} else {
			// otherwise include all the matching ids
			onChange(R.union(matchingOptionIds, value));
		}
	};

	handleOptionClick = (optionId: string): void => {
		const { onChange, value } = this.props;
		const filtered = value.filter((opt) => opt !== optionId);
		if (this.isOptionSelected(optionId)) {
			onChange(filtered);
		} else {
			onChange([...filtered, optionId]);
		}
	};

	renderOptions = () => {
		const { intl, options } = this.props;
		const filteredOptions = R.sortBy(
			R.prop('value'),
			options.filter((option) =>
				option.value.toLowerCase().includes(this.props.searchQuery.toLowerCase()),
			),
		);

		/**
		 * it's possible to have multiple options defined with the same name (value) when there are
		 * multiple contexts defined for the field.
		 * We don't want to show all these as the are indistinguishable duplicates to the user.
		 * Instead, just select based on the value and later re-map this to all the matching ids to
		 * perform the issue filtering using the ids.
		 */
		const dedupedOptions = R.uniqBy(R.prop('value'), filteredOptions);

		return (
			<>
				{options.length ? (
					<DialogMenuItem key={NO_CUSTOM_FIELD_VALUE_ID}>
						<Checkbox
							id={`${NO_CUSTOM_FIELD_VALUE_ID}`}
							key={NO_CUSTOM_FIELD_VALUE_ID}
							isChecked={this.isOptionSelected(`${NO_CUSTOM_FIELD_VALUE_ID}`)}
							onChange={() => this.handleOptionClick(`${NO_CUSTOM_FIELD_VALUE_ID}`)}
							label={
								// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
								<span className={styles.optionLabel}>{intl.formatMessage(messages.noValue)}</span>
							}
						/>
					</DialogMenuItem>
				) : null}
				{dedupedOptions.map(({ value }) => (
					<DialogMenuItem key={value}>
						<Checkbox
							id={`${value}`}
							key={value}
							isChecked={this.isOptionSelectedNew(`${value}`)}
							onChange={() => this.handleOptionClickNew(`${value}`)}
							label={
								<EllipsedWithTooltip id={value} content={value}>
									{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
									<span className={styles.optionLabel}>{value}</span>
								</EllipsedWithTooltip>
							}
						/>
					</DialogMenuItem>
				))}
				{!filteredOptions.length && (
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					<div className={styles.noMatchFoundText}>
						<FormattedMessage {...messages.noMatchFoundText} />
					</div>
				)}
			</>
		);
	};

	render() {
		const {
			customField,
			intl,
			isOpen,
			onOpenChange,
			onQueryChange,
			onRequestClear,
			searchQuery,
			value,
		} = this.props;
		return (
			<InlineDialog
				noPaddings
				maxWidth={FILTER_MAX_WIDTH}
				minWidth={FILTER_WIDTH}
				onClose={onOpenChange}
				isOpen={isOpen}
				content={
					<DialogMenuContainer>
						<ClearFilterButton isVisible={!!value.length} onClearClick={onRequestClear} />
						<SearchField
							placeholder={intl.formatMessage(messages.searchPlaceholder, {
								fieldName: customField.title,
							})}
							searchQuery={searchQuery}
							onQueryChange={onQueryChange}
						/>
						{this.renderOptions()}
					</DialogMenuContainer>
				}
			>
				<TriggerButton
					isOpen={isOpen}
					onOpenChange={onOpenChange}
					triggerButtonText={this.renderFilterText()}
					testId={`portfolio-3-portfolio.app-simple-plans.top.filter-bar.custom-field-filter.multi-select.trigger-btn.${customField.id}`}
					ariaLabel={this.ariaText()}
				/>
			</InlineDialog>
		);
	}
}

export default injectIntl(MultiSelect);
