import React, { useCallback } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { getAppliedNumberDecoration } from '@atlassian/jira-polaris-component-decorations/src/utils/index.tsx';
import { N0, G300 } from '@atlassian/jira-polaris-lib-color-palette/src/ui/colors/index.tsx';
import { getPaletteEntryForMainColor } from '@atlassian/jira-polaris-lib-color-palette/src/ui/index.tsx';
import { Rating } from '@atlassian/jira-polaris-lib-rating/src/ui/index.tsx';
import type { CommonFieldComponentProps } from '../../common/types.tsx';
import { useFieldValueDecorations } from '../../controllers/fields/index.tsx';

interface DecoratedRatingProps {
	value: number | undefined;
	getColorForValue: (value: number | undefined) => string | undefined;
	mainColor?: string | undefined;
	onChange?: (arg1: number | undefined) => void;
}

export const isRatingFieldComponentProps = (
	props: CommonFieldComponentProps,
): props is RatingFieldComponentProps =>
	'value' in props && (typeof props.value === 'number' || props.value === null);

export type RatingFieldComponentProps = CommonFieldComponentProps & {
	value: number | null;
	isInsideTooltip?: boolean;
};

export const RatingField = ({ fieldKey, value, isInsideTooltip }: RatingFieldComponentProps) => {
	const [fieldValueDecorations] = useFieldValueDecorations(fieldKey);

	const getColorForValue = useCallback<DecoratedRatingProps['getColorForValue']>(
		(dynamicValue) => {
			const numberDecoration = getAppliedNumberDecoration(fieldValueDecorations, dynamicValue);
			const { mainColor } = getPaletteEntryForMainColor(numberDecoration?.backgroundColor);
			// G300 is a default color for rating elements
			return mainColor === N0 ? G300 : mainColor;
		},
		[fieldValueDecorations],
	);

	return (
		<RatingFieldDisplay
			value={value ?? 0}
			getColorForValue={getColorForValue}
			isInsideTooltip={isInsideTooltip}
		/>
	);
};

type RatingFieldDisplayProps = {
	value: number;
	getColorForValue: (value: number | undefined) => string | undefined;
	isInsideTooltip?: boolean;
};

export const RatingFieldDisplay = ({
	value,
	getColorForValue,
	isInsideTooltip,
}: RatingFieldDisplayProps) => (
	<Box xcss={maxContentStyles}>
		<Rating
			value={value ?? 0}
			getColorForValue={getColorForValue}
			isInsideTooltip={isInsideTooltip}
		/>
	</Box>
);

const maxContentStyles = xcss({
	width: 'max-content',
});
